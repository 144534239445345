export const USER_ROLE = {
  OWNER: 'owner',
  WRITE: 'write',
};

export const ERROR_MESSAGE_KEY = {
  EMAIL_ALREADY_REGISTERED: 'EMAIL_ALREADY_REGISTERED',
  INVALID_EMAIL: 'INVALID_EMAIL',
  RATE_LIMIT: 'errors.rateLimited',
};

export const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;

export const CURRENT_URL_RULE_SHOP_TYPE_BASED_PAGE_TYPES = ['product', 'category'];

export const ESSENTIAL_FALLBACK_ID = '65d4e58e54c6bc5ba00a94e5';
