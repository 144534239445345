<template lang="pug">
div
  Dropdown#fallback-action.mt-3(
    :label="$t('ifCouponLimitReached')"
    :items="fallbackActions"
    v-model="fallbackAction"
    customClass="full-width"
  )
  template(v-if="fallbackAction === 'text'")
    .pb-2
      OmInput#fallback-coupon(:label="$t('text')" v-model="fallbackCoupon" small)
  .sidebar-input-wrapper.flex-row-baseline.flex-column.align-items-start.pt-0
    .sidebar-input-wrapper.sidebar-input-wrapper-label.px-0 {{ $t('availableCoupons') }}: ({{ couponCounts.available }} / {{ allCouponCount }})
    .sidebar-input-wrapper.sidebar-input-wrapper-label.px-0(v-if="couponCounts.reserved") {{ $t('pendingCoupons') }}: {{ couponCounts.reserved }}
    .d-flex.flex-row.align-items-center.w-100
      .unique-progress.progress.flex-grow-1
        .progress-bar(role="progressbar" :style="progressBarStyle")
    .pt-2.w-100
      OmButton#edit-coupons.mt-3(primary small block @click="showCouponSettings") {{ $t('editCoupons') }}
</template>
<script>
  import { mapActions, mapState } from 'vuex';
  import itemMixin from '@/editor/mixins/item';
  import sharedMixin from '@/components/Editor/Blocks/Coupon/shared';
  import Dropdown from '../../Dropdown/Dropdown.vue';

  export default {
    components: {
      Dropdown,
    },
    mixins: [itemMixin, sharedMixin],
    data() {
      return {
        fallbackActions: [
          { text: this.$t('stopCampaign'), value: 'hide' },
          { text: this.$t('showText'), value: 'text' },
        ],
      };
    },
    computed: {
      ...mapState(['showFormManager', 'couponCounts']),
      fallbackAction: {
        get() {
          return this.getValueOf('data.coupon.fallbackAction');
        },
        set(value) {
          this.setValueOf('data.coupon.fallbackAction', value);
        },
      },
      fallbackCoupon: {
        get() {
          return this.getValueOf('data.coupon.fallbackCoupon');
        },
        set(value) {
          this.setValueOf('data.coupon.fallbackCoupon', value);
        },
      },
      allCouponCount() {
        const { available, shown, reserved } = this.couponCounts;
        return available + shown + reserved;
      },
      progressBarStyle() {
        const percentage = (this.couponCounts.available / this.allCouponCount) * 100;
        return {
          width: `${percentage}%`,
        };
      },
    },
    watch: {
      'showFormManager.couponSettings': function (v) {
        if (v === false) {
          this.fetchCouponCounts();
        }
      },
      'coupon.type': function (v) {
        if (v === 'unique') {
          this.fetchCouponCounts();
        }
      },
    },
    mounted() {
      this.fetchCouponCounts();
    },
    methods: {
      ...mapActions(['fetchCouponCounts']),
      showCouponSettings() {
        this.$modal.show('coupon-settings');
      },
    },
  };
</script>
<style lang="sass">
  #edit-coupons
    color: white
  .unique-progress
    height: 12px
    .progress-bar
      height: 12px
</style>
