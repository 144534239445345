<template lang="pug">
.w-100(:class="classes")
  downgrade-info-bar
  .d-flex.justify-content-end.justify-content-md-start.pt-2.pb-5(v-if="showPeriodSelect")
    .d-inline-flex.align-items-center.brand-settings-toggle(
      @click="$emit('setPeriod', selectedPeriod === 12 ? 1 : 12)"
    )
      div(:class="{ selected: selectedPeriod === 12 }")
        span {{ $t('plan.yearly') }}
      div(:class="{ selected: selectedPeriod === 1 }")
        span {{ $t('plan.monthly') }}

  template(v-if="couponDetails && couponDetails.hasValidCoupon")
    .brand-pricing-table-coupon-details-wrapper
      .brand-pricing-table-coupon-details
        span {{ couponDetails.couponDescription }}
        om-tooltip.info-tooltip(
          v-if="couponDetails.couponValidityPeriod"
          theme="dark"
          placement="top-start"
          color="#1A7457"
        )
          span {{ $t('couponDetails.validityPeriod') }}:<br>{{ couponDetails.couponValidityPeriod.start }} - {{ couponDetails.couponValidityPeriod.end }}

  .d-flex.flex-column.flex-md-row.align-items-stretch.justify-content-between.brand-pricing-table(
    v-if="!loadingPlans"
    :class="{ 'brand-pricing-table-modal': isUsedInModal, 'with-recommendation': hasRecommendation }"
  )
    template(v-for="(plan, index) in planListToShow")
      .brand-pricing-table-item.d-flex.flex-column.w-100.replative(
        :key="index"
        v-if="plan.name !== 'MASTER' && (!isUsedInModal || (isUsedInModal && plan.name.toUpperCase() !== 'FREE'))"
        :class="{ 'brand-pricing-table-item-recommended order-0 order-md-1': isRecommended(plan), 'order-1': !isRecommended(plan), 'brand-pricing-table-item-notsufficient': isNotSufficient(plan) }"
      )
        template(v-if="showPoppers")
          .brand-pricing-table-item-notsufficient-pane.d-flex.justify-content-center.align-items-center.font-size-1--25.py-2(
            v-if="isNotSufficient(plan)"
          )
            span.mr-1 {{ $t('notSufficient') }}
            popper(trigger="hover" :options="{ placement: 'left' }")
              .popper.brand-pricing-table-item-popper.brand-pricing-table-item-popper-small.w-20.p-3
                span(
                  v-html="$t(remainingDaysToRunOut(plan) < 1 ? 'planMisc.notSufficientDescriptionPassed' : 'planMisc.notSufficientDescription', { remainingDays: remainingDaysToRunOut(plan) })"
                )
              span(slot="reference")
                i.fas.fa-info-circle.ml-2.align-items-center
          .brand-pricing-table-item-recommended-pane.d-flex.justify-content-center.align-items-center.font-size-1--25.py-2(
            v-if="isRecommended(plan)"
          )
            | {{ $t('recommended') }}
            popper(trigger="hover" :options="{ placement: 'left' }")
              .popper.brand-pricing-table-item-popper.w-20.p-3
                img.w-10(:src="require('@/components/Elements/Monks/svgs/point.svg')")
                h3 {{ $t('recommendedPlan') }}
                span(v-html="displayRecommendationText()")
              div(slot="reference")
                i.fas.fa-info-circle.ml-2.align-items-center

        .brand-pricing-table-item-name.text-center.font-weight-bold.font-size-1--25(
          :style="{ color: getFontColor(plan) }"
        ) {{ plan.displayName }}
          .alt-text
            .alt-text-r1 {{ $t(`altTitles.${plan.group}.row1`) }}
            .alt-text-r2 {{ $t(`altTitles.${plan.group}.row2`) }}

        template(v-if="isStandardFreemium")
          .brand-pricing-table-item-price.text-center.py-3.px-2.price-with-variants(
            :class="{ 'with-more-price': selectedPeriod === 12 || planListService.hasDiscountInPeriod(selectedPeriod) }"
          )
            div
              .brand-pricing-table-item-discounted-price(v-if="hasDiscountForPlan(plan)")
                span.font-weight-bold {{ getPlanOriginalPriceString(plan) }}
                span &nbsp; / {{ $t(selectedPeriodLanguageKey) }}
              span.brand-pricing-table-item-price-value.font-size-2.font-weight-bold {{ getPlanPriceString(plan) }}
              span.brand-pricing-table-item-price-label.ml-2.font-size-0--875 / {{ $t(selectedPeriodLanguageKey) }}
              .brand-pricing-table-item-annual-price(v-if="getCalculatedMonthlyPrice(plan)") {{ getCalculatedMonthlyPrice(plan) }} / {{ $t('month') }}

          template(v-if="getPlanVariantOptions(plan).length > 1")
            .brand-pricing-table-item-variant-select
              om-select(
                :id="`planVariantSelect-${plan.group.toLowerCase()}`"
                :value="getVariantOptionValue(plan)"
                @input="onChangeVariant($event)"
                :options="getPlanVariantOptions(plan)"
              )
          template(v-else)
            .brand-pricing-table-item-variant-select.one-option
              span.font-weight-bold {{ getVariantPvText(plan) }}

        template(v-else)
          .brand-pricing-table-item-price.text-center.py-3.px-2
            div
              span.brand-pricing-table-item-price-value.font-size-2.font-weight-bold {{ getPlanPriceString(plan) }}

        .brand-pricing-table-item-divider(v-if="!isStandardFreemium")

        .brand-pricing-table-item-body.h-100.d-flex.flex-column.py-3(v-if="plan.details")
          .mb-3.brand-pricing-table-item-features.font-size-0--875
            template(v-if="!isStandardFreemium")
              .font-weight-bold(v-if="plan.details.pageViews !== $t('contactUs')")
                fa-icon.mr-2(variant="fa-check")
                | {{ plan.details.pageViews }} {{ $t('planDetailTitles.pageViews') }}*
              .font-weight-bold(v-else)
                fa-icon.mr-2(variant="fa-check")
                | {{ plan.details.pageViews }}
            div
              fa-icon.mr-2(variant="fa-check")
              template(v-if="typeof plan.details.domains === 'string'")
                | {{ plan.details.domains }} {{ $t('planMisc.domains') }}
              template(v-else-if="plan.details.domains > 1")
                | {{ $t('planMisc.max') }} {{ plan.details.domains }} {{ $t('planMisc.domains') }}
              template(v-else)
                | {{ $t('planMisc.max') }} {{ plan.details.domains }} {{ $t('planMisc.domain') }}

              popper(trigger="hover")
                .popper.brand-pricing-table-item-popper.brand-pricing-table-item-popper-small.w-20.p-3
                  span {{ $t('planMisc.sitesDescription') }}
                span(slot="reference")
                  i.fas.fa-info-circle.ml-1.align-items-center
            div
              fa-icon.mr-2(variant="fa-check")
              | {{ $t('planDetailTitles.allFeatures') }}
            div
              fa-icon.mr-2(variant="fa-check")
              | {{ $t('planDetailTitles.unlimitedCampaigns') }}
            .feature-unbranded(:class="{ 'not-available': plan.details.unbranded !== 'yes' }")
              fa-icon.mr-2(:variant="plan.details.unbranded === 'yes' ? 'fa-check' : 'fa-times'")
              | {{ $t('planDetailTitles.unbranded') }}
            div(v-if="plan.details.prioritySupport === 'yes'")
              fa-icon.mr-2(variant="fa-check")
              | {{ $t('planDetailTitles.prioritySupport') }}

          .mt-auto.d-inline-flex.flex-column.align-items-center
            template(v-if="!canCalculateCorrectUpgradePrice")

            template(
              v-else-if="plan.name === getCurrentPlan && !isChangingPeriod && needsShopifyPlanUpdateWithNewPrice"
            )
              .cursor-pointer.brand-btn.brand-btn-primary.brand-btn-sm(
                @click="$emit('shopifyNextBillingUpdate', { plan: currentPlan, period: currentPeriod, type: 'recurring_update', location: 'plan-table' })"
              )
                span {{ $t('newCampaignFlow.needsPlanUpdate.withNewPrice') }}
            template(
              v-else-if="plan.name === getCurrentPlan && !isChangingPeriod && needsOverchargeUpgrade"
            )
              .cursor-pointer.brand-btn.brand-btn-primary.brand-btn-sm(
                @click="$emit('sendShopifyOrder', plan.name, 'usage_update')"
              )
                span {{ $t('newCampaignFlow.upGradeWithOvercharge') }}
            template(v-else)
              template(v-if="isChangingPeriod && !['MASTER', 'FREE'].includes(plan.name)")
                om-button(:primary="true" @click="$emit('setPlan', plan.name)")
                  span {{ $t('newCampaignFlow.choosePlan') }}
              template(v-else)
                .brand-pricing-table-item-current-plan.font-weight-bold(
                  v-if="plan.name === getCurrentPlan"
                  :class="{ 'px-0': $i18n.locale === 'hu' }"
                  :style="{ color: getFontColor(plan) }"
                ) {{ $t('upgradePlanPage.currentPlan').replace(':', '') }}

                om-button(
                  :primary="!isPlanDowngrade(plan.name)"
                  :secondary="isPlanDowngrade(plan.name)"
                  v-if="showPlanSelectButton(plan.name)"
                  @click="$emit('setPlan', plan.name)"
                )
                  span(v-if="isPlanSameAsCurrent(plan.name)") {{ $t('newCampaignFlow.selectCurrent') }}
                  span(v-else-if="isPlanDowngrade(plan.name)") {{ $t('newCampaignFlow.select') }}
                  span(v-else-if="needsOverchargeUpgrade") {{ $t('newCampaignFlow.upGradeAndActivateOvercharge') }}
                  span(v-else) {{ $t('newCampaignFlow.upGrade') }}

  .row.mt-4(style="justify-content: center")
    template(v-if="!canCalculateCorrectUpgradePrice")
      .mb-6 {{ $t('plan.masterEnterprise.contact') }}
        a.brand-link(href="mailto:support@optimonk.com") {{ $t('plan.masterEnterprise.support') }}

  master-contact-us-modal
  .brand-pricing-table-upgrade-info(v-if="hasActiveSubscription") {{ `${$t('planUpgradeInfoBox.title')} ${$t('planUpgradeInfoBox.description')}` }}
  master-plan-info-box(@action="contactUs")
</template>

<script>
  import moment from 'moment';
  import { createNamespacedHelpers, mapGetters } from 'vuex';
  import DowngradeInfoBar from '@/components/DowngradeInfoBar';
  import * as dateCalculator from '@om/payment/src/helpers/dateCalculator';
  import planDetails from '@om/payment/src/helpers/planDetails';
  import { separatePrice } from '@/util';

  const {
    mapMutations: paymentMutations,
    mapGetters: paymentGetters,
    mapState: paymentState,
  } = createNamespacedHelpers('payment');

  export default {
    components: {
      DowngradeInfoBar,
      MasterContactUsModal: () => import('@/components/Modals/MasterContactUs'),
      MasterPlanInfoBox: () => import('@/components/Payment/MasterPlanInfoBox.vue'),
    },

    props: {
      plans: { type: Array, required: true },
      triggers: {
        type: Array,
        default: () => {
          return [];
        },
      },
      selectedPeriod: { type: Number, default: 1 },
      showPeriodSelect: { type: Boolean, default: true },
      canCalculateCorrectUpgradePrice: { type: Boolean, required: true },
      couponDetails: { type: Object, default: null },
    },

    data() {
      return {
        loadingPlans: true,
        showPoppers: false,
      };
    },

    computed: {
      ...mapGetters(['getRegion', 'currentPlan', 'currentPeriod', 'hasActiveSubscription']),
      ...paymentGetters([
        'needsOverchargeUpgrade',
        'needToDisplayPriceIncreaseInfo',
        'needsShopifyPlanUpdateWithNewPrice',
        'isInactive',
        'isPlanSameAsCurrent',
        'isPlanExpired',
        'isBraintreePayment',
        'isShopifyPayment',
        'hasFuturePackage',
        'getFuturePackage',
        'getDateExpires',
        'usedPageViews',
      ]),
      ...paymentState(['planListService', 'groupedPlanListService']),

      isStandardFreemium() {
        return !!this.groupedPlanListService;
      },
      hasRecommendation() {
        return this.groupedPlanListService?.hasRecommendation() ?? false;
      },
      classes() {
        return {
          'mt-10': this.needsOverchargeUpgrade && !this.showPeriodSelect,
          'mt-5': !this.needsOverchargeUpgrade && !this.showPeriodSelect,
        };
      },
      planListToShow() {
        if (this.hasFuturePackage) {
          const found = this.plans.find((plan) => plan.name === this.getFuturePackage);
          return found ? [found] : [];
        }

        if (this.isStandardFreemium) {
          return Object.values(this.groupedPlanListService.getGroupList());
        }

        return this.plans.filter((plan) => plan.public !== false);
      },

      selectedPeriodKey() {
        let period = 'monthly';
        if (this.selectedPeriod === 12) {
          period = 'annual';
        } else if (this.selectedPeriod === 3) {
          period = 'quarterly';
        }
        return period;
      },

      selectedPeriodLanguageKey() {
        let period = 'month';
        if (this.selectedPeriod === 12) {
          period = 'annual';
        } else if (this.selectedPeriod === 3) {
          period = 'quarter';
        }
        return period;
      },

      getCurrentPlan() {
        return this.currentPlan.toUpperCase();
      },

      isUsedInModal() {
        // Probably this will be a wrong indicator in the future
        return this.triggers.length;
      },

      isUsedInPlanSettings() {
        return !this.triggers.length;
      },
      cycle() {
        return this.selectedPeriod === 1 ? 'month' : 'year';
      },
      isChangingPeriod() {
        return this.currentPeriod !== this.selectedPeriod;
      },
    },

    mounted() {
      if (this.isStandardFreemium) {
        this.groupedPlanListService.loadGroupList();
      }
      this.loadingPlans = false;
      this.setShowPoppers(true);
    },

    methods: {
      ...paymentMutations(['setPeriod']),
      contactUs() {
        this.$modal.show('master-contact-us');
      },
      showPlanSelectButton(planName) {
        if (planName === 'MASTER') {
          return false;
        }

        const usedInModalAndDowngrade = !!this.isUsedInModal && this.isPlanDowngrade(planName);

        if (usedInModalAndDowngrade) {
          return false;
        }

        const planSameAsCurrent = this.isPlanSameAsCurrent(planName);

        if (planName === 'FREE' && planDetails.isNonPayingPlan(this.currentPlan)) {
          return false;
        }

        if (planSameAsCurrent === false) {
          return true;
        }

        if (planName === 'FREE' && planSameAsCurrent) {
          return false;
        }

        // Braintree reactivation or manual pay after recurring problem
        if (this.isBraintreePayment && planSameAsCurrent && this.isPlanExpired) {
          return true;
        }

        // Shopify reactivation after Shopify pay has got inactive but recurring haven't put it to FREE
        // (nonfreemium suspended or freemium frozen account)
        if (this.isShopifyPayment && planSameAsCurrent && this.isPlanExpired && this.isInactive) {
          return true;
        }

        return false;
      },
      isRecommended(plan) {
        return plan.selectedVariant === plan?.recommendation?.recommendedVariant;
        // return !!plan?.recommendation?.isRecommendedGroup;
      },
      isRecommendedVariant(plan, variantName) {
        return variantName === plan?.recommendation?.recommendedVariant;
      },
      isNotSufficient(plan) {
        // if (plan?.recommendation?.isRecommendedGroup) return false;
        return !!plan?.recommendation?.notSufficient;
      },
      remainingDaysToRunOut(plan) {
        return plan?.recommendation?.remainingDaysToRunOut ?? 0;
      },
      displayRecommendationText() {
        const isShopifyMonthly = this.isShopifyPayment && this.currentPeriod === 1;
        const prevLimitResetAt = dateCalculator.getPrevLimitResetAt(
          this.getDateExpires,
          isShopifyMonthly,
        );

        const passedDays = moment.utc().startOf('day').diff(moment.utc(prevLimitResetAt), 'days');
        const pageViews = this.usedPageViews;

        const currentPlan = Object.values(this.groupedPlanListService.getGroupList()).find(
          (plan) => plan.currentVariant,
        );

        if (this.remainingDaysToRunOut(currentPlan) < 1) {
          return this.$t('planRecommendationItems.datePassed', {
            spentDays: passedDays,
            numPageviews: pageViews,
          });
        }
        return this.$t('planRecommendationItems.text', {
          spentDays: passedDays,
          numPageviews: pageViews,
          remainingDays: this.remainingDaysToRunOut(currentPlan),
        });
      },
      isPlanDowngrade(planName) {
        return planDetails.isDowngrade(this.getCurrentPlan, planName);
      },
      formatPrice(priceString) {
        if (this.getRegion === 'Hungary') {
          return priceString;
        }
        const { prefix, priceNumber, suffix } = separatePrice(priceString);
        return `${prefix}${(priceNumber || 0).toLocaleString('en-US', {
          minimumFractionDigits: 0,
          maximumFractionDigits: priceNumber === 0 ? 0 : 2,
        })}${suffix}`;
      },
      getFontColor(plan) {
        return plan.fontColor;
      },
      getPlanPriceString(plan) {
        return this.formatPrice(plan.price[this.selectedPeriodKey]);
      },
      hasDiscountForPlan(plan) {
        return plan.hasDiscount && plan.hasDiscount[this.selectedPeriodKey];
      },
      getPlanOriginalPriceString(plan) {
        if (!plan.originalPrice || !plan.originalPrice[this.selectedPeriodKey]) return null;
        return this.formatPrice(plan.originalPrice[this.selectedPeriodKey]);
      },
      getCalculatedMonthlyPrice(plan) {
        if (this.selectedPeriod !== 12) return null;
        const price = plan.calculatedPrice?.monthlyPriceWithAnnual;
        return price ? this.formatPrice(price) : null;
      },
      getPlanVariantOptions(plan) {
        return plan?.variantOptions?.map((variant) => {
          return {
            key: variant.key,
            value: this.getVariantOptionText(
              variant.value,
              this.isRecommendedVariant(plan, variant.key),
            ),
          };
        });
      },
      getVariantPvText(plan) {
        const pv = plan.details.pageViews;
        if (!pv) return '';
        return this.getVariantOptionText(pv);
      },
      getVariantOptionValue(plan) {
        return { key: plan.name };
      },
      getVariantOptionText(pageViews, recommended = false) {
        if (recommended) {
          return this.$t('plan.recPvVariantOptionText', { pageViews });
        }
        return this.$t('plan.pvVariantOptionText', { pageViews });
      },
      onChangeVariant(event) {
        this.setShowPoppers(false);
        this.groupedPlanListService.changeVariant({ variant: event.key });
        this.setShowPoppers(true);
      },
      setShowPoppers(value) {
        if (value) {
          this.$nextTick(() => {
            this.showPoppers = value;
          });
        } else {
          this.showPoppers = value;
        }
      },
    },
  };
</script>

<style lang="sass" scoped>
  .mt-10
    margin-top: 2.5rem
  .feature-unbranded
    &.not-available > div
      width: .875rem
      text-align: center
      i.fa
        font-size: 1.25em !important
</style>
