<template lang="pug">
ProductBlockWrapper
  OmInput#action-text(small v-model="actionText" :label="$t('text')")
  Dropdown#action(
    v-if="!isStatic"
    :items="clickActions"
    v-model="action"
    :label="$t('action')"
    :disabled="selectedMode === 'products-in-the-cart'"
  )
  Heading(expandableGap="4px" fix :open="true")
    template(#titleTrailing)
      span {{ $t('size') }}
    template(#expandableContent)
      Dropdown#width(
        layout="col"
        :label="$t('width')"
        :items="widthOptions"
        v-model="widthType"
        editMobile
      )
      RangeInput(
        v-if="widthType === 'manual'"
        :label="$t('width')"
        v-model.number="width"
        :min="30"
        :max="600"
        deviceSelector
      )
      RangeInput(
        v-if="!mobilePreview || widthType"
        :label="$t('height')"
        v-model.number="height"
        :min="30"
        :max="300"
        editMobile
      )
  Heading(expandableGap="4px" fix :open="true")
    template(#titleTrailing)
      span {{ $t('font') }}
    template(#expandableContent)
      Align(:labelOverride="$t('verticalAlignment')" v-model="textAlign" vertical flexOptions)
      FontFamily(v-model="fontFamily" layout="row" manage)
      FontStyle(
        :label="$t('style')"
        :textWeight.sync="textWeight"
        :fontItalic.sync="fontItalic"
        :textDecoration.sync="textDecoration"
        :textStrikeThrough.sync="textStrikeThrough"
        showTextStrikeThrough
      )
      RangeInput(
        :label="$t('fontSize')"
        v-model="fontSize"
        :min="8"
        :max="90"
        :step="1"
        editMobile
      )
      OmColorInput(
        :label="$t('color')"
        :property="`${baseProperty}.color`"
        typeOfComponent="product"
        layout="col"
        dsAllowed
        editMobile
      )
  Heading(fix :open="true")
    template(#titleTrailing)
      span {{ $t('background') }}
    template(#expandableContent)
      OmColorInput(
        :label="$t('fill')"
        property="subElements.button.$device.background.color"
        typeOfComponent="product"
        layout="col"
        dsAllowed
        editMobile
      )
  Heading(v-if="!mobilePreview" expandableGap="4px" fix :open="true")
    template(#titleTrailing)
      span {{ $t('hover') }}
    template(#expandableContent)
      OmColorInput(
        :label="$t('fontColor')"
        property="subElements.button.desktop.hover.fontColor"
        typeOfComponent="product"
        layout="col"
        dsAllowed
      )
      OmColorInput(
        :label="$t('borderColor')"
        property="subElements.button.desktop.hover.borderColor"
        typeOfComponent="product"
        layout="col"
        dsAllowed
      )
      OmColorInput(
        :label="$t('backgroundColor')"
        property="subElements.button.desktop.hover.background.color"
        typeOfComponent="product"
        layout="col"
        dsAllowed
      )
  Padding(customProperty="subElements.button.$device.padding")
  Margin(customProperty="subElements.button.$device.margin")
  Heading(expandableGap="4px" fix :open="true")
    template(#titleTrailing)
      span {{ $t('borderAndShadow') }}
    template(#expandableContent)
      Border(
        selectedProperty="subElements.button.$device.border.selectedBorder"
        typeProperty="subElements.button.$device.border.type"
        widthProperty="subElements.button.$device.border.width"
        coloringProperty="subElements.button.$device.border.color"
        typeOfComponent="product"
      )
      CornerRadius(
        radiusProperty="subElements.button.$device.border.radius"
        chainedProperty="subElements.button.$device.border.radiusChained"
        customThemeRoundingProperty="subElements.button.$device.border.customThemeRounding"
      )
      Shadow(
        v-model="buttonShadow"
        typeOfComponent="product"
        coloringProperty="subElements.button.$device.shadow.color"
        editMobile
      )
</template>
<script>
  import { mapState } from 'vuex';
  import itemMixin from '@/editor/mixins/item';
  import ProductBlockWrapper from './Wrapper.vue';
  import Heading from '../../Heading.vue';
  import Dropdown from '../../Dropdown/Dropdown.vue';
  import Align from '../../Controls/Alignments/Align.vue';
  import FontFamily from '../../Controls/FontFamily.vue';
  import FontStyle from '../../FontStyle/FontStyle.vue';
  import RangeInput from '../../RangeInput/RangeInput.vue';
  import Margin from '../../Controls/InputGroup/Margin.vue';
  import Padding from '../../Controls/InputGroup/Padding.vue';
  import Border from '../../Controls/Border.vue';
  import CornerRadius from '../../Controls/CornerRadius.vue';
  import Shadow from '../../Controls/Shadow.vue';

  export default {
    components: {
      ProductBlockWrapper,
      Heading,
      Dropdown,
      Align,
      FontFamily,
      FontStyle,
      RangeInput,
      Margin,
      Padding,
      Border,
      CornerRadius,
      Shadow,
    },
    mixins: [itemMixin],
    props: {
      subItem: {
        type: String,
        required: true,
      },
      clickActions: {
        type: Array,
        required: true,
      },
      selectedMode: {
        type: String,
        required: true,
      },
      isStatic: {
        type: Boolean,
        required: true,
      },
    },
    data: () => ({
      canEditMobile: true,
    }),
    computed: {
      ...mapState(['mobilePreview', 'isNano']),
      baseProperty() {
        return `subElements.buttonText.$device`;
      },
      actionText: {
        get() {
          return this.getValueOf(`data.ctaText`);
        },
        set(v) {
          this.setValueOf(`data.ctaText`, v);
        },
      },
      action: {
        get() {
          return this.getValueOf(`data.clickAction`);
        },
        set(v) {
          this.setValueOf(`data.clickAction`, v);
        },
      },
      widthType: {
        get() {
          return this.getValueOf(`subElements.button.$device.smartSize.type`);
        },
        set(v) {
          this.setValueOf(`subElements.button.$device.smartSize.type`, v);
        },
      },
      width: {
        get() {
          const mobileValue = this.getValueOf(`subElements.button.mobile.smartSize.width`);
          if (this.mobilePreview && !mobileValue) {
            return Number(this.getValueOf(`subElements.button.desktop.smartSize.width`));
          }
          return Number(this.getValueOf(`subElements.button.$device.smartSize.width`));
        },
        set(v) {
          this.setValueOf(`subElements.button.$device.smartSize.width`, v);
        },
      },
      height: {
        get() {
          const mobileValue = this.getValueOf(`subElements.button.mobile.height`);
          if (this.mobilePreview && !mobileValue) {
            return Number(this.getValueOf(`subElements.button.desktop.height`));
          }
          return Number(this.getValueOf(`subElements.button.$device.height`));
        },
        set(v) {
          this.setValueOf(`subElements.button.$device.height`, v);
        },
      },
      textAlign: {
        get() {
          return this.getValueOf(`${this.baseProperty}.alignSelf`);
        },
        set(v) {
          this.setValueOf(`${this.baseProperty}.alignSelf`, v);
        },
      },
      fontFamily: {
        get() {
          return this.getValueOf(`${this.baseProperty}.fontFamily`);
        },
        set(v) {
          this.setValueOf(`${this.baseProperty}.fontFamily`, v);
        },
      },
      textWeight: {
        get() {
          return this.getValueOf(`${this.baseProperty}.textWeight`);
        },
        set(v) {
          this.setValueOf(`${this.baseProperty}.textWeight`, v);
        },
      },
      fontItalic: {
        get() {
          return this.getValueOf(`${this.baseProperty}.fontItalic`);
        },
        set(v) {
          this.setValueOf(`${this.baseProperty}.fontItalic`, v);
        },
      },
      textDecoration: {
        get() {
          return this.getValueOf(`${this.baseProperty}.textDecoration`);
        },
        set(v) {
          this.setValueOf(`${this.baseProperty}.textDecoration`, v);
        },
      },
      textStrikeThrough: {
        get() {
          return this.getValueOf(`${this.baseProperty}.textStrikeThrough`);
        },
        set(v) {
          this.setValueOf(`${this.baseProperty}.textStrikeThrough`, v);
        },
      },
      fontSize: {
        get() {
          const fontSizeProperty = `${this.baseProperty}.fontSize`;
          const mobileValue = this.getValueOf(fontSizeProperty.replace('$device', 'mobile'));
          if (this.mobilePreview && !mobileValue) {
            return Number(this.getValueOf(fontSizeProperty.replace('$device', 'desktop')));
          }
          return Number(this.getValueOf(fontSizeProperty));
        },
        set(v) {
          this.setValueOf(`${this.baseProperty}.fontSize`, v);
        },
      },
      buttonShadow: {
        get() {
          return this.getValueOf('subElements.button.$device.shadow.type');
        },
        set(v) {
          this.setValueOf('subElements.button.$device.shadow.type', v);
        },
      },
      widthOptions() {
        const opts = [
          { value: 'fluid', text: this.$t('fluid') },
          { value: 'manual', text: this.$t('manual') },
        ];

        if (!this.isNano) {
          opts.push({ value: '100w', text: this.$t('simple100') });
        }

        if (this.mobilePreview) {
          opts.unshift({ value: null, text: this.$t('default') });
        }

        return opts;
      },
    },
  };
</script>
