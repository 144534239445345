<template lang="pug">
.ds-editor-pane-layout-wrapper
  .ds-editor-pane-layout.design-system(
    :class="layoutClasses"
    :style="{ gap: layout === 'row' ? gap : null }"
  )
    .ds-editor-pane-layout-left.pr-1(:class="{ grow }")
      slot(name="left")
    .ds-editor-pane-layout-middle(v-if="$slots.middle")
      slot(name="middle")
    .ds-editor-pane-layout-right
      slot(name="right")
  .ds-editor-pane-layout-error(v-if="$slots.error")
    slot(name="error")
  .ds-editor-pane-layout-help(v-if="$slots.help")
    slot(name="help")
</template>

<script>
  export default {
    props: {
      layout: {
        type: String,
        default: 'row',
        options: ['row', 'col'],
        validator(value) {
          return ['row', 'col'].includes(value);
        },
      },
      layoutRatio: {
        type: String,
        default: 'half',
        options: ['half'],
        validator(value) {
          return ['half'].includes(value);
        },
      },
      gridLayout: {
        type: String,
        default: () => null,
      },
      gap: {
        type: String,
        default: '12px',
      },
      grow: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      isRow() {
        return this.layout === 'row';
      },
      layoutClasses() {
        const list = [];

        if (!this.isRow && this.gridLayout) list.push(`grid-template-${this.gridLayout}`);
        if (!this.isRow && this.$slots.middle) list.push(`grid-template-third`);
        if (!this.isRow && !this.$slots.middle && !this.gridLayout) list.push(`grid-template-half`);

        if (!this.isRow) list.push('align-items-center');

        return list;
      },
    },
  };
</script>

<style lang="sass">
  @import '@/sass/variables/_colors.sass'
  .ds-pane
    .popper.select-popper
      right: 0
      min-width: 14.625rem !important
  .ds-editor-pane-layout
    display: grid
    color: #23262A
    &-left
      &.grow
        flex-grow: 1
        height: 100%
    .select-selection
      max-width: 12.125rem
    .select:not(.select-discountType):not(.select-savedStyle):not(.select-fallback-action):not(.select-dateFormat):not(.select-inputElementDropdown):not(.select-fontFamily):not(.select-action):not(.select-country)
      .select-input,
      .select-selection
        overflow: hidden
        white-space: nowrap
        text-overflow: ellipsis
        max-width: 80px
    .select-position-top.select-dropdown-open
      .select-options
        max-height: 15.75rem
    &.grid-template-third
      grid-template-columns: 1fr 1fr 1fr
    &.grid-template-half
      grid-template-columns: 1fr 1fr
    &.grid-template-two-third
      grid-template-columns: 2fr 1fr
    &.grid-template-three-fourth
      grid-template-columns: 3fr 1fr
    &.grid-template-one-third
      grid-template-columns: 1fr 2fr
    &-error
      color: red
    &-help
      color: $om-dark-grey-3
</style>
