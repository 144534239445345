import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['isSubUser', 'hasAccountFeature']),
    transKey() {
      const wlRule = ['click', 'visitorAttribute'];
      const key = `${this.settingType}.${this.ruleType}.desc`;

      return this.isSubUser && wlRule.includes(this.ruleType) ? `${key}WL` : key;
    },
  },
};
