<template lang="pug">
div
  .row
    .col-12.col-lg-10
      h3.my-5 {{ $t('settingsSummary.title') }}
    .col-12.col-lg-2.d-flex.justify-content-end.align-items-center
      om-button#edit-settings-btn(
        primary
        small
        @click="$router.push({ name: 'campaign_settings', params: { id: $route.params.id }, query: { activeBox: getDefaultActiveBox } })"
      ) {{ $t('editRules') }}
  .card(
    v-if="(campaign.positions && campaign.positions.length) || campaign.manuallyEmbedded"
    @click="navigateToCampaignSettings('embedding')"
  )
    .card-body.brand-settings-summary(v-if="!campaign.manuallyEmbedded")
      .brand-settings-summary-title {{ $t('settingsSummary.positionsTitle') }}
      .pl-5.mt-3
        template(v-for="(position, positionIndex) in campaign.positions")
          .d-flex.align-items-center.brand-settings-summary-row(
            :class="getRowLineType(positionIndex, 'positions')"
          )
            position-box(:position="position" :campaign="campaign" small)
            .rule-logical-condition.rule-logical-condition-or(
              v-if="positionIndex < campaign.events.length - 1"
              :class="`rule-logical-condition-and-${$i18n.locale}`"
            ) {{ $t('and') }}
    .card-body.brand-settings-summary(v-else)
      .brand-settings-summary-title {{ $t('settingsSummary.positionsTitle') }}
      .pl-5.mt-3
        .d-flex.align-items-center.brand-settings-summary-row.brand-settings-summary-row-line.brand-settings-summary-row-line-end
          position-box(:manuallyEmbedded="true" :campaign="campaign" small)
  .card(
    v-if="campaign.events && campaign.events.length"
    @click="navigateToCampaignSettings('trigger')"
  )
    .card-body.brand-settings-summary
      .brand-settings-summary-title {{ $t('settingsSummary.eventTitle') }}
      .pl-5.mt-3
        template(v-for="(event, eventIndex) in campaign.events")
          .d-flex.align-items-center.brand-settings-summary-row(
            :class="getRowLineType(eventIndex, 'events')"
          )
            event-box(:event="event" small)
            .rule-logical-condition.rule-logical-condition-or(
              v-if="eventIndex < campaign.events.length - 1"
              :class="`rule-logical-condition-or-${$i18n.locale}`"
            ) {{ $t('or') }}
  .card.mt-5(
    v-if="frontendRulesToShow && frontendRulesToShow.length"
    @click="navigateToCampaignSettings('condition')"
  )
    .card-body.brand-settings-summary
      .brand-settings-summary-title {{ frontendRuleTitle }}
      .pl-5
        template(v-for="(frontendRule, frontendRuleIndex) in frontendRulesToShow")
          .d-flex.align-items-center.brand-settings-summary-row(
            :class="getRowLineType(frontendRuleIndex, 'frontendRulesToShow')"
          )
            frontend-rule-box(:frontendRule="frontendRule" :campaign="campaign" small)
            .rule-logical-condition.rule-logical-condition-and(
              v-if="frontendRuleIndex < frontendRulesToShow.length - 1"
              :class="`rule-logical-condition-and-${$i18n.locale}`"
            ) {{ $t('and') }}
  .card.mt-5(v-if="campaign.frequency" @click="navigateToCampaignSettings('frequency')")
    .card-body.brand-settings-summary
      .brand-settings-summary-title {{ $t('settingsSummary.frequencyTitle') }}
      .pl-5
        .d-flex.align-items-center.brand-settings-summary-row.brand-settings-summary-row-line.brand-settings-summary-row-line-end
          .brand-rule-box.brand-rule-box-small
            frontend-rules-color-box.flex-shrink-0.mr-3(type="maximumPopupDisplay")
            .col-2
              .d-flex.align-items-center
                .brand-rule-box-title {{ $t('settingsSummary.frequencyShowAgainTitle') }}
              .text-color-mild-gray.brand-rule-box-lead(
                v-if="!campaign.frequency.showAgain.enabled"
              ) {{ $t('settingsSummary.frequencyAlways') }}
              .text-color-mild-gray.brand-rule-box-lead(v-else) {{ $t('settingsSummary.frequencyShowAgainMaximumTimes', { times: this.campaign.frequency.showAgain.maximumTime }) }}
            .col-3
              .d-flex.align-items-center
                .brand-rule-box-title {{ $t('settingsSummary.withFrequency') }}
              .text-color-mild-gray.brand-rule-box-lead(v-if="!campaign.frequency.delay.enabled") {{ $t('settingsSummary.frequencyUnlimitedTimes') }}
              .text-color-mild-gray.brand-rule-box-lead(v-else) {{ $t('settingsSummary.frequencyDelayTimes', { times: this.campaign.frequency.delay.minimumTime, unit: this.$t('fr' + this.campaign.frequency.delay.timeUnit) }) }}
            .col-7(
              v-if="campaign.frequency.stopEvents.afterClosed || campaign.frequency.stopEvents.afterConverted"
            )
              .d-flex.align-items-center
                .brand-rule-box-title {{ $t('settingsSummary.frequencyStopShowing') }}
              .text-color-mild-gray.brand-rule-box-lead(
                v-if="campaign.frequency.stopEvents.afterClosed"
              ) {{ $t('settingsSummary.frequencyStopAfterClosed') }}
              .text-color-mild-gray.brand-rule-box-lead(
                v-if="campaign.frequency.stopEvents.afterConverted"
              ) {{ $t('settingsSummary.frequencyStopAfterConverted') }}
  .card.mt-5(
    v-if="(campaign.integrations && campaign.integrations.length) || domain.analytics.enabled"
    @click="navigateToCampaignSettings('integration')"
    :style="isDynamicContent ? 'opacity: 0.7; pointer-events: none;' : ''"
  )
    .card-body.brand-settings-summary
      .brand-settings-summary-title {{ $t('integrationsTitle') }}
      .mt-3
        .row
          template(v-for="(integration, integrationIndex) in campaign.integrations")
            .col-6.col-sm-3.col-xl-2.d-flex.align-items-center.flex-grow-0.mb-3
              integration-logo.mr-3.cursor-unset(:type="integration.global.type" small)
              .d-flex.flex-column
                .font-size-0--9375.font-weight-semibold {{ $t(`integrations.${integration.global.type}.name`) }}
                .font-size-0--8125.line-height-1.brand-text-truncate(
                  style="max-width: 105px"
                  :title="integration.global.data.name"
                ) ({{ integration.global.data.name }})
          .col-6.col-sm-3.col-xl-2.d-flex.align-items-center.flex-grow-0.mb-3(
            v-if="domain.analytics.enabled"
          )
            img.mr-3.img-fluid(:src="require('@/assets/admin/svg/analytics-colored.svg')")
            .d-flex.flex-column
              .font-size-0--9375.font-weight-semibold Google Analytics
</template>
<script>
  import { mapState } from 'vuex';
  import FrontendRuleLead from '@/components/FrontendRuleLead.vue';
  import EventLead from '@/components/EventLead.vue';
  import * as ConditionSvgs from '@/components/Svg/Condition/';
  import * as EventSvgs from '@/components/Svg/Event/';
  import IntegrationLogo from '@/components/IntegrationLogo.vue';
  import domainInfos from '@/mixins/domainInfos';
  import EventBox from '@/components/CampaignSettings/EventBox';
  import PositionBox from '@/components/CampaignSettings/PositionBox';
  import FrontendRuleBox from '@/components/CampaignSettings/FrontendRuleBox';
  import FrontendRulesColorBox from '@/components/FrontendRulesColorBox.vue';
  import embeddedV3 from '@/mixins/embeddedV3';
  import dynamicContent from '@/mixins/dynamicContent';

  export default {
    components: {
      FrontendRuleLead,
      FrontendRulesColorBox,
      EventLead,
      ...ConditionSvgs,
      ...EventSvgs,
      IntegrationLogo,
      EventBox,
      FrontendRuleBox,
      PositionBox,
    },

    mixins: [domainInfos, embeddedV3, dynamicContent],

    props: {
      campaign: {
        type: Object,
        required: true,
      },
    },

    computed: {
      ...mapState(['account']),
      campaignDevice() {
        return this.campaign.name && this.campaign.device !== 'none'
          ? this.$t(`onDevice.${this.campaign.device}`)
          : '';
      },
      getDefaultActiveBox() {
        if (this.isDynamicContent) return 'condition';

        return (this.campaign.positions && this.campaign.positions.length) ||
          this.campaign.manuallyEmbedded
          ? 'embedding'
          : 'trigger';
      },

      frontendRuleTitle() {
        if (this.isDynamicContent || this.isEmbedded) {
          return this.$t('settingsSummary.frontendRuleTitleCampaign');
        }

        return this.$t('settingsSummary.frontendRuleTitle');
      },

      frontendRulesToShow() {
        const rules =
          this.campaign?.frontendRules?.map((rule, index) => {
            return { ...rule, originalIndex: index };
          }) ?? null;
        return rules?.filter(({ type }) => !this.isHiddenRuleType(type)) ?? [];
      },

      features() {
        return this.account?.features ?? [];
      },
    },

    methods: {
      getRowLineType(eventIndex, type) {
        // const rowLines = (this.campaign.frontendRules.length + this.campaign.events.length)
        const rulesLength =
          type === 'frontendRulesToShow'
            ? this.frontendRulesToShow.length
            : this.campaign[type].length;
        const start = eventIndex === 0 && rulesLength > 1;
        const end = (eventIndex === rulesLength - 1 && rulesLength > 1) || rulesLength === 1;
        return {
          'brand-settings-summary-row-line': start || end,
          'brand-settings-summary-row-line-start': start,
          'brand-settings-summary-row-line-end': end,
        };
      },
      navigateToCampaignSettings(activeBox) {
        this.$router.push({
          name: 'campaign_settings',
          params: { userId: this.$route.params.userId, id: this.$route.params.id },
          query: { activeBox },
        });
      },
      // eslint-disable-next-line no-unused-vars
      isHiddenRuleType(type) {
        return false;
      },
    },
  };
</script>

<style lang="sass">
  .brand-settings-summary
    padding: 2.1875rem 2.5rem 2rem !important
    font-size: 0.875rem
    position: relative
    border: 1px solid #D8DDE1
    border-radius: 3px

    &:hover
      cursor: pointer

    &-title
      font-size: .9375rem
      font-weight: 500
      text-transform: uppercase
      color: #17181A
      margin-bottom: 1.25rem

  .brand-settings-summary-row
    position: relative
    margin-bottom: 1rem
    &:last-child
      margin-bottom: 0

    &:before,
    &:after
      position: absolute
      content: ''
      width: 2px
      height: 50%
      background: #D8DDE1
      top: 0
      left: -1.5rem
      max-height: 50%
      cursor: default !important
      pointer-events: none !important
    &:after
      top: 50%

    &-hide-line
      &:before,
      &:after
        content: none

    .rule-logical-condition
      bottom: -.5rem

      &-or
        &-hu
          left: -2.6875rem
        &-en
          left: -2.25rem
      &-and
        &-hu
          left: -2.187rem
        &-en
          left: -2.5rem

    &-line
      &.brand-settings-summary-row-line-end
        &:before
          content: ''
          top: 0
        &:after
          content: none
      .brand-rule-box
        &:before
          position: absolute
          content: ''
          width: 0.9375rem
          height: 2px
          background: #D8DDE1
          top: 50%
          left: -1.5rem
          cursor: default !important
          pointer-events: none !important
      &.brand-settings-summary-row-line-start
        .brand-rule-box
          &:before
            content: none
</style>
