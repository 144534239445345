<template lang="pug">
campaign-settings-box(
  :show="show"
  :bottomBorder="true"
  :hasError="false"
  :campaign="campaign"
  v-if="hasFrequency"
  @activate="$emit('activate')"
  @close="$emit('close')"
)
  template(slot="title") {{ $t('settingsFrequency.title') }}
  template(slot="content")
    .flex.align-items-center.pt-12.col-12
      .d-flex.mt-3.mb-1.py-2.px-5
        .col-auto.frequency-label-column.px-5
          label.font-weight-bold.settings-label-larger.p-0.my-3.mx-1 {{ $t('settingsFrequency.showAgainTitle') }}
        .col.px-0.gray-600.radio-select-card
          label.settings-label-larger.p-0.mr-auto.ml-0.my-3 {{ $t('settingsFrequency.showAgainDescription') }}
          .form-check.mb-2.pl-0
            .form-check-radio-wrapper(
              :class="{ checked: isFrequencyValueEqual('showAgain', false) }"
            )
              input#frequency-show-again-false.form-check-radio(
                type="radio"
                :value="false"
                v-model="campaign.frequency.showAgain.enabled"
              )
            label.form-check-label(for="frequency-show-again-false") {{ $t('settingsFrequency.showAgainUnlimitedTimes') }}
          .form-check.d-flex.align-items-center.pl-0
            .form-check-radio-wrapper(
              :class="{ checked: isFrequencyValueEqual('showAgain', true) }"
            )
              input#frequency-show-again-true.form-check-radio(
                type="radio"
                :value="true"
                v-model="campaign.frequency.showAgain.enabled"
              )
            label.form-check-label(for="frequency-show-again-true") {{ $t('settingsFrequency.showAgainMaximum') }}
            om-input#showAgainEnabled.w-4.mx-2(
              :disabled="!campaign.frequency.showAgain.enabled"
              v-model="campaign.frequency.showAgain.maximumTime"
              type="number"
              small
            )
            label.form-check-label.ml-0(for="frequency-show-again-true") {{ $t('settingsFrequency.showAgainTimes') }}
      .d-flex.my-1.py-2.px-5
        .col-auto.frequency-label-column.px-5
          label.font-weight-bold.settings-label-larger.p-0.my-3.mx-1 {{ $t('settingsFrequency.withFrequencyTitle') }}
        .col.px-0.gray-600.radio-select-card
          label.settings-label-larger.p-0.mr-auto.ml-0.my-3 {{ $t('settingsFrequency.withFrequencyDescription') }}
          .form-check.mb-2.pl-0
            .form-check-radio-wrapper(:class="{ checked: isFrequencyValueEqual('delay', false) }")
              input#frequency-delay-false.form-check-radio(
                type="radio"
                :value="false"
                v-model="campaign.frequency.delay.enabled"
              )
            label.form-check-label(for="frequency-delay-false") {{ $t('settingsFrequency.withFrequencyAsap') }}
          .form-check.d-flex.align-items-center.pl-0
            .form-check-radio-wrapper(:class="{ checked: isFrequencyValueEqual('delay', true) }")
              input#frequency-delay-true.form-check-radio(
                type="radio"
                :value="true"
                v-model="campaign.frequency.delay.enabled"
              )
            label.form-check-label(for="frequency-delay-true") {{ $t('settingsFrequency.withFrequencyMinimum') }}
            om-input#frequencyDelayTrue.w-4.mx-2(
              :disabled="!campaign.frequency.delay.enabled"
              type="number"
              v-model="campaign.frequency.delay.minimumTime"
              small
            )
            om-select#frequencyUnit.w-7(
              :disabled="!campaign.frequency.delay.enabled"
              :options="frequencyUnitOptions"
              v-model="frequencyTimeUnit"
              size="small"
            )
            .form-check-label {{ $t('settingsFrequency.withFrequencyBetweenImpressions') }}
      .d-flex.my-1.py-2.px-5
        .col-auto.frequency-label-column.px-5
          label.font-weight-bold.settings-label-larger.p-0.p-0.my-3.mx-1 {{ $t('settingsFrequency.stopShowingTitle') }}
        .col.px-0.gray-600.radio-select-card
          label.settings-label-larger.p-0.mr-auto.ml-0.my-3 {{ $t('settingsFrequency.stopShowingDesciption') }}
          .form-check.mb-3.pl-0
            om-checkbox#frequencyStopAfterClosed.form-checkbox-input(
              v-model="campaign.frequency.stopEvents.afterClosed"
              name="stop_showing_closed"
              :label="$t('settingsFrequency.stopAfterClosed')"
            )
          .form-check.pl-0
            om-checkbox#frequencyStopAfterConverted.form-checkbox-input(
              v-model="campaign.frequency.stopEvents.afterConverted"
              name="stop_showing_converted"
              :label="$t('settingsFrequency.stopAfterConverted')"
            )
  template(slot="button") {{ $t('settingsFrequency.nextButton') }}
</template>
<script>
  import CampaignSettingsBox from '@/components/CampaignSettings/SettingsBox';

  export default {
    components: { CampaignSettingsBox },
    props: {
      campaign: {
        type: Object,
        required: true,
      },
      show: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        frequencyUnitOptions: [
          { key: 'second', value: this.$t('frsecond') },
          { key: 'minute', value: this.$t('frminute') },
          { key: 'hour', value: this.$t('frhour') },
          { key: 'day', value: this.$t('frday') },
        ],
      };
    },
    computed: {
      frequencyTimeUnit: {
        get() {
          const timeUnit = this.campaign?.frequency?.delay?.timeUnit;
          return this.frequencyUnitOptions.find((item) => item.key === timeUnit);
        },
        set(v) {
          // eslint-disable-next-line vue/no-mutating-props
          this.campaign.frequency.delay.timeUnit = v.key;
        },
      },
      features() {
        return this.account && this.account.features ? this.account.features : [];
      },
      hasFrequency() {
        return this.campaign.frequency != null;
      },
    },
    methods: {
      isFrequencyValueEqual(key, value) {
        return this.campaign.frequency[key]?.enabled === value;
      },
    },
  };
</script>
<style lang="sass" scoped>
  @import '../../sass/variables/variables'
  .frequency-label-column
    width: 180px
    @media screen and (max-width: 768px)
      width: 120px
  .settings-label-larger
    font-size: 14px
  .form-check
    display: flex

  .form-check-radio-wrapper
    width: 20px
    height: 20px
    border-radius: 100%
    border: 1px solid #d5d8dd
    position: relative
    display: flex
    justify-content: center
    align-items: center
    padding: 0
    &.checked
      border-color: #ed5a29
      &:before
        background: #ed5a29
    &:before
      content: ""
      display: block
      width: 9px
      height: 9px
      border-radius: 100%
  .form-check-radio
    opacity: 0
    width: 100% !important
    height: 100% !important
    position: absolute
    left: 0
    top: 0
</style>
