<template lang="pug">
.collection-previews(v-if="templates.length")
  .d-flex.justify-content-between.align-items-end.mb-6
    .collection-previews-heading.collection-previews-heading--title
      editable-title(
        h3
        v-if="isTitleEditable"
        :title="name"
        :maxLength="50"
        cancel-on-blur
        @renameTitle="renameTitle($event)"
      )
      om-heading(h3 v-else) {{ name }}
    .collection-previews-heading
      .collection-previews-heading-action(v-if="isArchiveEnabled")
        om-button(ghost small icon="archive" @click="archiveCollection") {{ $t('archive') }}
      om-link.text-nowrap(v-if="showLink" primary withIconRight @click="onSeeAll") {{ $t('seeAll') }}

  .row
    .col-4(v-for="template in templateList")
      TemplateBox(
        :title="getTitle(template.useCase)"
        :key="templateKey(template)"
        @observable="addObservable($event.$el)"
        heap-event="new-selector-campaign-collection"
        :template="template"
        :themeKit="themeKit"
        :baseThemeKitName="baseThemeKitName"
      )
</template>

<script>
  import { nanoid } from 'nanoid';
  import observableCollectionMixin from '@/mixins/observableCollection';
  import EditableTitle from '@/components/EditableTitle.vue';
  import templateBoxParent from '../mixins/templateBoxParent';

  export default {
    components: {
      EditableTitle,
      TemplateBox: () => import('@/components/TemplateChooser/components/TemplateBox.vue'),
    },
    mixins: [observableCollectionMixin, templateBoxParent],
    props: {
      name: { type: String, required: true },
      to: { type: [String, Object] },
      isTitleEditable: { type: Boolean, default: () => false },
      isArchiveEnabled: { type: Boolean, default: () => false },
      templates: { type: Array, default: () => [] },
      themeKit: { type: Object, default: () => null },
      useCaseMap: { type: Object, default: () => ({}) },
      baseThemeKitName: { type: String, default: null },
    },
    data: () => ({ uuid: nanoid(12) }),
    computed: {
      showLink() {
        return this.to && this.templates.length > 3;
      },
      templateList() {
        return (this.templates || [])?.slice?.(0, 3) ?? [];
      },
    },
    methods: {
      renameTitle(title) {
        this.$nextTick(() => {
          this.$emit('renameCollection', title);
        });
      },
      archiveCollection() {
        this.$emit('archive');
      },
      templateKey(template) {
        if (this.themeKit?.id) {
          return `${this.uuid}-${this.themeKit?.id}-${template._id}`;
        }
        return `${this.uuid}-${template._id}`;
      },
      onSeeAll() {
        this.$emit('track', { setting: 'theme_open', options: this.name });
        this.$emit('navigateWithFilter', this.to);
      },
      getTitle(useCaseId) {
        return this.useCaseMap?.[useCaseId]?.name?.[this.$i18n.locale];
      },
    },
  };
</script>
<style lang="sass">

  .collection-previews-heading
    display: flex
    column-gap: 1rem
    align-items: center
    height: 2rem


    &-action
      min-width: auto
      display: none
</style>
