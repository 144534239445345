import {
  FREEMIUM_PLANS,
  MASTER_PLAN,
  NON_FREEMIUM_PLANS,
  UNLIMITED_VALUE,
  isInPriceChange2024,
} from '@om/payment/src/plans';

const UNLIMITED_STRING = 'unlimited';
const NA_STRING = 'NA';

const formatDetails = (details) => {
  let pageViews = NA_STRING;
  if (details.pageViewsString) {
    pageViews = details.pageViewsString;
  } else if (details.pageViews) {
    pageViews = details.pageViews.toLocaleString('en-US');
  }

  let visitors = NA_STRING;
  if (details.visitorsString) {
    visitors = details.visitorsString;
  } else if (details.visitors) {
    visitors = details.visitors.toLocaleString('hu-HU');
  }

  let domains = NA_STRING;
  if (details.domains) {
    domains = details.domains === UNLIMITED_VALUE ? UNLIMITED_STRING : details.domains;
  }

  let campaigns = NA_STRING;
  if (details.campaigns) {
    campaigns = details.campaigns === UNLIMITED_VALUE ? UNLIMITED_STRING : details.campaigns;
  }

  let unbranded = '';
  if (details.unbranded) {
    unbranded = 'yes';
  }

  let prioritySupport = '';
  if (details.prioritySupport) {
    prioritySupport = 'yes';
  }

  return {
    ...(pageViews !== NA_STRING ? { pageViews } : {}),
    ...(visitors !== NA_STRING ? { visitors } : {}),
    domains,
    campaigns,
    prioritySupport,
    unbranded,
  };
};

const getMasterPlan = () => {
  return { ...MASTER_PLAN, details: formatDetails(MASTER_PLAN) };
};

const getPlanByName = (planName) => {
  const plan = [...FREEMIUM_PLANS, ...NON_FREEMIUM_PLANS].find(
    (plan) => plan.name.toUpperCase() === planName.toUpperCase(),
  );
  if (!plan) return;
  return {
    ...plan,
    details: formatDetails(plan.details),
    maxDomains: plan.details.domains,
    maxVisitors: plan.details.pageViews ?? plan.details.visitors,
  };
};

export { getPlanByName, getMasterPlan, isInPriceChange2024 };
