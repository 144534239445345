<template lang="pug">
om-modal.downgrade-modal(
  :name="name"
  scrollable
  color="light"
  :width="625"
  ref="modal"
  :clickToClose="closable"
  @beforeOpen="opened"
  @closed="closed"
)
  template(slot="modal-header")
    .brand-modal-action-icon(v-if="closable")
      span.cursor-pointer(@click="cancel" title="close" aria-hidden="true") X

    h2 {{ $t('downgradeModal.headerTitle') }}

    .plan-cards-container(v-if="!loading")
      .plan-card(:style="{ backgroundColor: planBackgroundColor(oldPlanColor) }")
        .plan-card-header {{ $t('downgradeModal.currentPlan') }}
        .plan-card-body(:style="{ backgroundColor: oldPlanColor }") {{ currentPlanName }}

      img.arrow-right(:src="require('@/assets/admin/img/arrow-right.svg')")

      .plan-card(v-if="toPlan" :style="{ backgroundColor: planBackgroundColor(newPlanColor) }")
        .plan-card-header {{ $t('downgradeModal.changeToPlan') }}
        .plan-card-body(:style="{ backgroundColor: newPlanColor }") {{ getPlanName(toPlan, false) }}

  template(slot="modal-body")
    template(v-if="currentStep === 0 && !loading")
      section.lost-features-section
        h2 {{ $t('downgradeModal.lostFeaturesTitle') }}

        .lost-features-container
          .lost-feature(v-if="lostPageViews > 0")
            i.fa.fa-times-circle
            .lost-feature-name {{ lostPageViews }} {{ $t(isPageViewBasedPackage ? `planDetailTitles.pageViews` : `planDetailTitles.visitor`).toLowerCase() }}
          .lost-feature(v-if="lostDomainsCount > 0")
            i.fa.fa-times-circle
            .lost-feature-name(v-if="newDomainsCount === 999") {{ $t(`downgradeModal.unlimitedDomains`).toLowerCase() }}
            .lost-feature-name(v-else) {{ lostDomainsCount }} {{ $t(`planDetailTitles.sites`).toLowerCase() }}
          .lost-feature(v-for="feature in lostFeatures")
            i.fa.fa-times-circle
            .lost-feature-name {{ $t(`planDetailTitles.${feature}`) }}

      .divider

      section.domains-section(v-if="lostDomainsCount > 0")
        h2 {{ $t('downgradeModal.chooseDomainsTitle') }}

        .domain-lead.mb-4
          i.fa.fa-exclamation-circle
          .domain-lead-text(v-html="$t('downgradeModal.chooseDomainsLead', { newDomainsCount })")

        .domains-container
          .domain(v-for="domain in domains")
            .domain-name {{ domain.domain }}
            toggle-button(
              :value="domainStatuses[domain._id]"
              @click.native.prevent="toggleDomainActiveStatus(domain._id)"
              :sync="true"
              :labels="{ checked: $t('active'), unchecked: $t('inactive') }"
              :width="75"
              :height="28"
            )

      section.button-section
        om-button.mx-auto.mb-4(primary large @click="cancel") {{ $t('downgradeModal.cancelBtn') }}
        a.brand-link.color-brand-primary(@click="nextStep()") {{ $t('downgradeModal.downgradeBtn', { planName: toPlan }) }}

    template(v-if="currentStep === 1")
      section.changes-section
        h2 {{ $t('downgradeModal.changesTitle') }}

        .lost-features-container
          .lost-feature
            i.fa.fa-times-circle
            .lost-feature-name(
              v-html="$t('downgradeModal.changesPageViewsRemaining', { remainingPageViews: newPageViews })"
            )

          .lost-feature(v-if="campaigns.inactiveDomains.length > 0")
            i.fa.fa-times-circle
            .lost-feature-name
              div(v-html="$t('downgradeModal.changesDomainInactivationCampaigns')")
              om-table.mb-4.mt-4(
                :items="campaigns.inactiveDomains"
                :columns="campaignColumns"
                :paginate="false"
                :selectable="false"
                :sortable="false"
                :showHeader="false"
              )
                template(slot="preview" slot-scope="{ rowData }")
                  variant-image(
                    :variant="getPreviewVariant(rowData.row)"
                    :classes="{ v1: rowData.row.version === 1, [rowData.row.templateType]: true }"
                    :templateName="rowData.row.templateName"
                    :isNew="rowData.row.version !== 1"
                  )
                template(slot="name" slot-scope="{ rowData }")
                  .d-flex.align-items-center.ml-3 {{ rowData.row.name }}
                template(slot="impressions" slot-scope="{ rowData }")
                  .d-flex.align-items-center.flex-direction-column
                    div {{ rowData.row.impressions }}
                    .label {{ $t('impressions') }}
                template(slot="conversions" slot-scope="{ rowData }")
                  .d-flex.align-items-center.flex-direction-column
                    div {{ rowData.row.conversions }}
                    .label {{ $t('conversions') }}
                template(slot="conversionRate" slot-scope="{ rowData }")
                  .d-flex.align-items-center.flex-direction-column
                    div {{ rowData.row.conversionRate }}%
                    .label {{ $t('conversionRate') }}

          .lost-feature(v-if="campaigns.proRules.length > 0")
            i.fa.fa-times-circle
            .lost-feature-name
              div(v-html="$t('downgradeModal.changesProRuleCampaigns')")
              om-table.mb-4.mt-4(
                :items="campaigns.proRules"
                :columns="campaignColumns"
                :paginate="false"
                :selectable="false"
                :sortable="false"
                :showHeader="false"
              )
                template(slot="preview" slot-scope="{ rowData }")
                  variant-image(
                    :variant="getPreviewVariant(rowData.row)"
                    :classes="{ v1: rowData.row.version === 1, [rowData.row.templateType]: true }"
                    :templateName="rowData.row.templateName"
                    :isNew="rowData.row.version !== 1"
                  )
                template(slot="name" slot-scope="{ rowData }")
                  .d-flex.align-items-center.ml-3 {{ rowData.row.name }}
                template(slot="impressions" slot-scope="{ rowData }")
                  .d-flex.align-items-center.flex-direction-column
                    div {{ rowData.row.impressions }}
                    .label {{ $t('impressions') }}
                template(slot="conversions" slot-scope="{ rowData }")
                  .d-flex.align-items-center.flex-direction-column
                    div {{ rowData.row.conversions }}
                    .label {{ $t('conversions') }}
                template(slot="conversionRate" slot-scope="{ rowData }")
                  .d-flex.align-items-center.flex-direction-column
                    div {{ rowData.row.conversionRate }}%
                    .label {{ $t('conversionRate') }}

          .lost-feature(v-if="campaigns.abTesting.length > 0")
            i.fa.fa-times-circle
            .lost-feature-name
              div(v-html="$t('downgradeModal.changesAbTestingCampaigns')")
              om-table.mb-4.mt-4(
                :items="campaigns.abTesting"
                :columns="campaignColumns"
                :paginate="false"
                :selectable="false"
                :sortable="false"
                :showHeader="false"
              )
                template(slot="preview" slot-scope="{ rowData }")
                  variant-image(
                    :variant="getPreviewVariant(rowData.row)"
                    :classes="{ v1: rowData.row.version === 1, [rowData.row.templateType]: true }"
                    :templateName="rowData.row.templateName"
                    :isNew="rowData.row.version !== 1"
                  )
                template(slot="name" slot-scope="{ rowData }")
                  .d-flex.align-items-center.ml-3 {{ rowData.row.name }}
                template(slot="impressions" slot-scope="{ rowData }")
                  .d-flex.align-items-center.flex-direction-column
                    div {{ rowData.row.impressions }}
                    .label {{ $t('impressions') }}
                template(slot="conversions" slot-scope="{ rowData }")
                  .d-flex.align-items-center.flex-direction-column
                    div {{ rowData.row.conversions }}
                    .label {{ $t('conversions') }}
                template(slot="conversionRate" slot-scope="{ rowData }")
                  .d-flex.align-items-center.flex-direction-column
                    div {{ rowData.row.conversionRate }}%
                    .label {{ $t('conversionRate') }}

      section.button-section
        om-button.mx-auto.mb-4(primary large @click="cancel") {{ $t('downgradeModal.cancelBtn') }}
        a.brand-link.color-brand-primary(@click="afterFinalCancel") {{ $t('downgradeModal.downgradeBtn', { planName: toPlan }) }}
      next-billing-confirmation-modal.downgrade-confirmation-modal(
        name="downgrade-confirmation-modal"
        :toPlan="toPlan"
        :toPeriod="toPeriod"
        @confirmedDowngrade="downgrade"
      )
  template(slot="modal-footer")
    div
      | {{ $t('downgradeModal.needSomeHelp') }}
      a.brand-link.color-brand-primary(
        href="https://calendly.com/optimonk-session"
        target="_blank"
      ) {{ $t('downgradeModal.scheduleACall') }}
</template>
<script>
  import moment from 'moment';
  import { mapGetters, mapState, createNamespacedHelpers } from 'vuex';
  import GET_ACTIVE_CAMPAIGNS_USING_FEATURES from '@/graphql/GetActiveCampaignsUsingFeatures.gql';
  import GET_DETAILED_DOMAINS_WITH_PAGINATION from '@/graphql/GetDetailedDomainsWithPagination.gql';
  import GET_DOWNGRADE_LOST_FEATURES from '@/graphql/GetDowngradeLostFeatures.gql';
  import { hexToRgb, getPreviewVariant } from '@/util';
  import { track } from '@/services/xray';
  import VariantImage from '@/components/VariantImage.vue';
  import dateFormat from '@/mixins/dateFormat';
  import { isNewPaymentEnabled } from '@/utils/features';
  import checkoutMixin from '@/mixins/checkout';
  import NextBillingConfirmationModal from '@/components/Modals/NextBillingConfirmationModal.vue';

  const { mapGetters: paymentGetters } = createNamespacedHelpers('payment');

  const GRACE_PERIOD_IN_DAYS = 14;

  export default {
    components: {
      VariantImage,
      NextBillingConfirmationModal,
    },
    mixins: [dateFormat, checkoutMixin],
    props: {
      name: { type: String, default: 'downgrade-subscription' },
      toPlan: { type: String, default: null },
      toPeriod: { type: Number, default: null },
      fromPlan: { type: String, default: null },
      closable: { type: Boolean, default: true },
    },
    data() {
      return {
        currentStep: 0,
        loading: false,
        downgradeAccepted: false,
        campaignColumns: [
          { header: '', key: 'preview' },
          { header: this.$t('campaign'), key: 'name' },
          { header: this.$t('impressions'), key: 'impressions' },
          { header: this.$t('conversions'), key: 'conversions' },
          { header: this.$t('conversionRate'), key: 'conversionRate' },
        ],
        oldPlanColor: '#000000',
        newPlanColor: '#000000',
        lostPageViews: 0,
        lostDomainsCount: 0,
        newPageViews: 0,
        newDomainsCount: 0,
        lostFeatures: [],
        domainStatuses: {},
        campaigns: {
          proRules: [],
          abTesting: [],
          inactiveDomains: [],
        },
      };
    },
    computed: {
      ...mapState({
        domains: (state) => state.account.settings.domains,
      }),
      ...mapState(['account', 'currentPeriod']),
      ...mapGetters(['accountProfile']),
      ...paymentGetters(['getPeriodLanguageKey', 'isPageViewBasedPackage', 'getPlainPlanName']),
      currentPlanName() {
        const currentPlan = this.fromPlan || this.$store.state.currentPlan;
        if (currentPlan.toUpperCase().includes('MASTER')) {
          return 'MASTER';
        }

        return this.getPlanName(currentPlan, true);
      },
    },
    watch: {
      async toPlan(toPlan) {
        if (toPlan && this.currentPlanName.toLowerCase() !== toPlan.toLowerCase()) {
          await this.fetchLostFeatures();
        }
      },
      currentStep(newStep) {
        if (newStep === 1) {
          if (this.lostDomainsCount > 0) {
            this.fetchDomainCampaigns();
          }
        }
      },
    },
    methods: {
      ...mapGetters(['getDateExpires']),
      ...paymentGetters(['isShopifyPayment']),
      getPlanName(plan, isCurrent) {
        if (this.toPlan === 'FREE' || this.toPeriod === this.currentPeriod) {
          return this.getPlainPlanName(plan).toUpperCase();
        }

        const planName = this.getPlainPlanName(plan).toUpperCase();
        const periodInMonth = isCurrent ? this.currentPeriod : this.toPeriod;
        const periodLanguageKey = `billingCycle.${this.getPeriodLanguageKey(periodInMonth)}`;
        return `${planName} (${this.$t(periodLanguageKey)})`;
      },
      async opened() {
        this.currentStep = 0;

        this.$nextTick(() => {
          if (this.toPlan) {
            track('downgradeModalShown', {
              currentPlan: `${this.currentPlanName}`,
              toPlan: `${this.getPlanName(this.toPlan)}`,
            });
          }
        });
      },
      closed() {
        if (this.downgradeAccepted) {
          track('downgradeModalAccepted', {
            currentPlan: `${this.currentPlanName}`,
            toPlan: `${this.getPlanName(this.toPlan)}`,
          });
        } else {
          track('downgradeModalCanceled', {
            currentPlan: `${this.currentPlanName}`,
            toPlan: `${this.getPlanName(this.toPlan)}`,
          });
        }
      },
      async fetchLostFeatures() {
        if (['TRIAL', 'FREE'].includes(this.fromPlan)) return;
        if (this.toPlan) {
          this.loading = true;

          const {
            data: { getDowngradeLostFeatures: resp },
          } = await this.$apollo.query({
            query: GET_DOWNGRADE_LOST_FEATURES,
            variables: { toPlan: this.toPlan, fromPlan: this.fromPlan || null },
          });

          if (!resp) {
            return;
          }

          this.oldPlanColor = resp.oldColor;
          this.newPlanColor = resp.newColor;
          this.lostPageViews = resp.lostPageViews;
          this.lostDomainsCount = resp.lostDomainsCount;
          this.newPageViews = resp.newPageViews;
          this.newDomainsCount = resp.newDomainsCount;
          this.lostFeatures = resp.lostFeatures;

          this.resetDomainStatuses();

          this.fetchCampaigns();
          this.loading = false;
        }
      },
      async fetchCampaigns() {
        const lostFeatures = this.lostFeatures.filter((feature) => feature !== 'abTesting');
        const isABTestingLost = this.lostFeatures.includes('abTesting');

        this.campaigns.abTesting = [];
        this.campaigns.proRules = [];

        if (lostFeatures.length > 0) {
          this.$apollo
            .query({
              query: GET_ACTIVE_CAMPAIGNS_USING_FEATURES,
              variables: { features: lostFeatures },
            })
            .then(({ data: { activeCampaignsUsingFeatures: proRuleCampaigns } }) => {
              this.campaigns.proRules = proRuleCampaigns;
            });
        }

        if (isABTestingLost) {
          this.$apollo
            .query({
              query: GET_ACTIVE_CAMPAIGNS_USING_FEATURES,
              variables: { features: ['abTesting'] },
            })
            .then(({ data: { activeCampaignsUsingFeatures: abTestingCampaigns } }) => {
              this.campaigns.abTesting = abTestingCampaigns;
            });
        }
      },
      async fetchDomainCampaigns() {
        const {
          data: {
            getDetailedDomainsWithPagination: { domains },
          },
        } = await this.$apollo.query({
          query: GET_DETAILED_DOMAINS_WITH_PAGINATION,
        });

        const inactiveDomainIds = Object.entries(this.domainStatuses)
          .filter(([isActive]) => isActive === false)
          .map(([domainId]) => domainId);

        const inactiveCampaigns = [];

        inactiveDomainIds.forEach((domainId) => {
          const inactiveDomain = domains.find((domain) => domain._id === domainId);

          inactiveCampaigns.push(...inactiveDomain.activeCampaigns);
        });

        this.campaigns.inactiveDomains = inactiveCampaigns;
      },
      planBackgroundColor(color, alpha = 0.8) {
        if (color) {
          const { r, g, b } = hexToRgb(color);
          const multiplier = 1.1;

          return `rgba(${Math.min(255, r * multiplier)}, ${Math.min(
            255,
            g * multiplier,
          )}, ${Math.min(255, b * multiplier)}, ${alpha})`;
        }

        return `rgba(0, 0, 0, 1)`;
      },
      planFeatures(plan) {
        const features = [];

        if (plan && plan.details) {
          Object.entries(plan.details).forEach(([name, value]) => {
            if (value === 'yes') {
              features.push(name);
            }
          });
        }

        return features;
      },
      cancel() {
        this.$emit('cancel');
        this.$modal.hide('downgrade-subscription');
      },
      downgrade() {
        this.downgradeAccepted = true;

        // PlanSettings > downgrade
        this.$emit('downgrade', {
          activeDomainIds: Object.entries(this.domainStatuses)
            .filter(([, isActive]) => isActive === true)
            .map(([domainId]) => domainId),
        });
      },
      getPreviewVariant(campaign) {
        return getPreviewVariant(campaign);
      },
      resetDomainStatuses() {
        const newDomainsCount = this.newDomainsCount;
        let activeDomainsCount = 0;

        this.domainStatuses = {};

        if (this.domains) {
          this.domains.forEach((domain) => {
            if (!domain.inactive && activeDomainsCount < newDomainsCount) {
              this.$set(this.domainStatuses, domain._id, true);
              activeDomainsCount++;
            } else {
              this.$set(this.domainStatuses, domain._id, false);
            }
          });
        }
      },
      toggleDomainActiveStatus(domainId) {
        const active = !this.domainStatuses[domainId];

        this.$set(this.domainStatuses, domainId, active);

        if (active) {
          const activeCount = Object.values(this.domainStatuses).filter(
            (isActive) => isActive === true,
          ).length;

          if (activeCount > this.newDomainsCount) {
            const inactivatedOther = Object.entries(this.domainStatuses).some(([id, isActive]) => {
              if (isActive && id !== domainId) {
                this.$set(this.domainStatuses, id, false);

                return true;
              }
              return false;
            });

            if (!inactivatedOther) {
              this.$notify({
                type: 'error',
                text: `You can use only ${this.newDomainsCount} domains in your new package.`,
              });

              this.$set(this.domainStatuses, domainId, false);
            }
          }
        }
      },
      nextStep() {
        this.currentStep++;

        this.$refs.modal.$el.scrollTop = 0;
      },

      afterFinalCancel() {
        if (
          !isNewPaymentEnabled(this.account.features) ||
          (this.toPlan === 'FREE' && this.isShopifyPayment)
        ) {
          return this.downgrade();
        }

        this.$modal.show('downgrade-confirmation-modal');
      },

      getBillingExpiresDate() {
        return moment(this.getDateExpires())
          .subtract(GRACE_PERIOD_IN_DAYS, 'days')
          .format(this.dateFormat);
      },
    },
  };
</script>
<style lang="sass">
  .custom-confirmation-text
    font-size: 16px
    color: #23262A
    font-family: Roboto
    font-style: normal
    font-weight: normal
    line-height: 24px

  .downgrade-confirmation-modal
    height: -webkit-fill-available !important
    .brand-modal
      .brand-modal-header
        background: none !important
        border-bottom: 1px solid #E3E5E8
        box-shadow: none !important
        padding-top: 20px !important
        padding-bottom: 16px !important
      .brand-modal-footer
        background: none !important
        padding: 0.75rem 3.125rem 2.75rem !important
        margin-bottom: 0 !important

  .downgrade-modal
    .brand-modal.brand-modal-light
      .brand-modal-header
        background: #F2F4F5
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1)
        padding-top: 43px
        padding-bottom: 43px

      .brand-modal-footer
        background: #F2F4F5

    .divider
      width: 100%
      height: 1px
      background: #E9EFF4

    section
      margin: 30px 0

    h2
      font-size: 1.5em
      text-align: center
      margin-bottom: 27px

    .plan-cards-container
      display: flex
      align-items: center
      justify-content: center

      .arrow-right
        margin: 0 1.75em

      .plan-card
        border-radius: 3px
        overflow: hidden
        color: #fff
        width: 200px
        box-sizing: border-box
        .plan-card-header
          font-size: .75em
          font-weight: 700
          text-align: center
          padding: .3125em 0
        .plan-card-body
          text-align: center
          padding: .625em 0
          border-radius: 3px
          font-weight: 700
          font-size: 1em
          text-transform: uppercase

    .lost-features-section
      text-align: center
      h2
        margin-bottom: 17px

    .lost-features-container
      flex-direction: column
      display: inline-flex
      align-items: flex-start
      margin: 0 auto

      .lost-feature
        display: flex
        align-items: center

        i
          color: #DC3545
          margin-right: .625em

        .lost-feature-name
          font-weight: 700
          flex-grow: 1

    .changes-section
      .lost-feature
        align-items: flex-start
        margin-bottom: 1em
        width: 100%

        i
          margin-top: .1875em

        .lost-feature-name
          font-weight: 400

      .brand-table .tbody .brand-table-tr
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1)
        border: 1px solid #D1D5DF
        padding: 1.325em
        color: #EB5A29
        font-size: 1em

        .label
          color: #AAB1C1
          font-size: .75em

        .flex-direction-column
          flex-direction: column

    .domains-section
      .domain-lead
        display: flex
        align-items: center

        i
          font-size: 2em
          color: #DC3545
          margin-right: 1em

        .domain-lead-text
          flex-grow: 1

      .domains-container
        max-width: 80%
        margin: 0 auto

        .domain
          display: flex
          justify-content: space-between
          align-items: center

        .domain-name
          font-size: 1.25em

    .button-section
      text-align: center
      button
        width: 80%
        font-size: 1.25em
        font-weight: 700
</style>
