const constants = require('./constants');
const freemiumPlans = require('./freemiumPlans');
const nonFreemiumPlans = require('./nonFreemiumPlans');
const priceChange = require('./priceChange');

const NOT_UPGRADEABLE_PLAN_NAMES = [
  ...freemiumPlans.FREEMIUM_PLAN_NAMES.slice(-1),
  ...nonFreemiumPlans.NON_FREEMIUM_PLAN_NAMES.slice(-1),
  freemiumPlans.MASTER_PLAN.name,
  nonFreemiumPlans.ENTERPRISE_PLAN.name,
];

module.exports = {
  ...constants,
  ...freemiumPlans,
  ...nonFreemiumPlans,
  ...priceChange,
  NOT_UPGRADEABLE_PLAN_NAMES,
};
