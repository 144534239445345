module.exports = {
  UNLIMITED_VALUE: 999,
  FREE_PACKAGE_SKU: 'Package-EN-FREE-1',
  TRIAL_PACKAGE_SKU: 'Package-HU-TRIAL-1',
  DEMO_PACKAGE_SKU: 'Package-EN-DEMO-1',
  BELSO_PACKAGE_SKU: 'Package-EN-BELSO-1',
  OLD_PLAN_NAMES: ['BASIC', 'BUSINESS', 'PROFESSIONAL'],
  INVALID_SKUES: ['Package-HU-DEMO-1', 'Package-EN-BELSO-1'],
  OLD_AND_NEW_PLAN_ASSOCIATIONS: {
    ESSENTIAL: 'ESSENTIAL30',
    GROWTH: 'GROWTH100',
    PREMIUM: 'PREMIUM500',
  },
};
