<template lang="pug">
BasePane
  PaneLayout.mt-3.mb-2(gap="4px")
    template(#left)
      .input-label {{ $t('type') }}
    template(#right)
      Dropdown#typeDropdown(:items="socialTypes" v-model="type")
  template(v-if="type === 'socialShare'")
    PaneLayout.mb-2(gap="4px")
      template(#left)
        .input-label {{ $t('url') }}
      template(#right)
        OmInput#socialShareUrl(
          small
          v-model="socialShareUrl"
          :errorText="$t(urlError)"
          :error="urlError.length > 1"
        )
    PaneLayout.mb-4(gap="4px")
      template(#left)
        .input-label {{ $t('text') }}
      template(#right)
        OmInput#socialShareText(small v-model="socialShareText")
  template(v-if="type === 'socialFollow'")
    template(v-for="(item, index) in socialFollowServices")
      Accordion(fix)
        template(#title) {{ `${$t('service')} ${index + 1}` }}
        PaneLayout.mb-1(gap="4px")
          template(#left)
            .d-flex.justify-content-between.align-items-center
              .input-label {{ $t('linkText') }}
              .om-remove.social-remove(v-if="followLength > 1" @click="removeFollowService(index)") {{ $t('remove') }}
          template(#right)
            OmInput(small :id="`socialFollowLinkText-${index}`" v-model="item.linkText")
        PaneLayout.mb-2(gap="4px")
          template(#left)
            .input-label {{ $t('serviceUrl') }}
          template(#right)
            .d-flex.align-items-center
              Dropdown.mr-2.social-follow-service-dropdown(
                :id="`socialFollowServiceDropdown-${index}`"
                :items="followTypes"
                v-model="selectedElement.data.social[index].type"
                @input="changeSocialFollowType($event, index)"
              )
              OmInput.social-follow-service-input(
                small
                :id="`socialFollowServiceInput-${index}`"
                :value="socialFollowInputValue(index)"
                @input="updateSocialFollow($event, index)"
              )
  template(v-else)
    template(v-for="(item, index) in socialShareServices")
      Accordion(fix)
        template(#title) {{ `${$t('service')} ${index + 1}` }}
        PaneLayout.mb-2(gap="4px")
          template(#left)
            .d-flex.justify-content-between.align-items-center
              .input-label {{ $t('service') }}
              .om-remove.social-remove(v-if="shareLength > 1" @click="removeShareService(index)") {{ $t('remove') }}
          template(#right)
            Dropdown.mr-2.social-follow-service-dropdown(
              :id="`socialShareServiceDropdown-${index}`"
              :items="shareTypes"
              v-model="selectedElement.data.socialShare.types[index].type"
            )
  .d-flex.align-items-center.cursor-pointer.brand-color.mb-3.mt-2(
    v-if="!isAddDisabled"
    @click="onAddClick"
  )
    AddIcon.mr-2
    .font-size-0--875.font-weight-semibold.line-height-1 {{ $t('addAnotherService') }}
  hr.mb-4
  Accordion(fix)
    template(#title) {{ $t('style') }}
    PaneLayout(gap="4px" layout="col")
      template(#left)
        .d-flex.justify-content-center
          OmButton.px-4.py-2(iconOnly secondary @click="changeSocialIconStyle('square')")
            template(#icon)
              img(src="@/assets/editor/svg/social-icon-types/square.svg")
      template(#middle)
        .d-flex.justify-content-center
          OmButton.px-4.py-2(iconOnly secondary @click="changeSocialIconStyle('filled')")
            template(#icon)
              img(src="@/assets/editor/svg/social-icon-types/circle-filled.svg")
      template(#right)
        .d-flex.justify-content-center
          OmButton.px-4.py-2(iconOnly secondary @click="changeSocialIconStyle('simple')")
            template(#icon)
              img(src="@/assets/editor/svg/social-icon-types/circle.svg")
    RangeInput(:label="$t('iconSize')" v-model="iconSize" :min="10" :max="100" :step="1")
    Align.mb-2(:label-override="$t('align')" v-model="alignSelf")
  hr.mb-4
  Font(
    fontFamilyProperty="desktop.link.fontFamily"
    fontSizeProperty="desktop.link.fontSize"
    textWeightProperty="desktop.link.textWeight"
    fontItalicProperty="desktop.link.fontItalic"
    textDecorationProperty="desktop.link.textDecoration"
    hideAlign
  )
    template(#colors)
      OmColorInput(
        :label="$t('fontColor')"
        property="link.color"
        typeOfComponent="social"
        layout="col"
        dsAllowed
      )
  hr.mt-1.mb-4
  Accordion(fix)
    template(#title) {{ $t('spacing') }}
    template(#device)
      DeviceSelector(:hasViewText="false")
    Margin
  hr.mt-3.mb-4
  template(#advancedContent)
    Hide(:hideOnDesktop.sync="hideElementOnDesktop" :hideOnMobile.sync="hideElementOnMobile")
    BackgroundAndBorder(hideTitle typeOfComponent="social" fixOptions shadow)
      template(#color)
        OmColorInput(
          :label="$t('background')"
          typeOfComponent="social"
          property="desktop.background.color"
          layout="col"
          dsAllowed
        )
</template>

<script>
  import { mapState } from 'vuex';
  import Accordion from '@/components/Editor/Accordion/Accordion.vue';
  import itemMixin from '@/editor/mixins/item';
  import PaneLayout from '@/components/Editor/PaneLayout.vue';
  import Dropdown from '@/components/Editor/Dropdown/Dropdown.vue';
  import Align from '@/components/Editor/Controls/Alignments/Align.vue';
  import RangeInput from '@/components/Editor/RangeInput/RangeInput.vue';
  import Font from '@/components/Editor/Blocks/Font.vue';
  import Margin from '@/components/Editor/Controls/InputGroup/Margin.vue';
  import AddIcon from '@/editor/components/svg/Add.vue';
  import Hide from '@/components/Editor/Controls/Hide.vue';
  import BackgroundAndBorder from '@/components/Editor/Blocks/BackgroundAndBorder.vue';
  import BasePane from './BasePane.vue';
  import DeviceSelector from '../../components/DeviceSelector.vue';

  export default {
    components: {
      BasePane,
      Accordion,
      PaneLayout,
      Dropdown,
      Align,
      RangeInput,
      Font,
      Margin,
      AddIcon,
      BackgroundAndBorder,
      Hide,
      DeviceSelector,
    },
    mixins: [itemMixin],
    data: () => ({
      followTypes: [
        { text: 'instagram.com/', value: 'instagram' },
        { text: 'linkedin.com/', value: 'linkedin' },
        { text: 'facebook.com/', value: 'facebook' },
        { text: 'x.com/', value: 'x' },
        { text: 'pinterest.com/', value: 'pinterest' },
        { text: 'youtube.com/', value: 'youtube' },
        { text: 'tiktok.com/', value: 'tiktok' },
      ],
      shareTypes: [
        { text: 'Linkedin', value: 'linkedin' },
        { text: 'Facebook', value: 'facebook' },
        { text: 'X', value: 'x' },
      ],
      socialUrls: {
        instagram: 'https://instagram.com/',
        linkedin: 'https://linkedin.com/',
        facebook: 'https://facebook.com/',
        x: 'https://x.com/',
        pinterest: 'https://pinterest.com/',
        'plus.google': 'https://plus.google.com/',
        youtube: 'https://youtube.com/',
        tiktok: 'https://tiktok.com/',
      },
    }),
    computed: {
      ...mapState(['selectedElement', 'validationError']),
      socialTypes() {
        return [
          { text: this.$t('socialShare'), value: 'socialShare' },
          { text: this.$t('socialFollow'), value: 'socialFollow' },
        ];
      },
      socialFollowServices() {
        return this.selectedElement.data.social;
      },
      socialShareServices() {
        return this.selectedElement.data.socialShare.types;
      },
      followLength() {
        return this.socialFollowServices.length;
      },
      shareLength() {
        return this.socialShareServices.length;
      },
      isFollowLimit() {
        return this.followLength < 4;
      },
      isShareLimit() {
        return this.shareLength < 3;
      },
      isAddDisabled() {
        return this.type === 'socialFollow' ? !this.isFollowLimit : !this.isShareLimit;
      },
      type: {
        get() {
          return this.getValueOf('data.follow');
        },
        set(v) {
          this.setValueOf('data.follow', v);
        },
      },
      socialShareUrl: {
        get() {
          return this.getValueOf('data.socialShare.url');
        },
        set(v) {
          this.setValueOf('data.socialShare.url', v);
        },
      },
      socialShareText: {
        get() {
          return this.getValueOf('data.socialShare.text');
        },
        set(v) {
          this.setValueOf('data.socialShare.text', v);
        },
      },
      alignSelf: {
        get() {
          return this.getValueOf(`data.socialAlignment`);
        },
        set(v) {
          this.smartSetValueOf(`data.socialAlignment`, v);
        },
      },
      iconSize: {
        get() {
          return Number(this.getValueOf(`desktop.iconSize`));
        },
        set(v) {
          this.smartSetValueOf(`desktop.iconSize`, v);
        },
      },
      hideElementOnDesktop: {
        get() {
          return this.getValueOf('desktop.hidden');
        },
        set(v) {
          this.setValueOf('desktop.hidden', v);
        },
      },
      hideElementOnMobile: {
        get() {
          return this.smartGetValueOf('mobile.hidden');
        },
        set(v) {
          this.smartSetValueOf('mobile.hidden', v);
        },
      },
      urlError() {
        if (
          this.validationError &&
          this.validationError.property &&
          this.validationError.property.includes('socialShare.url')
        ) {
          return this.validationError.msg;
        }
        return '';
      },
    },
    methods: {
      changeSocialIconStyle(type) {
        this.setValueOf('data.socialType', type);
      },
      removeFollowService(index) {
        this.socialFollowServices.splice(index, 1);
        this.$store.commit('updateData', {
          property: 'selectedElement.data.social',
          value: this.socialFollowServices,
        });
      },
      removeShareService(index) {
        this.socialShareServices.splice(index, 1);
        this.$store.commit('updateData', {
          property: 'selectedElement.data.socialShare.types',
          value: this.socialShareServices,
        });
      },
      changeSocialFollowType(value, index) {
        const serviceUrl = this.socialUrls[value];
        console.log(value, serviceUrl);
        const inputValue = this.socialFollowServices[index].serviceUrl.replace(
          new RegExp('https://.*.com/'),
          '',
        );
        const fullServiceUrl = serviceUrl + inputValue;
        this.$store.commit('updateData', {
          property: `selectedElement.data.social.${index}.serviceUrl`,
          value: fullServiceUrl,
        });
      },
      socialFollowInputValue(index) {
        const social = this.socialFollowServices[index];
        const inputValue = social.serviceUrl.replace(this.socialUrls[social.type], '');
        return inputValue;
      },
      updateSocialFollow(value, index) {
        const social = this.socialFollowServices[index];
        const serviceUrl = this.socialUrls[social.type];
        const inputValue = value.charAt(0) === '/' ? value.substr(1) : value;
        const fullServiceUrl = serviceUrl + inputValue;
        this.$store.commit('updateData', {
          property: `selectedElement.data.social.${index}.serviceUrl`,
          value: fullServiceUrl,
        });
      },
      addFollowService() {
        this.socialFollowServices.push({
          linkText: this.$t('follow'),
          type: 'linkedin',
          serviceUrl: 'https://linkedin.com/',
        });
        this.$store.commit('updateData', {
          property: 'selectedElement.data.social',
          value: this.socialFollowServices,
        });
      },
      addShareService() {
        this.socialShareServices.push({ type: this.shareTypes[0].value });
        this.$store.commit('updateData', {
          property: 'selectedElement.data.socialShare.types',
          value: this.socialShareServices,
        });
      },
      onAddClick() {
        if (this.isAddDisabled) return;

        if (this.type === 'socialFollow') {
          this.addFollowService();
        } else {
          this.addShareService();
        }
      },
    },
  };
</script>

<style lang="sass">
  @import "@/sass/variables/_colors.sass"

  .input-label
    font-size: .75rem
    color: $om-dark-grey-3
  .social-follow-service-dropdown
    flex: 3
  .social-follow-service-input
    flex: 2
</style>
