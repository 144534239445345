const moment = require('moment');

const calculatePaidUntil = (dateExpires) => {
  return moment.utc(dateExpires).startOf('day').subtract(14, 'days');
};

const calculatePaidFrom = (dateExpires, period, isShopify) => {
  const paidUntil = calculatePaidUntil(dateExpires);

  if (isShopify && period === 1) {
    return moment(paidUntil).subtract(30, 'days');
  }
  return moment(paidUntil).subtract(period, 'months');
};

const getNextLimitResetAt = (dateExpires) => {
  const eventDate = moment.utc().startOf('day');
  const datePaid = moment(dateExpires).utc().subtract(14, 'day').startOf('day');
  const resetDayOfMonth = datePaid.date();
  const nextDate = moment(eventDate).startOf('day').startOf('month').set('date', resetDayOfMonth);
  if (nextDate.diff(eventDate) < 1 && !datePaid.isSame(eventDate)) {
    nextDate.add(1, 'month');
  }
  return nextDate.toDate();
};

const getPrevLimitResetAt = (dateExpires, isShopifyMonthly) => {
  const nextLimitResetAt = getNextLimitResetAt(dateExpires);
  return isShopifyMonthly
    ? moment.utc(nextLimitResetAt).subtract(30, 'days').toDate()
    : moment.utc(nextLimitResetAt).subtract(1, 'month').toDate();
};

module.exports = {
  calculatePaidUntil,
  calculatePaidFrom,
  getNextLimitResetAt,
  getPrevLimitResetAt,
};
