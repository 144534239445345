import { CURRENT_URL_RULE_SHOP_TYPE_BASED_PAGE_TYPES } from '@/utils/constant';

import { RULE_CART_RULES_V3, RULE_CURRENT_PAGE_URL_V2 } from '@/config/frontendRules/rules';

const checkShopifyLoggedIn = (rule, { isShopifyDomain }) => {
  if (!isShopifyDomain && rule.type === 'loggedIn') return false;
  return true;
};

const checkKlaviyoSegment = (rule, { isKlaviyoDomain }) => {
  if (!isKlaviyoDomain && rule.type === 'klaviyoSegment') return false;
  return true;
};

const checkEnhancedPageViews = (rule, { isShopifyDomain, isShoprenterDomain }) => {
  const isShopDomain = isShopifyDomain || isShoprenterDomain;
  if (!isShopDomain && rule.type === 'enhancedPageViews') return false;
  return true;
};

const EMBEDDED_APPLICABLE_RULES = [
  'pageViewerType',
  'source',
  'country',
  'subscribers',
  'klaviyoSegment',
  'previouslyViewedPage',
  'minimumPageVisit',
];

const EMBEDDED_V3_APPLICABLE_RULES = [
  'maximumPopupDisplay',
  'timeBasedSession',
  'campaignProgressState',
  'pageViewerType',
  'source',
  'country',
  'previouslyViewedPage',
  'notViewedPageRecent',
  'minimumPageVisit',
  'visitorCartV3',
  'cookie',
  'visitorAttribute',
];

const checkEmbedded = (rule, { isEmbedded, campaignVersion, currentUrlVersion }) => {
  if (!isEmbedded) return true;

  if (campaignVersion !== 3 && !EMBEDDED_APPLICABLE_RULES.includes(rule.type)) return false;

  const applicableRules = EMBEDDED_V3_APPLICABLE_RULES;
  if (currentUrlVersion === RULE_CURRENT_PAGE_URL_V2) {
    applicableRules.push('viewedPageV2');
  }

  if (campaignVersion === 3 && !applicableRules.includes(rule.type)) return false;

  return true;
};

const checkDynamicContentRule = (rule, { isDynamicContent, campaignVersion }) => {
  if (!isDynamicContent) return true;

  if (campaignVersion === 3 && !EMBEDDED_V3_APPLICABLE_RULES.includes(rule.type)) return false;

  return true;
};

const checkCartRules = (rule, { isShopifyDomain, isShoprenterDomain }) => {
  const isShopDomain = isShopifyDomain || isShoprenterDomain;

  if (!isShopDomain && [RULE_CART_RULES_V3].includes(rule.type)) {
    return !rule.options.expressions.some((expression) => {
      return expression.attributeName === 'id' && expression?.meta?.products?.length;
    });
  }
  if (isShoprenterDomain && [RULE_CART_RULES_V3].includes(rule.type)) {
    return !rule.options.expressions.some((expression) => {
      return (
        expression.attributeName === 'id' && expression?.meta?.products?.[0].type !== 'shoprenter'
      );
    });
  }
  if (isShopifyDomain && [RULE_CART_RULES_V3].includes(rule.type)) {
    return !rule.options.expressions.some((expression) => {
      return (
        expression.attributeName === 'id' && expression?.meta?.products?.[0].type !== 'shopify'
      );
    });
  }

  return true;
};

const checkCurrentUrlRuleCompatibility = (rule, { isShopifyDomain, isShoprenterDomain }) => {
  if (rule.type !== RULE_CURRENT_PAGE_URL_V2) return true;
  if (isShopifyDomain || isShoprenterDomain) return true;

  const expressions = [
    ...(rule.options?.includeExpressions ?? []),
    ...(rule.options?.excludeExpressions ?? []),
  ];
  const hasShopTypeBasedCondition = expressions.find((condition) =>
    CURRENT_URL_RULE_SHOP_TYPE_BASED_PAGE_TYPES.includes(condition.pageType),
  );
  return !hasShopTypeBasedCondition;
};

const rulesValidators = [
  checkShopifyLoggedIn,
  checkCartRules,
  checkEmbedded,
  checkKlaviyoSegment,
  checkEnhancedPageViews,
  checkDynamicContentRule,
  checkCurrentUrlRuleCompatibility,
];

const isPersonalized = ({ type }, { personalizedRules }) => personalizedRules.includes(type);

const isApplicable = (rule, extras) => {
  return rulesValidators.reduce((acc, validator) => acc && validator(rule, extras), true);
};

const filterRules = (rules, extras) => {
  const applicable = [];
  const notApplicable = [];
  const personalized = [];
  rules.forEach((rule) => {
    if (isPersonalized(rule, extras)) personalized.push(rule);
    else if (isApplicable(rule, extras)) applicable.push(rule);
    else notApplicable.push(rule);
  });

  return {
    applicable,
    notApplicable,
    personalized,
  };
};

export default filterRules;
