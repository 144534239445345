<template lang="pug">
BasePane(:savedStyle="false" :zIndex="false" :customClass="false")
  PaneLayout.mt-2(gap="4px")
    template(#left)
      .input-label {{ $t('message') }}
    template(#right)
      OmInput#message(small v-model="message")
  .flex-row-baseline.scratchcard-button
    OmButton(@click="showScratchCardSettings" primary small block) {{ $t('settings') }}
  .flex-row-baseline.scratchcard-button
    OmButton(@click="showScratchCardTest" primary small block) {{ $t('scratchCard.test.showButton') }}
  PaneLayout.mt-4.mb-3(gap="8px")
    template(#left)
      .input-label {{ $t('scratchCard.bg.win') }}
    template(#right)
      .sidebar-input-wrapper.p-0
        .sidebar-feedback-image.sidebar-upload-area.mb-2(@click="imageManager('win')")
          img(:src="imageUrl('win')")
  PaneLayout.mb-3(gap="8px")
    template(#left)
      .input-label {{ $t('scratchCard.bg.lose') }}
    template(#right)
      .sidebar-input-wrapper.p-0
        .sidebar-feedback-image.sidebar-upload-area.mb-2(@click="imageManager('lose')")
          img(:src="imageUrl('lose')")
  PaneLayout(gap="0px")
    template(#left)
      .input-label {{ $t('image') }}
    template(#right)
      OmColorInput(size="lg" typeOfComponent="scratchCard" deviceSelector dsAllowed)
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import Heading from '@/components/Editor/Heading.vue';
  import itemMixin from '@/editor/mixins/item';
  import PaneLayout from '@/components/Editor/PaneLayout.vue';
  import BasePane from './BasePane.vue';

  export default {
    components: {
      BasePane,
      PaneLayout,
      Heading,
    },
    mixins: [itemMixin],
    data: () => ({}),
    computed: {
      ...mapState(['selectedElement']),
      message: {
        get() {
          return this.getValueOf('selectedElement.data.message');
        },
        set(v) {
          this.setValueOf('selectedElement.data.message', v);
        },
      },
      backgroundSrc() {
        return this.getValueOf('data.background.win.imageUrl');
      },
    },
    methods: {
      ...mapMutations([
        'showImageManager',
        'setImageManagerDestination',
        'changeFormManagerVisibility',
      ]),
      showScratchCardSettings() {
        this.changeFormManagerVisibility({ show: 'scratchCardSettings' });
      },
      showScratchCardTest() {
        this.changeFormManagerVisibility({ show: 'scratchCardTest' });
      },
      imageUrl(type) {
        return this.getValueOf(`data.background.${type}.imageUrl`);
      },
      imageManager(type) {
        this.setImageManagerDestination({
          targetAttr: `selectedElement.data.background.${type}`,
          simpleImage: true,
        });
        this.showImageManager();
      },
    },
  };
</script>

<style lang="sass">
  @import "@/sass/variables/_colors.sass"

  .input-label
    font-size: .75rem
    color: $om-dark-grey-3
  .scratchcard-button
    margin-top: .75rem
  .ds-editor-pane-layout
    .sidebar-upload-area
      height: 5rem !important
</style>
