<template lang="pug">
nav.navbar.sticky-top.navbar-expand-lg.brand-custom-navbar.brand-editor-navbar.editor(
  :class="`brand-editor-navbar-${$i18n.locale}`"
)
  .container.p-0.h-100(
    :class="{ 'brand-editor-navbar-floating': !isCreateGoal && !isCampaignSettings && isEditor }"
  )
    .d-flex-1.h-100
      .om-logo.br-1.align-items-center.cursor-pointer(
        v-if="shouldShowLogo"
        @click="$bus.$emit('logoClicked', isAgencyAccount ? `/${$route.params.userId}/partner-dashboard` : `/${$route.params.userId}/dashboard`)"
      )
        img.logo(:src="customSquaredLogo || require('@/assets/editor/svg/logo.svg')")
      .center-slide
        .left-part.d-flex-1(v-if="!isCampaignSettings && !isCreateGoal")
          .after-logo
            .left-navigation.px-3
              i.fas.fa-chevron-left.pointer(style="color: #505763" @click="back")
            .left-side-campaign-info.d-flex-1.flex-column
              .campaign-name.truncate25(@click="campaignNameClicked") {{ $store.state.campaign.campaignName }}
              .variant-name.truncate25 {{ $store.state.campaign.variantName }}
        .center-part.d-flex-1(v-if="!isCampaignSettings && !isCreateGoal")
          .device-select-area.d-flex-1
            span.device-selectors
              .device-selector(:class="{ selected: !mobilePreview }")
                om-button.btn-link(
                  :class="{ selected: !mobilePreview }"
                  icon="desktop"
                  iconOnly
                  ghost
                  @click="setEditorPreviewMode({ mode: 'desktop', reset: false })"
                )
              .device-selector.mobile-editor(:class="{ selected: mobilePreview }")
                om-button.btn-link(
                  :class="{ selected: mobilePreview }"
                  icon="mobile-android"
                  iconOnly
                  ghost
                  @click="setEditorPreviewMode({ mode: 'mobile', reset: false })"
                )
              .preview-link
                om-button.btn-link(
                  v-if="!isEmbedded"
                  ghost
                  :loading="isPreviewSaving"
                  @click="openPreview()"
                ) {{ $t('editor.preview') }}
            template(v-if="featureGenerateWithAI")
              om-button.btn-link(@click="$modal.show('generate-content-with-ai')") Generate
          .undo-redo-area.d-flex-1.justify-content-end(v-if="isEditor && !isCampaignSettings")
            om-button.px-1(
              @click="undo"
              :disabled="!isActiveUndo"
              :title="$t('undo')"
              ghost
              iconOnly
            )
              template(slot="icon")
                Undo(:disabled="!isActiveUndo")
            om-button.px-1(
              @click="redo"
              :disabled="!isActiveRedo"
              :title="$t('redo')"
              ghost
              iconOnly
            )
              template(slot="icon")
                Redo(:disabled="!isActiveRedo")
        .right-part.d-flex-1(:class="{ 'b-0': (isCreateGoal || isCampaignSettings) && isEditor }")
          .topbar.justify-content-end(
            :class="{ 'existing-campaign': !isCampaignSettings && !isNew }"
          )
            .right-slide-group
              .separator.bl-1(v-if="!isCampaignSettings && !isCreateGoal")
              .save-next-area(
                :class="{ 'justify-content-end': isCampaignSettings || isCreateGoal }"
              )
                om-button.mx-2(
                  small
                  ghost
                  v-if="!isCreateGoal && !isCampaignSettings && isEditor"
                  @click="backOrCancel"
                ) {{ isNew ? $t('back') : $t('cancel') }}
                om-button.mx-2(
                  small
                  secondary
                  v-if="(isCreateGoal || isCampaignSettings) && isEditor"
                  @click="back"
                ) {{ $t('back') }}
                template(v-if="!isCreateGoal && !isCampaignSettings && isEditor")
                  om-button.mx-2(
                    small
                    secondary
                    v-if="!isNew"
                    :loading="!saveCompleted && clicked === 'save'"
                    @click="save('save')"
                  )
                    span {{ $t('save') }}
                  om-button.mx-2(
                    :class="{ 'wide-button': lang === 'hu' && !isNew }"
                    small
                    primary
                    @click="nextOrSave"
                    v-if="isTeaserSelected"
                    :loading="!saveCompleted && !clicked"
                  )
                    span {{ getButtonTextOnTeaserSelection }}
                  om-button.mr-1(
                    :class="{ 'wide-button': lang === 'hu' && !isNew, 'mx-2': lang === 'en' }"
                    primary
                    small
                    :disabled="isNextButtonDisabled"
                    @click="nextOrSave"
                    :loading="!saveCompleted && !clicked"
                    v-else
                  )
                    span {{ isNew ? $t('next') : $t('saveAndExit') }}
                om-button.mx-2(
                  small
                  primary
                  @click="next"
                  v-if="(isCreateGoal || isCampaignSettings) && isEditor"
                  :class="{ loading: !saveCompleted }"
                )
                  span {{ isNew ? $t('next') : $t('save') }}
  modal(name="save-or-discard-changes" :click-to-close="false" height="auto")
    .vue-dialog(style="margin: auto")
      .dialog-content(v-html="$t('cancelDialog')")
      .vue-dialog-buttons.d-flex-1.justify-content-center.save-or-discard-changes-btn-box
        button.brand-btn.brand-btn-success.mr-3(@click="localSave" ref="saveOrDiscardSaveBtn") {{ $t('save') }}
        button.brand-btn.brand-btn-primary.mr-3(@click="localDiscard") {{ $t('discard') }}
        button.brand-btn.brand-btn-secondary(@click="localCancel()") {{ $t('cancel') }}
  modal(name="save-or-discard-changes-change-editor" :click-to-close="false" height="auto")
    .vue-dialog(style="margin: auto")
      .dialog-content(v-html="$t('cancelDialog')")
      .vue-dialog-buttons.d-flex-1.justify-content-center.save-or-discard-changes-btn-box
        button.brand-btn.brand-btn-success.mr-3(
          @click="localSaveChangeEditor"
          ref="saveOrDiscardSaveBtn"
        ) {{ $t('save') }}
        button.brand-btn.brand-btn-primary.mr-3(@click="localDiscard") {{ $t('discard') }}
        button.brand-btn.brand-btn-secondary(@click="localCancel(true)") {{ $t('cancel') }}
  modal(name="products-missing" :click-to-close="false" height="auto")
    .vue-dialog(style="margin: auto")
      .dialog-content(v-html="$t('productsMissing')")
      .dialog-text.mt-3(v-html="$t('productsMissingText')")
      .vue-dialog-buttons.d-flex-1.justify-content-center.products-missing-btn-box
        button.brand-btn.brand-btn-secondary.mr-3(@click="confirmProductsMissingModal") {{ $t('onlyNeedThese') }}
        button.brand-btn.brand-btn-primary.px-5(
          @click="$modal.hide('products-missing'); clicked = null"
        ) {{ $t('addMore') }}
  modal(name="generate-content-with-ai" height="auto")
    .vue-dialog(style="margin: auto")
      .dialog-content.align-items-center
        label Prompt
        om-textarea(v-model="contentPrompt")
      .vue-dialog-buttons.d-flex-1.justify-content-center
        om-button.brand-btn.brand-btn-secondary.mr-3(
          @click="$modal.hide('generate-content-with-ai')"
        ) Close
        om-button.brand-btn.brand-btn-primary.px-5.mr-3(
          :loading="loadingContentGen"
          @click="generateWithAI"
        ) Generate
        om-button.brand-btn.brand-btn-primary.px-5(
          :loading="loadingContentGen"
          @click="generateByResponse"
        ) Generate from JSON
</template>

<script>
  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
  import { track } from '@/services/xray';
  import moment from 'moment';
  import History from '@/editor/services/history';
  import { get as _get } from 'lodash-es';
  import whiteLabelling from '@/mixins/whiteLabelling';
  import unsavedChangesMixin from '@/editor/mixins/unsavedChanges';
  import DELETE_CAMPAIGNS from '@/graphql/DeleteCampaigns.gql';
  import SAVE_PREVIEW from '@/graphql/SavePreview.gql';
  import Undo from '@/assets/editor/svg/undo.vue';
  import Redo from '@/assets/editor/svg/redo.vue';
  import { TEMPLATE_FEATURES } from '@/editor/util';
  import { MockLayouts } from '@/editor/components/MockSite.vue';
  import { frameStore } from '@/util';
  import dynamicContent from '@/mixins/dynamicContent';
  import { isGenerateWithAIEnabled } from '@/utils/features';
  import { getContextProperties } from '@/services/userInteractionTracker/properties';
  import { baseUrl } from '@/config/url';

  const defaultPromptText = `Create a friendly 2 page popup campaign to blitzwolf website visitors, about a new coupon offer which applies to all product. The aim of the popup is to get as many email as possible. The first page contains a heading with email input and button. The second page contains a thank you heading and a coupon with button. The popup campaigns should be short and relevant.
    A page can have heading, subheading, text, input, button.
    The output should be JSON formatted and with the following structure:
    """
    "Page 1": {
        "Heading": "some text",
        "Subheading": "some text"
        "Input": "placeholder text",
        "Button": "button text"
    },
    "Page 2": {
      ...
    }
    """`;

  export default {
    components: {
      Undo,
      Redo,
    },
    mixins: [whiteLabelling, unsavedChangesMixin, dynamicContent],
    data() {
      return {
        contentPrompt: defaultPromptText,
        loadingContentGen: false,

        redirectAfterSave: null,
        currentDomain: null,
        previouslySaved: false,
        lastEditedVariant: null,
        clicked: null,
        MockLayouts,
        isPreviewSaving: false,
      };
    },
    computed: {
      ...mapState([
        'campaign',
        'isActiveUndo',
        'isActiveRedo',
        'account',
        'selectedPage',
        'teaserPreview',
        'validationError',
        'template',
        'mobilePreview',
        'mockSiteLayout',
        'partnerTypeService',
      ]),
      ...mapGetters([
        'databaseId',
        'isShopifyDomain',
        'shouldEnableShopifyAppEmbedForDomain',
        'customSquaredLogo',
        'isAgencyImpersonate',
        'customSquaredLogo',
        'campaignInnerId',
        'campaignId',
        'teaserPage',
        'hasTemplateFeature',
        'isEmbedded',
        'isSteveMode',
        'hasAccountFeature',
        'isNewCampaign',
      ]),
      validatePage() {
        if (this.isNew && !this.mobilePreview) return true;
        return false;
      },
      getButtonTextOnTeaserSelection() {
        if (this.validatePage) return this.$t('next');
        return this.$t('saveAndExit');
      },
      saveCompleted() {
        return this.$store.state.saveCompleted;
      },
      isCreateGoal() {
        return this.$route.name === 'dc_goal_create';
      },
      isCampaignSettings() {
        return this.$route.name === 'campaign_settings';
      },
      isEditor() {
        return this.$store.state.isEditor;
      },
      isNextButtonDisabled() {
        return !this.selectedPage?.uid;
      },
      isTemplateEditor() {
        return this.$route.name === 'template_editor';
      },
      isNew() {
        return this.$route.params.new === 'new';
      },
      newParam() {
        return this.$route.params.new;
      },
      campaignId() {
        return this.$route.params.campaignId;
      },
      id() {
        return this.$route.params.id;
      },
      domains() {
        return _get(this.account, 'settings.domains');
      },

      isAgencyAccount() {
        return (
          this.partnerTypeService.isResellerPartnerType() || this.partnerTypeService.isCustom()
        );
      },
      isTeaserSelected() {
        return (
          this.teaserPreview ||
          (this.teaserPage && this.selectedPage && this.teaserPage.uid === this.selectedPage.uid)
        );
      },
      lang() {
        return window.om.i18n.locale;
      },
      isFirstCampaign() {
        return this.isNew && this.$route.params.id === '1';
      },
      shouldShowLogo() {
        return !['campaign_settings', 'dc_goal_create'].includes(this.$route.name);
      },
      features() {
        return this.account && this.account.features ? this.account.features : [];
      },
      isOnboardingFinished() {
        return this.$store.state.account.settings.onboarding.finished;
      },
      featureGenerateWithAI() {
        return isGenerateWithAIEnabled(this.features);
      },
    },
    watch: {
      saveCompleted(v) {
        if (v === true && this.redirectAfterSave) {
          this.$router.push(this.redirectAfterSave);
          this.redirectAfterSave = null;
        }
      },
      validationError(v) {
        if (v && (v.msg || v.property)) {
          this.setStateAttr({ attr: 'teaserPreview', value: false });
          this.$bus.$emit('setTeaserPreview', false);

          if (v.property === 'products.count') {
            this.$modal.show('products-missing');
          }
        }
      },
    },
    created() {
      this.getWhiteLabelSettings();
      this.$bus.$on('cleared', this.setupEventHandlers);
      this.$bus.$on('endLoadTemplate', this.templateLoadingEnded);
      this.setupEventHandlers();
    },
    beforeUpdate() {
      if (this.$route.name === 'campaign_settings') {
        this.historyPushed = false;
        this.prevTemplateHash = null;
        this.skip = false;
        this.saved = false;
        this.pathName = null;
        this.setStateAttr({ attr: 'teaserPreview', value: false });
        this.$bus.$emit('setTeaserPreview', false);
      }
    },
    beforeDestroy() {
      this.$bus.$off('cleared', this.setupEventHandlers);
      this.$bus.$off('endLoadTemplate', this.templateLoadingEnded);
    },
    mounted() {
      this.$bus.$on('undo-redo', ({ type }) => {
        this[type]();
      });
      this.$bus.$on('templateSaved', () => {
        this.clicked = null;
      });
    },
    methods: {
      ...mapActions(['setEditorPreviewMode', 'validateElements']),
      ...mapMutations([
        'selectPage',
        'setStateAttr',
        'deselectAll',
        'activatePaneL2',
        'confirmProductsMissing',
        'changeFormManagerVisibility',
      ]),
      setupEventHandlers() {
        this.$bus.$on('domainChanged', (domain) => {
          this.currentDomain = domain;
        });
      },
      templateLoadingEnded(isEnded) {
        if (!isEnded && this.isNew) {
          this.$router.replace({ name: 'new_campaign' });
        }
      },
      openWindow(url, type) {
        track('editor_click', {
          location: 'editor-header',
          setting: 'open-preview',
          previewType: type,
          ...getContextProperties(),
        });
        window.open(url, '_blank');
      },
      async openPreview() {
        if (!this.isCampaignSettings) {
          const isElementsValid = await this.validateElements(this.selectedPage?.uid);
          if (!isElementsValid) return;
        }

        this.isPreviewSaving = true;

        const { domain } = this.campaign;
        const { lastRequestDate, v3LastRequestDate } = this.domains.find(
          ({ domain: d }) => d === domain,
        );
        const variantId = this.$route.params.id;
        const previewQueryParam = `om-preview-id=${variantId}`;
        const previewURL = `https://${domain}/?${previewQueryParam}`;

        const success = await this.savePreview();

        this.isPreviewSaving = false;
        const noInsertCodePreviewUrl = `${baseUrl}preview/${this.databaseId}?${previewQueryParam}`;

        if (!lastRequestDate && !v3LastRequestDate) {
          this.openWindow(noInsertCodePreviewUrl, 'no-insert-code');
          return;
        }

        const detectedOldScript = moment().diff(moment(lastRequestDate), 'hours') < 24;
        const detectedV3Script = v3LastRequestDate
          ? moment().diff(moment(v3LastRequestDate), 'hours') < 24
          : false;

        if (!detectedOldScript && !detectedV3Script) {
          this.openWindow(noInsertCodePreviewUrl, 'no-insert-code');
          return;
        }

        if (success) {
          this.openWindow(previewURL, 'live-site');
        }
      },

      collectPreviewData() {
        return {
          campaignId: this.campaign.campaignId,
          variantId: this.$route.params.id,
          template: frameStore().state.template,
        };
      },
      async savePreview() {
        const { campaignId, variantId, template } = this.collectPreviewData();
        // No need to save in template editor mode
        if (campaignId == null || variantId == null) {
          return false;
        }
        try {
          const {
            data: { savePreview },
          } = await this.$apollo.mutate({
            mutation: SAVE_PREVIEW,
            variables: {
              variantId,
              campaignId,
              template,
            },
          });
          return savePreview;
        } catch (e) {
          this.$notify({
            type: 'error',
            text: 'Error while saving preview',
          });
        }
        return false;
      },

      campaignNameClicked() {
        this.showUnsavedWarning();
      },
      undo() {
        if (this.isActiveUndo) {
          History.undo();
        }
      },
      redo() {
        if (this.isActiveRedo) {
          History.redo();
        }
      },
      editorClick() {
        if (this.isCampaignSettings) this.back();
      },
      settingsClick() {
        if (!this.isCampaignSettings) this.next();
      },
      backOrCancel() {
        const isNewTeaser = this.hasTemplateFeature(TEMPLATE_FEATURES.NEW_TEASER);
        const teaser = this.getTeaser();
        const onFirstPage = this.onFirstPage();

        const pages = this.getPages();

        if (this.isSteveMode) {
          this.$router.push({ name: 'template_manager' });
          return;
        }

        if (this.isNewCampaign) {
          if (!isNewTeaser && this.teaserPreview) {
            this.toggleDesktopMobilePreview(() => {
              this.$emit('resetEditorPane');
              this.selectPage(pages[pages.length - 1].uid);
              this.setStateAttr({ attr: 'teaserPreview', value: false });
              this.$bus.$emit('setTeaserPreview', false);
              this.setStateAttr({ attr: 'paneLevel', value: 1 });
            }, 'prev');
          } else if (isNewTeaser && this.selectedPage.uid === teaser.uid) {
            this.toggleDesktopMobilePreview(() => {
              this.selectPage(pages[pages.length - 1].uid);
            }, 'prev');
          } else if (onFirstPage) {
            if (!this.hasUnsavedChanges()) {
              this.toggleDesktopMobilePreview(() => {
                this.deleteNewCampaign();
              }, 'prev');
            } else {
              this.toggleDesktopMobilePreview(() => {
                this.goBackWithSaveCheck();
              }, 'prev');
            }
          } else if (!onFirstPage) {
            this.toggleDesktopMobilePreview(() => {
              const index = this.getCurrentPageIndex();
              const prevPage = pages[index - 1];

              this.selectPage(prevPage.uid);
            }, 'prev');
          }
        } else {
          this.back();
        }
      },
      toggleDesktopMobilePreview(callback, direction = 'next') {
        const mode = this.mobilePreview ? 'desktop' : 'mobile';
        const shouldCallback =
          (direction === 'prev' && !this.mobilePreview) ||
          (direction === 'next' && this.mobilePreview);

        if (
          direction === 'prev' &&
          this.isNewCampaign &&
          this.onFirstPage() &&
          !this.mobilePreview
        ) {
          callback();
        } else {
          this.setEditorPreviewMode({ mode, reset: false });

          if (shouldCallback) {
            callback();
          }
        }
      },
      async nextOrSave() {
        const pageValidation = this.validatePage ? this.selectedPage.uid : 'all'; // validating full template (saveAndExit)
        const isElementsValid = await this.validateElements(pageValidation);
        if (!isElementsValid) return;

        if (this.isNew) {
          this.deselectAll();
          this.$nextTick(() => {
            const isNewTeaser = this.hasTemplateFeature(TEMPLATE_FEATURES.NEW_TEASER);
            const teaser = this.getTeaser();
            const onLastPage = this.onLastPage();
            this.clicked = null;
            const isSaveAvailable =
              this.mobilePreview &&
              ((this.isEmbedded && onLastPage) ||
                (!isNewTeaser && this.teaserPreview) ||
                (isNewTeaser && teaser && this.selectedPage.uid === teaser.uid));

            this.$bus.$emit('openRightSidebar', true);
            if (isSaveAvailable) {
              this.next();
            } else if (isNewTeaser && onLastPage) {
              this.toggleDesktopMobilePreview(() => {
                this.selectPage(teaser.uid);
              });
            } else if (!onLastPage) {
              this.toggleDesktopMobilePreview(() => {
                const index = this.getCurrentPageIndex();
                const pages = this.getPages();
                const nextPage = pages[index + 1];
                this.selectPage(nextPage.uid);
              });
            } else if (onLastPage) {
              this.toggleDesktopMobilePreview(() => {
                this.$emit('resetEditorPane');
                this.setStateAttr({ attr: 'teaserPreview', value: true });
                this.$bus.$emit('setTeaserPreview', true);
                this.activatePaneL2('TeaserPane');
              });
            }
          });
        } else {
          this.next();
        }
      },
      back() {
        if (this.isCampaignSettings) {
          if (this.isDynamicContent) {
            const { id, userId } = this.$route.params;
            this.pathName = `/${userId}/campaign/${id}`;
            this.goBackWithSaveCheck();
            return;
          }
          this.$router.go(-1);
        } else if (this.isCreateGoal) {
          const { id, userId } = this.$route.params;
          this.pathName = `/${userId}/campaign/${id}/settings?activeBox=condition`;
          this.$router.push(this.pathName);
        } else if (this.isNewCampaign && !this.hasUnsavedChanges()) {
          this.deleteNewCampaign();
        } else if (this.isNewCampaign && this.hasUnsavedChanges()) {
          this.goBackWithSaveCheck();
        } else {
          const { campaignId, userId } = this.$route.params;
          this.pathName = `/${userId}/campaign/${campaignId}`;
          this.goBackWithSaveCheck();
        }
      },
      async save(type) {
        this.clicked = type || null;
        if (!this.isCampaignSettings) {
          const isElementsValid = await this.validateElements();
          if (!isElementsValid) return;
        }
        this.$bus.$emit('saveTemplate');
        this.savePreview();
      },
      emitEventWithRedirect(eventToEmit, routeName, params, query) {
        this.$bus.$emit(eventToEmit, {
          redirect: {
            name: routeName,
            params,
            ...(query && { query }),
          },
        });
      },
      async next() {
        if (!this.isCampaignSettings) {
          const isElementsValid = await this.validateElements(this.selectedPage?.uid);
          if (!isElementsValid) return;
        }

        if (this.isCampaignSettings) {
          if (this.isDynamicContent && this.isNew) {
            this.emitEventWithRedirect('saveCampaignSettings', 'dc_goal_create', {
              id: this.id,
              new: this.newParam,
            });

            return;
          }

          if (this.isFirstCampaign) {
            this.emitEventWithRedirect('saveCampaignSettings', 'user_experience_protector', {
              campaignId: this.id,
              new: this.newParam,
            });
            return;
          }

          this.emitEventWithRedirect('saveCampaignSettings', 'campaign_variants', {
            id: this.id,
            new: this.newParam,
          });
        } else if (this.isCreateGoal) {
          if (this.isFirstCampaign) {
            this.emitEventWithRedirect('saveDCGoal', 'user_experience_protector', {
              campaignId: this.id,
              new: this.newParam,
            });

            return;
          }

          this.emitEventWithRedirect('saveDCGoal', 'campaign_variants', {
            id: this.id,
            new: this.newParam,
          });
        } else {
          this.savePreview();
          this.$bus.$emit('saveTemplate', { nextButton: true, new: this.isNew });
          const userId = this.$route.params.userId;
          if (this.isTemplateEditor) {
            this.redirectAfterSave = `/${userId}/template-manager`;
            return;
          }

          const extraParam = this.isNew ? '/settings/new' : '';

          this.redirectAfterSave = `/${userId}/campaign/${this.campaignId}${extraParam}`;
        }
      },
      getPages() {
        return window.om.template.elements.filter((el) => el.type === 'OmPage' && !el.isTeaser);
      },
      getTeaser() {
        return window.om.template.elements.find((el) => el.type === 'OmPage' && el.isTeaser);
      },
      onFirstPage() {
        const firstPage = this.getPages()[0];
        return this.selectedPage?.uid === firstPage?.uid;
      },
      deleteNewCampaign(cb) {
        cb =
          cb ||
          (() => {
            const source = _get(this.$route.query, 'source');
            if (source === 'wizard') {
              this.$router.go(-1);
            } else if (source?.includes('use-case')) {
              const useCaseId = _get(this.$route.query, 'use-case-id');
              this.$router.replace({
                name: 'use_case_templates',
                params: { id: useCaseId },
              });
            } else {
              this.$router.replace({ name: 'new_campaign' });
            }
          });
        this.$apollo
          .mutate({
            mutation: DELETE_CAMPAIGNS,
            variables: {
              campaignIds: [this.campaignInnerId],
            },
          })
          .then(cb);
      },
      localDiscard() {
        this.skip = true;
        if (this.isNewCampaign && !this.previouslySaved) {
          if (this.pathName) {
            this.deleteNewCampaign(() => this.$router.replace(this.pathName));
          } else {
            this.deleteNewCampaign();
          }
        } else {
          this.redirectByPathName();
        }
      },
      redirectByPathName() {
        let location = {
          name: 'campaign_variants',
          params: { id: this.campaignId, new: this.newParam },
        };

        if (this.pathName) {
          const path = this.pathName;
          this.pathName = null;
          location = { path };
        }

        this.$router.replace(location);
      },
      async localSave() {
        const isElementsValid = await this.validateElements();

        if (!isElementsValid) return;

        this.skip = true;
        this.saved = true;
        this.previouslySaved = true;
        this.$bus.$on('templateSaved', () => {
          this.$router.replace({
            name: 'campaign_variants',
            params: { id: this.campaignId, new: this.newParam },
          });
        });
        this.$bus.$emit('saveTemplate');
      },
      localSaveChangeEditor() {
        this.skip = true;
        this.saved = true;
        this.previouslySaved = true;
        this.$bus.$emit('saveTemplate');
      },
      localCancel(editorVersionChanged = false) {
        this.cancelChanges(editorVersionChanged);
      },
      onLogoClick(path) {
        this.setPathName(path);
        if (this.hasUnsavedChanges()) {
          this.showUnsavedWarning();
        } else if (this.isNewCampaign && !this.previouslySaved) {
          this.deleteNewCampaign(() => this.$router.replace(path));
        } else {
          this.$router.push(path);
        }
      },
      onLastPage() {
        const pages = this.getPages();
        const lastPage = pages[pages.length - 1];
        return this.selectedPage.uid === lastPage.uid;
      },
      getCurrentPageIndex() {
        const pages = this.getPages();
        return pages.findIndex((el) => el.uid === this.selectedPage.uid);
      },
      confirmProductsMissingModal() {
        this.confirmProductsMissing();
        this.$modal.hide('products-missing');
        this.nextOrSave();
      },
      async generateWithAI() {
        const frameStore = () => {
          const workspace = document.getElementById('workspaceFrame');
          if (!workspace) {
            return;
          }
          return workspace.contentWindow.om?.store;
        };

        this.loadingContentGen = true;
        const workspaceStore = frameStore();

        try {
          await workspaceStore.dispatch('generateContentWithAi', { prompt: this.contentPrompt });
        } catch (e) {
          console.error(e);
        }
        this.loadingContentGen = false;
      },
      async generateByResponse() {
        const frameStore = () => {
          const workspace = document.getElementById('workspaceFrame');
          if (!workspace) {
            return;
          }
          return workspace.contentWindow.om?.store;
        };

        this.loadingContentGen = true;
        const workspaceStore = frameStore();

        try {
          await workspaceStore.dispatch('generateContentByJSON', { content: this.contentPrompt });
        } catch (e) {
          console.error(e);
        }
        this.loadingContentGen = false;
      },
    },
  };
</script>
