<template lang="pug">
BasePane
  Accordion(fix)
    InputElementDropdown.mt-3(
      :items="inputs"
      v-model="inputValue"
      @manage="manageFields"
      @create="createNewInput"
    )
    InputCommonFields
      template(v-if="hasOptions" #placeholder)
        InputElementOptions
    OnClickAction
  hr.mt-3.mb-4
  Font(
    fontFamilyProperty="subElements.option.$device.fontFamily"
    fontSizeProperty="subElements.option.$device.fontSize"
    textWeightProperty="subElements.option.$device.textWeight"
    fontItalicProperty="subElements.option.$device.fontItalic"
    textDecorationProperty="subElements.option.$device.textDecoration"
    textAlignProperty="subElements.option.$device.textAlign"
    editMobile
  )
    template(#colors)
      OmColorInput(
        :label="$t('fontColor')"
        property="subElements.option.$device.color"
        typeOfComponent="survey"
        layout="col"
        dsAllowed
        :editMobile="false"
      )
  hr.mt-1.mb-4
  BackgroundAndBorder(
    borderProp="subElements.option.$device.border"
    shadowProp="subElements.option.$device.shadow"
    typeOfComponent="survey"
    shadow
    :deviceSelector="false"
  )
    template(#color)
      OmColorInput(
        :label="$t('background')"
        property="subElements.option.$device.background.color"
        typeOfComponent="survey"
        layout="col"
        dsAllowed
        :editMobile="false"
      )
    template(#cornerRadius)
      CornerRadius(
        radiusProperty="subElements.option.$device.border.radius"
        chainedProperty="subElements.option.$device.border.radiusChained"
        customThemeRoundingProperty="subElements.option.$device.border.customThemeRounding"
      )
  hr.mt-1.mb-4
  Accordion(fix)
    template(#title)
      span {{ $t('sizeAndSpacing') }}
    Dropdown#width.mt-3(
      layout="col"
      :label="$t('optionWidth')"
      :items="widthOptions"
      v-model="widthType"
    )
    RangeInput(v-if="widthType === 'manual'" label="" v-model.number="width" :min="30" :max="600")
    Align(
      v-if="['manual', 'fluid'].includes(widthType) || (mobilePreview && widthType !== '100w')"
      v-model="align"
      flexOptions
    )
    RangeInput(
      v-if="widthType"
      :label="$t('optionHeight')"
      :min="30"
      :max="300"
      v-model.number="height"
    )
    RangeInput(
      v-if="hasMultipleOptions"
      :label="$t('spaceBetweenOptions')"
      :step="1"
      v-model.number="spaceBetweenOptions"
    )
    Heading(label v-if="breakLines && hasMultipleOptions") {{ $t('breakLines') }}
      template(#prependEnding)
        OmSwitch#break-lines(v-model="orientation")
    Margin
  hr.mt-3.mb-4
  template(#advancedContent)
    Hide(:hideOnDesktop.sync="hideElementOnDesktop" :hideOnMobile.sync="hideElementOnMobile")
</template>
<script>
  import { mapActions, mapMutations, mapState } from 'vuex';
  import { get as _get } from 'lodash-es';
  import itemMixin from '@/editor/mixins/item';
  import InputElementDropdown from '@/components/Editor/InputElementDropdown/InputElementDropdown.vue';
  import InputCommonFields from '@/components/Editor/InputCommonFields/InputCommonFields.vue';
  import InputElementOptions from '@/components/Editor/InputElementOptions/InputElementOptions.vue';
  import Heading from '@/components/Editor/Heading.vue';
  import Accordion from '@/components/Editor/Accordion/Accordion.vue';
  import Font from '@/components/Editor/Blocks/Font.vue';
  import BackgroundAndBorder from '@/components/Editor/Blocks/BackgroundAndBorder.vue';
  import Dropdown from '@/components/Editor/Dropdown/Dropdown.vue';
  import RangeInput from '@/components/Editor/RangeInput/RangeInput.vue';
  import Align from '@/components/Editor/Controls/Alignments/Align.vue';
  import Margin from '@/components/Editor/Controls/InputGroup/Margin.vue';
  import Hide from '@/components/Editor/Controls/Hide.vue';
  import CornerRadius from '@/components/Editor/Controls/CornerRadius.vue';
  import OnClickAction from './OnClickAction.vue';
  import BasePane from '../BasePane.vue';

  export default {
    components: {
      InputElementDropdown,
      InputCommonFields,
      InputElementOptions,
      Heading,
      Accordion,
      Font,
      BackgroundAndBorder,
      BasePane,
      Dropdown,
      RangeInput,
      Align,
      Margin,
      Hide,
      CornerRadius,
      OnClickAction,
    },
    mixins: [itemMixin],
    data() {
      return {
        inputs: [],
        canEditMobile: true,
      };
    },
    computed: {
      ...mapState(['mobilePreview', 'selectedElement']),
      hasOptions() {
        return ['survey'].includes(this.selectedElementType);
      },
      hasMultipleOptions() {
        return _get(this.selectedElement, 'data.form.customSettings.options').length > 1;
      },
      inputValue: {
        get() {
          const name = this.getValueOf('data.form.customSettings.name');
          const customId = this.getValueOf('data.form.customSettings.customId');
          return { name, customId };
        },
        set(v) {
          this.$bus.$emit('switchInputElement', { customField: v });
        },
      },
      onClickOptions() {
        const options = [{ value: 'none', text: this.$t('none') }];

        if (!this.isEmbedded) {
          options.push({ value: 'closePopup', text: this.$t('closePopup') });
        }

        options.push(
          { value: 'nextPopup', text: this.$t('nextPopup') },
          { value: 'jumpToPage', text: this.$t('jumpToPage') },
        );

        return options;
      },
      choosePageBasedOn: {
        get() {
          return this.selectedElement.data.choosePageBasedOn;
        },
        set(v) {
          this.setValueOf('data.choosePageBasedOn', v);
        },
      },
      onClick: {
        get() {
          return this.selectedElement.data.onClick;
        },
        set(v) {
          this.setValueOf('data.onClick', v);
        },
      },
      width: {
        get() {
          return Number(this.getValueOf('subElements.option.$device.smartSize.width', 100));
        },
        set(v) {
          this.setValueOf('subElements.option.$device.smartSize.width', v);
        },
      },
      height: {
        get() {
          return Number(this.getValueOf('subElements.option.$device.height', 0));
        },
        set(v) {
          this.setValueOf('subElements.option.$device.height', v);
        },
      },
      spaceBetweenOptions: {
        get() {
          return Number(this.getValueOf('$device.spaceBetweenOptions', 0));
        },
        set(v) {
          this.setValueOf('$device.spaceBetweenOptions', v);
        },
      },
      align: {
        get() {
          return this.getValueOf('$device.justifyContent');
        },
        set(value) {
          this.setValueOf('$device.justifyContent', value);
        },
      },
      hideElementOnDesktop: {
        get() {
          return this.getValueOf('desktop.hidden');
        },
        set(v) {
          this.setValueOf('desktop.hidden', v);
        },
      },
      hideElementOnMobile: {
        get() {
          return this.smartGetValueOf('mobile.hidden');
        },
        set(v) {
          this.smartSetValueOf('mobile.hidden', v);
        },
      },
      orientation: {
        get() {
          return this.smartGetValueOf(`${this.getDevice}.orientation`);
        },
        set(orientation) {
          this.smartSetValueOf(`${this.getDevice}.orientation`, orientation);
        },
      },
      getDevice() {
        return this.mobilePreview ? 'mobile' : 'desktop';
      },
      breakLines() {
        return this.selectedElement.subElements.option[this.getDevice].smartSize.type === 'manual';
      },
      selectedElementType() {
        return this.getValueOf('data.form.customSettings.type');
      },
      widthOptions() {
        const opts = [
          { value: 'fluid', text: this.$t('fitToContent') },
          { value: 'manual', text: this.$t('manual') },
        ];

        if (!this.isNano) {
          opts.push({ value: '100w', text: this.$t('simple100') });
        }

        if (this.mobilePreview) {
          opts.unshift({ value: null, text: this.$t('default') });
        }

        return opts;
      },
      widthType: {
        get() {
          return this.getValueOf('subElements.option.$device.smartSize.type');
        },
        set(value) {
          this.setValueOf('subElements.option.$device.smartSize.type', value);
        },
      },
    },
    watch: {
      async selectedElementType() {
        this.setMatchingInputs();
      },
    },
    mounted() {
      this.getInputs();

      // region Manage inputs ON listener
      this.$bus.$on('switchInputElement', this.getInputs);
      // endregion
    },

    beforeDestroy() {
      // region Manage inputs OFF listener
      this.$bus.$off('switchInputElement', this.getInputs);
      // endregion
    },
    methods: {
      ...mapMutations(['changeFormManagerVisibility']),
      ...mapActions(['getMatchingInputs']),
      manageFields() {
        this.changeFormManagerVisibility({ show: 'manageField', fields: this.inputs });
      },
      createNewInput() {
        this.changeFormManagerVisibility({ show: 'secondStep' });
      },
      async getInputs() {
        this.inputs = await this.getMatchingInputs();
      },
      async setMatchingInputs() {
        this.matchingInputs = await this.getMatchingInputs();
      },
    },
  };
</script>
