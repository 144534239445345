<template lang="pug">
.popup-custom-editor-bottom.new-design-editor(
  ref="editorWrapper"
  :class="{'custom-css-disable': !showCustomEditorBox, 'resizable': orientation === 'bottom', 'to-right': orientation === 'right', 'resizable-right': orientation === 'right'}"
  )
  .custom-editor-resizable-area
    .custom-editor-tab-editor.col
      brand-tabs.d-flex-1(:showTabs="true" :active="0" @selected="changeMode($event)")
        brand-tab(v-for="button in buttons" :key="button.mode" :name="$t(button.text)")
          .d-flex-1.align-items-center.custom-editor-js-events(v-if="button.mode === 'js'")
            .sidebar-input-wrapper.sidebar-input-wrapper-label.mr-2(style="{'padding-top': 0px}") {{ $t('jsEventsLabel') }}
            .sidebar-input-wrapper.flex-row-baseline
              span.epic-simple-input
                .select-arrow
                  i(class="fa fa-angle-down" aria-hidden="true")
                select(v-model="customJsEvent")
                  option(v-for="item in jsEvents" :value="item.value") {{ $t(item.key) }}
    .new-resizer.col-auto(@mousedown="initDrag")
      svg(width="52" height="4" viewBox="0 0 52 4" fill="none" xmlns="http://www.w3.org/2000/svg")
        rect(width="52" height="4" rx="2" fill="#D5D8DD")
    .dock-side-wrapper.col
      .dock-side.dock-side-right(@click="setOrientation('right')" :class="getActiveClass('right')")
        img.set-img-size(:src="require('@/assets/editor/img/Vector.png')")
      .dock-side.dock-side-bottom(@click="setOrientation('bottom')" :class="getActiveClass('bottom')")
        img.set-img-size.transform(:src="require('@/assets/editor/img/Vector.png')")
      span
        om-button.mx-2(secondary @click="preview") {{isCssEditor ? $t('previewAndSave') : $t('save')}}
      .dock-side.dock-side-close(@click="setCustomEditorBoxTrigger(false)")
        svg(width="17" height="17")
          path(d="M 3.4 3.4 L 13.6 13.6 Z" fill="#ababab" stroke="#999ea1" stroke-width="1")
          path(d="M 3.4 13.6 L 13.6 3.4 Z" fill="#ababab" stroke="#999ea1" stroke-width="1")
  .new-resizer.rotate.horizontal(@mousedown="initDrag" v-if="orientation === 'right'")
    svg(width="52" height="4" viewBox="0 0 52 4" fill="none" xmlns="http://www.w3.org/2000/svg")
      rect(width="52" height="4" rx="2" fill="#D5D8DD")
  #customCssEditor(ref="cssEditor" v-show="isCssEditor") {{css}}
  #customJsEditor(ref="jsEditor" v-show="isJsEditor") {{getCustomJs}}
</template>
<script>
  import { mapMutations } from 'vuex';
  import customJsCss from '@/mixins/customJsCss';

  export default {
    mixins: [customJsCss],
    methods: {
      ...mapMutations(['setStateAttr', 'resetCustomCss', 'previewCss']),
      changeMode(index) {
        this.mode = index === 0 ? 'css' : 'js';
      },
      preview() {
        this.previewCss(this.css);
        this.showLoading(true);
        this.save();
      },
      setCustomEditorBoxTrigger(value) {
        if (!value) {
          this.resetCustomCss();
          this.editor.css.setValue(this.customCss || '', -1);
          this.editor.js.setValue(this.js[this.customJsEvent] || '', -1);
          window.parent.om.bus.$emit('deselectAllButton');
        }
        this.setStateAttr({ attr: 'showCustomEditorBox', value });
      },
      getActiveClass(targetOrientation) {
        return {
          active: this.orientation === targetOrientation,
        };
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import '../../editor/sass/shared/_vars.sass'
  @import '../sass/custom_editor_box.sass'
  @import '../sass/custom_js_css_box.sass'

  .new-resizer svg
    pointer-events: none
  .dock-side.active
    background: #FFEFE5
    border-radius: 8px
  .brand-tabs-content
    display: flex !important
    align-items: flex-end !important
  .brand-tab
    padding-top: 0px !important
  .brand-tab-list-element
    font-size: 14px
    flex: 0 0 auto !important
  .new-design-editor
    background: #FFFFFF !important
  .new-resizer
    display: flex
    align-items: center
    cursor: ns-resize
  .rotate
    z-index: 5
    position: absolute
    top: 0
    bottom: 0
    transform: rotate(90deg)
    cursor: ew-resize
  .set-img-size
    width: 20px
    height: 20px
  .transform
    transform: rotate(90deg)
</style>
