<template lang="pug">
.ds-input-group.design-system
  PaneLayout(gap="4px")
    template(#left)
      .d-flex.align-items-center
        .ds-input-group-label {{ label }}
        DeviceSelector(v-if="editMobile" :hasViewText="false")
    template(#right)
      .d-flex.w-100
        .d-flex
          .ds-input-group-element(v-for="(key, index) in keys" :key="key")
            .ds-input-group-element-label {{ $t(key) }}
            input.ds-input-group-input.w-100(
              type="number"
              :value="disabled[index] ? '' : value[index]"
              :min="allowNegative ? null : 0"
              :step="step"
              :disabled="disabled[index]"
              @input="setValue(index, $event)"
              :placeholder="!disabled[index] ? placeholders[index] : ''"
              :class="{ error: errors[index] }"
            )
        .ds-input-group-element.ds-input-group-element-link
          OmButton.ds-input-group-element-input-link(
            icon="link-alt"
            iconOnly
            ghost
            iconSize="1.625em"
            :title="$t('allSides')"
            :selected="allSides"
            @click="$emit('update:allSides', !allSides)"
          )
</template>

<script>
  import { debounce } from 'lodash-es';
  import OmButton from '@/components/Elements/Button';
  import PaneLayout from '../PaneLayout.vue';

  export default {
    name: 'InputGroup',
    components: {
      PaneLayout,
      OmButton,
      DeviceSelector: () => import('@/editor/components/sidebar/components/DeviceSelector.vue'),
    },
    props: {
      label: {
        type: String,
      },
      keys: {
        type: Array,
        default: () => ['top', 'right', 'bottom', 'left'],
      },
      value: {
        type: Array,
        default: () => [0, 0, 0, 0],
      },
      disabled: {
        type: Array,
        default: () => [false, false, false, false],
      },
      placeholders: {
        type: Array,
        default: () => ['', '', '', ''],
      },
      errors: {
        type: Array,
        default: () => [false, false, false, false],
      },
      step: {
        type: String,
        default: '1',
      },
      allSides: {
        type: Boolean,
        default: false,
      },
      allowNegative: {
        type: Boolean,
        default: false,
      },
      editMobile: {
        type: Boolean,
        default: true,
      },
    },
    methods: {
      setValue: debounce(function (index, event) {
        const value = Number(event.target.value);
        const isNanValue = isNaN(parseInt(event.target.value, 10));
        let values = [...this.value];
        if (this.allSides) {
          values = this.value.map(() => (isNanValue ? null : value));
        } else {
          values[index] = isNanValue ? null : value;
        }

        this.$emit('input', { values, index });
      }, 120),
    },
  };
</script>
<style lang="sass">
  @import '../../../sass/variables/_colors.sass'

  .ds-input-group
    &-label
      color: $om-dark-grey-3
      font-size: 0.75rem
      line-height: 1.5
    &-all-sides
      display: flex
      align-items: center
      margin-bottom: 0.875rem
    &-element
      display: flex
      flex-direction: column-reverse
      gap: 4px
      &-link
        display: flex
        flex-direction: column
        margin-left: 0.5rem
      &-input-link
        color: #696d72
        padding: .25rem !important
        &.selected
          background-color: transparent !important
          color: $om-orange
        > div
          line-height: 1.625rem
        &.active
          background: var(--brand-primary-color)
          border-color: var(--brand-primary-color)
          color: white
      &-label
        font-size: 0.75rem
        color: $om-dark-grey-3

      input
        border: 1px solid $om-light-grey2
        padding: 0.5rem 0.25rem 0.5rem 0.5rem
        font-size: .75rem
        line-height: 1.2
        color: $om-dark-grey-3
        border-right: 0
        &:disabled
          color: $om-light-grey2
          background: $om-light-grey2
        &.error
          border: 1px solid red !important
      &:first-child
        input
          border-top-left-radius: 4px
          border-bottom-left-radius: 4px
      &:last-child
        input
          border-top-right-radius: 4px
          border-bottom-right-radius: 4px
          border: 1px solid $om-light-grey2
</style>
