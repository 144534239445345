<template lang="pug">
.radio-select-card(@click="$emit('click', $event)" :class="classes")
  .row
    .col-auto.radio-select-card-input
      .form-check
        input.form-check-input(
          type="radio"
          role="radio"
          :value="index"
          :name="`radio-${name}`"
          :aria-checked="checked"
          :checked="checked"
        )
    .col.radio-select-card-content
      .radio-select-card-header
        .radio-select-card-icon(v-if="icon")
          component(v-if="icon" :is="iconComponent" :size="iconSize")
        .radio-select-card-title(v-if="title") {{ title }}
      .radio-select-card-description(v-if="description") {{ description }}
</template>
<script>
  import { UilShield, UilShieldCheck, UilShieldSlash } from '@iconscout/vue-unicons';
  import designSystemMixin from '../mixins/designSystem';
  import iconComponent from '../mixins/iconComponent';

  export default {
    components: {
      UilShield,
      UilShieldCheck,
      UilShieldSlash,
    },
    mixins: [designSystemMixin, iconComponent],
    props: {
      name: {
        type: String,
        default: '_id',
      },
      item: {
        type: Object,
        default: () => {
          return {};
        },
      },
      index: {
        type: Number,
        default: 1,
      },
      value: {
        type: Boolean,
        default: false,
      },
      iconSize: {
        type: String,
        default: '1.5rem',
      },
      icon: {
        type: String,
        default: null,
        options: ['shield', 'shield-check', 'shield-slash', null],
        validator: (value) => {
          return ['shield', 'shield-check', 'shield-slash', null].includes(value.toLowerCase());
        },
      },
    },
    computed: {
      checked: {
        get() {
          return this.value;
        },
        set(v) {
          return v;
        },
      },
      title() {
        return this.item.title ? this.item.title : false;
      },
      description() {
        return this.item.description ? this.item.description : false;
      },
      classes() {
        return {
          'description-shown': this.description,
          'title-shown': this.title,
          'icon-shown': this.icon,
          'design-system': this.designSystem,
          checked: this.checked,
        };
      },
    },
  };
</script>
<style lang="sass" scoped>
  @import 'radioSelectCards.sass'
</style>
