import { track } from '@/services/xray';

const frame = () => {
  return document.getElementById('workspaceFrame');
};

const frameStore = () => {
  const _frame = frame();
  return _frame && _frame.contentWindow && _frame.contentWindow.om
    ? _frame.contentWindow.om.store
    : null;
};

const REQUEST_TYPES = {
  UPGRADE: 'upgrade',
  NONE: 'none',
};

const LOCATIONS = {
  WORKSPACE: 'workspace',
  LAYERS: 'layers',
};

export default {
  data() {
    return {
      requestType: REQUEST_TYPES,
      location: LOCATIONS,
    };
  },

  computed: {
    hasIntercom() {
      return !!window.Intercom || !!window.parent.Intercom;
    },

    isTrialShop() {
      return this.getEditorStore().getters.hasShopifyTrial;
    },

    isPageViewBasedPackage() {
      return window.parent.om.store.getters['payment/isPageViewBasedPackage'];
    },

    getPoweredBy() {
      return frameStore().state.poweredBy;
    },
  },
  methods: {
    getEditorStore() {
      return frameStore() ? this.$store : window.parent.om.store;
    },

    showUpgradeModal() {
      window.parent.om.bus.$emit('showUpgradePlanModal');
    },

    clickHandler(where, type) {
      if (type === this.requestType.UPGRADE) {
        this.showUpgradeModal();
      }

      track('click-powered-by', {
        where,
        type,
      });
    },
  },
};
