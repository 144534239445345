export const COLOR = 'color';
export const COLOR_BRANDKIT = 'colorBrandkit';
export const AB_TEST = 'abTest';
export const RECOMMENDATION = 'recommendation';
export const SIMILAR_TEMPLATES = 'similar-templates';
export const DISCOUNTS = 'discounts';
export const SEASONAL_SALES = 'seasonalSales';
export const SMART_BUILD_LIST = 'buildList';
export const PICK_THEME = 'pickTheme';
export const PICK_THEME_V2 = 'pickThemeV2';
export const BRAND_KIT = 'brandKit';
export const GOALS = 'goals';

const CUSTOM_THEME = 'customTheme';

const BASE_THEME_ID = 'baseThemeId';

const THEME = 'theme';

export const wizardFlowA = {
  [GOALS]: SMART_BUILD_LIST,
  [SMART_BUILD_LIST]: {
    email: DISCOUNTS,
    sms: DISCOUNTS,
    emailAndSms: DISCOUNTS,
    none: DISCOUNTS,
  },
  [DISCOUNTS]: {
    yes: SEASONAL_SALES,
    no: SEASONAL_SALES,
  },
  [SEASONAL_SALES]: {
    yes: COLOR_BRANDKIT,
    fewTimes: COLOR_BRANDKIT,
    no: COLOR_BRANDKIT,
  },
  [COLOR_BRANDKIT]: PICK_THEME,
  [PICK_THEME]: BRAND_KIT,
  [BRAND_KIT]: RECOMMENDATION,
};

export const wizardFlowB = {
  [GOALS]: SMART_BUILD_LIST,
  [SMART_BUILD_LIST]: {
    email: DISCOUNTS,
    sms: DISCOUNTS,
    emailAndSms: DISCOUNTS,
    none: DISCOUNTS,
  },
  [DISCOUNTS]: {
    yes: SEASONAL_SALES,
    no: SEASONAL_SALES,
  },
  [SEASONAL_SALES]: {
    yes: PICK_THEME_V2,
    fewTimes: PICK_THEME_V2,
    no: PICK_THEME_V2,
  },
  [PICK_THEME_V2]: BRAND_KIT,
  [BRAND_KIT]: RECOMMENDATION,
};

export const wizardOrderA = [
  GOALS,
  SMART_BUILD_LIST,
  DISCOUNTS,
  SEASONAL_SALES,
  COLOR_BRANDKIT,
  PICK_THEME,
  BRAND_KIT,
  RECOMMENDATION,
  SIMILAR_TEMPLATES,
];

export const wizardOrderB = [
  GOALS,
  SMART_BUILD_LIST,
  DISCOUNTS,
  SEASONAL_SALES,
  PICK_THEME_V2,
  BRAND_KIT,
  RECOMMENDATION,
  SIMILAR_TEMPLATES,
];

export const percentage = (isOriginalFlow) => {
  if (isOriginalFlow) {
    return {
      [GOALS]: 33 / 4,
      [SMART_BUILD_LIST]: 33 * (2 / 4),
      [DISCOUNTS]: 33 * (3 / 4),
      [SEASONAL_SALES]: 35.4,
      [COLOR_BRANDKIT]: 33 + 33 / 3,
      [PICK_THEME]: 33 + 33 * (2 / 3),
      [BRAND_KIT]: 67.075,
      [RECOMMENDATION]: 103,
      [SIMILAR_TEMPLATES]: 103,
    };
  }
  return {
    [GOALS]: 33 / 4,
    [SMART_BUILD_LIST]: 33 * (2 / 4),
    [DISCOUNTS]: 33 * (3 / 4),
    [SEASONAL_SALES]: 35.4,
    [COLOR_BRANDKIT]: 33 + 33 / 3,
    [PICK_THEME_V2]: 50,
    [BRAND_KIT]: 67.075,
    [RECOMMENDATION]: 103,
    [SIMILAR_TEMPLATES]: 103,
  };
};

export const segmentSettings = (isOriginalFlow) => [
  {
    name: 'business',
    icon: 'shop',
    startPage: GOALS,
    neededParams: [AB_TEST],
    text: 'onboarding.wizard.progress.yourBusiness.title',
    tooltip: 'onboarding.wizard.progress.yourBusiness.tooltip',
  },
  {
    name: 'brandKit',
    icon: 'brush-alt',
    startPage: isOriginalFlow ? COLOR_BRANDKIT : PICK_THEME_V2,
    neededParams: [AB_TEST, BASE_THEME_ID, COLOR, CUSTOM_THEME, THEME],
    text: 'onboarding.wizard.progress.themeKit.title',
    tooltip: 'onboarding.wizard.progress.themeKit.tooltip',
  },
  {
    name: 'useCase',
    icon: 'web-grid-alt',
    startPage: RECOMMENDATION,
    neededParams: [
      AB_TEST,
      GOALS,
      SMART_BUILD_LIST,
      DISCOUNTS,
      SEASONAL_SALES,
      COLOR,
      PICK_THEME,
      BRAND_KIT,
      CUSTOM_THEME,
      BASE_THEME_ID,
      THEME,
    ],
    text: 'onboarding.wizard.progress.useCase.title',
    tooltip: 'onboarding.wizard.progress.useCase.tooltip',
  },
];

export const getSmartWizardFlow = (isOriginalFlow) => {
  return isOriginalFlow ? wizardFlowA : wizardFlowB;
};

export const getSmartWizardStepsOrder = (isOriginalFlow) => {
  return isOriginalFlow ? wizardOrderA : wizardOrderB;
};

export const NAVIGATED_ON_SELECT = [SMART_BUILD_LIST, DISCOUNTS, SEASONAL_SALES, PICK_THEME];
