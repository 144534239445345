<template lang="pug">
.content-step2
  loading-logo(v-if="loading")
  template(v-else)
    om-select#listName(
      v-model="listId"
      :options="lists"
      :label="$t('integrationFlow.listamesterV2.listTooltip')"
      :placeholder="$t('integrationFlow.listamesterV2.selectAList')"
      :error="isListInvalidByAlert"
      @input="onListChange"
    )
    #listName.error-msg.mt-3(v-if="isListInvalidByAlert" key="error-message")
      span.text-danger(v-html="$t('integrationFlow.integrationModal.general.invalidList')")
</template>
<script>
  import { IntegrationService } from '@/services/integrations/integrationService';
  import validationByAlert from '@/mixins/integration/validationByAlert';
  import trackStep2Mixin from '@/mixins/integration/trackStep2';

  export default {
    mixins: [validationByAlert, trackStep2Mixin],
    props: {
      settings: {
        type: Object,
        required: true,
      },

      validations: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        step2Fields: ['listId'],
        listId: null,
        lists: [],
        hasError: false,
        loading: false,
      };
    },

    watch: {
      listId(option) {
        this.updateListSetting(option.key);
      },
    },

    async mounted() {
      this.loading = true;
      // IMPORTANT to load data then set model (behavior of om-select)
      const lists = await this.loadLists();

      if (!lists.length) {
        this.updateListSetting(null);
        return;
      }

      this.lists = lists;

      const key = this.settings.convertedSettings.listId;

      // om-select selected option only needs "key" from object
      this.listId = key ? { key } : null;

      this.loading = false;

      this.storeInitialStep2State();
    },

    methods: {
      async loadLists() {
        const integrationService = new IntegrationService(this.$apollo);

        try {
          const { lists } = await integrationService.fetchIntegrationData(
            this.settings.type,
            this.settings.id,
          );

          return lists.map((list) => ({
            key: list.id,
            value: list.name,
          }));
        } catch (e) {
          this.errorNotification(e.message);
        }

        return [];
      },
      errorNotification(message) {
        this.hasError = true;
        this.$notify({
          type: 'error',
          text: message,
        });
        setTimeout(() => {
          this.$bus.$emit('integration-show-first-step');
        }, 2000);
      },

      updateListSetting(value) {
        const settings = { ...this.settings };
        settings.convertedSettings.listId = value;
        this.$emit('update:settings', settings);
        this.emitIsModified();
      },
    },
  };
</script>
