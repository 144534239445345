<template lang="pug">
.onboarding-dashboard-accordions.mt-5.mb-5.pb-5(v-if="!$apollo.loading && !allDone")
  .row
    .col-12
      om-accordion
        om-accordion-item(
          :step="1"
          :openDefault="!isTaskDone('create')"
          :readingTime="$t('dashboardSections.onboarding.steps.create.readingTime')"
          :done="isTaskDone('create')"
          :openableDone="true"
          @click="onTaskClick('createFirstCampaign')"
        )
          template(#icon)
            img(
              :src="require(`@/assets/admin/svg/onboarding/dashboard-section-${campaignCount ? 'done' : 'createPopup'}.svg`)"
            )
          template(#name) {{ $t('dashboardSections.onboarding.steps.create.title') }}
          .row.pb-5
            .col-7.d-flex
              .row.align-self-center
                .col-6.align-self-center(v-if="this.hasCustomTheme")
                  om-heading.mb-4(h2) {{ $t(`DashboardWizardTaskList.onboarding.recommendation.title`, { businessName: getAccountBusinessName }) }}
                  om-body-text.mb-5(bt400md) {{ $t(`DashboardWizardTaskList.onboarding.recommendation.subTitle`) }}
                  om-button(primary large @click="handleClick('showRecommendation')") {{ $t(`DashboardWizardTaskList.onboarding.recommendation.cta`) }}
                .col-5.col-xxl-4.align-self-center(v-else)
                  om-heading.mb-4(h2) {{ $t(`DashboardWizardTaskList.onboarding.getStarted.title`) }}
                  om-body-text.mb-5(bt400md) {{ $t(`DashboardWizardTaskList.onboarding.getStarted.subTitle`) }}
                  om-button(primary large @click="handleClick('startWizard')") {{ $t(`DashboardWizardTaskList.onboarding.getStarted.cta`) }}
                .col.text-center.align-self-center
                  om-monks(monk="medit-wizard")
            .col-auto.align-self-center
              wizard-separator {{ $t('or') }}
            .col-4.pl-5.pr-5.d-flex
              .onboarding-dashboard-list-wrapper.align-self-center
                .onboarding-dashboard-list(v-for="action in actions")
                  om-link.mb-2(primary medium withIconRight @click="handleClick(action)") {{ $t(`DashboardWizardTaskList.onboarding.steps.${action}.title`) }}
                  om-body-text(bt400md) {{ $t(`DashboardWizardTaskList.onboarding.steps.${action}.description`) }}
        om-accordion-item(
          :readingTime="$t('dashboardSections.onboarding.steps.connect.readingTime')"
          :done="isTaskDone('connect')"
          @click="onTaskClick('insertCode')"
          :openDefault="isTaskDone('create')"
        )
          template(#icon)
            img(
              :src="require(`@/assets/admin/svg/onboarding/dashboard-section-${isTaskDone('connect') ? 'done' : 'connect'}.svg`)"
            )
          template(#name) {{ $t('dashboardSections.onboarding.steps.connect.title') }}
          .row.pb-4
            .col-7.align-self-center
              om-body-text.mb-5(bt400lg) {{ $t('dashboardSections.onboarding.steps.connect.description') }}
              om-button(primary large @click="handleClick('connect')") {{ $t('dashboardSections.onboarding.steps.connect.cta') }}
            .col-5.text-center.px-5
              om-monks(monk="connect")
        om-tooltip.mt-3.w-100(v-if="lockedAccordion" transition="fade")
          span {{ $t('dashboardSections.onboarding.steps.activate.errorText') }}
          template(slot="trigger")
            DashboardWizardAccordionActivate(
              :done="isTaskDone('activate')"
              :disabled="lockedAccordion"
              :openDefault="isTaskDone('create') && isTaskDone('connect')"
              @accordionClick="onTaskClick('activateFirstCampaign')"
              @handleActivate="handleClick('activate')"
            )
        DashboardWizardAccordionActivate(
          v-else
          :done="isTaskDone('activate')"
          :disabled="false"
          :openDefault="isTaskDone('create') && isTaskDone('connect')"
          @accordionClick="onTaskClick('activateFirstCampaign')"
          @handleActivate="handleClick('activate')"
        )
</template>
<script>
  import { mapState, mapGetters } from 'vuex';
  import { get } from 'lodash-es';
  import moment from 'moment';
  import slugify from 'slugify';
  import { track } from '@/services/xray';
  import { PROFILE_KEY_ONBOARDING_DONE, WIZARD_LAST_PAGE_KEY } from '@/config/constants';
  import WizardSeparator from '@/components/Wizard/Separator.vue';
  import { getAccountIdFromCookie } from '@/util';
  import { tacticLibraryUrl } from '@/utils/tactics';
  import dashboardTaskListMixin from '@/mixins/dashboardTaskList';
  import GET_CHOOSABLE_THEME_CATEGORIES from '@/graphql/GetChoosableThemeCategories.gql';
  import GET_DASHBOARD_TASK_LIST_DATA from '@/graphql/GetDashboardTaskListData.gql';
  import GENERATE_AUTO_LOGO from '@/graphql/GenerateAutoLogo.gql';
  import DashboardWizardAccordionActivate from './components/DashboardWizardAccordionActivate.vue';
  import { ESSENTIAL_FALLBACK_ID } from '@/utils/constant';

  const PROFILE_KEY_ACTIVATED = 'onboardingSectionActivated';
  const PROFILE_KEY_SHOWN = 'onboardingSectionShown';

  const HEAP_EVENT_NAMES = {
    connect: 'insertCode',
    create: 'createFirstCampaign',
    activate: 'activateFirstCampaign',
  };

  const ucFirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const themeWithSlug = (theme) => {
    return {
      ...theme,
      slug: `${slugify(theme.name)}-${theme._id}`,
    };
  };

  const themeKitWithLogo = (theme) => {
    return {
      ...theme,
      themeKit: {
        ...theme.themeKit,
        logo: theme?.logo?.current,
      },
    };
  };

  export default {
    components: {
      WizardSeparator,
      DashboardWizardAccordionActivate,
    },
    mixins: [dashboardTaskListMixin],
    data: () => ({
      visible: false,
      actions: ['browseTemplates', 'optimize', 'browseLibrary'],
      themes: {},
      data: null,
      dataLoading: true,
      themesLoading: true,
    }),
    apollo: {
      data: {
        query: GET_DASHBOARD_TASK_LIST_DATA,
        result() {
          this.dataLoading = false;
        },
      },
      themes: {
        query: GET_CHOOSABLE_THEME_CATEGORIES,
        update({ themes: { user, base } }) {
          return {
            user: user.map(themeWithSlug).map(themeKitWithLogo),
            base: base.map(themeWithSlug).map(themeKitWithLogo),
          };
        },
        result() {
          this.themesLoading = false;
        },
      },
    },
    computed: {
      ...mapState(['account']),
      ...mapGetters([
        'userProfileDoneValue',
        'isTaskListCompleted',
        'getAccountBusinessName',
        'wizardPreferences',
      ]),
      preferredBaseThemeId() {
        return (
          this.themes?.user?.[0]?.sourceTheme ||
          this.wizardPreferences?.customThemeId ||
          ESSENTIAL_FALLBACK_ID
        );
      },
      preferredThemeId() {
        const userTheme = this.themes.user.find(
          ({ sourceTheme }) => sourceTheme === this.wizardPreferences?.customThemeId,
        );
        return this.themes?.user?.[0]?._id || userTheme?._id || this.preferredBaseThemeId;
      },
      preferredBaseThemeName() {
        const theme = this.themes?.base?.find(({ _id }) => _id === this.preferredBaseThemeId);
        return theme?.name ?? undefined;
      },
      lockedAccordion() {
        return this.campaignCount === 0 || !this.isFullyConnected;
      },
      hasCustomTheme() {
        return this.themes?.user?.length;
      },
      allDone() {
        const validate =
          this.isTaskDone('connect') && this.isTaskDone('create') && this.isTaskDone('activate');
        return this.isAlreadyDone || validate;
      },
      isShownBefore() {
        return !!get(this.account, `profile.${PROFILE_KEY_SHOWN}`, false);
      },
      isActivatedBefore() {
        return !!get(this.account, `profile.${PROFILE_KEY_ACTIVATED}`, false);
      },
      isAlreadyDone() {
        return this.isTaskListCompleted;
      },
      inactiveCampaignId() {
        return this.data?.firstInactive ?? null;
      },
      campaignCount() {
        return this.data?.campaignCount;
      },
      queriesLoading() {
        return this.dataLoading || this.themesLoading;
      },
    },
    watch: {
      queriesLoading(loading) {
        if (!loading) this.$emit('initiated');
      },
    },
    async created() {
      await Promise.all([this.setShopifyAppEmbedStatuses(), this.setImpressionFlag()]);

      const isOldUserDone = !this.isShownBefore && this.allDone;

      if (isOldUserDone) {
        await this.setToUserProfile(PROFILE_KEY_ONBOARDING_DONE, true);
        return;
      }

      if (!this.isAlreadyDone) {
        await this.setToUserProfile(PROFILE_KEY_SHOWN, true);

        if (this.allDone && !this.userProfileDoneValue) {
          await this.setToUserProfile(
            PROFILE_KEY_ONBOARDING_DONE,
            moment().utc().add(24, 'hours').toISOString(),
          );
        }
      }

      this.visible = !this.isAlreadyDone;
    },
    methods: {
      handleClick(task) {
        const method = `on${ucFirst(task)}`;
        this?.[method]?.();
      },
      onBrowseTemplates() {
        this.$router.push({
          name: 'templates',
          params: {
            userId: getAccountIdFromCookie(),
          },
        });
      },
      onOptimize() {
        this.$router.push({ name: 'new_campaign_flow_optimize' });
      },
      onBrowseLibrary() {
        window.open(tacticLibraryUrl[this.$i18n.locale], '_blank');
      },
      onShowRecommendation() {
        this.$router.push({
          name: 'tactics_recommendation',
          query: {
            customTheme: this.preferredThemeId || this.preferredBaseThemeId,
            theme: this.preferredBaseThemeName,
          },
        });
      },
      onStartWizard() {
        const [accountId, savedUrl] =
          this.account?.profile?.[WIZARD_LAST_PAGE_KEY]?.split?.(':') ?? [];
        const isSameAccount = accountId && accountId === `${getAccountIdFromCookie()}`;
        let url = savedUrl;
        let urlObject;
        if (savedUrl) {
          const fullSavedUrl = `${window.location.origin}${savedUrl}`;
          urlObject = new URL(fullSavedUrl);
          urlObject.searchParams.set('continued', '1');

          url = `${urlObject.pathname}${urlObject.search}`;
        } else {
          this.$apollo.query({
            query: GENERATE_AUTO_LOGO,
          });
        }
        this.$router.push(
          isSameAccount && url
            ? url
            : {
                name: 'onboarding_wizard',
                params: {
                  userId: getAccountIdFromCookie(),
                  step: 'goals',
                },
              },
        );
      },
      onConnect() {
        this.$router.push({ name: 'code-insert' });
      },
      create() {
        this.$router.push({ name: 'templates' });
      },
      onActivate() {
        if (!this.campaignCount) return this.create();
        if (this.campaignCount === 1) {
          this.$router.push({ name: 'campaign_variants', params: { id: this.inactiveCampaignId } });
          return;
        }
        this.$router.push({ name: 'campaigns' });
      },
      getEventName(task) {
        const eventName = HEAP_EVENT_NAMES[task];
        return `dashboard-onboarding-${eventName}`;
      },
      onTaskClick(task) {
        if (this.isTaskDone(task)) return;
        const eventName = this.getEventName(task);
        track(eventName);
        this?.[task]?.(task);
      },
      isTaskDone(task) {
        if (task === 'connect') {
          return this.isFullyConnected;
        }
        if (task === 'create') {
          return !!this.campaignCount;
        }
        if (task === 'activate') {
          return this.isActivatedBefore || this.hasConversion || this.hadImpressionEver;
        }
      },
      async hide() {
        console.log('RUN HIDE');
        this.visible = false;
        const eventName = this.getEventName('close');
        track(eventName);
        await this.setToUserProfile(PROFILE_KEY_ONBOARDING_DONE, true);
      },
    },
  };
</script>
<style lang="sass">
  @import "@/sass/variables/_colors.sass"
  .onboarding-dashboard-accordions
    .ds-tooltip .popper
      text-align: center !important
      max-width: 220px
    .btn-primary
      &.btn-lg
        min-width: 9.25rem
    .om-wizard-separator-line
      height: 7rem
    .onboarding-dashboard-list
      border-bottom: 1px dashed $om-gray-400
      padding: 0.75rem 0
      &:last-child
        border-bottom: 0
    .accordion-v2-item-arrow
      svg
        fill: $om-orange-500
    .accordion-v2-item
      border-radius: 8px
      padding: 1rem 0
      padding-right: 1rem
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07)
      border: 0
      &.done
        background: transparent
        .accordion-v2-item-arrow
          background: transparent
      &.active
        .accordion-v2-item-body
          margin-top: 0
          padding-left: 5.25rem
      &.disabled
        width: 100%
    .accordion-v2-item-name
      font-size: 1.25rem
      line-height: 1.4
      display: flex
      align-items: center
      .ds-tooltip
        pointer-events: all
    .brand-monk-medit-wizard
      transform: scaleX(-1)
      width: auto
      @media screen and (max-width: 1199px)
        width: 100%
    .brand-monk-connect
      margin-top: -2.75rem
      width: auto
    .brand-monk-start
      margin-top: -4.75rem
      margin-bottom: -1rem
      width: auto
    .accordion-v2-item-step-col
      padding-left: 1rem
      padding-right: 1rem
      img
        max-width: 3.25rem
</style>
