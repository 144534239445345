import { merge as _merge } from 'lodash-es';
import * as generators from './helpers';
import { campaignScope } from '../util';

const setStyles = (element, template, fromObj, toObj, device) => {
  const { uid, type } = element;

  Object.keys(fromObj).forEach((prop) => {
    const generator = generators[prop];

    if (generator) {
      let styles;

      styles = generator(uid, type, fromObj[prop], device === 'mobile', template);

      if (prop === 'justifyContent') {
        styles = generators.justifyContent(
          uid,
          type,
          fromObj[prop],
          device === 'mobile',
          template,
          `${campaignScope} #${element.uid}_wrapper`,
        );
      }

      if (prop === 'alignItems') {
        styles = generators.alignItems(
          uid,
          type,
          fromObj[prop],
          device === 'mobile',
          template,
          `${campaignScope} #${element.uid}`,
        );
      }

      if (prop === 'height') {
        styles = generators.couponHeight(
          uid,
          type,
          fromObj[prop],
          device === 'mobile',
          template,
          undefined,
        );
      }

      _merge(toObj, styles);
    }
  });
};

export default (element, template) => {
  const { desktop, mobile } = element;
  const _desktop = {};
  const _mobile = {};

  setStyles(element, template, desktop, _desktop, 'desktop');
  setStyles(element, template, mobile, _mobile, 'mobile');

  return {
    desktop: _desktop,
    mobile: _mobile,
  };
};
