export default {
  props: {
    src: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    backgroundColor: {
      type: String,
      default: 'rgb(222, 222, 222)',
    },
    position: {
      type: String,
      default: 'center center',
    },
    lazyLoad: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      imgSrc: this.placeholder,
      img: null,
      loaded: false,
    };
  },

  computed: {
    backgroundImageUrl() {
      return {
        background: `url(${this.imgSrc}) ${this.backgroundColor} no-repeat ${this.position} / contain`,
      };
    },
    imageStyle() {
      const style = {};
      if (this.inEditor && !this.loaded) Object.assign(style, this.backgroundImageUrl);
      return style;
    },
  },

  methods: {
    onImageLoad() {
      this.imgSrc = this.getImageSrc();
    },

    onImageLoadError() {
      this.imgSrc = this.placeholder;
    },

    removeEventListeners() {
      if (this.img) {
        this.img.removeEventListener('load', this.onImageLoad);
        this.img.removeEventListener('error', this.onImageLoadError);
      }
    },

    getImageSrc() {
      return this.src || this.url || this.mobileUrl;
    },

    loadImg() {
      this.removeEventListeners();

      this.imgSrc = this.placeholder;

      if (this.lazyLoad) {
        const observer = new IntersectionObserver((entries) => {
          if (entries[0].intersectionRatio > 0) {
            this.replacePlaceholderWithImage();
          }
        });

        if (this.$refs.imageRef) {
          observer.observe(this.$refs.imageRef);
        }
      } else {
        this.replacePlaceholderWithImage();
      }
    },

    replacePlaceholderWithImage() {
      this.img = new Image();

      this.img.addEventListener('load', this.onImageLoad);
      this.img.addEventListener('error', this.onImageLoadError);

      this.img.src = this.getImageSrc();
    },
  },

  watch: {
    src() {
      this.loadImg();
    },
  },

  mounted() {
    this.loadImg();
  },

  beforeDestroy() {
    this.removeEventListeners();
  },
};
