<template lang="pug">
.language-chooser
  popper(:force-show="true" :options="{ placement: 'left' }" trigger="click" v-if="popoverVisible")
    badge.new-feature-tooltip-badge(slot="reference" :text="$t('new')" type="rounded")
    .popper.new-feature-popper.brand-tooltip.brand-tooltip-light.brand-tooltip-left.mt-5
      translate-feature-popover(@close="closePopover")
  .language-chooser-label {{ $t('translateFeature.templateLanguage') }}
  LanguageChooserDropdown(@change="onChange")
</template>

<script>
  import { mapGetters } from 'vuex';
  import Badge from '@/components/Badge.vue';
  import TranslateFeaturePopover from '@/components/Popovers/TranslateFeature.vue';
  import LanguageChooserDropdown from '@/components/TemplateChooser/components/LanguageChooserDropdown.vue';
  import cookie from '@/mixins/cookie';

  const COUNTRY_CODES_TO_SHOW = [
    'HU',
    'FR',
    'BE',
    'CH',
    'LU',
    'CA',
    'DE',
    'AT',
    'LI',
    'ES',
    'MX',
    'AR',
    'CO',
    'PE',
    'VE',
    'CL',
    'EC',
    'GT',
    'CU',
    'BO',
    'DO',
    'HN',
    'PY',
    'SV',
    'NI',
    'CR',
    'PA',
    'UY',
    'GQ',
    'PR',
    'PT',
    'BR',
  ];

  export default {
    components: { Badge, TranslateFeaturePopover, LanguageChooserDropdown },
    mixins: [cookie],
    data: () => ({
      popoverVisible: false,
      popoverClosedCookieKey: 'x-om-translate-feature-popover-closed',
    }),
    computed: {
      ...mapGetters(['databaseId']),
    },
    mounted() {
      if (this.shouldShowPopover()) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.popoverVisible = true;
            setTimeout(() => {
              this.popoverVisible = false;
              this.setPopoverClosed();
            }, 60000);
          }, 300);
        });
      }
    },
    methods: {
      closePopover() {
        this.popoverVisible = false;
        this.setPopoverClosed();
      },
      setPopoverClosed() {
        this.setCookie(this.popoverClosedCookieKey, 1, 33);
      },
      getStoredOMCountry() {
        const stored = localStorage.getItem('OptiMonkVisitorAttributes');
        if (!stored) return null;

        return JSON.parse(stored)._country_code?.toUpperCase();
      },
      shouldShowPopover() {
        const hasCookie = this.getCookie(this.popoverClosedCookieKey);
        const isInTargetCountry = COUNTRY_CODES_TO_SHOW.includes(this.getStoredOMCountry());
        const isIDAllowed = this.databaseId < 244386;

        if (!isIDAllowed || !isInTargetCountry || hasCookie) return false;

        const now = new Date();
        const endPopoverTime = new Date('2024-12-13T00:00:00');
        return now <= endPopoverTime;
      },
      onChange(langCode) {
        this.$emit('change', langCode);
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import '@/sass/variables/colors'
  .popper
    border: 1px solid $om-gray-300 !important
    border-radius: 4px
  .language-chooser
    display: flex
    justify-content: flex-end
    align-items: center
    position: relative
    width: 100%
    &-label
      color: #505763
      font-size: 13px
      margin: 0 15px
      white-space: nowrap
    &-select
      width: 100%
      max-width: 150px
      min-width: 90px
      .select-wrapper .select-content
        min-height: 44px
</style>
