<template lang="pug">
Heading.mt-1(label)
  template(#titleTrailing)
    .d-flex.align-items-center
      .label {{ $t('campaignConversion.title') }}
      .tooltip-icon.d-flex.align-items-center
        OmTooltip.ml-1(transition="fade" big :arrow="false")
          span {{ $t('campaignConversion.tooltip') }}
  template(#prependEnding)
    OmSwitch#campaignConversion(v-model="campaignConversion")
</template>

<script>
  import Heading from '@/components/Editor/Heading.vue';
  import itemMixin from '@/editor/mixins/item';

  export default {
    components: { Heading },
    mixins: [itemMixin],
    props: {
      property: {
        type: String,
        default: 'data.campaignConversion',
      },
    },
    computed: {
      campaignConversion: {
        get() {
          return this.getValueOf(this.property);
        },
        set(value) {
          this.setValueOf(this.property, value);
        },
      },
    },
  };
</script>
