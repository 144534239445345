<template lang="pug">
div
  .flex-row-baseline.main-graphic-select
    .flex-column-4(v-if="transparentOption" @click="setBackgroundType('transparent')")
      .graphic-item.transparent-bg(
        :class="{ 'graphic-selected-item': backgroundType === 'transparent' }"
      )
        .graphic-select
      span.graphic-label {{ $t('transparent') }}
    .flex-column-4(@click="setBackgroundType('solid-fill')")
      .graphic-item.transparent-bg(
        :class="{ 'graphic-selected-item': backgroundType === 'solid-fill' }"
      )
        .graphic-select.solid-bg(:style="solidBackground")
      span.graphic-label {{ $t('solid') }}
    .flex-column-4(@click="setBackgroundType('gradient')")
      .graphic-item.transparent-bg(
        :class="{ 'graphic-selected-item': backgroundType === 'gradient' }"
      )
        .graphic-select.gradient-bg(v-if="gradientType === 'linear'" :style="gradientBackground")
        .graphic-select.gradient-bg(v-if="gradientType === 'radial'" :style="gradientBackground")
      span.graphic-label {{ $t('gradient') }}
    .flex-column-4(@click="setBackgroundType('image')")
      .graphic-item(:class="{ 'graphic-selected-item': backgroundType === 'image' }")
        .graphic-select.image-bg(style="text-align: center")
          img(:src="require('@/assets/editor/svg/image.svg')")
      span.graphic-label {{ $t('image') }}
  upload-area(
    v-if="backgroundType === 'image'"
    :src="backgroundSrc"
    :editMobile="editMobile"
    @click="imageManager"
    @remove="resetMobileImage"
  )
  template(v-if="showColor1")
    om-color-input(
      :label="backgroundType === 'image' ? 'backgroundColor' : 'color'"
      :property="getPath('background.color')"
      :typeOfComponent="typeOfComponent"
      :alpha="transparentOption"
    )
  template(v-if="backgroundType === 'gradient'")
    om-color-input(
      label="color2"
      :property="getPath('background.color2')"
      :typeOfComponent="typeOfComponent"
      :alpha="transparentOption"
    )
    // om-dropdown-input(label="gradientType", :property="propsProperty + `.${subPath}.background.gradientType`", :items="[{key: 'linear', value: 'linear'}, {key: 'radial', value: 'radial'}]", :typeOfComponent="typeOfComponent")
  template(v-if="gradientType === 'radial' && backgroundType === 'gradient'")
    om-dropdown-input(
      label="gradientCenter"
      :property="getPath('background.gradientCenter')"
      :items="radialDirection"
      :typeOfComponent="typeOfComponent"
    )
  template(v-if="gradientType === 'linear' && backgroundType === 'gradient'")
    .mt-4
      om-range-input(
        label="gradientDirection"
        :property="getPath('background.linearDirection')"
        :min="0"
        :max="360"
        :step="1"
        unit="°"
        :typeOfComponent="typeOfComponent"
      )
  template(v-if="imageProperties && imageId && imageId.length > 0 && backgroundType === 'image'")
    om-dropdown-input(
      label="imagePosition"
      :property="getPath('background.imagePosition')"
      :items="imagePosition"
      :typeOfComponent="typeOfComponent"
    )
    template(v-if="selectedImagePosition === 'contain'")
      om-dropdown-input.sidebar-horizontal-alignment(
        label="horizontalAlignment"
        :property="getPath('background.imageHorizontalAlign')"
        :items="imageHorizontalAlign"
        :typeOfComponent="typeOfComponent"
        defaultValue="center"
      )
      om-dropdown-input.sidebar-horizontal-alignment(
        label="verticalAlignment"
        :property="getPath('background.imageAlign')"
        :items="imageAlign"
        :typeOfComponent="typeOfComponent"
        defaultValue="center"
      )
    template(v-if="selectedImagePosition === 'cover'")
      om-dropdown-input.sidebar-horizontal-alignment(
        label="horizontalAlignment"
        :property="getPath('background.imageHorizontalAlign')"
        :items="imageHorizontalAlign"
        :typeOfComponent="typeOfComponent"
        defaultValue="center"
      )
      om-dropdown-input.sidebar-horizontal-alignment(
        label="verticalAlignment"
        :property="getPath('background.imageVerticalAlign')"
        :items="imageAlign"
        :typeOfComponent="typeOfComponent"
        defaultValue="center"
      )
    om-dropdown-input(
      label="imageRepeat"
      :property="getPath('background.imageRepeat')"
      :items="imageRepeat"
      :typeOfComponent="typeOfComponent"
    )
  template(v-if="subPath.includes('overlay')")
    sidebar-accordion(:title="$t('animation')" :embedded="true" :opened="true")
      om-dropdown-input(
        label="type"
        :property="getPath('animation.type')"
        :items="overlay.animation.types"
        :typeOfComponent="typeOfComponent"
      )
      om-dropdown-input(
        v-if="isAnimationConfetti"
        label="mode"
        :property="getPath('animation.mode')"
        :items="overlay.animation.modes"
        :typeOfComponent="typeOfComponent"
      )
      om-dropdown-input(
        label="page"
        :property="getPath('animation.page')"
        :items="choosablePages"
        :typeOfComponent="typeOfComponent"
      )
</template>
<script>
  import { mapGetters, mapMutations, mapState } from 'vuex';
  import itemMixin from '@/editor/mixins/item';
  import themeColorsMixin from '@/editor/mixins/themeColors';
  import UploadArea from '../../UploadArea.vue';

  const globalSubpaths = [
    'selectedPage',
    'globalStyle.overlay',
    'globalStyle.tab',
    'selectedElement.subElements.button.desktop',
  ];

  export default {
    components: { UploadArea },
    mixins: [itemMixin, themeColorsMixin],

    props: {
      label: { type: String, default: 'background' },
      propsProperty: { type: String, required: true },
      subPath: { type: String, default: '' },
      isOpen: { type: Boolean, default: false },
      typeOfComponent: { type: String, default: 'global' },
      transparentOption: { type: Boolean, default: true },
      imageProperties: { type: Boolean, default: true },
      saveImageUrl: { type: Boolean, default: false },
      blacklist: { type: Array, default: () => [] },
    },
    data() {
      return {
        radialDirection: [
          { key: 'center', value: 'circle at center' },
          { key: 'topLeft', value: 'to top left' },
          { key: 'top', value: 'to top' },
          { key: 'topRight', value: 'to top right' },
          { key: 'right', value: 'to right' },
          { key: 'bottomRight', value: 'to bottom right' },
          { key: 'bottom', value: 'to bottom' },
          { key: 'bottomLeft', value: 'to bottom left' },
          { key: 'left', value: 'to left' },
        ],
        imagePosition: [
          { key: 'original', value: 'full' },
          { key: 'contain', value: 'contain' },
          { key: 'cover', value: 'cover' },
          { key: 'stretch', value: '100% 100%' },
        ],
        imageAlign: [
          { key: 'top', value: 'top' },
          { key: 'center', value: 'center' },
          { key: 'bottom', value: 'bottom' },
        ],
        imageHorizontalAlign: [
          { key: 'left', value: 'left' },
          { key: 'center', value: 'center' },
          { key: 'right', value: 'right' },
        ],
        imageRepeat: [
          { key: 'noRepeat', value: 'no-repeat' },
          { key: 'repeat', value: 'repeat' },
          { key: 'repeatHorizontally', value: 'repeat-x' },
          { key: 'repeatVertically', value: 'repeat-y' },
        ],
        options: {
          acceptedFileTypes: ['image/*'],
          url: 'http://localhost:3000/fileupload',
          clickable: false,
        },
        overlay: {
          animation: {
            types: [
              { key: 'none', value: null },
              { key: 'confetti', value: 'confetti' },
              { key: 'fireworks', value: 'fireworks' },
            ],
            modes: [
              { key: 'cannon', value: 'cannon' },
              { key: 'fireworks', value: 'fireworks' },
            ],
          },
        },
      };
    },
    computed: {
      ...mapGetters(['nestedAccess', 'pages']),
      ...mapState(['globalStyle', 'images', 'mobilePreview']),
      showColor1() {
        let result = true;
        if (this.backgroundType === 'transparent' || !this.backgroundType) {
          result = false;
        }
        return result;
      },
      backgroundType() {
        if (this.isGlobalSubPath()) {
          const path = this.getPath('background.type');
          const type = this.getValueOf(path);

          return type;
        }

        let type = this.getValueOf('desktop.background.type');
        const mobileType = this.getValueOf('mobile.background.type');

        if (this.mobilePreview && mobileType) {
          type = mobileType;
        }

        return type;
      },
      selectedImagePosition() {
        const path = this.getPath('background.imagePosition');
        return this.getValueOf(path);
      },
      savedImageUrl() {
        const path = this.getPath('background.imageUrl');
        return this.getValueOf(path);
      },
      imageId() {
        if (this.isGlobalSubPath()) {
          const path = this.getPath('background.imageId');
          const type = this.getValueOf(path);

          return type;
        }

        let imageId = this.getValueOf('desktop.background.imageId');
        const mobileImageId = this.getValueOf('mobile.background.imageId');

        if (this.mobilePreview && mobileImageId) {
          imageId = mobileImageId;
        }

        return imageId;
      },
      backgroundColor() {
        const mainColor = this.getColors[0];
        const path = this.getPath('background.color');
        const color = this.getValueOf(path, mainColor);
        return color > -1 ? this.getColors[color] : color;
      },
      backgroundColor2() {
        const firstSecondary = this.getColors[1];
        const path = this.getPath('background.color2');
        const color = this.getValueOf(path, firstSecondary);
        return color > -1 ? this.getColors[color] : color;
      },
      linearDirection() {
        const path = this.getPath('background.linearDirection');
        return this.getValueOf(path, 0);
      },
      gradientCenter() {
        const path = this.getPath('background.gradientCenter');
        return this.getValueOf(path);
      },
      gradientType() {
        const path = this.getPath('background.gradientType');
        return this.getValueOf(path) || 'linear';
      },
      solidBackground() {
        return { background: this.backgroundColor };
      },
      gradientBackground() {
        const color1 = this.backgroundColor;
        const color2 = this.backgroundColor2;
        const gradientType =
          this.gradientType === 'linear' ? `${this.linearDirection || 0}deg` : this.gradientCenter;
        return {
          'background-image': `linear-gradient(${gradientType},${color1},${color2})`,
        };
      },
      backgroundImage() {
        if (this.imageId && this.imageId.length > 0) {
          const image = this.images.find((i) => i._id === this.imageId);
          const url = image ? image.url : this.savedImageUrl;
          return url;
        }

        return undefined;
      },
      backgroundSrc() {
        let path = this.getPath('background.imageId');
        if (this.isGlobalSubPath()) {
          path = this.getPath('background.imageId');
        }

        const imageId = this.getValueOf(path);
        const image = this.images.find((i) => i._id === imageId);
        return image ? image.url : this.savedImageUrl;
      },
      backgroundStyle() {
        // TODO - GLOBAL IMAGE
        const style = {
          width: '100%',
          height: '100%',
        };
        if (this.imageId && this.imageId.length > 0) {
          const image = this.images.find((i) => i._id === this.imageId);
          const url = image ? image.url : this.savedImageUrl;
          style.background = `url(${url || ''}) center center / contain no-repeat`;
        }

        return style;
      },
      choosablePages() {
        const pages = this.pages || [];
        const choosablePages = [{ key: 'allPages', value: -1 }];
        for (let i = 0; i < pages.length; i++) {
          const key =
            this.pages[i] && this.pages[i].data && this.pages[i].data.title
              ? this.pages[i].data.title
              : i + 1;
          choosablePages.push({ key, value: i });
        }
        return choosablePages;
      },
      isAnimationConfetti() {
        return this.globalStyle.overlay.animation.type === 'confetti';
      },
    },
    methods: {
      ...mapMutations([
        'updateStyle',
        'showImageManager',
        'setImageManagerDestination',
        'setSelectedPath',
      ]),
      imageManager() {
        this.setImageManagerDestination({
          targetAttr: this.getTargetAttr(),
          addElement: false,
          background: true,
          saveImageUrl: this.saveImageUrl,
          extBlacklist: this.blacklist,
        });
        this.setSelectedPath(this.subPath);
        this.showImageManager();
      },
      getTargetAttr() {
        if (this.subPath.includes('subElements.button')) {
          return this.subPath;
        }

        return this.propsProperty;
      },
      setBackgroundType(value) {
        const property = this.getPath('background.type');

        // On mobile you can override or inherit background style
        if (this.mobilePreview && value !== 'image' && this.backgroundType === value) {
          this.setValueOf(property, null);
        } else {
          this.setValueOf(property, value);
        }

        window.om.bus.$emit('userInputChange', { property, value });
      },
      triggerBrowse() {
        this.$refs.uploader.triggerBrowseFiles();
      },
      uploadSuccess() {
        this.setValueOf(this.getPath('background.type'), 'image');
      },
      getPath(path) {
        let result = `${this.editedDeviceType}.${path}`;

        if (this.subPath && this.subPath === 'selectedPage') {
          result = `${this.subPath}.desktop.${path}`;
        } else if (this.subPath) {
          result = `${this.subPath}.${path}`;
        }

        return result;
      },
      resetMobileImage() {
        this.setValueOf('mobile.background.imageId', null, true);
      },
      isGlobalSubPath() {
        return globalSubpaths.includes(this.subPath);
      },
    },
  };
</script>
