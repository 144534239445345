import { track } from '@/services/xray';
import { TEMPLATE_FEATURES } from '@/editor/util';
import i18n from '@/i18n';

export const TOUR_NAME = 'editorUITourExperiment';

const createFakeEl = (driver) => {
  const fakeEl = document.querySelector('.iframe-fake');
  const iframeEl = document.querySelector('#workspaceFrame');

  const setFakeEl = () => {
    let targetSelector = fakeEl.getAttribute('data-target');
    let targetEl = iframeEl.contentDocument.querySelector(targetSelector);
    let box = targetEl.getBoundingClientRect();
    if (box.width === 0 || box.height === 0) {
      targetSelector = '.om-overlay .om-outer-canvas';
      targetEl = iframeEl.contentDocument.querySelector(targetSelector);
      box = targetEl.getBoundingClientRect();
    }
    fakeEl.style.width = `${box.width}px`;
    fakeEl.style.height = `${box.height}px`;
    fakeEl.style.left = `${box.left}px`;
    fakeEl.style.top = `${box.top}px`;
    fakeEl.style.position = `absolute`;
    fakeEl.style.pointerEvents = `none`;
  };
  setFakeEl();
  window.addEventListener('resize', setFakeEl);
  window.om.bus.$on('setFakeEl', (element) => {
    fakeEl.setAttribute('data-target', element);
    setFakeEl();
    setTimeout(() => {
      window.om.bus.$emit('advanceProductTour');
    }, 1000);
  });
  driver.fakeEl = fakeEl;
};

const createFakeSidebar = () => {
  const fakeSidebar = document.createElement('div');
  fakeSidebar.classList.add('fake-sidebar');
  document.body.appendChild(fakeSidebar);
  const setFakeSidebar = () => {
    const sidebar = document.querySelector('.editor-right-column').getBoundingClientRect();
    fakeSidebar.style.width = `280px`;
    fakeSidebar.style.height = `85vh`;
    fakeSidebar.style.right = `0px`;
    fakeSidebar.style.top = `${sidebar.top}px`;
    fakeSidebar.style.position = `absolute`;
    fakeSidebar.style.pointerEvents = `none`;
  };
  setFakeSidebar();
  window.addEventListener('resize', setFakeSidebar);
};

const createFakeLeftSidebar = (elementName) => {
  const fakeSidebar = document.createElement('div');
  fakeSidebar.classList.add(elementName);
  document.body.appendChild(fakeSidebar);
  const setFakeSidebar = () => {
    const sidebar = document.querySelector('.sidebar').getBoundingClientRect();
    fakeSidebar.style.width = '260px';
    fakeSidebar.style.height = `85vh`;
    fakeSidebar.style.left = `72px`;
    fakeSidebar.style.top = `${sidebar.top}px`;
    fakeSidebar.style.position = `absolute`;
    fakeSidebar.style.pointerEvents = `none`;
  };
  setFakeSidebar();
  window.addEventListener('resize', setFakeSidebar);
};

const createFakeTeaserSettings = (isNewTeaser) => {
  const fakeTeaserSettings = document.createElement('div');
  fakeTeaserSettings.classList.add('fake-teaser-settings');
  document.body.appendChild(fakeTeaserSettings);
  const classForSearch = '.class-for-product-tour';
  window.om.bus.$on('setFakeTeaserSettings', () => {
    const teaserIndex = isNewTeaser ? 0 : 1;
    const teaserSettings = document
      .querySelectorAll(classForSearch)
      [teaserIndex].getBoundingClientRect();
    fakeTeaserSettings.style.height = isNewTeaser ? '400px' : '450px';
    fakeTeaserSettings.style.width = `${teaserSettings.width}px`;
    fakeTeaserSettings.style.left = `${teaserSettings.left}px`;
    fakeTeaserSettings.style.top = `${teaserSettings.top}px`;
    fakeTeaserSettings.style.position = `absolute`;
    fakeTeaserSettings.style.pointerEvents = `none`;
  });
};

const closeSvg = (events = ['resetProductTour']) => {
  let eventsString = '';
  if (events.length === 1) {
    eventsString = `window.om.bus.$emit('${events[0]}')`;
  } else {
    for (const [i, event] of events.entries()) {
      eventsString += `window.om.bus.$emit('${event}')${i === events.length - 1 ? '' : ' && '}`;
    }
  }

  return `
    <svg style="cursor:pointer;float:right" onClick="${eventsString}" width="14" height="14" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.3334 9.44438L0.555784 1.66665C0.248498 1.35937 0.248498 0.862841 0.555784 0.555551C0.863069 0.248262 1.35959 0.248262 1.66687 0.555551L9.44449 8.33327C9.75178 8.64056 9.75178 9.13709 9.44449 9.44438C9.13721 9.75166 8.64069 9.75166 8.3334 9.44438Z" fill="#C2C2C2"/>
      <path d="M0.555745 8.33325L8.33337 0.555532C8.64065 0.248243 9.13717 0.248243 9.44446 0.555532C9.75174 0.862822 9.75174 1.35935 9.44446 1.66664L1.66683 9.44436C1.35955 9.75164 0.863031 9.75164 0.555745 9.44436C0.24846 9.13707 0.24846 8.64054 0.555745 8.33325Z" fill="#C2C2C2"/>
    </svg>
  `;
};

export const init = ({ state, commit, dispatch, getters, rootGetters }) => {
  commit('setName', TOUR_NAME);
  state.isNewTeaser = !!rootGetters.hasTemplateFeature(TEMPLATE_FEATURES.NEW_TEASER);
  state.teaserPosition = rootGetters.getTeaserPosition();

  createFakeEl(state.driver);
  createFakeSidebar();
  createFakeTeaserSettings(state.isNewTeaser);
  createFakeLeftSidebar('fake-left-sidebar-theme');
  createFakeLeftSidebar('fake-left-sidebar-elements');
  createFakeLeftSidebar('fake-left-sidebar-layers');

  let teaserPopoverPosition;
  switch (state.teaserPosition) {
    case 'top-left':
      teaserPopoverPosition = 'right-top';
      break;
    case 'top-right':
      teaserPopoverPosition = 'left-top';
      break;
    case 'bottom-right':
      teaserPopoverPosition = 'left-bottom';
      break;
    case 'bottom-left':
      teaserPopoverPosition = 'right-bottom';
      break;
    default:
      teaserPopoverPosition = 'auto';
  }

  let all = getters.isUniversal ? 11 : 10;
  if (rootGetters.pageCount <= 1) {
    all--;
  }

  const steps = [];
  let current = 1;
  let button;

  if (getters.isUniversal) {
    if (rootGetters.isNewTemplate) {
      window.om.bus.$emit('openRightSidebar', true);
    } else {
      window.om.bus.$emit('show-left-sidebar-content', 0);
    }
    window.om.bus.$emit('show-sidebar-content', 'theme');
    window.om.bus.$emit('toggleHighlight', '.sidebar');

    button = `<button class="btn btn-link" onClick="window.om.bus.$emit('advanceProductTour') && window.om.bus.$emit('toggleHighlight', '.sidebar') && window.om.bus.$emit('setEditorStateAttr', { attr: 'paneLevel', value: 2 })">${i18n.t(
      'next',
    )}</button>`;

    steps.push({
      element: '.fake-left-sidebar-theme',
      popover: {
        className: 'popover-theme popover-wide',
        title: `${closeSvg()}`,
        description: `<div class="d-flex">
      <div class="description" style="margin-left: 15px;"><div class="driver-popover-title">${i18n.t(
        'productTour.brandKit',
      )}</div>${i18n.t('productTour.brandKitText')}
      <div class="popover-footer d-flex justify-content-between">
        <span class="step-status">${i18n.t('productTour.page', { current, all })}</span>
        ${button}
      </div>
      </div>
      </div>
      </div>`,
        position: 'right-center',
      },
    });
    current++;
  }

  button = `<button class="btn btn-link" onClick="window.om.bus.$emit('show-left-sidebar-content', 2) && window.om.bus.$emit('advanceProductTour') && window.om.bus.$emit('toggleHighlight', '.sidebar') && window.om.bus.$emit('setEditorStateAttr', { attr: 'paneLevel', value: 2 })">${i18n.t(
    'next',
  )}</button>`;

  steps.push({
    element: '.fake-sidebar',
    popover: {
      className: 'popover-layers popover-wide',
      title: `${i18n.t('productTour.layers')} ${closeSvg()}`,
      description: `${i18n.t('productTour.layersText')}
         <div class="popover-footer d-flex justify-content-between">
           <span class="step-status d-flex align-items-center">${i18n.t('productTour.page', {
             current,
             all,
           })}</span>
           ${button}
         </div>`,
      position: 'left-center',
    },
  });
  current++;

  steps.push({
    element: '.fake-left-sidebar-elements',
    popover: {
      className: 'popover-add-elements popover-wide',
      title: `${closeSvg()}`,
      description: `<div class="d-flex"><div class="animation"><lottie-player src="/animations/lottie/addElement.json" style="width: 160px; height: 160px;"></lottie-player></div>
      <div class="description"><div class="driver-popover-title">${i18n.t(
        'productTour.addElement',
      )}</div>${i18n.t('productTour.addElementText')}
      <div class="popover-footer d-flex justify-content-between">
        <span class="step-status">${i18n.t('productTour.page', { current, all })}</span>
        <button class="btn btn-link" onClick="window.om.bus.$emit('setFakeEl', '.om-overlay .om-canvas-content')">${i18n.t(
          'next',
        )}</button>
      </div>
      </div>
      </div>
      </div>`,
      position: 'right-center',
    },
  });
  current++;

  steps.push({
    element: '.iframe-fake',
    popover: {
      className: 'popover-edit-content',
      title: `${i18n.t('productTour.editContent')} ${closeSvg()}`,
      description: `${i18n.t('productTour.editContentText')}
      <div class="popover-footer"><span class="step-status">${i18n.t('productTour.page', {
        current,
        all,
      })}</span></div>`,
      position: 'bottom-center',
    },
  });
  current++;

  steps.push({
    element: '.fake-sidebar',
    popover: {
      className: 'popover-customize-elements',
      title: `${i18n.t('productTour.customizeElements')} ${closeSvg()}`,
      description: `${i18n.t('productTour.customizeElementsText')}.
      <div class="popover-footer d-flex justify-content-between">
        <span class="step-status d-flex align-items-center">${i18n.t('productTour.page', {
          current,
          all,
        })}</span>
        <button class="btn btn-link" onClick="window.om.bus.$emit('advanceProductTour') && window.om.bus.$emit('toggleHighlight', '.sidebar', false)">${i18n.t(
          'next',
        )}</button>
      </div>`,
      position: 'left-center',
    },
  });
  current++;

  if (rootGetters.pageCount > 1) {
    steps.push({
      element: '.new-navigation-items',
      popover: {
        className: 'popover-select-pages popover-wide',
        title: `${closeSvg()}`,
        description: `<div class="d-flex"><div class="animation"><lottie-player src="/animations/lottie/selectPages.json" style="width: 160px; height: 160px;"></lottie-player></div>
        <div class="description pl-2"><div class="driver-popover-title">${i18n.t(
          'productTour.selectPages',
        )}</div>${i18n.t('productTour.selectPagesText')}
        <div class="popover-footer">
          <span class="step-status">${i18n.t('productTour.page', { current, all })}</span>
        </div>
        </div>
        </div>`,
        position: 'top-center',
      },
    });
    current++;
  }

  steps.push({
    element: '.new-navigation-item-teaser',
    popover: {
      className: 'popover-select-teaser',
      title: `${i18n.t('tab')} ${closeSvg()}`,
      description: `${i18n.t('productTour.selectTeaserText')}.
      <div class="popover-footer">
        <span class="step-status">${i18n.t('productTour.page', { current, all })}</span>
      </div>`,
      position: 'top-center',
    },
  });
  current++;

  steps.push({
    element: '.iframe-fake',
    popover: {
      className: 'popover-teaser popover-wide',
      title: `${closeSvg()}`,
      description: `<div class="d-flex"><div class="animation"><lottie-player src="/animations/lottie/teaser.json" style="width: 160px; height: 160px;"></lottie-player></div>
      <div class="description"><div class="driver-popover-title">${i18n.t(
        'productTour.teaser',
      )}</div>${i18n.t('productTour.teaserText')}
      <div class="popover-footer d-flex justify-content-between">
        <span class="step-status">${i18n.t('productTour.page', { current, all })}</span>
        <button class="btn btn-link" onClick="window.om.bus.$emit('advanceProductTour') && window.om.bus.$emit('toggleHighlight', '.sidebar') && window.om.bus.$emit('setOverlayClickable', true)">${i18n.t(
          'next',
        )}</button>
      </div>
      </div>
      </div>`,
      position: teaserPopoverPosition,
    },
  });
  current++;

  steps.push({
    element: '.fake-teaser-settings',
    popover: {
      className: 'popover-teaser-settings',
      title: `${i18n.t('productTour.showTeaser')} ${closeSvg()}`,
      description: `${i18n.t('productTour.showTeaserText')}
      <div class="popover-footer d-flex justify-content-between">
        <span class="step-status d-flex align-items-center">${i18n.t('productTour.page', {
          current,
          all,
        })}</span>
        <button class="btn btn-link" onClick="window.om.bus.$emit('advanceProductTour')">${i18n.t(
          'next',
        )}</button>
      </div>`,
      position: 'right-center',
    },
  });
  current++;

  steps.push({
    element: '.device-selectors',
    popover: {
      className: 'popover-select-device',
      title: `${i18n.t('productTour.selectDevice')} ${closeSvg()}`,
      description: `${i18n.t('productTour.selectDeviceText')}
      <div class="popover-footer">
        <span class="step-status">${i18n.t('productTour.page', { current, all })}</span>
      </div>`,
      position: 'bottom-center',
    },
  });
  current++;

  steps.push({
    element: '#workspaceFrame',
    popover: {
      className: 'popover-mobile-view',
      title: `${i18n.t('productTour.mobileView')} ${closeSvg([
        'advanceProductTour',
        'showProductTourSuccess',
      ])}`,
      description: `${i18n.t('productTour.mobileViewText')}
      <div class="popover-footer d-flex justify-content-between">
        <span class="step-status d-flex align-items-center">${i18n.t('productTour.page', {
          current,
          all,
        })}</span>
        <button class="btn btn-link" onClick="window.om.bus.$emit('advanceProductTour') && window.om.bus.$emit('showProductTourSuccess')">${i18n.t(
          'finish',
        )}</button>
      </div>`,
      position: 'left-center',
    },
  });

  state.driver.defineSteps(steps);

  state.driver.start();

  window.om.bus.$emit('initProductTour', state.driver);
  window.om.bus.$on('advanceProductTour', () => dispatch('next'));
  window.om.bus.$on('resetProductTour', () => dispatch('reset'));
  window.om.bus.$on('setEditorPreviewMode', (mode) =>
    dispatch('setEditorPreviewMode', { mode }, { root: true }),
  );
  window.om.bus.$on('toggleHighlight', (selector, value = true) => {
    if (value) {
      document.querySelector(selector).classList.add('driver-highlighted-element');
    } else {
      document.querySelector(selector).classList.remove('driver-highlighted-element');
    }
  });
  window.om.bus.$on('setOverlayClickable', (value) => {
    document
      .querySelector('#driver-page-overlay')
      .style.setProperty('z-index', value ? 999999998 : 999999999, 'important');
  });
  window.om.bus.$on('hideProductTour', () => dispatch('hide'));
};

export const next = ({ state, dispatch, getters, rootState }) => {
  const { databaseId } = rootState.account;
  const highlightedElement = state.driver.getHighlightedElement();
  if (highlightedElement.popover.options.className.includes('popover-mobile-view')) {
    dispatch('storeStatus', { status: 'finished', tour: TOUR_NAME });
    track('ProductTour', {
      action: 'finish',
      databaseId,
      experiment: TOUR_NAME,
    });
    setTimeout(() => window.om.bus.$emit('toggleHighlight', '.sidebar', false), 3000);

    if (getters.isUniversal) {
      window.om.bus.$emit('openRightSidebar', false);
      window.om.bus.$emit('show-left-sidebar-content', 0);
    }
  }
};
