import { get as _get } from 'lodash-es';
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState([
      'mobilePreview',
      'selectedElement',
      'selectedPage',
      'selectedColumn',
      'selectedRow',
    ]),
    device() {
      return this.mobilePreview ? 'mobile' : 'desktop';
    },
  },
  methods: {
    hasBackground(element) {
      let selectedPrefix = 'selectedPage';

      if (element === 'column') selectedPrefix = 'selectedColumn';
      if (element === 'row') selectedPrefix = 'selectedRow';

      const backgroundType = _get(this[selectedPrefix], `${this.device}.background.type`);
      if (backgroundType && backgroundType === 'transparent') return false;

      const backgroundImageUrl = _get(this[selectedPrefix], `${this.device}.background.imageUrl`);
      const backgroundImageId = _get(this[selectedPrefix], `${this.device}.background.imageId`);
      if (
        backgroundType &&
        backgroundType === 'image' &&
        (!backgroundImageUrl || !backgroundImageId)
      ) {
        return false;
      }

      return true;
    },
  },
};
