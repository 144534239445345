<template lang="pug">
.container.h-100.d-flex.align-items-center
  loading-logo(v-if="loading")
  template(v-else)
    .close-btn(@click.prevent="handleClose")
      i.fa.fa-times(aria-hidden="true")
    template(v-if="notLoggedIn")
      login-box(
        v-if="type === 'normal'"
        :shopRenter="$i18n.locale === 'hu'"
        :shopify="$i18n.locale === 'en'"
        @success="loginSuccess"
        :loginToOwner="!service"
      )

      .w-100.w-md-35.mx-auto.text-center.brand-auth-login-box.brand-auth-permission(
        v-else-if="type === 'shoprenter'"
      )
        integration-logo(type="shoprenter")
        .font-italic.font-weight-bold {{ shoprenterDomain }}
        .my-3 {{ $t('loginViaShopRenter') }}
        .d-flex.justify-content-center
          button.brand-btn.brand-btn-primary.mr-3(@click="redirectToShopRenter") {{ $t('yes') }}
          button.brand-btn.brand-btn-secondary(@click="type = 'normal'") {{ $t('no') }}

    .w-100.w-md-35.mx-auto.text-center.brand-auth-login-box.brand-auth-permission(
      v-else-if="service"
    )
      .brand-auth-permission-user.font-size-0--75
        .font-italic.font-weight-bold.mr-2 {{ email }}
        a.brand-link(href="javascript:void(0)" @click="logout") {{ $t('itsNotMe') }}
      .font-size-2 {{ $t('connect.hi', { firstName }) }},
      .mt-1.mb-3 {{ $t('oauth.connect') }}
      img.brand-requesting-application-image.mb-3(:src="require('@/assets/admin/svg/zapier.svg')")
      .mb-3 {{ $t('oauth.permission') }}
      .font-weight-bold.mb-1 - {{ $t('oauth.permissions.readSubscribers') }}
      .font-weight-bold - {{ $t('oauth.permissions.writeSubscribers') }}
      .d-flex.justify-content-center.mt-4
        button.brand-btn.brand-btn-primary.mr-3(@click="allow") {{ $t('allow') }}
        button.brand-btn.brand-btn-secondary(@click="logout") {{ $t('deny') }}
    .w-100.w-md-35.mx-auto.text-center.brand-auth-login-box.brand-auth-permission(
      v-else-if="autoLogin"
    )
      om-logo-svg.mb-3
      .font-italic.font-weight-bold {{ email }}
      .my-3 {{ $t('isThatYou') }}
      .d-flex.justify-content-center
        button.brand-btn.brand-btn-primary.mr-3(@click="sendTokenToOpener") {{ $t('yes') }}
        button.brand-btn.brand-btn-secondary(@click="logout") {{ $t('no') }}
</template>

<script>
  import LoginBox from '@/components/Login/LoginBox.vue';
  import IntegrationLogo from '@/components/IntegrationLogo.vue';
  import axios from 'axios';
  import queryString from 'query-string';
  import { mapActions } from 'vuex';
  import LOGOUT from '@/graphql/Logout.gql';
  import { apiBaseUrl } from '@/config/url';
  import guessLang from '@/mixins/guessLang';
  import runtimeConfig from '@/config/runtime';

  export default {
    components: { LoginBox, IntegrationLogo },
    mixins: [guessLang],

    data() {
      return {
        loggedIn: false,
        loading: true,
        autoLogin: false,
        cameFromSwitchAccount: false,
        service: null,
        type: 'normal',
        shoprenterDomain: null,
      };
    },

    computed: {
      firstName() {
        return this.$store.state.account.login.firstName;
      },
      email() {
        if (this.$store.state.account.name !== this.$store.state.account.login.email) {
          return this.$store.state.account.name;
        }
        return this.$store.state.account.login.email;
      },
      notLoggedIn() {
        return !this.loggedIn;
      },
    },

    async created() {
      this.setLang();
      const { data } = await axios.get(`${apiBaseUrl}/login/isAuthenticated`, {
        withCredentials: true,
      });

      if (data.success) {
        if (data.switchAccount) {
          if (data.pendingShopifyInstall) {
            localStorage.setItem('om_redirect_after_switch_account', '/connect-shopify');
          }

          return this.$router.replace({ name: 'switch_account' });
        }

        if (data.pendingShopifyInstall) {
          return this.$router.replace({ name: 'connect_shopify' });
        }

        await this.fetchAccount();
        this.loggedIn = true;
        this.autoLogin = true;
      }
      this.loading = false;

      this.service = this.$route.params.service;
      this.type = this.$route.query.type || 'normal';

      if (this.type === 'shoprenter') {
        this.shoprenterDomain = this.$route.query.shoprenterDomain;

        if (!this.shoprenterDomain) {
          this.type = 'normal';
        }
      }
    },

    methods: {
      ...mapActions(['fetchAccount', 'fetchOAuthToken', 'broadcastOAuthToken']),

      loginSuccess() {
        this.loggedIn = true;
        this.sendTokenToOpener();
      },

      allow() {
        const qS = queryString.stringify(this.$route.query);
        window.location = `${apiBaseUrl.replace('api', '')}app/zapier/authorize?${qS}`;
      },

      async logout() {
        this.loading = true;
        await this.$apollo.mutate({
          mutation: LOGOUT,
        });
        this.$store.commit('logout', false);
        this.loggedIn = false;
        this.loading = false;
        this.autoLogin = false;
        this.setLang();
      },

      openedInPopupWindow() {
        return window.opener != null;
      },

      openedInIframe() {
        return window.parent != null;
      },

      async sendTokenToOpener() {
        const token = await this.fetchOAuthToken();

        this.broadcastOAuthToken(token);

        if (this.openedInPopupWindow()) {
          window.close();
        }
      },

      setLang() {
        const language = this.guessLanguage();
        this.$i18n.locale = language;
      },
      redirectToShopRenter() {
        localStorage.setItem('shoprenter_oauth', 1);
        window.location = `${this.shoprenterDomain}/admin/app/${
          parseInt(this.$route.query.install, 10) ? 'install/' : ''
        }${runtimeConfig.VUE_APP_SHOPRENTER_APP_ID || 17}`;
      },
      handleClose() {
        const opener = window.opener || window.parent;
        if (opener) {
          opener.postMessage(JSON.stringify({ type: 'optimonk', action: 'close' }), '*');
        }

        if (this.openedInPopupWindow()) {
          window.close();
        }
      },
    },
  };
</script>

<style lang="sass">
  .oauth-login-page
    font-size: 14px

    body
      background: transparent

    .brand-page-wrap
      height: 100%

    .brand-auth-login-box
      background-color: white
      padding: 25px 30px
      border-radius: 3px

      .shoprenter-logo
        height: 60px
        margin: auto

    .brand-auth-permission
      padding: 25px 0
      &-user
        display: flex
        justify-content: center
        margin-bottom: 15px
    .brand-requesting-application-image
      max-height: 50px
      margin: 0 auto

    .close-btn
      color: #ccc
      position: fixed
      cursor: pointer
      right: 10px
      top: 10px
      font-size: 1.2em
      padding: 10px
</style>
