<template lang="pug">
div
  campaign-settings-box(
    :show="show"
    :bottomBorder="bottomBorder"
    :hasWarning="hasWarning"
    :campaign="campaign"
    @activate="$emit('activate')"
    @close="$emit('close')"
  )
    template(#title)
      slot(name="title-content") {{ $t('whoToShowLong') }}
    template(#content)
      .pt-3
        transition-group.overflow-hidden(name="fade" tag="div")
          template(v-for="(frontendRule, index) in frontendRulesToShow")
            .campaign-setting-card.card.cursor-pointer(
              @click="addNewRule(frontendRule.type)"
              :key="frontendRule.type"
            )
              .card-body.card-body-line.px-0
                frontend-rule-box(:frontendRule="frontendRule" :campaign="ruleBoxCampaign")
                  template(#action)
                    .d-flex.justify-content-end.flex-grow-1
                      .remove-setting-box(
                        @click.stop="removeRule('frontendRules', frontendRule.originalIndex)"
                      )
                        uil-times.cursor-pointer.remove-setting(:size="'25px'")
              .rule-logical-condition.rule-logical-condition-and(
                v-if="index < frontendRulesToShow.length - 1"
                :class="`rule-logical-condition-and-${$i18n.locale}`"
              ) {{ $t('and') }}
          .campaign-setting-card.card.last-child.campaign-setting-card-skeleton(key="add-rule-row")
            .card-body.px-0.card-body-line
              .d-flex
                om-button.text-primary(secondary large icon="plus-circle" @click="addNewRule()") {{ $t('addNewCondition') }}
                slot(name="add-rule-section")
    template(slot="button")
      slot(name="settings-button")
  new-campaign-setting-rule(
    settingType="frontendRules"
    :settingArray.sync="frontendRules"
    @editRule="editRule('frontendRules', $event)"
    :domain="campaign ? campaign.domain : 'optimonk.com'"
    :campaign="campaign ? campaign : { templateType: 'popup' }"
    @ruleIsPartOfExperienceWarning="ruleIsPartOfExperienceWarning"
    :editMode="editMode"
  )
  RuleIsPartOfExperienceWarningModal(@continueAddRule="continueAddRule")
</template>

<script>
  import Vue from 'vue';
  import { mapState } from 'vuex';
  import CampaignSettingsBox from '@/components/CampaignSettings/SettingsBox';
  import NewCampaignSettingRule from '@/components/Modals/NewCampaignSettingRule.vue';
  import CampaignSettingsSkeleton from '@/components/CampaignSettings/Skeleton.vue';
  import EventBox from '@/components/CampaignSettings/EventBox.vue';
  import FrontendRuleBox from '@/components/CampaignSettings/FrontendRuleBox.vue';
  import AddSvg from '@/components/Svg/Icons/Add';
  import { UilTimes } from '@iconscout/vue-unicons';
  import dynamicContentMixin from '@/mixins/dynamicContent';
  import RuleIsPartOfExperienceWarningModal from '@/components/Modals/RuleIsPartOfExperienceWarning.vue';

  import { getBrandedClassString } from '@/components/Elements/Button';

  export default {
    components: {
      CampaignSettingsBox,
      NewCampaignSettingRule,
      CampaignSettingsSkeleton,
      EventBox,
      FrontendRuleBox,
      AddSvg,
      UilTimes,
      RuleIsPartOfExperienceWarningModal,
    },
    mixins: [dynamicContentMixin],
    props: {
      rules: {
        type: Array,
        default: () => [],
      },
      show: {
        type: Boolean,
        default: false,
      },
      bottomBorder: {
        type: Boolean,
        default: false,
      },
      hasError: {
        type: Boolean,
        default: false,
      },
      hasWarning: {
        type: Boolean,
        default: false,
      },
      campaign: {
        type: Object,
        default: () => {},
      },
      editMode: {
        default: 'campaignSettings',
        type: String,
      },
    },
    data: () => ({
      name: null,
      frontendRules: [],
    }),
    computed: {
      ...mapState(['account']),
      isEmbedded() {
        return (
          this.campaign && this.campaign.templateType && this.campaign.templateType === 'embedded'
        );
      },
      campaignType() {
        if (this.isEmbedded && this.campaign.version === 3) {
          return 'embeddedv3';
        }

        if (this.isDynamicContent) {
          return 'dynamic_content';
        }

        return this.isEmbedded ? 'embedded' : 'default';
      },
      ruleBoxCampaign() {
        if (!this.campaign || !this.campaign.domain) return { domain: 'optimonk.com' };
        return this.campaign;
      },
      frontendRulesToShow() {
        const rules =
          this.frontendRules?.map((rule, index) => {
            return { ...rule, originalIndex: index };
          }) ?? null;
        return rules?.filter(({ type }) => !this.isHiddenRuleType(type)) ?? [];
      },
      features() {
        return this.account?.features ?? [];
      },
    },
    watch: {
      frontendRules: {
        handler(nValue) {
          this.$emit('change', nValue);
        },
        deep: true,
      },
      campaign: {
        deep: true,
        handler() {
          this.frontendRules = this.campaign.frontendRules;
        },
      },
    },
    mounted() {
      if (this.campaign) this.frontendRules = this.campaign.frontendRules;
      if (this.rules.length) this.frontendRules = this.rules;
    },
    methods: {
      ruleIsPartOfExperienceWarning({ ruleType }) {
        this.$modal.show('rule-is-part-of-experience-warning', { ruleType });
      },
      continueAddRule({ ruleType }) {
        this.$modal.hide('rule-is-part-of-experience-warning');
        this.$modal.hide('new-frontendrules-modal');
        this.$modal.show('new-frontendrules-modal', {
          ruleset: this.campaignType,
          ruleType,
          needRuleExtractionFromExperienceModal: false,
        });
      },
      editRule(type, rule) {
        for (let i = 0; i < this[type].length; i++) {
          if (this[type][i].type === rule.type) {
            Vue.set(this[type], i, rule);
            break;
          }
        }
      },
      removeRule(type, index) {
        this.$modal.show('dialog', {
          text: this.$t('confirmationDialog'),
          buttons: [
            {
              title: this.$t('yes'),
              class: getBrandedClassString({ primary: true }, 'mr-3'),
              handler: () => {
                this[type].splice(index, 1);
                this.$modal.hide('dialog');
              },
            },
            {
              title: this.$t('cancel'),
              default: true,
              class: getBrandedClassString({ secondary: true }),
            },
          ],
        });
      },
      addNewRule(type) {
        this.$modal.show('new-frontendrules-modal', {
          ruleset: this.campaignType,
          type,
        });
      },
      // eslint-disable-next-line no-unused-vars
      isHiddenRuleType(type) {
        return false;
      },
    },
  };
</script>
