<template lang="pug">
base-pane
  Accordion.mt-3(fix)
    .user-info-message.alert.alert-danger.font-size-0--875(v-if="noCouponUploaded") {{ $t('uploadCoupons') }}
    Dropdown#discountType(
      :label="$t('discountPane.title')"
      v-model="discountType"
      :items="discountTypeOptions"
      customClass="full-width"
    )
      template(#help)
        span {{ helpText }}
    MoreOrLess(
      :open="openNestedCouponComponent"
      @toggle="openNestedCouponComponent = !openNestedCouponComponent"
    )
      BaseCoupon
  hr.mt-1.mb-4
  Font(
    fontFamilyProperty="subElements.couponText.desktop.fontFamily"
    fontSizeProperty="subElements.couponText.desktop.fontSize"
    textWeightProperty="subElements.couponText.desktop.textWeight"
    fontItalicProperty="subElements.couponText.desktop.fontItalic"
    textDecorationProperty="subElements.couponText.desktop.textDecoration"
    hideAlign
  )
    template(#colors)
      OmColorInput(
        :label="$t('fontColor')"
        property="subElements.couponText.desktop.color"
        typeOfComponent="coupon"
        layout="col"
        dsAllowed
      )
  hr.mt-1.mb-4
  Accordion.mb-3.mt-2(expandableGap="12px" fix)
    template(#title)
      .font-weight-bold {{ $t('sizeAndSpacing') }}
    template(#device)
      DeviceSelector(:hasViewText="false")
    Size(
      :label="$t('align')"
      layout="col"
      editMobile
      :deviceSelector="false"
      typeOfComponent="coupon"
    )
    template(v-if="size === 'manual'")
      RangeInput(:label="$t('width')" :min="0" :max="600" :step="1" v-model="width")
    template(v-if="size !== null")
      RangeInput(:label="$t('height')" :min="0" :max="600" :step="1" v-model="height")
    template(v-if="size !== '100w'")
      Align(:labelOverride="$t('verticalAlignment')" v-model="verticalAlign" vertical flexOptions)
      Align(v-model="horizontalAlign" flexOptions)
    Margin
  hr.mt-1.mb-4
  Accordion.mt-2(expandableGap="12px" fix)
    template(#title)
      .font-weight-bold {{ $t('backgroundAndBorder') }}
    template(#device)
      DeviceSelector(:hasViewText="false")
    OmColorInput(
      :label="$t('backgroundColor')"
      typeOfComponent="coupon"
      layout="col"
      editMobile
      dsAllowed
    )
    Border(typeOfComponent="coupon" :deviceSelector="false")
    Accordion(fix)
      template(#moreOrLess)
        CornerRadius(:deviceSelector="false")
        Shadow(v-model="shadow" editMobile typeOfComponent="coupon")
  template(#advancedContent)
    Hide(:hideOnDesktop.sync="hideElementOnDesktop" :hideOnMobile.sync="hideElementOnMobile")
  hr.mt-1.mb-4
</template>
<script>
  import { mapState, mapGetters, createNamespacedHelpers } from 'vuex';
  import itemMixin from '@/editor/mixins/item';
  import Accordion from '@/components/Editor/Accordion/Accordion.vue';
  import Heading from '@/components/Editor/Heading.vue';
  import MoreOrLess from '@/components/Editor/MoreOrLess.vue';
  import Dropdown from '@/components/Editor/Dropdown/Dropdown.vue';
  import Size from '@/components/Editor/Controls/Size.vue';
  import Border from '@/components/Editor/Controls/Border.vue';
  import CornerRadius from '@/components/Editor/Controls/CornerRadius.vue';
  import Shadow from '@/components/Editor/Controls/Shadow.vue';
  import InputGroup from '@/components/Editor/Controls/InputGroup.vue';
  import Align from '@/components/Editor/Controls/Alignments/Align.vue';
  import Margin from '@/components/Editor/Controls/InputGroup/Margin.vue';
  import Font from '@/components/Editor/Blocks/Font.vue';
  import RangeInput from '@/components/Editor/RangeInput/RangeInput.vue';
  import Hide from '@/components/Editor/Controls/Hide.vue';
  import BaseCoupon from '@/components/Editor/Blocks/Coupon/BaseCoupon.vue';
  import BasePane from './BasePane.vue';
  import DeviceSelector from '../../components/DeviceSelector.vue';

  const { mapGetters: editorOuterGetters } = createNamespacedHelpers('editorOuter');

  export default {
    components: {
      BasePane,
      Accordion,
      Heading,
      MoreOrLess,
      Dropdown,
      Font,
      Size,
      RangeInput,
      Border,
      CornerRadius,
      Shadow,
      InputGroup,
      Align,
      Margin,
      Hide,
      BaseCoupon,
      DeviceSelector,
    },
    mixins: [itemMixin],
    data: () => ({
      canEditMobile: true,
      openNestedCouponComponent: false,
    }),
    computed: {
      ...mapState(['campaign', 'mobilePreview', 'validationError']),
      ...mapGetters(['isActiveShopifyDomain', 'isTemplateEditorMode', 'isEmbedded']),
      ...editorOuterGetters(['isGamificationTemplate']),
      discountType: {
        get() {
          return this.getValueOf('data.coupon.type');
        },
        set(v) {
          this.setValueOf('data.coupon.type', v);
        },
      },
      width: {
        get() {
          const mobileValue = this.getValueOf('mobile.smartSize.width');
          if (this.mobilePreview && !mobileValue) {
            return Number(this.getValueOf('desktop.smartSize.width'));
          }
          return Number(this.getValueOf('$device.smartSize.width'));
        },
        set(v) {
          this.smartSetValueOf(`$device.smartSize.width`, v);
        },
      },
      height: {
        get() {
          const mobileValue = this.getValueOf('mobile.height');
          if (this.mobilePreview && !mobileValue) {
            return Number(this.getValueOf('desktop.height'));
          }
          return Number(this.getValueOf('$device.height'));
        },
        set(v) {
          this.smartSetValueOf(`$device.height`, v);
        },
      },
      verticalAlign: {
        get() {
          return this.getValueOf(`$device.alignItems`);
        },
        set(v) {
          this.smartSetValueOf(`$device.alignItems`, v);
        },
      },
      horizontalAlign: {
        get() {
          return this.getValueOf(`$device.justifyContent`);
        },
        set(v) {
          this.smartSetValueOf(`$device.justifyContent`, v);
        },
      },
      shadow: {
        get() {
          return this.getValueOf('shadow.type');
        },
        set(value) {
          this.setValueOf('shadow.type', value);
        },
      },
      hideElementOnDesktop: {
        get() {
          return this.getValueOf('desktop.hidden');
        },
        set(v) {
          this.setValueOf('desktop.hidden', v);
        },
      },
      hideElementOnMobile: {
        get() {
          return this.smartGetValueOf('mobile.hidden');
        },
        set(v) {
          this.smartSetValueOf('mobile.hidden', v);
        },
      },
      size() {
        return this.getValueOf(`smartSize.type`);
      },
      helpText() {
        const type = this.discountType;
        if (type === 'unique') return this.$t(`discountPane.fixedOrUnique.subTitle`);
        if (type === 'shopify_automatic') return this.$t(`discountPane.automatic.subTitle`);

        if (!this.$te(`discountPane.${type}.subTitle`)) return;

        return this.$t(`discountPane.${type}.subTitle`);
      },
      isActiveShopify() {
        return this.isActiveShopifyDomain(this.campaign.domain);
      },
      isVersion3() {
        return this.campaign.version === 3;
      },
      discountTypeOptions() {
        let types = [
          { value: 'fixed', text: this.$t('discountPane.types.fix') },
          { value: 'unique', text: this.$t('discountPane.types.unique') },
          { value: 'followup', text: this.$t('discountPane.types.followup') },
        ];
        const isV3Embedded = this.isEmbedded && this.isVersion3;

        if (this.isActiveShopify || this.isTemplateEditorMode) {
          // need to insert to the second place, not the end of the array
          types.splice(1, 0, {
            value: 'shopify_automatic',
            text: this.$t('discountPane.types.automatic'),
          });
        }

        if (this.isGamificationTemplate) {
          types = [{ value: 'gamification', text: this.$t('discountPane.types.gamification') }];
          this.setValueOf('data.coupon.type', 'gamification');
        }

        const spliceSize = this.isActiveShopify ? 3 : 2;
        if (isV3Embedded) {
          types.splice(spliceSize);
        }

        return types;
      },

      noCouponUploaded() {
        return (
          this.validationError &&
          this.validationError.property &&
          this.validationError.property.includes('noCouponUploaded')
        );
      },
      hasInvalidState() {
        return (
          this.validationError &&
          this.validationError.property &&
          this.validationError.property.includes('data.coupon')
        );
      },
    },
    watch: {
      validationError(error) {
        this.openNestedCouponComponentLogic(error);
      },
    },
    mounted() {
      if (!this.validationError) return;

      this.openNestedCouponComponentLogic(this.validationError);
    },
    methods: {
      openNestedCouponComponentLogic(error) {
        if (!error || (!this.hasInvalidState && !this.noCouponUploaded)) return;

        this.openNestedCouponComponent = true;
      },
    },
  };
</script>
<style lang="sass">
  @import '@/sass/variables/_colors.sass'
  .coupon-data
    .shopify-coupon-info-box
      font-size: 12px
      color: #8F97A4
      .coupon-info-text
        .cursor-pointer
          color: inherit
          text-decoration: underline
    .create-coupon-button
      color: white
    .shopify-coupon-details
      border-radius: 8px
      border: 1px dashed $om-ds-gray
      padding: 12px
      .shopify-coupon-title
        font-weight: 600
        color: #23262A
  .loading-coupon
    height: 81px
    position: relative
    display: flex
    align-items: center
    justify-content: center
    z-index: 10
    font: normal normal normal 0.875rem/1 FontAwesome
    -animation: spin .7s infinite linear
    -moz-animation: spin .7s infinite linear
    -ms-animation: spin .7s infinite linear
    -webkit-animation: spin .7s infinite linear
  .alert-danger
    margin-bottom: unset
</style>
