<template lang="pug">
span
  div
    upload-area.vc-upload-area(
      :src="backgroundSrc"
      :editMobile="editMobile"
      @click="imageManager"
      @remove="resetMobileImage"
      :inColorPicker="true"
    )
  .vc-sketch-top
    .vc-sketch-section.mt-2
      .vc-sketch-section--title {{ $t('image') }}
      .mt-2
        image-position-select(
          label="position"
          :property="imagePositionPath"
          :options="imagePositions"
          :typeOfComponent="typeOfComponent"
          :colorInstance="colorInstance"
          @positionSelectChange="positionChange"
        )
        image-position-select.mt-3(
          v-if="selectedImagePosition !== 'crop'"
          label="repeat"
          :property="imageRepeatPath"
          :options="imageRepeat"
          :typeOfComponent="typeOfComponent"
          :colorInstance="colorInstance"
          @positionSelectChange="repeatChange"
        )
    .vc-sketch-section.mt-3(v-if="['contain', 'cover'].includes(selectedImagePosition)")
      .vc-sketch-section--title.d-flex {{ $t('alignment') }}
        om-tooltip.ml-1.align-self-center(iconSize="16px" transition="fade")
          span {{ $t('imageAlignDisableTooltip') }}
      .d-flex
        align-select.mt-2.mr-5.aligning(
          label="horizontal"
          :property="horizontalAlignPath"
          :options="imageHorizontalAlign.map(({ key }) => key)"
          :inline="false"
          :small="true"
          :showOption="true"
          :colorInstance="colorInstance"
          @alignSelectChange="horizontalAlignChange"
          :isDisabled="isAligningDisabled.horizontal"
        )
        align-select.mt-2.aligning(
          label="vertical"
          :property="selectedImagePosition === 'contain' ? imageAlignPath : imageVerticalAlign"
          :options="imageAlign.map(({ key }) => key)"
          :vertical="true"
          :inline="false"
          :small="true"
          :showOption="true"
          :colorInstance="colorInstance"
          @alignSelectChange="verticalAlignChange"
          :isDisabled="isAligningDisabled.vertical"
        )
</template>

<script>
  import { mapState, mapMutations, mapGetters } from 'vuex';
  import itemMixin from '@/editor/mixins/item';
  import UploadArea from '@/editor/components/UploadArea';
  import AlignSelect from '@/editor/components/sidebar/components/AlignSelect.vue';
  import ImagePositionSelect from '../common/ImagePositionSelect.vue';
  import ImageAlignSelect from '../common/ImageAlignSelect.vue';

  export default {
    name: 'ImageTab',
    components: {
      UploadArea,
      ImagePositionSelect,
      ImageAlignSelect,
      AlignSelect,
    },
    mixins: [itemMixin],
    props: {
      property: { type: String, required: true },
      propsProperty: { type: String, required: true },
      isGlobalSubPath: { type: Boolean, default: false },
      subPath: { type: String, default: '' },
      saveImageUrl: { type: Boolean, default: false },
      blacklist: { type: Array, default: () => [] },
      i18n: { type: Boolean, default: true },
      typeOfComponent: { type: String, default: 'global' },
      colorInstance: {
        type: Object,
        default() {
          return null;
        },
      },
    },
    data() {
      return {
        isAligningDisabled: {
          vertical: false,
          horizontal: false,
        },
        canEditMobile: true,
        imageAlign: [
          { key: 'top', value: 'top' },
          { key: 'center', value: 'center' },
          { key: 'bottom', value: 'bottom' },
        ],
        imageHorizontalAlign: [
          { key: 'left', value: 'left' },
          { key: 'center', value: 'center' },
          { key: 'right', value: 'right' },
        ],
        imageRepeat: [
          { key: 'noRepeat', value: 'no-repeat' },
          { key: 'repeat', value: 'repeat' },
          { key: 'repeatHorizontally', value: 'repeat-x' },
          { key: 'repeatVertically', value: 'repeat-y' },
        ],
      };
    },
    computed: {
      ...mapState(['globalStyle', 'images', 'mobilePreview', 'selectedElement']),
      isContainPosition() {
        return this.selectedImagePosition === 'contain';
      },
      isCoverPosition() {
        return this.selectedImagePosition === 'cover';
      },
      ...mapGetters(['hasAccountFeature']),
      backgroundSrc() {
        return this.colorInstance.getImageSrc();
      },
      savedImageUrl() {
        return this.colorInstance.getImageSrc();
      },
      selectedImagePosition() {
        return this.colorInstance.getImagePosition();
      },
      imagePositionPath() {
        return this.colorInstance.getImagePositionProperty();
      },
      imageRepeatPath() {
        return this.colorInstance.getImageRepeatProperty();
      },
      imageAlignPath() {
        return this.colorInstance.getImageAlignProperty();
      },
      horizontalAlignPath() {
        return this.colorInstance.getImageHorizontalAlignProperty();
      },
      imageVerticalAlign() {
        return this.colorInstance.getImageVerticalAlignProperty();
      },
      imagePositions() {
        const positions = [
          { key: 'original', value: 'full' },
          { key: 'contain', value: 'contain' },
          { key: 'cover', value: 'cover' },
          { key: 'stretch', value: '100% 100%' },
        ];

        if (this.selectedElement.type === 'OmCol' && this.colorInstance.getImageId()) {
          positions.push({ key: 'crop', value: 'crop' });
        }

        return positions;
      },
    },
    watch: {
      selectedImagePosition: {
        async handler() {
          this.isAligningDisabled = {
            vertical: false,
            horizontal: false,
          };
          await this.isDisabledToSetAlign();
        },
        deep: true,
        immediate: true,
      },
      backgroundSrc: {
        async handler() {
          this.isAligningDisabled = {
            vertical: false,
            horizontal: false,
          };
          await this.isDisabledToSetAlign();
        },
        deep: true,
        immediate: true,
      },
    },
    async mounted() {
      await this.isDisabledToSetAlign();
    },
    methods: {
      ...mapMutations([
        'updateStyle',
        'showImageManagerV2',
        'setImageManagerDestination',
        'setSelectedPath',
      ]),
      imageManager() {
        this.setImageManagerDestination({
          targetAttr: this.getTargetAttr(),
          addElement: false,
          background: true,
          saveImageUrl: this.saveImageUrl,
          extBlacklist: this.blacklist,
          editedDevice: this.editedDeviceType,
          colorInstance: this.colorInstance,
        });
        this.setSelectedPath(this.subPath);
        this.showImageManagerV2(true);
      },
      resetMobileImage() {
        this.setValueOf('mobile.background.imageId', null, true);
      },
      getTargetAttr() {
        if (this.subPath.includes('subElements')) {
          return `${this.propsProperty}.${this.subPath}`;
        }
        return this.propsProperty.includes('globalStyle')
          ? this.propsProperty.replace('.background', '')
          : this.propsProperty;
      },
      positionChange(value) {
        this.colorInstance.setImagePosition(value);
        this.$store.commit('handleColorInstanceToStore', this.colorInstance);
      },
      repeatChange(value) {
        this.colorInstance.setImageRepeat(value);
        this.$store.commit('handleColorInstanceToStore', this.colorInstance);
      },
      horizontalAlignChange(value) {
        this.colorInstance.setImageHorizontalAlign(value);
        this.$store.commit('handleColorInstanceToStore', this.colorInstance);
      },
      verticalAlignChange(value) {
        this.colorInstance.setImageVerticalAlign(value);
        this.$store.commit('handleColorInstanceToStore', this.colorInstance);
      },
      async isDisabledToSetAlign() {
        const selectedImageSrc = this.colorInstance.getImageSrc();
        if (!selectedImageSrc) return;

        const { width: loadedImageWidth, height: loadedImageHeight } = await this.getImageSize(
          selectedImageSrc,
        );

        const { uid } = this.selectedElement;
        const workspaceFrame = document.getElementById('workspaceFrame').contentDocument;
        const { width: containerWidth, height: containerHeight } = workspaceFrame
          .getElementById(uid)
          .getBoundingClientRect();

        const imageRatio = loadedImageHeight / loadedImageWidth;
        const isLandScape = this.isLandScapeImage(imageRatio);
        const containerRatio = containerHeight / containerWidth;

        const wholeRatio = containerRatio / imageRatio;
        if (this.isContainPosition) {
          this.handleContainAligning(isLandScape, wholeRatio);
          return;
        }

        if (this.isCoverPosition) this.handleCoverAligning(isLandScape, wholeRatio);
      },
      handleContainAligning(isLandScape, ratio) {
        if (isLandScape && ratio < 1) {
          this.isAligningDisabled.vertical = true;
        } else if (isLandScape && ratio > 1) {
          this.isAligningDisabled.horizontal = true;
        } else if (!isLandScape && ratio > 1) {
          this.isAligningDisabled.horizontal = true;
        } else {
          this.isAligningDisabled.vertical = true;
        }
      },
      handleCoverAligning(isLandScape, ratio) {
        if (isLandScape && ratio < 1) {
          this.isAligningDisabled.horizontal = true;
        } else if (isLandScape && ratio > 1) {
          this.isAligningDisabled.vertical = true;
        } else if (!isLandScape && ratio > 1) {
          this.isAligningDisabled.vertical = true;
        } else {
          this.isAligningDisabled.horizontal = true;
        }
      },
      getImageSize(src) {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.addEventListener('load', () => resolve(img));
          img.addEventListener('error', reject);
          img.src = src;
        });
      },
      isLandScapeImage(imageRatio) {
        if (imageRatio > 1) {
          return false;
        }

        return true;
      },
    },
  };
</script>
<style lang="sass" scoped>
  .vc-sketch-section--title
    font-family: Roboto
    font-style: normal
    font-weight: bold
    font-size: 12px
    line-height: 16px
    color: #505763
  .aligning
    font-size: 12px
    line-height: 16px
    color: #505763
    flex: 1
</style>
