<template lang="pug">
.brand-wrapper
  .container-fluid.plan-settings.px-3
    template(v-if="!loadingPlans")
      alert-bar.brand-notification-info.my-4.mx-0.px-4.py-2(
        v-if="$store.state.showBankTransferToBraintreeNotification"
        @close="$store.commit('setBankTransferToBraintreeNotification', false)"
        :brandSeparator="false"
      )
        template(slot="body")
          .brand-notification-info-icon.fa.fa-exclamation-circle.pr-2
          .font-size-0--875 {{ $t('payment.notification.deprecatedProforma') }}
      alert-bar.brand-notification-info.my-4.mx-0.px-4.py-2(
        v-if="$store.state.showBankTransferNotification"
        @close="$store.commit('setBankTransferNotification', false)"
        :brandSeparator="false"
      )
        template(slot="body")
          .brand-notification-info-icon.fa.fa-exclamation-circle.pr-2
          .font-size-0--875 {{ $t('plan.success') }}
      .custom-heading {{ $t('menu.upgrade') }}
      .plan-section-table.row
        .col-6
          plan-details(:couponDetails="getCouponDetail")
        .col-6(v-if="!isFreePackage")
          payment-details

      template(v-if="!loadingPlans && isPlanTableReady")
        .row
          .col-10
            om-heading.mt-1(h3) {{ $t('changePlan') }}

        template(v-if="couponService.hasValidPendingCoupon()")
          .row
            .col-12.py-4
              next-billing-coupon-notification(
                type="pending"
                @action="shopifyNextBillingCouponActivation"
              )
        template(v-else-if="couponService.hasValidNextBillingCoupon()")
          .row
            .col-12.py-4
              next-billing-coupon-notification(
                type="next-billing"
                :couponDetails="getNextBillingCouponDetail"
              )
        template(v-else-if="needsOverchargeUpgrade")
          overcharge-upgrade-notification(
            type="activation"
            @action="sendShopifyOrder(currentPlan, 'usage_update')"
          )
        template(v-else-if="needsFlexiPayMaximumTotalUpdate")
          overcharge-upgrade-notification(
            type="maximum-total-update"
            @action="sendShopifyOrder(currentPlan, 'usage_update')"
          )
    loading-logo(v-if="loadingPlans")

    template(v-if="!loadingPlans && isPlanTableReady")
      plans-list(
        v-if="(!isPageViewBasedPackage || hasFuturePackage || isHuDemoPackage || (isPageViewBasedPackage && isEnterpriseOrMaster)) && ((isShopifyPayment && !hasCustomPay) || !isShopifyPayment)"
        :showPeriodSelect="showNonFreemiumPeriodSelect"
        :canCalculateCorrectUpgradePrice="_canCalculateCorrectUpgradePrice"
        :couponDetails="getCouponDetail"
        @sendShopifyOrder="sendShopifyOrder"
        @shopifyNextBillingUpdate="shopifyNextBillingUpdate"
        @planSelected="normalPlanSelected"
      )
      .row.mt-4(style="justify-content: center" v-if="isEnterpriseOrMaster")
        div {{ $t('plan.masterEnterprise.contact') }}
          a.brand-link(href="mailto:support@optimonk.com") {{ $t('plan.masterEnterprise.support') }}

      .row.pb-2.justify-content-center(v-if="isShopifyPayment && hasCustomPay")
        .col-6.col-md-4.d-flex.flex-wrap.justify-content-center.align-items-start(
          v-if="hasShopifyOneTimePay"
        )
          .mt-3.plan-card.plan-card-custompay
            .title.text-center {{ $t('plan.oneTimePayment') }}
            .plan-body
              .col-12.plan-body-col.font-weight-bold.bb-1.font-size-3.p-4 ${{ paymentRecord.oneTime.amount }}
              .col-12.plan-body-col.p-0
                .col-5.br-1.plan-body-field.p-0
                  .plan-body-inner.p-4.font-size-0--875 {{ $t('plan.reason') }}
                .col-7.plan-body-field.p-0
                  .plan-body-inner.p-4.font-size-0--875 {{ paymentRecord.oneTime.reason }}
          om-button.mt-4.mx-2(primary @click="sendCustomShopifyConfirm('onetime')") {{ $t('pay') }}
        .col-6.col-md-4.d-flex.flex-wrap.justify-content-center.align-items-start(
          v-if="hasShopifyRecurringPay"
        )
          template(v-if="getShopifyDiscount")
            .text-center.mb-3 {{ $t('payment.shopifyDiscount1') }}
            .text-center {{ $t('payment.shopifyDiscount2') }}
          .mt-3.plan-card.plan-card-custompay
            .title.text-center {{ $t('plan.recurringPayment') }}
            .plan-body
              .col-12.bb-1.p-0.plan-body-col
                .col-5.p-4.br-1.plan-body-field
                  .plan-body-inner.font-size-0--875 {{ $t('plan.plan') }}
                .col-7.py-4.px-2.plan-body-field
                  .plan-body-inner
                    .col-12.font-weight-bold.text-center.font-size-2 {{ paymentRecord.recurring.packagePrice }}
                    .col-12.font-weight-bold.text-center {{ paymentRecord.recurring.packageName }}
              .col-12.p-0.plan-body-col
                .col-5.p-0.p-4.br-1.plan-body-field
                  .plan-body-inner.text-center.font-size-0--875 {{ $t('plan.nextBillingDate') }}
                .col-7.p-0.p-4.plan-body-field
                  .plan-body-inner.font-weight-bold.font-size-1--5 {{ formatDate(moment(paymentRecord.recurring.nextPaymentDate)) }}
          om-button.mt-4.mx-2(primary @click="sendCustomShopifyConfirm('recurring')") {{ $t('accept') }}
      .row.mx-0(
        v-if="isPageViewBasedPackage && !isEnterpriseOrMaster && !hasCustomPay && !hasFuturePackage && !isHuDemoPackage"
      )
        upgrade-plan-table(
          :plans="plans"
          :rawPlans="{}"
          :selectedPeriod="selectedPeriod"
          :showPeriodSelect="showFreemiumPeriodSelect"
          :canCalculateCorrectUpgradePrice="_canCalculateCorrectUpgradePrice"
          :couponDetails="getCouponDetail"
          @setPlan="freemiumPlanSelected"
          @sendShopifyOrder="sendShopifyOrder"
          @shopifyNextBillingUpdate="shopifyNextBillingUpdate"
          @setPeriod="setPeriod"
        )
  cancel-subscription-hu(
    v-if="$store.state.locale === 'hu'"
    :PaymentAdapter="PaymentAdapter"
    @successfulCancel="cancelledSubscription"
  )
  cancel-subscription-en(
    v-else
    :PaymentAdapter="PaymentAdapter"
    @successfulCancel="cancelledSubscription"
  )
  downgrade-modal(
    v-if="!loadingPlans"
    :toPlan="selectedPlan"
    :toPeriod="selectedPeriod"
    @cancel="resetDowngrade"
    @downgrade="downgrade"
  )
  next-billing-confirmation-modal.downgrade-confirmation-modal(
    v-if="!loadingPlans"
    name="annual-to-monthly-confirmation-modal"
    :toPlan="selectedPlan"
    :toPeriod="selectedPeriod"
    @confirmedDowngrade="handleAnnualToMonthlyCase"
  )
</template>
<script>
  import GET_SALES_AUTH from '@/graphql/GetSalesAuth.gql';
  import CANCEL_SHOPIFY_CHARGE from '@/graphql/CancelShopifyCharge.gql';
  import DOWNGRADE_SUBSCRIPTION from '@/graphql/DowngradeSubscription.gql';
  import { PaymentAdapter } from '@/services/paymentAdapter';
  import { getAccountIdFromCookie, pushDataLayer } from '@/util';
  import moment from 'moment';
  import { get as _get } from 'lodash-es';
  import { mapGetters, mapMutations, mapActions, createNamespacedHelpers } from 'vuex';
  import CancelSubscriptionEn from '@/components/Modals/CancelSubscription/En.vue';
  import CancelSubscriptionHu from '@/components/Modals/CancelSubscription/Hu.vue';
  import DowngradeModal from '@/components/Modals/DowngradeModal.vue';
  import NextBillingConfirmationModal from '@/components/Modals/NextBillingConfirmationModal.vue';
  import UpgradePlanTable from '@/components/Tables/UpgradePlan.vue';
  import AlertBar from '@/components/AlertBar';
  import dateFormat from '@/mixins/dateFormat';
  import PlansList from '@/components/Payment/PlansList';

  import OverchargeUpgradeNotification from '@/components/Notifications/OverchargeUpgradeNotification';
  import NextBillingCouponNotification from '@/components/Notifications/NextBillingCouponNotification';
  import checkoutMixin from '@/mixins/checkout';
  import flexiPay from '@/mixins/planSettings';
  import { track } from '@/services/xray';

  import { canCalculateCorrectUpgradePrice } from '@om/payment/src/helpers/upgradeCalculator';
  import { isNotFreemiumPackage } from '@om/payment/src/helpers/planDetails';

  const {
    mapGetters: paymentGetters,
    mapActions: paymentActions,
    mapState: paymentState,
    mapMutations: paymentMutations,
  } = createNamespacedHelpers('payment');
  const _clone = (v) => JSON.parse(JSON.stringify(v));

  export default {
    components: {
      PlansList,
      AlertBar,
      CancelSubscriptionEn,
      CancelSubscriptionHu,
      DowngradeModal,
      NextBillingConfirmationModal,
      UpgradePlanTable,
      OverchargeUpgradeNotification,
      NextBillingCouponNotification,
      PlanDetails: () => import('@/views/Subscription/PlanDetails.vue'),
      PaymentDetails: () => import('@/views/Subscription/PaymentDetails.vue'),
    },
    mixins: [dateFormat, checkoutMixin, flexiPay],

    data() {
      return {
        loadingPlans: true,
        PaymentAdapter: null,
        cancelReason: 'Other',
        shopifyDiscount: false,
      };
    },

    computed: {
      ...mapGetters([
        'getRegion',
        'databaseId',
        'currentPlan',
        'currentPeriod',
        'accountFeatures',
        'domains',
        'currentPeriod',
        'getDowngradeInformation',
      ]),
      ...paymentGetters([
        'isPlanTableReady',
        'isPageViewBasedPackage',
        'isDowngrade',
        'getBilling',
        'isInactive',
        'hasPaymentMethod',
        'isBraintreePayment',
        'isBankTransfer',
        'isShopifyPayment',
        'isEnterpriseOrMaster',
        'isPlanExpired',
        'needsOverchargeUpgrade',
        'needsFlexiPayMaximumTotalUpdate',
        'hasCustomPay',
        'getFuturePackage',
        'isOverrun',
        'hasFuturePackage',
        'getPlanDetail',
        'isHuDemoPackage',
      ]),
      ...paymentState([
        'plans',
        'billing',
        'paymentMethod',
        'paymentData',
        'paymentRecord',
        'cancelled',
        'selectedPeriod',
        'selectedPlan',
        'dateExpires',
        'datePaid',
        'couponService',
      ]),

      getCouponCode() {
        if (this.hasFuturePackage) return false;
        if (this.couponService.hasValidCoupon() === false) return null;
        const code = this.couponService.getCouponCode();
        return code;
      },
      getCouponCodeWithPlanValidation() {
        if (this.hasFuturePackage) return null;
        if (this.hasValidCouponDiscountForSelectedPlan === false) return null;
        return this.getCouponCode;
      },
      hasValidCouponDiscountForSelectedPlan() {
        if (this.couponService.hasValidCoupon() === false) return false;
        const plan = this.getPlanDetail(this.selectedPlan);
        const periodInMonth = this.selectedPeriod;
        const periodKey = this.periodKeyByNumberOfMonths(periodInMonth);
        return plan.hasDiscount && plan.hasDiscount[periodKey];
      },

      showFreemiumPeriodSelect() {
        if ([71970].includes(this.databaseId)) return true;
        if (this.hasFuturePackage) return false;
        if (this.cancelled) return false;
        if (this.isShopifyPayment && this.isOverrun) return false; // SY flexipay-es nem lehet éves
        if (this.isBankTransfer && this.hasPayingPlan) return false;

        return true;
      },
      showNonFreemiumPeriodSelect() {
        if (this.hasFuturePackage) return false;
        if (this.cancelled) return false;
        if (this.isPageViewBasedPackage) return false;
        if (this.isEnterpriseOrMaster) return false;
        if (this.isShopifyPayment && this.isOverrun) return false; // SY flexipay-es nem lehet éves
        if (this.isShopifyPayment && isNotFreemiumPackage(this.currentPlan, true)) return false;
        if (this.isBankTransfer && this.hasPayingPlan) return false;

        return true;
      },
      getCheckoutPlan() {
        return this.getFuturePackage || this.currentPlan;
      },
      isFreePackage() {
        return this.currentPlan === 'FREE';
      },
      hasPayingPlan() {
        return this.$store.getters.isPaying;
      },
      getName() {
        return this.$store.locale === 'en'
          ? `${this.billing.firstName} ${this.billing.lastName}`
          : `${this.billing.lastName} ${this.billing.firstName}`;
      },
      hasShopifyOneTimePay() {
        return this.paymentRecord && this.paymentRecord.oneTime;
      },
      hasShopifyRecurringPay() {
        return this.paymentRecord && this.paymentRecord.recurring;
      },
      getPaymentSuffix() {
        if (this.cancelled || (this.isBraintreePayment && this.paymentData.active === false)) {
          return this.$t('inactive');
        }
        if (this.isShopifyPayment && this.paymentData.active === false) {
          return this.$t('inactiveShopifyStatus', { domain: this.paymentData.domain });
        }
        if (this.isShopifyPayment) {
          return this.paymentData.domain ? `(${this.paymentData.domain})` : '';
        }

        return '';
      },

      getShopifyDiscount() {
        return this.shopifyDiscount;
      },

      getCouponDetail() {
        return {
          hasValidCoupon: this.hasValidCoupon,
          couponDescription: this.getCouponDescription,
          couponValidityPeriod: this.getCouponValidityPeriod,
        };
      },
      getNextBillingCouponDetail() {
        if (this.couponService.hasValidNextBillingCoupon() === false) return {};
        return {
          couponDescription: this.getCouponDescription,
          couponValidityPeriod: this.getCouponValidityPeriod,
        };
      },
      hasValidCoupon() {
        if (this.hasFuturePackage) return false;
        return this.couponService.hasValidCoupon();
      },
      getCouponDescription() {
        const languageKey = this.$i18n.locale === 'hu' ? 'hu' : 'en';
        return this.couponService.getCouponDescription(languageKey);
      },
      getCouponValidityPeriod() {
        const dates = this.couponService.getCouponValidityPeriod();
        if (!dates) return null;
        const start = this.formatDate(dates.start);
        const end = this.formatDate(dates.end);
        return { start, end };
      },

      _canCalculateCorrectUpgradePrice() {
        return canCalculateCorrectUpgradePrice({
          currentPlan: this.currentPlan,
          dateExpires: this.dateExpires,
          period: this.currentPeriod,
          isShopify: this.isShopifyPayment,
        });
      },
    },

    created() {
      this.moment = moment;
      this.fetchAccount();
    },

    async mounted() {
      await this.loadAccount();
      await Promise.all([this.loadPlans(), this.fetchFlexiPayDetail()]);

      const {
        data: { salesAuth },
      } = await this.$apollo.query({
        query: GET_SALES_AUTH,
      });

      this.$set(
        this,
        'PaymentAdapter',
        new PaymentAdapter(salesAuth, this.accountFeatures, this.$apollo),
      );

      const { action, payment, charge_id: chargeId, type } = this.$route.query;
      const { shopifyDiscount } = this.$route.query;

      if (shopifyDiscount) {
        this.$set(this, 'shopifyDiscount', true);
      }

      if (payment === 'shopify' && chargeId && action === 'return2') {
        await this.sendShopifyReturn(chargeId, type);
        await this.goToSuccessPage(this.currentPlan.toLowerCase());
      } else if (payment === 'customshopify' && chargeId && action === 'return2' && type) {
        await this.sendCustomShopifyReturn(type, chargeId);
        this.goToPlanSettingsWithoutQueries();
        return;
      } else if (payment === 'shopifyOneTime' && chargeId && action === 'return' && type) {
        await this.sendShopifyOneTimeReturn(type, chargeId, this.$route.query.cphash);
        this.goToPlanSettingsWithoutQueries();
        return;
      }

      this.saveBillingInfo();

      if (this.$route.query.updateRecurringPlan) {
        await this.updateRecurringPlan();
        return;
      }

      if (this.$route.query.extendRecurringPlan) {
        this.normalPlanSelected(this.currentPlan);
      }

      this.loadingPlans = false;
    },

    methods: {
      ...mapActions(['fetchAccount']),
      ...paymentGetters(['isPlanSameAsCurrent', 'isAnnualToMonthlyPlanChange']),
      ...mapMutations(['showAdminLoader']),
      ...paymentActions(['loadAccount', 'loadBilling', 'loadPlans', 'fetchFlexiPayDetail']),
      ...paymentMutations([
        'setPlan',
        'setPeriod',
        'setPlans',
        'setCancelled',
        'setUpdateRecurringPlan',
      ]),

      periodKeyByNumberOfMonths(periodInMonth) {
        let period = 'monthly';
        if (periodInMonth === 12) {
          period = 'annual';
        } else if (periodInMonth === 3) {
          period = 'quarterly';
        }
        return period;
      },

      async updateRecurringPlan() {
        const isSuccess = await this.shopifyNextBillingUpdate({
          plan: this.currentPlan,
          period: this.currentPeriod,
          type: 'recurring_update',
          location: 'notification-bar',
        });

        if (!isSuccess) {
          this.goToPlanSettingsWithoutQueries(6000);
        }
      },
      goToPlanSettingsWithoutQueries(timeout = 0) {
        setTimeout(() => {
          this.$router.replace({
            name: 'plan_settings',
          });
        }, timeout);
      },
      goToAgency() {
        window.open('https://www.optimonk.com/agency', '_blank');
      },
      hideBankTransferAlert() {
        this.showBankTransferNotification = false;
      },
      resetDowngrade() {
        this.setPlan(this.currentPlan);

        this.$modal.hide('cancel-subscription-en');
      },
      async sendCustomShopifyConfirm(type) {
        this.showAdminLoader(true);

        const { data } = await this.PaymentAdapter.sendCustomShopifyConfirm(type);

        if (data.success) {
          window.location = data.confirmUrl || data.confirmUri;
        } else {
          this.$notify({
            type: 'error',
            text: data.message || data.error,
          });

          this.showAdminLoader(false);
        }
      },
      async sendCustomShopifyReturn(type, chargeId) {
        this.showAdminLoader(true);

        const { data } = await this.PaymentAdapter.sendCustomShopifyReturn(type, chargeId);

        if (data.success) {
          if (this.paymentRecord) {
            const paymentRecord = _clone(this.paymentRecord);
            delete paymentRecord[type === 'recurring' ? 'recurring' : 'oneTime'];

            this.$set(this, 'paymentRecord', paymentRecord);
          }
          this.$notify({
            type: 'success',
            text: this.$t('notifications.successShopify'),
          });
          this.$router.replace({
            name: 'plan_settings',
            params: { userId: getAccountIdFromCookie() },
          });
        } else {
          this.$notify({
            type: 'error',
            text: data.message || data.error,
          });
        }

        this.showAdminLoader(false);
      },
      async sendShopifyOneTimeReturn(type, chargeId, cphash) {
        this.showAdminLoader(true);

        const { data } = await this.PaymentAdapter.sendShopifyOneTimeReturn(type, chargeId, cphash);

        if (data.success) {
          await this.loadBilling();
          await this.fetchFlexiPayDetail();
          this.$notify({
            type: 'success',
            text: this.$t('notifications.successShopify'),
          });
          this.$router.replace({
            name: 'plan_settings',
            params: { userId: getAccountIdFromCookie() },
            query: { shopifyDiscount: '1' },
          });
        } else {
          this.$notify({
            type: 'error',
            text: data.message || data.error,
          });
        }

        this.showAdminLoader(false);
      },
      async sendShopifyOrder(plan, type = 'recurring', location = 'plan_settings') {
        this.showAdminLoader(true);
        this.setPlan(plan);
        const period = this.selectedPeriod;
        const couponCode = this.getCouponCodeWithPlanValidation;

        if (type === 'recurring') {
          track('admin_click', {
            location,
            setting: type,
            change: 'go-to-shopify-checkout',
          });

          this.$router.replace({
            name: 'shopify_checkout',
            params: { userId: getAccountIdFromCookie() },
            query: { package: `${plan}-${this.selectedPeriod}` },
          });
          return;
        }

        track('admin_click', {
          location,
          setting: type,
          change: 'shopify-plan',
        });

        if (type === 'downgrade') type = 'recurring'; // no need for another (downgrade) type, BO will check it again
        const { data } = await this.PaymentAdapter.sendShopifyPay(plan, period, couponCode, type);

        if (data && data.success) {
          pushDataLayer({
            event: 'select_item',
            item_id: plan,
            item_name: 'plan_upgrade',
          });
          window.location = data.confirmUrl || data.confirmUri;
        } else {
          this.$notify({
            type: 'error',
            text: data.message || data.error,
          });

          this.showAdminLoader(false);
        }
      },

      shopifyNextBillingCouponActivation() {
        this.showAdminLoader(true);

        track('admin_click', {
          location,
          setting: 'next-billing-discount-activation',
          change: 'go-to-shopify-checkout',
        });

        this.$router.replace({
          name: 'shopify_checkout',
          params: { userId: getAccountIdFromCookie() },
          query: {
            nextBillingDiscountActivation: true,
          },
        });
      },

      // recurring_update type for price change acceptation
      async shopifyNextBillingUpdate({ plan, period, type, location }) {
        if (!type) type = 'recurring_update';
        if (!location) location = '';

        this.showAdminLoader(true);
        this.setPlan(plan);

        const couponCode = this.getCouponCodeWithPlanValidation;

        track('admin_click', {
          location,
          setting: type,
          change: 'shopify-next-billing-plan-create',
        });

        const { data } = await this.PaymentAdapter.sendShopifyPay(
          plan,
          period,
          couponCode,
          type,
          'next-billing', // replacementBehavior
        );

        if (data && data.success) {
          pushDataLayer({
            event: 'select_item',
            item_id: plan,
            item_name: 'plan_shopify_billing_update',
          });
          window.location = data.confirmUrl || data.confirmUri;
        } else {
          this.$notify({
            type: 'error',
            text: data.message || data.error,
          });

          this.showAdminLoader(false);
          return false;
        }
        return true;
      },
      async sendShopifyReturn(chargeId, type = 'recurring') {
        this.showAdminLoader(true);
        const { data } = await this.PaymentAdapter.sendShopifyReturn(chargeId, type);

        if (data.success) {
          this.couponService.afterOrderActions();

          track('admin_event', {
            location: 'plan-settings',
            setting: type,
            change: 'shopify-plan',
          });

          await this.fetchAccount();
          this.$notify({
            type: 'success',
            text: this.$t('notifications.successShopify'),
          });
          pushDataLayer({
            event: 'select_item',
            item_id: this.currentPlan,
            item_name: 'plan_shopify_order',
          });
          pushDataLayer({
            event: 'Successful order',
            paymentMethod: 'shopify',
            newPackage: this.currentPlan,
            period: this.currentPeriod,
          });
          await this.loadPlans();
          await this.loadBilling();
          await this.fetchFlexiPayDetail();
          /* Handled by checkout result redirection
          this.$router.replace({
            name: 'plan_settings',
            params: { userId: getAccountIdFromCookie() },
          });
          */
        } else {
          this.$notify({
            type: 'error',
            text: data.message || data.error,
          });
        }

        this.showAdminLoader(false);
        await this.fetchAccount();
      },
      async cancelledSubscription({ reason = '' }) {
        this.cancelReason = reason;

        if (this.isPageViewBasedPackage) {
          this.$modal.show('downgrade-subscription');
        } else {
          await this.successCancel();
        }
      },

      async successCancel(shopify = false) {
        if (!shopify) {
          try {
            await this.PaymentAdapter.cancelSubscription(this.cancelReason);
          } catch (e) {
            this.$notify({
              type: 'error',
              text: this.$t('notifications.registerError'),
            });
            return;
          }
        }

        this.setCancelled(true);

        this.$notify({
          type: 'success',
          text: this.$t('notifications.successCancel'),
        });
        pushDataLayer({
          event: 'select_item',
          item_id: this.currentPlan,
          item_name: 'plan_cancel',
        });

        return this.reloadPage({ successfulCancel: true });
      },
      getCheckoutStepBy(planName) {
        let step = this.isPlanSameAsCurrent(planName) ? 'payment' : 'summary';
        if (!this.hasPayingPlan || this.isEnterpriseOrMaster) {
          step = null;
        }
        return step;
      },

      async normalPlanSelected(planName) {
        this.planSelected(planName);
      },

      async freemiumPlanSelected(planName) {
        this.planSelected(planName);
      },

      async planSelected(planName) {
        this.setPlan(planName);

        if (this.isDowngrade(planName)) {
          return this.$modal.show('downgrade-subscription');
        }

        if (this.isAnnualToMonthlyPlanChange()) {
          this.$modal.show('annual-to-monthly-confirmation-modal');
          return;
        }

        if (this.isShopifyPayment) {
          return this.sendShopifyOrder(planName);
        }

        this.navigateToCheckout(planName);
      },

      // Annual to monthly upgrade or same package
      async handleAnnualToMonthlyCase() {
        this.loadingPlans = true;
        const allDomainIds = this.domains.map((domain) => domain._id);

        await this.$apollo.mutate({
          mutation: DOWNGRADE_SUBSCRIPTION,
          variables: {
            plan: this.selectedPlan.toUpperCase(),
            period: this.selectedPeriod,
            activeDomainIds: allDomainIds,
            hasFunctionalDowngrade: false,
            needShopifyApprove: !!this.isShopifyPayment,
            skipDisableLostFeatures: false,
          },
        });

        if (this.isBraintreePayment) {
          return this.reloadPage({ successfulNextBillingSetting: true });
        }

        if (this.isShopifyPayment) {
          return this.sendShopifyOrder(this.selectedPlan, 'downgrade');
        }
      },

      navigateToCheckout(planName) {
        const step = this.getCheckoutStepBy(planName);
        this.$router.push({
          name: 'checkout',
          params: {
            userId: getAccountIdFromCookie(),
            step,
          },
          query: { package: `${planName}-${this.selectedPeriod}` },
        });
      },

      async downgrade({ activeDomainIds }) {
        this.loadingPlans = true;

        const planName = `${this.selectedPlan}`.toUpperCase();
        this.$modal.hide('downgrade-subscription');

        const needShopifyApprove = this.isShopifyPayment && planName !== 'FREE';

        await this.$apollo.mutate({
          mutation: DOWNGRADE_SUBSCRIPTION,
          variables: {
            plan: planName,
            period: this.selectedPeriod,
            activeDomainIds,
            hasFunctionalDowngrade: true,
            needShopifyApprove,
            skipDisableLostFeatures: false,
          },
        });

        pushDataLayer({
          event: 'select_item',
          item_id: this.selectedPlan.toUpperCase(),
          item_name: 'plan_downgrade',
        });

        if (planName === 'FREE' && this.isShopifyPayment) {
          // This will also run the downgrade immediately
          return this.cancelShopifyCharge();
        }

        if (this.isShopifyPayment) {
          return this.sendShopifyOrder(planName, 'downgrade');
        }

        this.downgradedSubscription();

        return this.reloadPage({ successfulNextBillingSetting: true });
      },

      downgradedSubscription() {
        if (this.selectedPlan.toUpperCase() === 'FREE') {
          return this.successCancel();
        }
      },

      async cancelShopifyCharge() {
        this.loadingPlans = true;

        const result = await this.$apollo.mutate({
          mutation: CANCEL_SHOPIFY_CHARGE,
        });

        const data = _get(result, 'data.cancelShopifyCharge', null);

        if (data?.success) {
          await this.successCancel(true);
          this.$modal.hide('dialog');
          return this.reloadPage({ successfulShopifyCancel: true });
        }

        this.$notify({
          type: 'error',
          text: this.$t('notifications.sendError'),
        });

        this.loadingPlans = false;
      },

      reloadPage(query) {
        query.ts = Date.now();
        return this.$router.replace({
          name: 'plan_settings',
          query,
        });
      },
    },
  };
</script>
<style lang="sass" scoped>
  .custom-heading
    font-size: 40px
    font-weight: 400
    margin-bottom: 56px
  .plan-section-table
    margin-bottom: 56px
</style>
