<template lang="pug">
.col-12.experience-item(
  :id="`experience-item-${experienceId}`"
  :class="{ 'experience-closed': !opened, 'less-than-two-item': variantCount < 2 }"
  :data-testid="`experienceRow-${experience.name}`"
)
  priority(@changePriority="$emit('changePriority', $event)" :index="index")
  .col.pr-0
    .card.experience-container
      .experience-content
        .experience-datas
          .experience-data-col.experience-name.cursor-pointer.col.pl-0(
            @click="accordionExperience"
          )
            .experience-name-container
              .experience-name-title
                span {{ experience.name }}
              .experience-info(:class="{ 'experience-info-closed': opened }")
                span {{ $t('experiences.list.variantCount', { variantCount, postfix: variantCount > 1 ? 's' : '' }) }}
                span.dot .
                span {{ $t('experiences.list.frontendRulesCount', { frontendRulesCount }) }}
          .experience-data-col.col.experience-impressions.text-right(v-if="isDefaultGoal")
            .experience-data-value(data-testid="experienceImpressionCell") {{ sumImpressions | thousandSep }}
            .experience-data-title {{ $t('experiences.list.impressions') }}
          .experience-data-col.col.experience-visitors.text-right(v-if="!isDefaultGoal")
            vue-skeleton-loader.skeleton-goals(
              v-if="loadingGoals"
              type="rect"
              width="50%"
              height="24px"
              :rounded="true"
            )
            template(v-else)
              .experience-data-value(data-testid="experienceVisitorCell") {{ sumVisitors | thousandSep }}
              .experience-data-title {{ $t('analyticsPage.visitors') }}
          .experience-data-col.col.experience-conversions.text-right
            vue-skeleton-loader.skeleton-goals(
              v-if="loadingGoals"
              type="rect"
              width="50%"
              height="24px"
              :rounded="true"
            )
            template(v-else)
              .experience-data-value(data-testid="experienceConversionCell") {{ sumConversions | thousandSep }}
              .experience-data-title {{ $t('experiences.list.conversions') }}
          .experience-data-col.col.experience-conversion-rate.text-right
            vue-skeleton-loader.skeleton-goals(
              v-if="loadingGoals"
              type="rect"
              width="50%"
              height="24px"
              :rounded="true"
            )
            template(v-else)
              .experience-data-value {{ sumConversionRate }}
              .experience-data-title {{ $t('experiences.list.conversionsRate') }}
          .experience-data-col.col.experience-uplift.text-right.mt-auto
            .experience-data-value {{ '&nbsp' }}
            om-tooltip(:placement="'top'" :delay="300")
              div(v-html="$t(`analyticsPage.header-explanation-uplift`)")
              template(slot="trigger")
                .experience-data-title.tooltip-header {{ $t('analyticsPage.uplift') }}
          .experience-data-col.col.experience-chanceToWin.text-right.mt-auto
            vue-skeleton-loader.skeleton-goals(
              v-if="loadingChanceToWin"
              type="rect"
              width="50%"
              height="24px"
              :rounded="true"
            )
            template(v-else)
              .experience-data-value(data-testid="experienceChanceToWinCell") {{ '&nbsp' }}
              om-tooltip(:placement="'top'" :delay="300")
                div(v-html="$t(`analyticsPage.header-explanation-chanceToWin`)")
                template(slot="trigger")
                  .experience-data-title.tooltip-header {{ $t('analyticsPage.chanceToWin') }}
          .experience-data-col.col.col-auto.experience-actions.pr-0
            om-kebab-menu(
              @rename="showRenameModal"
              @delete="$emit('deleteExperience', experience._id)"
            )
              template(slot="rename")
                span {{ $t('rename') }}
              template(slot="delete")
                span {{ $t('delete') }}
        slide-up-down.experience-accordion(:active="opened" :duration="400")
          .experience-variants
            variant-table(
              v-if="variantCount"
              ref="variantTable"
              :domain="campaign.domain"
              :isNew="isNew"
              :templateName="campaign.templateName"
              :templateType="campaign.templateType"
              :variants="variantsWithCalculatedData"
              :updatedAt="campaign.updatedAt"
              :variantCount="variantCount"
              :pagination="pagination"
              :loadingVariantsData="loadingVariantsData"
              :customPaginationControls="true"
              :experienceId="experienceId"
              :allVariantsCount="allVariants.length"
              :loadingGoals="loadingGoals"
              :isDefaultGoal="isDefaultGoal"
              :showDeleteVariantMenu="showDeleteVariantMenu"
              @changeVariantName="$emit('changeVariantName', $event)"
              @updateStatus="$emit('variantStatusUpdate', $event)"
              @deleteVariantFromExperience="deleteExperienceVariant($event)"
              @refetchVariants="refetchVariants"
            )
          .experience-table-actions.justify-content-between
            om-link.experience-add-variant(
              primary
              small
              withIconLeft
              @click="addNewVariant(experienceId)"
            ) {{ $t('newVariant') }}
              template(slot="left-icon")
                UilPlusCircle.mr-2(size="1.5rem")
            .experience-pagination(v-if="filteredVariants.length > 5")
              om-pagination(
                :value="pagination.page"
                :maxPage="maxPage"
                @paginateTo="pageChange($event)"
              )
          .experience-rules
            .experience-title
              om-all-caps-text {{ $t('experiences.list.personalizeFor') }}
            .pl-5.pt-4.experience-rules-container(v-if="frontendRulesToShow.length")
              template(v-for="(frontendRule, frontendRuleIndex) in frontendRulesToShow")
                .d-flex.align-items-center.brand-settings-summary-row.experience-rules-row(
                  :class="getRowLineType(frontendRuleIndex)"
                )
                  frontend-rule-box.cursor-pointer(
                    :frontendRule="frontendRule"
                    :campaign="campaign"
                    small
                    @click.native="$emit('openRuleModal')"
                  )
                    template(slot="action")
                      om-button.experience-delete-rule(
                        ghost
                        iconOnly
                        small
                        icon="trash-alt"
                        @click.stop="deleteRule(experienceId, frontendRule.originalIndex)"
                      )
                  .rule-logical-condition.rule-logical-condition-and(
                    v-if="frontendRuleIndex < frontendRulesToShow.length - 1"
                    :class="`rule-logical-condition-and-${$i18n.locale}`"
                  ) {{ $t('and') }}
          .experience-table-actions
            om-link.experience-add-rule(primary small withIconLeft @click="$emit('openRuleModal')") {{ $t('experiences.list.addNewRule') }}
              template(slot="left-icon")
                UilPlusCircle.mr-2(size="1.5rem")
</template>
<script>
  import { mapState } from 'vuex';
  import Priority from '@/components/Experiences/Priority.vue';
  import { UilPlusCircle } from '@iconscout/vue-unicons';
  import DELETE_VARIANT_FROM_EXPERIENCE from '@/graphql/DeleteVariantFromExperience.gql';
  import VariantTable from '@/components/VariantTable.vue';
  import FrontendRuleBox from '@/components/CampaignSettings/FrontendRuleBox';
  import getRowLineType from '@/mixins/getRowLineType';
  import cookie from '@/mixins/cookie';
  import Kebab from '@/components/Svg/Icons/Kebab.vue';
  import calculatedCampaignData from '@/mixins/calculatedCampaignData';

  export default {
    name: 'Experience',
    components: { Priority, UilPlusCircle, VariantTable, FrontendRuleBox, Kebab },
    mixins: [getRowLineType, cookie, calculatedCampaignData],
    props: {
      campaign: {
        type: Object,
        default: () => {},
      },
      experience: {
        type: Object,
        default: () => {},
      },
      allVariants: {
        type: Array,
        default: () => [],
      },
      index: {
        type: Number,
        default: 0,
      },
      loadingGoals: {
        type: Boolean,
        default: false,
      },
      isDefaultGoal: { type: Boolean, default: true },
      goal: {
        type: Object,
        default: () => {},
      },
      dateKey: {
        type: String,
      },
    },
    data() {
      return {
        opened: false,
        loadingVariantsData: false,
        pagination: { page: 1, limit: 5 },
        showSkeletonAllowed: true,
        chanceToWin: {},
        uplift: {},
        loadingChanceToWin: false,
      };
    },
    computed: {
      ...mapState(['account']),
      sumImpressions() {
        return this.filteredVariants.reduce((sum, variant) => {
          sum += variant?.impressions || 0;
          return sum;
        }, 0);
      },
      sumVisitors() {
        return this.filteredVariants.reduce((sum, variant) => {
          sum += variant?.visitors || 0;
          return sum;
        }, 0);
      },
      sumConversionRate() {
        const divisor = this.isDefaultGoal ? this.sumImpressions : this.sumVisitors;
        return this.sumConversions && divisor
          ? `${((this.sumConversions / divisor) * 100).toFixed(2)}%`
          : '0%';
      },
      activeVariants() {
        return this.filteredVariants.filter((v) => v.status === 'active').length;
      },
      eligibleForTestRunning() {
        return (
          this.filteredVariants.filter((v) => v.status === 'active' && !v.isControlVariant).length >
          1
        );
      },
      sumConversions() {
        return this.filteredVariants.reduce((sum, variant) => {
          sum += variant?.conversions || 0;
          return sum;
        }, 0);
      },
      variantCount() {
        return this.filteredVariants.length;
      },
      rules() {
        return this.experience?.frontendRules || [];
      },
      frontendRulesCount() {
        return this.frontendRulesToShow.length;
      },
      isNew() {
        return this.campaign.version !== undefined && this.campaign.version !== 1;
      },
      experienceId() {
        return this.experience._id;
      },
      variants() {
        return this.experience?.variants || [];
      },
      priority() {
        return this.experience.priority + 1;
      },
      maxPage() {
        return Math.ceil(this.filteredVariants.length / this.pagination.limit);
      },
      filteredVariants() {
        const all = this.allVariants.reduce((acc, variant) => {
          acc[variant._id] = variant;
          return acc;
        }, {});

        return this.variants.map((id) => all[id]).filter((v) => !!v);
      },
      showDeleteVariantMenu() {
        const normalVariants = this.allVariants.filter((variant) => !variant.isControlVariant);

        const wouldLeaveOnlyControlVariant =
          this.filteredVariants.length === 2 &&
          this.filteredVariants.some((variant) => variant.isControlVariant);
        return normalVariants.length > 1 && !wouldLeaveOnlyControlVariant;
      },
      paginatedVariants() {
        const { page, limit } = this.pagination;
        const start = (page - 1) * limit;
        return this.filteredVariants.slice(start, start + limit);
      },
      variantsWithCalculatedData() {
        return this.paginatedVariants.map((variant) => {
          const chanceToWin =
            this.chanceToWin?.[this.goal._id]?.[this.dateKey]?.[this.campaignID]?.allType?.[
              variant._id
            ];
          const uplift = this.uplift?.[this.goal._id]?.[this.dateKey]?.[variant._id];
          return {
            ...variant,
            chanceToWin,
            uplift,
          };
        });
      },
      frontendRulesToShow() {
        const rules =
          this.rules?.map((rule, index) => {
            return { ...rule, originalIndex: index };
          }) ?? null;
        return rules?.filter(({ type }) => !this.isHiddenRuleType(type)) ?? [];
      },
      features() {
        return this.account?.features ?? [];
      },
      campaignID() {
        return this.$route.params.id;
      },
    },
    watch: {
      experienceId() {
        this.opened = false;
      },
      filteredVariants() {
        const chanceToWinVariants = this.filteredVariants.map((variant) => {
          return {
            impressions: { allType: this.isDefaultGoal ? variant.impressions : variant.visitors },
            conversions: { allType: variant.conversions },
            id: variant._id,
          };
        });
        const type = this.isDefaultGoal ? 'default' : 'goal';
        this.getChanceToWin(chanceToWinVariants, this.goal._id, this.dateKey);
        const baseline = this.getBaselineVariant(this.filteredVariants);
        this.getUplift({
          variants: this.filteredVariants,
          baseline,
          date: this.dateKey,
          goalId: this.goal._id,
        });

        if (this.isOnWrongPage(this.filteredVariants)) {
          this.pagination.page = this.pagination.page > 0 ? this.pagination.page - 1 : 0;
        }
      },
    },
    methods: {
      showRenameModal() {
        this.$modal.show('experience-rename', {
          name: this.experience.name,
          experienceId: this.experienceId,
          index: this.index,
        });
      },
      async deleteExperienceVariant({ experienceId, variantId }) {
        const { data } = await this.$apollo.mutate({
          mutation: DELETE_VARIANT_FROM_EXPERIENCE,
          variables: {
            experienceId,
            variantId,
          },
        });

        if (!data?.deleteVariantFromExperience?.success) {
          console.error('Failed to delete variant from experience');
        }

        this.refetchVariants();
      },
      refetchVariants(eventData) {
        this.showSkeletonAllowed = eventData?.skeletonAllowed ?? true;
        this.$emit('refetchExperiences', true);
      },
      accordionExperience() {
        this.opened = !this.opened;
      },
      open() {
        this.opened = true;
      },
      addNewVariant(experienceId) {
        this.$emit('addNewVariant', { experienceId });
      },
      deleteRule(experienceId, frontendRuleIndex) {
        this.$emit('deleteRule', { frontendRuleIndex, experienceId });
      },
      pageChange(newPage) {
        this.pagination.page = parseInt(newPage, 10);
      },
      // eslint-disable-next-line no-unused-vars
      isHiddenRuleType(type) {
        return false;
      },
    },
  };
</script>
<style lang="sass" scoped>
  @import '@/sass/variables/_colors.sass'

  .experience-data-col
    height: 3.25rem
    justify-content: flex-end
    display: flex
    flex-direction: column
    &.experience-actions
      justify-content: center

    .ds-tooltip,
    .ds-tooltip span
      display: flex
      align-items: center
      justify-content: flex-end
      white-space: nowrap
  .experience-accordion,
  .experience-list,
  .experience-rules-container
    display: flex
    flex-direction: column
    width: 100%

  .experience-item
    display: flex
    margin-bottom: 1.25rem
    transition: order 1s
  .experience-name
    display: flex
    flex-direction: column
    justify-content: center
    &-container
      display: flex
      flex-direction: column
    .experience-info-closed
      height: 0!important
      opacity: 0
    .experience-info
      color: $om-gray-600
      font-size: 0.75rem
      align-items: center
      justify-content: flex-start
      display: flex
      .dot
        padding: 0 0.75rem
        line-height: 1
        padding-bottom: 0.5rem
    &-title
      font-weight: bold
      color: $om-orange-500
      font-size: 1.125rem
      line-height: 2rem
  .experience-container
    width: 100%
    display: flex
    border: 1px solid
    box-sizing: border-box
    border-radius: 4px
    border-color: $om-gray-300
    padding-left: 4px
    position: relative
    &:before
      content: ""
      display: block
      background: $om-orange-500
      width: 5px
      border-radius: 4px 0 0 4px
      height: 100%
      position: absolute
      left: -1px
      top: 0
  .experience-content
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    .experience-kebab .kebab-icon-btn
      z-index: 1
  .experience-data-value
    font-size: 0.85rem
    color: $om-gray-800
    font-weight: bold
  .experience-data-title
    font-size: 0.75rem
    color: $om-gray-600

  .experience-delete-rule
    .btn
      opacity: 0
  .experience-title
    font-weight: 500

  .experience-variants
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    width: 100%

  .experience-rules,
  .experience-table-actions,
  .experience-variant,
  .experience-datas
    display: flex
    align-items: center
    padding: 1rem
    width: 100%

  .experience-variants
    padding: 0 1rem

  .experience-datas
    border-bottom: 1px solid $om-gray-300
    padding: 0.75rem 1rem
    position: relative
    .experience-actions
      position: absolute
      top: 50%
      margin-top: -25px
      right: 10px
      background: #fff
      width: 3rem
      display: flex
      align-items: flex-end

    .experience-closed &
      border-bottom: 1px solid transparent
  .experience-rules
    border-top: 1px solid $om-gray-300
    flex-direction: column
    align-items: flex-start
  .experience-data-col
    ::v-deep .ds-tooltip .reference
      justify-content: flex-end
    ::v-deep .ds-tooltip .popper
      min-width: 215px
      white-space: break-spaces
    .tooltip-header
      border-bottom: 1px dashed $om-gray-500
</style>
<style lang="sass">
  .experience-variants .variant-emphasized-table
    width: 100%
  .experience-rules-container .brand-rule-box-small
    border-bottom: 1px solid transparent

  .experience-variants
    .brand-table .thead
      display: none
    .td.brand-variant-settings-col
      padding: 0 0 0 10px!important

    .brand-campaigns-variants-table .tbody .brand-table-tr
      padding: 0!important

  .experience-variants .control-variant-delete
    opacity: 0

  .experience-variants .brand-table-tr:hover .control-variant-delete
    opacity: 1

  .experience-delete
    display: flex
    justify-content: flex-end

  .experience-data-col
    transition: flex 100ms

  .experience-rules-row:hover
    .experience-delete-rule
      opacity: 1

  .experience-item.less-than-two-item
    .experience-conversion-rate
      margin-right: 3rem
    .experience-data-col.experience-uplift,
    .experience-data-col.experience-chanceToWin
      flex: 1 1 0%!important
      padding: 0
      .experience-data-value,
      .experience-data-title
        display: none
    .experience-variants .td.brand-variant-name-col
      flex: 1 1 auto !important
    .experience-name
      flex: 1 1 auto !important

  .experience-item
    &:not(.less-than-two-item)
      .experience-chanceToWin
        margin-right: 3rem
      &.experience-closed
        .experience-uplift,
        .experience-chanceToWin
          padding: 0
    &.experience-closed
      .experience-data-col.experience-uplift,
      .experience-data-col.experience-chanceToWin
        flex: 1 1 0%!important
        .experience-data-value,
        .experience-data-title
          display: none
      .experience-name
        flex: 1 1 auto !important

  .experience-name
    flex: 1 1 auto !important

  .experience-variants .td.brand-variant-preview-col
    max-width: initial!important
    min-width: initial!important
    flex: 0 0 auto!important

  .experience-variants .td.brand-variant-name-col
    flex: 1 1 auto !important

  .experience-variants .td.brand-variant-results-col
    padding: 0 10px

  .experience-data-col.experience-chanceToWin
    margin-right: auto

  .experience-variants .td.brand-variant-results-col,
  .experience-variants .brand-variant-status-col,
  .experience-data-col.experience-impressions,
  .experience-data-col.experience-visitors,
  .experience-data-col.experience-conversions,
  .experience-data-col.experience-conversion-rate,
  .experience-data-col.experience-uplift,
  .experience-data-col.experience-chanceToWin
    flex: 0 0 10%!important

  .experience-variants .td.brand-variant-settings-col,
  .experience-data-col.experience-delete
    flex: 1 1 5rem!important
    max-width: 3rem

  .experience-item.less-than-two-item
    .experience-variants .td.brand-variant-settings-col,
    .experience-data-col.experience-delete
      flex: 1 1 5rem!important
      max-width: 3rem

  .experience-variants
    .brand-variant-name-col
      flex-wrap: wrap
      display: flex
      max-width: 100%
      &:before
        content: ''
        display: block
        width: 10px
    .multi-row-variant-table
      .brand-variant-name-col
        a,
        .control-desc
          width: 100%
          @media screen and (min-width: 992px)
            width: 6vw
          @media screen and (min-width: 1200px)
            width: 11.5vw
          @media screen and (min-width: 1395px)
            width: 15.4vw
          @media screen and (min-width: 1441px)
            width: 19vw
          @media screen and (min-width: 1600px)
            width: 22vw
          @media screen and (min-width: 1756px)
            width: 24vw
          @media screen and (min-width: 1950px)
            width: 26vw

  .experience-pagination .pagination
    margin-bottom: 0
  @media screen and (max-width: 1440px)
    .experience-item.experience-closed
      .experience-data-col.experience-uplift,
      .experience-data-col.experience-chanceToWin
        flex: 0 0 0%!important

    .experience-variants .td.brand-variant-results-col,
    .experience-data-col.experience-impressions,
    .experience-data-col.experience-visitors,
    .experience-data-col.experience-conversions,
    .experience-data-col.experience-conversion-rate,
    .experience-data-col.experience-chanceToWin,
    .experience-data-col.experience-uplift
      flex: 0 0 11%!important

    .experience-item.less-than-two-item .experience-variants .td.brand-variant-name-col
      flex: 1 1 auto !important
</style>
