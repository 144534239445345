<template lang="pug">
BasePane
  Accordion(fix)
    InputElementDropdown.mt-3(:items="feedbackOptions" v-model="selectedFeedback" @manage="manageFields" @create="createNewFeedback")
    DynamicDropdown(:label="$t('afterClick')" layout="col" v-model="onClick" :items="onClickOptions" id="feedbackAfterClick" :error="noFeedbackAction" :errorMessage="$t(validationError.property)")
      template(#jumpToPage)
        DynamicDropdown.mt-2(:label="$t('choosedPage')" layout="col" v-model="choosePageBasedOn" :items="chosenPageOptions" id="chosenPage")
          template(#specificPage)
            Dropdown.mt-2(
              :label="$t('feedbackPane.jumpTo')"
              layout="col"
              v-model="jumpToPage"
              :items="pageOptions"
              id="jumpToPage"
            )
          template(#byRating)
            Dropdown.mt-2(
              v-for="(option, index) in options"
              layout="col"
              :items="pageOptions"
              :key="index"
              :value="getRatingOption(index)"
              :label="$t(`feedbackOptions.${option.value}`)"
              @input="setByRating(index, $event)"
              :id="`jumpToPage-${index}`"
            )
  hr.mt-3.mb-4
  Accordion(fix)
    template(#title)
      .font-weight-bold {{ $t('display') }}
    OmChooser.p-0(:options="displayTypes" property="data.displayType")

    div(v-if="displayType === 'yesno' || displayType === 'smiley'")
      OmChooser.pb-0(label="feedbackPane.iconType" :options="iconTypes[displayType]" property="data.iconType")
      template(v-if="iconType === 'image'" v-for="(option, optionIndex) in options")
        .sidebar-input-wrapper.p-0
          .sidebar-feedback-image.sidebar-upload-area.my-2(@click="imageManager(optionIndex)")
            template(v-if="imageUrl(optionIndex)")
              img(:src="imageUrl(optionIndex)")
            i.fa(v-else :class="iconClass(option)")
    OmColorInput(v-if="iconType === 'vector'" :label="$t('color')" property="selectedElement.subElements.icon.desktop.color" typeOfComponent="feedback")

    <!--region Feedback question-->
    template(v-if="selectedElement.data.required")
      .sidebar-input-wrapper.sidebar-input-wrapper-label.pb-0 {{$t('errorMessage')}}
      OmInput.mb-2.pb-6.bb-1(label="" v-model="errorMessage")
    .sidebar-input-wrapper.sidebar-input-wrapper-label.pb-0(v-if="hasFeedbackQuestion")
      | {{$t('question')}}
      div.sidebar-title-tooltip-container.pdl-0(style="display: inline-block; vertical-align: middle;")
        i.fa.fa-info.mx-2.basic-tooltip-color.tooltip-mark(v-tooltip="{content: $t('questionTooltip')}")
    OmInput.mb-2(v-if="hasFeedbackQuestion" label="" v-model="question")
    // Font component
    <!--endregion-->
    RangeInput(:label="$t('iconSize')" :min="0" :max="200" :step="1" unit="px" v-model="fontSize" editMobile)
    RangeInput(:label="$t('spacing')" :min="0" :max="200" :step="1" unit="px" v-model="horizontalSpacing")
    Margin(deviceSelector)
  hr.mt-3.mb-4
  template(#advancedContent)
    OmColorInput(:label="$t('backgroundColor')" property="desktop.background.color" typeOfComponent="feedback" layout="col" dsAllowed)
    Border(typeOfComponent="feedback")
    Shadow(v-model="shadow" typeOfComponent="feedback" editMobile deviceSelector)
    Hide(:hideOnDesktop.sync="hideElementOnDesktop" :hideOnMobile.sync="hideElementOnMobile")
  template(#elementDefaults)
    SetElementDefaults(:extras="elementDefaultsExtras")
</template>
<script>
  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
  import { initOptions } from '@om/editor-ssr-shared/src/core/initializers/elements/feedback';
  import { get as _get } from 'lodash-es';
  import { OmFeedback } from '@/editor/shared/components';
  import InputElementDropdown from '@/components/Editor/InputElementDropdown/InputElementDropdown.vue';
  import Dropdown from '@/components/Editor/Dropdown/Dropdown.vue';
  import RangeInput from '@/components/Editor/RangeInput/RangeInput.vue';
  import Margin from '@/components/Editor/Controls/InputGroup/Margin.vue';
  import Accordion from '@/components/Editor/Accordion/Accordion.vue';
  import Border from '@/components/Editor/Controls/Border.vue';
  import Shadow from '@/components/Editor/Controls/Shadow.vue';
  import Hide from '@/components/Editor/Controls/Hide.vue';
  import DynamicDropdown from '@/components/Editor/DynamicDropdown/DynamicDropdown.vue';
  import itemMixin from '@/editor/mixins/item';
  import pageValidationMixin from '@/editor/mixins/pageValidation';
  import BasePane from './BasePane.vue';

  const { elementDefaultsExtras } = OmFeedback;

  export default {
    components: {
      BasePane,
      Accordion,
      DynamicDropdown,
      Dropdown,
      RangeInput,
      Margin,
      Border,
      Shadow,
      Hide,
      InputElementDropdown,
    },

    mixins: [itemMixin, pageValidationMixin],
    data() {
      return {
        canEditMobile: true,
        feedbackOptions: [],
        chosenPageOptions: [
          { value: 'specificPage', text: this.$t('feedbackPane.specificPage') },
          { value: 'byRating', text: this.$t('feedbackPane.byRating') },
        ],
        displayTypes: [
          {
            image: require('@/assets/editor/svg/feedback-styles/stars.svg'),
            name: 'feedbackPane.displayTypes.stars',
            value: 'stars',
          },
          {
            image: require('@/assets/editor/svg/feedback-styles/smiley.svg'),
            name: 'feedbackPane.displayTypes.smiley',
            value: 'smiley',
          },
          {
            image: require('@/assets/editor/svg/feedback-styles/yesno.svg'),
            name: 'feedbackPane.displayTypes.yesno',
            value: 'yesno',
          },
        ],
        iconTypes: {
          smiley: [
            {
              image: require('@/assets/editor/svg/feedback-icons/default-smiley.svg'),
              name: 'feedbackPane.iconTypes.vector',
              value: 'vector',
            },
            {
              image: require('@/assets/editor/svg/feedback-icons/image-smiley.svg'),
              name: 'feedbackPane.iconTypes.image',
              value: 'image',
            },
          ],
          yesno: [
            {
              image: require('@/assets/editor/svg/feedback-icons/default-yesno.svg'),
              name: 'feedbackPane.iconTypes.vector',
              value: 'vector',
            },
            {
              image: require('@/assets/editor/svg/feedback-icons/image-yesno.svg'),
              name: 'feedbackPane.iconTypes.image',
              value: 'image',
            },
          ],
        },
        elementDefaultsExtras,
      };
    },
    computed: {
      ...mapState(['selectedElement', 'mobilePreview', 'selectedPage', 'pages', 'validationError']),
      ...mapGetters(['nestedAccess', 'pageCount', 'isEmbedded', 'isUniversal']),
      onClickOptions() {
        const options = [
          { value: 'none', text: this.$t('none') },
          ...(!this.isEmbedded ? [{ text: this.$t('closePopup'), value: 'closePopup' }] : []),
          { value: 'jumpToPage', text: this.$t('jumpToPage') },
        ];

        if (!this.isNextPageValid) {
          return options;
        }

        const nextPopupType = { text: this.$t('nextPopup'), value: 'nextPopup' };
        return [...options, nextPopupType];
      },
      selectedFeedback() {
        const { customId, name } = this.selectedElement.data.form.customSettings;

        return { name, customId };
      },
      chosenPage() {
        const chosenPageBasedOn = this.selectedElement.data.choosePageBasedOn;
        return { value: chosenPageBasedOn, text: this.$t(`${chosenPageBasedOn}`) };
      },
      question: {
        get() {
          return this.getValueOf('data.feedback.question');
        },
        set(v) {
          this.setValueOf('data.feedback.question', v);
        },
      },
      errorMessage: {
        get() {
          return this.getValueOf('data.errorMessage');
        },
        set(v) {
          this.setValueOf('data.errorMessage', v);
        },
      },
      jumpToPage: {
        get() {
          return this.getValueOf('data.jumpTo');
        },
        set(v) {
          this.setValueOf('data.jumpTo', v);
        },
      },
      getRatingOption() {
        return (index) => this.selectedElement.data.form.customSettings.options[index].jumpTo;
      },
      onClick: {
        get() {
          return this.selectedElement.data.onClick;
        },
        set(v) {
          this.setValueOf('data.onClick', v);
        },
      },

      fontSize: {
        get() {
          const mobileValue = this.getValueOf('subElements.icon.mobile.fontSize');
          if (this.mobilePreview && !mobileValue) {
            // Fallback to desktop
            return Number(this.getValueOf('subElements.icon.desktop.fontSize'));
          }
          return Number(this.getValueOf('subElements.icon.$device.fontSize'));
        },
        set(v) {
          this.setValueOf('subElements.icon.$device.fontSize', v);
        },
      },
      horizontalSpacing: {
        get() {
          return Number(this.getValueOf('desktop.horizontalSpacing'));
        },
        set(v) {
          this.setValueOf('desktop.horizontalSpacing', v);
        },
      },
      displayType() {
        return this.selectedElement.data.displayType;
      },
      iconType() {
        return this.selectedElement.data.iconType;
      },
      choosePageBasedOn: {
        get() {
          return this.selectedElement.data.choosePageBasedOn;
        },
        set(v) {
          this.setValueOf('data.choosePageBasedOn', v);
        },
      },
      pageOptions() {
        const pages = [
          ...this.pages.map((page, pageIndex) => ({
            value: pageIndex + 1,
            text: this.$t(`${page.data.title}`),
          })),
        ];
        pages.unshift({ value: 'none', text: this.$t('none') });
        pages.splice(this.selectedPage + 1, 1);
        return pages;
      },
      options() {
        return this.selectedElement.data.form.customSettings.options;
      },
      noFeedbackAction() {
        return (
          this.validationError &&
          this.validationError.property &&
          this.validationError.property.includes('noFeedbackAction')
        );
      },
      hasFeedbackQuestion() {
        const question = _get(this.selectedElement, 'data.feedback.question');
        return question && question.length;
      },
      spacingProps() {
        return {
          showPadding: false,
          typeOfComponent: 'feedback',
        };
      },
      shadow: {
        get() {
          return this.getValueOf('$device.shadow.type');
        },
        set(value) {
          this.setValueOf('$device.shadow.type', value);
        },
      },
      hideElementOnDesktop: {
        get() {
          return this.getValueOf('desktop.hidden');
        },
        set(v) {
          this.setValueOf('desktop.hidden', v);
        },
      },
      hideElementOnMobile: {
        get() {
          return this.smartGetValueOf('mobile.hidden');
        },
        set(v) {
          this.smartSetValueOf('mobile.hidden', v);
        },
      },
    },
    watch: {
      isRequired() {
        const errorValue = this.nestedAccess('selectedElement.data.errorMessage');
        if (errorValue === null || errorValue === undefined) {
          this.$store.commit('updateData', {
            property: 'selectedElement.data.errorMessage',
            value: this.$t('fieldRequired'),
          });
        }
      },
      displayType(displayType) {
        const triggeredByCustomTheme =
          this.selectedElement.data?.form?.customSettings?.triggeredByCustomTheme === true;

        if (triggeredByCustomTheme) {
          delete this.selectedElement.data.form.customSettings.triggeredByCustomTheme;
          return;
        }

        this.fixOptions(initOptions(displayType));

        if (displayType === 'stars') {
          this.selectedElement.data.iconType = 'vector';
        }
      },
      onClick(n) {
        if (n === 'jumpToPage' && this.jumpToPage === 'none') {
          const firstPage = this.pageOptions[0];

          if (firstPage) {
            this.jumpToPage = firstPage.value;
          }
        }
        this.fixOptions();
      },
      choosePageBasedOn() {
        this.fixOptions();
      },
      jumpToPage() {
        this.fixOptions();
      },
      pageOptions() {
        this.fixOptions();
      },
      'selectedElement.data.feedback.question': function (v, o) {
        if (v.length && !o.length) {
          this.updateStyle({
            property: 'selectedElement.subElements.question.desktop.verticalSpacing',
            value: 15,
          });
        } else if (!v.length && o.length) {
          this.updateStyle({
            property: 'selectedElement.subElements.question.desktop.verticalSpacing',
            value: 0,
          });
        }
      },
    },
    mounted() {
      const isNextPopupClick = this.onClick === 'nextPopup';
      const isJumpToPageClick = this.onClick === 'jumpToPage';

      if (!this.isNextPageValid && (isNextPopupClick || isJumpToPageClick)) {
        const actionType = this.isEmbedded ? 'none' : 'closePopup';
        this.setValueOf('data.onClick', actionType);
      }

      this.setFeedbackInputs();

      // region Manage field ON listeners
      this.$bus.$on('switchInputElement', this.setFeedbackInputs);
      // endregion
    },
    beforeDestroy() {
      // region Manage field OFF listeners
      this.$bus.$off('switchInputElement', this.setFeedbackInputs);
      // endregion
    },
    methods: {
      ...mapMutations([
        'showImageManager',
        'setImageManagerDestination',
        'setSelectedPath',
        'updateStyle',
        'changeFormManagerVisibility',
      ]),
      // region Manage fields
      ...mapActions(['getMatchingInputs']),
      // endregion
      imageManager(index) {
        this.setImageManagerDestination({
          targetAttr: this.optionProperty(index, 'image'),
          simpleImage: true,
        });
        this.showImageManager();
      },
      updateOptions(options) {
        this.selectedElement.data.form.customSettings.options = options;
        this.$store.commit('updateData', {
          property: 'selectedElement.data.form.customSettings.options',
          value: this.selectedElement.data.form.customSettings.options,
        });
      },
      optionProperty(index, key) {
        return `selectedElement.data.form.customSettings.options.${index}.${key}`;
      },
      iconClass(it) {
        return {
          'fa-star-o': this.displayType === 'stars',
          'fa-thumbs-o-up': this.displayType === 'yesno' && it.value === 'yes',
          'fa-thumbs-o-down': this.displayType === 'yesno' && it.value === 'no',
          'fa-smile-o': this.displayType === 'smiley' && it.value === 'good',
          'fa-meh-o': this.displayType === 'smiley' && it.value === 'neutral',
          'fa-frown-o': this.displayType === 'smiley' && it.value === 'bad',
        };
      },
      imageUrl(index) {
        return this.options[index].image && this.options[index].image.imageUrl;
      },
      fixOptions(options = this.options) {
        const firstPage = this.pageOptions.length > 0 ? this.pageOptions[0].value : null;

        this.updateOptions(
          options.map((option) => {
            let action = option.action || 'none';
            let jumpTo = option.jumpTo || null;

            if (this.onClick === 'jumpToPage') {
              action = 'jumpToPage';

              if (this.choosePageBasedOn === 'specificPage') {
                jumpTo = this.jumpToPage;
              } else {
                jumpTo = option.jumpTo;
              }

              if (
                jumpTo === null ||
                jumpTo === 'none' ||
                !this.pageOptions.find((p) => p.value === jumpTo)
              ) {
                jumpTo = firstPage;
              }
            } else if (this.onClick === 'nextPopup') {
              action = 'nextPopup';
              jumpTo = null;
            } else if (this.onClick === 'closePopup') {
              action = 'closePopup';
              jumpTo = null;
            } else if (this.onClick === 'none') {
              action = 'none';
              jumpTo = null;
            }

            return { ...option, action, jumpTo };
          }),
        );
      },
      manageFields() {
        this.changeFormManagerVisibility({ show: 'manageField', fields: this.feedbackOptions });
      },
      createNewFeedback() {
        this.changeFormManagerVisibility({ show: 'secondStep' });
      },
      async setFeedbackInputs() {
        this.feedbackOptions = await this.getMatchingInputs();
      },
      setByRating(index, event) {
        this.setValueOf(`data.form.customSettings.options.${index}.jumpTo`, event);
      },
    },
  };
</script>

<style lang="sass">
  .ds-input-element-dropdown-select .add-new-button-holder
    padding: .75rem
    position: sticky
    left: 0
    bottom: 0
    width: 100%
    background: #fff
    border-top: 1px solid #e3e5e8
</style>
