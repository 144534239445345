<template lang="pug">
om-modal(:name="name" :width="520" :clickToClose="closable")
  template(slot="modal-header")
    .row
      .font-weight-bold.font-size-1--25 {{ titleText }}
    .brand-modal-action-icon
      span.cursor-pointer(@click="$modal.hide(name)")
        close-icon(:width="14" :height="14" :color="'#C2C2C2'")
  template(slot="modal-body")
    .custom-confirmation-text
      p
        span {{ altText }}
      p
        span {{ infoBarText }}
      p(v-if="isShopifyPayment")
        span {{ $t('downgradeModal.shopifyApproveInfo') }}
  template(slot="modal-footer")
    .d-flex.justify-content-center.mt-2
      om-button(secondary @click="$modal.hide(name)") {{ $t('cancel') }}
      om-button.ml-3(secondary @click="confirmedDowngrade") {{ actionButtonText }}
</template>

<script>
  import moment from 'moment';
  import { mapGetters, createNamespacedHelpers } from 'vuex';
  import dateFormat from '@/mixins/dateFormat';

  const { mapGetters: paymentGetters } = createNamespacedHelpers('payment');

  export default {
    mixins: [dateFormat],
    props: {
      name: { type: String, default: '' },
      toPlan: { type: String, default: null },
      toPeriod: { type: Number, default: 1 },
      closable: { type: Boolean, default: true },
    },
    computed: {
      ...mapGetters(['getDateExpires']),
      ...paymentGetters([
        'isShopifyPayment',
        'getDatePaidFromExpires',
        'isFreePackageBy',
        'getPeriodLanguageKey',
        'getPlainPlanName',
      ]),
      titleText() {
        return this.$t('downgradeModal.downgradeConfirmationTitle');
      },
      altText() {
        return this.$t('downgradeModal.downgradeConfirmationAltText');
      },
      infoBarText() {
        const downgradedPackage = this.toPackageName;
        const nextBilling = this.nextBillingDate;

        if (nextBilling.isBefore(moment.utc())) {
          return this.$t('downgradeModal.infoBarAfterExpires', { downgradedPackage });
        }

        return this.$t('downgradeModal.infoBarWithoutDowngrade', {
          nextBilling: nextBilling.format(this.dateFormat),
          downgradedPackage,
        });
      },
      actionButtonText() {
        return this.$t('downgradeModal.downgrade');
      },
      toPackageName() {
        if (this.isFreePackageBy(this.toPlan)) {
          return this.toPlan;
        }

        const planName = this.getPlainPlanName(this.toPlan).toUpperCase();
        const periodInMonth = this.toPeriod;
        const periodLanguageKey = `billingCycle.${this.getPeriodLanguageKey(periodInMonth)}`;
        return `${planName} (${this.$t(periodLanguageKey)})`;
      },
      nextBillingDate() {
        return moment.utc(this.getDatePaidFromExpires).startOf('day');
      },
    },
    methods: {
      confirmedDowngrade() {
        this.$modal.hide(this.name);
        this.$emit('confirmedDowngrade');
      },
    },
  };
</script>
