<template lang="pug">
.account-table.managed-accounts-table
  om-table(
    name="managedAccounts"
    :items="managedAccounts"
    :columns="columns"
    :allItemsCount="managedAccounts.length"
    :selectable="false"
    :columnClasses="columnClasses"
    :serverSort="false"
    :serverPaginate="false"
    :sorting="sorting"
    :pagination="pagination"
    :customSortFields="customSortFields"
    @paginationChange="pagination = $event"
  )
    template(slot="login.email" slot-scope="{ rowData }")
      span.brand-link(:title="rowData.row.login.email" @click="logInto(rowData.row)") {{ rowData.row.login.email }}
    template(slot="settings.domains" slot-scope="{ rowData }")
      span {{ getActiveDomains(rowData) }}
    template(slot="account.billing.package" slot-scope="{ rowData }")
      span {{ getPackageStr(rowData.row) }}
    template(slot="account.limits.maxPageViews" slot-scope="{ rowData }")
      span
        span {{ max(rowData.row) | thousandSep }}
    template(slot="account.limits.pageViews" slot-scope="{ rowData }")
      .d-flex.flex-row.align-items-center.justify-content-end
        .progress.flex-grow-1.mx-3(style="max-width: 100px")
          .progress-bar(
            role="progressbar"
            :style="{ width: visitorPercent(used(rowData.row), max(rowData.row)) + '%' }"
            :aria-valuenow="visitorPercent(used(rowData.row), max(rowData.row))"
            aria-valuemin="0"
            aria-valuemax="100"
          )
        .flex-column {{ used(rowData.row) | thousandSep }}
</template>

<script>
  import { get as _get } from 'lodash-es';
  import moment from 'moment';

  import { mapActions, createNamespacedHelpers } from 'vuex';
  import { setAccountIdInCookie } from '@/util';

  import CREATE_LOGIN_CREDENTIALS from '@/graphql/CreateLoginCredentials.gql';
  import GET_AGENCY_MANAGED_ACCOUNTS from '@/graphql/GetAgencyManagedAccounts.gql';

  import agencyDashboardMixin from '@/mixins/agencyDashboard';

  const { mapGetters: paymentGetters } = createNamespacedHelpers('payment');

  export default {
    mixins: [agencyDashboardMixin],

    props: {
      filter: { type: Object, required: false },
    },

    data() {
      return {
        managedAccounts: [],
        columnClasses: {
          'login.email': 'text-left',
          options: 'flex-grow-0 text-center px-5 sub-account-setting-col',
        },
        sorting: { field: 'account.createdAt', direction: 1 },
        pagination: { page: 1, limit: 30 },
        customSortFields: {
          'account.limits.maxPageViews': (row) => this.maxKey(row),
          'account.limits.pageViews': (row) => this.usedKey(row),
        },
      };
    },

    computed: {
      ...paymentGetters(['isPageViewBasedPackageByPlan']),
      columns() {
        return [
          { header: this.$t('email'), key: 'login.email' },
          { header: this.$t('domains'), key: 'settings.domains' },
          { header: this.$t('package'), key: 'account.billing.package' },
          { header: this.$t('visitorLimit'), key: 'account.limits.maxPageViews' },
          { header: this.$t('visitors'), key: 'account.limits.pageViews' },
          { header: '', key: 'options' },
        ];
      },
    },

    watch: {
      filter: {
        handler() {
          this.pagination.page = 1;
          this.$store.commit('updateTablePagination', this.pagination);
          this.fetchManagedAccounts();
        },
        deep: true,
      },
    },

    async created() {
      await this.fetchManagedAccounts();
    },

    methods: {
      ...mapActions(['fetchAccount']),
      getActiveDomains({ row }) {
        return row.account.settings.domains
          .reduce((prev, curr) => {
            if (curr.inactive === false) {
              prev.push(curr.domain);
            }
            return prev;
          }, [])
          .join(', ');
      },

      async logInto(row) {
        this.$store.commit('hideStickyBar', true);
        this.$router.replace({ name: 'account-switching' });

        const {
          data: { login },
        } = await this.$apollo.mutate({
          mutation: CREATE_LOGIN_CREDENTIALS,
          variables: {
            databaseId: row.account.databaseId,
            affiliateImpersonate: true,
          },
        });

        const userId = login.dataAccountId;

        setAccountIdInCookie(userId);

        if (login.redirectUrl) {
          window.location = login.redirectUrl;
        } else {
          this.$store
            .dispatch('login', {
              expiration: login.expiration,
              accountType: login.accountType,
              locale: login.locale,
              impersonate: true,
              affiliateImpersonate: true,
            })
            .then(() => {
              sessionStorage.removeItem('campaignFilter');
              this.$router.push({ name: login.redirectRoute, params: { userId } });
              this.$store.commit('hideStickyBar', false);
            });
        }
      },

      async fetchManagedAccounts() {
        const {
          data: { managedAccounts },
        } = await this.$apollo.query({
          query: GET_AGENCY_MANAGED_ACCOUNTS,
          variables: {
            filter: this.filter,
          },
        });

        this.managedAccounts = managedAccounts;
      },

      getPackageStr(row) {
        const currentPackage = row.account.billing.package;
        const isExpired = moment().isAfter(moment(row.account.billing.dateExpires));
        const activity = currentPackage !== 'FREE' ? `(${isExpired ? 'inactive' : 'active'})` : '';

        return `${currentPackage} ${activity}`;
      },

      usedKey(row) {
        return this.isPageViewBasedPackageByPlan(row.account.billing.package)
          ? 'account.limits.pageViews'
          : 'account.limits.usedVisitor';
      },

      maxKey(row) {
        return this.isPageViewBasedPackageByPlan(row.account.billing.package)
          ? 'account.limits.maxPageViews'
          : 'account.limits.maxVisitor';
      },

      used(row) {
        return _get(row, this.usedKey(row));
      },

      max(row) {
        return _get(row, this.maxKey(row));
      },
    },
  };
</script>
