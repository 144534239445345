<template lang="pug">
om-tooltip
  span {{ $t(segment.tooltip) }}
  template(slot="trigger")
    om-link.wizard-block(
      @click="isActive() ? toBlockStart() : false"
      withIconLeft
      :class="{ 'disabled-segment': !isActive() }"
    ) {{ $t(text) }}
      template(#left-icon)
        component(:is="`uil-${icon}`" :size="iconSize")
</template>
<script>
  import { getSmartWizardStepsOrder } from '@/views/Wizard/flow';
  import { getAccountIdFromCookie } from '@/util';
  import { UilShop, UilBrushAlt, UilWebGridAlt, UilEdit } from '@iconscout/vue-unicons';
  import abTestMixin from '@/views/Wizard/abTest';

  export default {
    components: { UilShop, UilBrushAlt, UilWebGridAlt, UilEdit },
    mixins: [abTestMixin],
    props: {
      segment: {
        type: Object,
        default: () => {},
      },
      iconSize: {
        type: String,
        default: '1.5em',
      },
    },
    data() {
      return {
        step: this.$route.params.step,
      };
    },
    computed: {
      text() {
        return this.segment.text;
      },
      icon() {
        return this.segment.icon;
      },
      flowOrder() {
        return getSmartWizardStepsOrder(this.isOriginalFlow);
      },
    },
    methods: {
      isActive() {
        const segmentStartPage = this.segment.startPage;
        const segmentStartPageOrder = this.getPageOrder(segmentStartPage);

        const actualPage = this.step;
        const actualPageOrder = this.getPageOrder(actualPage);
        return !!(segmentStartPageOrder <= actualPageOrder && segmentStartPageOrder >= 0);
      },
      getPageOrder(route) {
        return this.flowOrder.indexOf(route);
      },
      toBlockStart() {
        const neededParams = this.segment.neededParams;
        const query = {};
        for (const [key, value] of Object.entries(this.$route.query)) {
          if (!neededParams.includes(key)) continue;
          query[key] = value;
        }

        if (['pickTheme', 'color'].some((step) => this.segment.startPage.startsWith(step))) {
          ['color', 'baseThemeId', 'customTheme', 'theme'].forEach((key) => {
            delete query[key];
          });
        }

        this.$router.push({
          name: this.$route.name.includes('onboarding') ? 'onboarding_wizard' : 'wizard',
          params: {
            step: this.segment.startPage,
            userId: getAccountIdFromCookie(),
          },
          query,
        });
      },
    },
  };
</script>
<style scoped lang="sass">
  @import '@/sass/variables/variables'

  .wizard-block
    &:hover
      color: $om-gray-600
    &.disabled-segment
      color: $om-gray-500
      cursor: default
    svg
      margin-right: 0.5rem
</style>
