<template lang="pug">
div
  .brand-wrapper.brand-template-chooser-v2
    .container-fluid
      wizard-loader(v-if="loader" @loaderComplete="loader = false")
      template(v-else)
        .search-bar-heading.row.align-items-start.justify-content-between(
          ref="heading"
          :class="{ 'search-bar-shadow': addBoxShadow }"
        )
          .col-auto(:class="{ 'col-6': isLangChooserAndBelowSize }")
            om-heading(h1) {{ $t(title) }}
          .row.col
            .col-12.col-lg.d-flex.justify-content-end.my-2
              language-chooser(@change="languageUpdated")
            .col-12.col-lg.search-bar-input-wrapper.ml-auto.my-2
              om-input#template-search-input.search-bar-input(
                type="text"
                :placeholder="$t('search')"
                v-model.trim="searchTerm"
                @enter="doSearch"
              )
                template(#suffix)
                  om-button.search-bar-button(title="Search" ghost iconOnly @click.stop="doSearch")
                    template(#icon)
                      UilSearch(size="24")
        SideBySide
          template(#leftColumn)
            Navigation(:items="menuItems" @click="onMenuClick")
            hr.horizontal-divider
            .filters(:class="{ 'filters-fixed': fixedFilters }" ref="filters")
              TemplateFilter(
                newChooser
                :templateTypes="templateTypes"
                :templateGoals="templateGoals"
                :templateContents="templateContents"
                :selectedTypes.sync="filter.types"
                :selectedGoals.sync="filter.goals"
                :selectedContents.sync="filter.contents"
                @track="reportLeftMenuClick"
              )
          .template-chooser-page-content
            transition-group(name="fade" mode="out-in")
              .template-chooser-filter-tags.d-flex.pb-5(
                v-if="filters.length || activeSearch"
                key="filters"
              )
                om-chip(
                  v-if="activeSearch"
                  small
                  color="primary"
                  removable
                  @remove="onSearchRemove"
                  key="search-chip"
                ) {{ $t('templateChooser.search.resultTitle', { term: activeSearch }) }}
                om-chip(
                  v-if="filters.length"
                  small
                  color="primary"
                  removable
                  v-for="filter in filters"
                  @remove="removeFilter(filter.type, filter.value)"
                  :key="filter.value"
                ) {{ getFilterName(filter) }}
                .template-chooser-filter-clear.cursor-pointer.py-2.px-1.ml-1.text-center.font-size-0--9375(
                  v-if="filters.length || activeSearch"
                  @click="onClearFilterClick"
                ) {{ $t('clear') }}
              router-view(
                key="child-view"
                :filter="filter"
                :useCaseMap="useCaseMap"
                :themes="themes"
                :search="activeSearch"
                @setLoading="onSetLoading"
                @refreshData="refreshData"
                @navigateWithFilterReset="onNavigateWithFilterReset"
                @navigateWithFilter="onNavigateWithFilter"
                @messageTypeFilter="onMessageTypeFilter"
                @createCampaign="startFromScratch"
                @track="reportMenuClick"
                @trackActivity="onTrack"
              )
    template-preview
    new-campaign
    NewCampaignSMSPrompt
    from-scratch-chooser(@start-from-scratch="startFromScratch($event)")
    last-request-date-old
</template>

<script>
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
  import { get } from 'lodash-es';
  import slugify from 'slugify';
  import CUSTOM_TEMPLATE_ID from '@/graphql/CustomTemplateId.gql';
  import FLYER_TEMPLATE_ID from '@/graphql/FlyerTemplateId.gql';
  import GET_CHOOSABLE_THEME_CATEGORIES from '@/graphql/GetChoosableThemeCategories.gql';
  import GET_USE_CASE_MAP from '@/graphql/GetUseCaseMap.gql';
  import SAVE_SEARCH_ACTIVITY from '@/graphql/SaveSearchActivity.gql';
  import { convertObjectToQueryParamsString } from '@/util';
  import WizardLoader from '@/components/Wizard/WizardLoader.vue';
  import LanguageChooser from '@/components/TemplateChooser/components/LanguageChooser.vue';
  import { UilSearch } from '@iconscout/vue-unicons';
  import { track } from '@/services/xray';

  const themeWithSlug = (theme) => {
    return {
      ...theme,
      slug: `${slugify(theme.name)}-${theme._id}`,
    };
  };

  const themeKitWithLogo = (theme) => {
    return {
      ...theme,
      themeKit: {
        ...theme.themeKit,
        logo: theme?.logo?.current,
      },
    };
  };

  export default {
    components: {
      TemplatePreview: () => import('@/components/Modals/TemplatePreview'),
      WizardLoader,
      LanguageChooser,
      NewCampaign: () => import('@/components/Modals/NewCampaign.vue'),
      NewCampaignSMSPrompt: () => import('@/components/Modals/NewCampaignSMSPrompt.vue'),
      FromScratchChooser: () => import('@/components/Modals/FromScratchChooser.vue'),
      LastRequestDateOld: () => import('@/components/Modals/LastRequestDateOld.vue'),
      SideBySide: () => import('@/components/TemplateChooser/layout/SideBySide.vue'),
      Navigation: () => import('@/components/TemplateChooser/components/Navigation.vue'),
      TemplateFilter: () => import('@/components/Template/Filter.vue'),
      UilSearch,
    },

    beforeRouteUpdate(to, from, next) {
      // the user changed the filters
      if (to.name !== from.name) {
        this.fixedFilters = false;
        this.scrollToTop();
        this.setFilterPosition();
      }
      next();
    },

    beforeRouteEnter(to, from, next) {
      next((vm) => {
        if (from.name !== 'templates') {
          vm.scrollToTop();
        }
        const loaderParam = to?.params?.recLoader ?? true;
        const showWizardLoader = from.path.includes('onboarding') && loaderParam;
        if (showWizardLoader) {
          vm.loader = true;
        } else {
          vm.showAdminLoader(true);
        }
      });
    },
    data: () => ({
      initialized: false,
      loading: true,
      templateTypes: [],
      templateGoals: [],
      templateContents: [],
      filter: {
        types: [],
        goals: [],
        contents: [],
      },
      filters: [],
      activeMenu: null,
      themes: {},
      queryParameters: {},
      loader: false,
      language: null,
      fixedFilters: false,
      filterPosition: 0,
      searchTerm: null,
      activeSearch: null,
      addBoxShadow: false,
      filterDeepWatch: null,
      windowWidth: window.innerWidth,
    }),
    apollo: {
      customTemplateId: {
        query: CUSTOM_TEMPLATE_ID,
      },
      flyerTemplateId: {
        query: FLYER_TEMPLATE_ID,
      },
      themes: {
        query: GET_CHOOSABLE_THEME_CATEGORIES,
        update({ themes: { user, base, seasons } }) {
          return {
            user: user.map(themeWithSlug).map(themeKitWithLogo),
            base: base.map(themeWithSlug).map(themeKitWithLogo),
            seasons: seasons.map((s) => ({
              name: this.$t(`templateFilter.categories.${s}`),
              id: s,
              slug: s,
            })),
          };
        },
        result() {
          this.initiateBaseThemeKitIds();
        },
      },
      useCaseMap: {
        query: GET_USE_CASE_MAP,
      },
    },
    computed: {
      ...mapState(['preferredTemplateLanguage']),
      ...mapGetters([
        'isRealTemplateAuthor',
        'onboardingFinished',
        'isSubUser',
        'isAffiliate',
        'hasAccountFeature',
        'templateChooserFilterValues',
        'baseThemeKitIds',
      ]),
      title() {
        return this.activeMenu?.key !== 'home'
          ? this.activeMenu?.name ?? 'templateChooser.title'
          : 'templateChooser.title';
      },
      baseThemes() {
        const base = this.themes?.base || [];
        return base.filter(({ _id }) => !!_id);
      },
      baseThemeIds() {
        return this.baseThemes.map(({ _id }) => _id);
      },
      seasonals() {
        return this.themes?.seasons || [];
      },
      yourThemes() {
        return this.themes?.user || [];
      },
      validYourThemes() {
        return this.yourThemes.filter(({ sourceTheme }) => this.baseThemeIds.includes(sourceTheme));
      },
      query() {
        return Object.entries(this.queryParameters).reduce((obj, [key, value]) => {
          obj[key] = JSON.stringify(value);
          return obj;
        }, {});
      },
      isLangChooserAndBelowSize() {
        return this.windowWidth <= 1450;
      },
      menuItems() {
        const query = { ...this.query };
        return [
          {
            key: 'home',
            name: 'templateChooser.home',
            icon: 'estate',
            to: { name: 'templates', query },
          },
          {
            key: 'your-themes-collection',
            name: 'customTheme.chooser.block.title',
            to: { name: 'your-themes-collection', query },
            children: this.validYourThemes.map(({ _id, name, slug }) => ({
              key: _id,
              name,
              to: {
                name: 'your-themes-templates',
                params: { slug },
                query,
              },
            })),
          },
          {
            key: 'themes-collection',
            name: 'themes',
            to: { name: 'themes-collection', query },
            children: this.baseThemes.map(({ _id, name, slug }) => ({
              key: _id,
              name: `${name} theme`,
              to: {
                name: 'themes-templates',
                params: { slug },
                query,
              },
            })),
          },
          {
            key: 'seasonal-collection',
            name: 'templateChooser.seasonalTemplates',
            to: { name: 'seasonal-collection', query },
            children: this.seasonals.map(({ name, id, slug }) => ({
              key: id,
              name,
              to: {
                name: 'seasonal-templates',
                params: { slug },
                query,
              },
            })),
          },
          {
            key: 'custom',
            name: 'templateChooser.customTemplates',
            iconless: true,
            to: { name: 'custom-templates', query },
          },
          {
            key: 'new-empty',
            name: this.$t('startFromScratch'),
            icon: 'vector-square',
          },
        ];
      },
      needAdvancedTemplateQuery() {
        return (
          this.isRealTemplateAuthor ||
          this.isAgencyAccount ||
          this.isSubUser ||
          (this.isAffiliate && this.$i18n.locale !== 'hu')
        );
      },
    },
    watch: {
      'filter.types': function (newValues, oldValues) {
        if (!this.initialized) return;
        this.addOrRemoveFilter('types', newValues, oldValues);
      },
      'filter.goals': function (newValues, oldValues) {
        if (!this.initialized) return;
        this.addOrRemoveFilter('goals', newValues, oldValues);
      },
      'filter.contents': function (newValues, oldValues) {
        if (!this.initialized) return;
        this.addOrRemoveFilter('contents', newValues, oldValues);
      },
      loading(value) {
        this.showAdminLoader(value);
      },
      '$route.fullPath': function () {
        this.initActiveMenu();
      },
    },
    mounted() {
      window.addEventListener('scroll', this.onScroll, true);
      window.addEventListener('popstate', this.onPopState, true);
      this.filterDeepWatch = this.$watch('filter', this.debouncedFilterWatch, { deep: true });
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.onScroll, true);
      window.removeEventListener('popstate', this.onPopState, true);
    },
    async created() {
      await Promise.all([
        ...(this.onboardingFinished ? [] : [this.finishOnboarding()]),
        this.fetchPossibleFilterValues(),
      ]);
      this.initiateFilters();
    },
    methods: {
      ...mapMutations(['showAdminLoader', 'setBaseThemeKitIds']),
      ...mapActions(['finishOnboarding', 'fetchPossibleFilterValues']),
      debouncedFilterWatch() {
        if (!this.initialized) return;
        this.addFiltersToQuery();
      },
      toggleFilterWatch() {
        if (this.filterDeepWatch) {
          this.filterDeepWatch();
          this.filterDeepWatch = null;
        } else {
          this.filterDeepWatch = this.$watch('filter', this.debouncedFilterWatch, { deep: true });
        }
      },
      onMenuClick(path) {
        if (path === '0') {
          this.toggleFilterWatch();
          this.$router.push({ name: 'templates' });
          this.clearAllFilters();
          this.toggleFilterWatch();
        }
        if (path === 'new-empty') return this.createEmpty();
        const realPath = path.replace('.', '.children.');
        this.activeMenu = get(this.menuItems, realPath, this.activeMenu);
        this.reportMenuClick(this.activeMenu);
      },
      onMessageTypeFilter(option) {
        this.onTrack({
          component: 'Message type',
          setting: this.$tc(`templateFilter.types.${option}`, 1),
        });
        this.filter.types = [...this.filter.types, option];
      },
      onScroll() {
        if (this.isLeftColumnHigherThanContent()) {
          return this.handleFixedFilterWithHigherContent();
        }

        this.setNavigationMinHeight(null);

        const element = this.$refs.filters;
        const heading = this.$refs.heading;

        const { height = 0 } = heading?.getBoundingClientRect?.() ?? {};
        this.addBoxShadow = document.body.scrollTop > 5;
        if (this.fixedFilters === false && this.filterPosition < document.body.scrollTop) {
          this.fixedFilters = true;
          this.filterPosition = element.offsetTop - height;

          const stickyTop = document.querySelector('.sticky-top');
          const stickyOffset = stickyTop?.offsetHeight ?? 0;
          if (stickyOffset || height) {
            const offset = height > stickyOffset ? height : stickyOffset;
            element.style.top = `${offset}px`;
          }
        }

        if (this.fixedFilters === true && this.filterPosition > document.body.scrollTop) {
          element.scrollTop = 0;
          this.fixedFilters = false;
          this.filterPosition = element.offsetTop - height;
        }
      },
      setFilterPosition() {
        const element = this.$refs.filters;
        const heading = this.$refs.heading;
        const { height = 0 } = heading?.getBoundingClientRect?.() ?? {};
        this.filterPosition = height ? element.offsetTop - height : element.offsetTop;
      },
      navigateWithFilter(to) {
        const params = this.getParams();
        if (Object.keys(params).length) {
          to.query = { ...params };
          this.$router.push(to);
        } else {
          this.onNavigateWithFilterReset(to);
        }
      },
      getParams() {
        const params = {};
        Object.keys(this.filter).forEach((k) => {
          if (this.filter[k].length) {
            params[k] = this.filter[k];
          }
        });

        return params;
      },
      addFiltersToQuery() {
        const params = this.getParams();
        if (this.language !== 'en') {
          params.lang = this.language;
        }
        this.queryParameters = params;

        const path = this.$route.path + convertObjectToQueryParamsString(params);
        if (this.$route.fullPath !== path) {
          this.$router.push(path);
        }
      },

      scrollToTop() {
        const { body } = document;
        this.$nextTick(() => body.scrollTo(0, 0));
      },

      addOrRemoveFilter(type, newValues, oldValues) {
        const newValueLength = newValues.length;
        if (newValueLength > oldValues.length) {
          this.addFilter(type, newValues[newValueLength - 1]);
        } else {
          const difference = oldValues.filter((value) => !newValues.includes(value));

          this.removeFilter(type, difference[0]);
        }
      },
      clearAllFilters() {
        this.filters = [];
        this.filter = {
          types: [],
          goals: [],
          contents: [],
        };
        this.resetSearch();
      },
      addFilter(type, value) {
        const added = this.filters.find(
          ({ type: fType, value: fValue }) => fType === type && value === fValue,
        );

        if (!added) this.filters.push({ type, value });
      },
      removeFilter(type, value) {
        const filterIndex = this.filters.findIndex((f) => f.type === type && f.value === value);
        if (filterIndex > -1) {
          this.filters.splice(filterIndex, 1);
        }

        const selectedIndex = this.filter[type].findIndex((s) => s === value);
        if (selectedIndex > -1) {
          this.filter[type].splice(selectedIndex, 1);
        }
      },
      getFilterName(filter) {
        if (filter.type === 'goals') {
          return this.$t(`tactic.goal.${filter.value}`);
        }
        return this.$tc(`templateFilter.${filter.type}.${filter.value}`, 1);
      },
      onSetLoading(value) {
        this.setFilterPosition();
        if (!this.initialized) {
          this.initActiveMenu();
          this.loading = value;
          this.initialized = true;
        }
      },
      createEmpty() {
        this.$modal.show('from-scratch-chooser');
      },
      startFromScratch(type) {
        this.onTrack({ component: 'Start from scratch', setting: type });
        const templateId = type === 'empty' ? this.customTemplateId : this.flyerTemplateId;
        this.$modal.show('name-campaign', { templateId });
      },
      initiateFilters() {
        this.initiatePossibleFilterValues();

        const currentSearch = new URLSearchParams(window.location.search);
        const filterKeys = Object.keys(this.filter);
        currentSearch.forEach((value, key) => {
          if (filterKeys.includes(key)) {
            try {
              const parsed = JSON.parse(value);
              parsed.forEach((value) => this.addFilter(key, value));
              this.filter[key] = parsed;
            } catch (e) {
              this.filter[key] = [];
            }
          }
        });
      },
      onNavigateWithFilterReset(to) {
        this.clearAllFilters();
        this.$router.push(to);
      },
      onNavigateWithFilter(to) {
        this.navigateWithFilter(to);
      },
      onPopState() {
        this.clearAllFilters();
        this.initiateFilters();
      },
      refreshData() {
        this.$apollo.queries.themes.refetch();
      },
      getAllMenuItems() {
        return this.menuItems
          .map((item) => {
            if (!item.to) return null;
            if (!item.children) return item;

            return [{ ...item, children: undefined }, ...item.children];
          })
          .filter((v) => !!v)
          .flat();
      },
      findActiveMenu(allMenuItems) {
        return allMenuItems.find(({ to }) => {
          const sameRoute = to?.name === this.$route.name;
          const routeSlug = this.$route.params?.slug;
          return routeSlug ? sameRoute && routeSlug === to?.params?.slug : sameRoute;
        });
      },
      initActiveMenu() {
        const allMenuItems = this.getAllMenuItems();
        const activeMenu = this.findActiveMenu(allMenuItems);
        this.activeMenu = activeMenu || null;
      },
      languageUpdated(lang) {
        if (this.language !== null) {
          this.language = lang;
          this.debouncedFilterWatch();
          window.location.reload();
        } else {
          this.language = lang;
          this.debouncedFilterWatch();
        }
      },
      initiatePossibleFilterValues() {
        const { goals = [], types = [], contents = [] } = this.templateChooserFilterValues || {};

        const goalsKeyValue = Object.keys(this.$t('tactic.goal')).reduce((acc, key, index) => {
          acc[key] = index;
          return acc;
        }, {});
        this.templateGoals = goals.map((name) => name);
        this.templateGoals.sort((a, b) => goalsKeyValue[a] - goalsKeyValue[b]);
        this.templateTypes = types.map((name) => name).filter((type) => type !== 'fullscreen'); // Interstitial enough
        this.templateContents = contents.map((name) => name);
      },

      initiateBaseThemeKitIds() {
        if (this.baseThemeKitIds?.length) return;
        const ids = this.baseThemes?.map?.(({ _id }) => _id);
        this.setBaseThemeKitIds(ids ?? []);
      },
      doSearch() {
        if (this.searchTerm?.length) {
          this.$apollo.mutate({
            mutation: SAVE_SEARCH_ACTIVITY,
            variables: { searchTerm: this.searchTerm },
          });
        }
        this.activeSearch = this.searchTerm;
        this.searchTerm = null;
      },
      resetSearch() {
        this.searchTerm = null;
        this.doSearch();
      },
      getLeftColSize() {
        const leftNavigation = document
          .querySelector('.layout-left-column .navigation-v2')
          ?.getBoundingClientRect();
        const leftFilters = document
          .querySelector('.layout-left-column .filters')
          ?.getBoundingClientRect();

        return leftNavigation?.height + leftFilters?.height;
      },
      isLeftColumnHigherThanContent() {
        const leftColSize = this.getLeftColSize();
        const rightWrapperSize = document
          .querySelector('.template-chooser-page-content span[mode="out-in"]')
          ?.getBoundingClientRect();
        return rightWrapperSize?.height < leftColSize;
      },
      setNavigationMinHeight(height) {
        if (this.$refs.navigation?.$el?.style?.minHeight) {
          this.$refs.navigation.$el.style.minHeight = height ? `${height}px` : null;
        }
      },
      handleFixedFilterWithHigherContent() {
        if (!this.fixedFilters) return;

        const colHeight = this.getLeftColSize();

        this.setNavigationMinHeight(colHeight);
        this.fixedFilters = false;
        document.querySelector('body')?.scrollTo?.({
          top: 0,
          left: 0,
          behavior: 'auto',
        });
      },
      reportMenuClick(menu) {
        if (!menu) return;
        const { name } = menu;
        this.reportLeftMenuClick({
          component: this.$te(name) ? this.$t(name, 'en') : name,
        });
      },
      onTrack(event) {
        const fixedLocationEvent = {
          ...event,
          location: 'main',
        };
        this.trackWithHeap(fixedLocationEvent);
      },
      reportLeftMenuClick(event) {
        const fixedLocationEvent = {
          ...event,
          location: 'left_menu',
        };
        this.trackWithHeap(fixedLocationEvent);
      },
      trackWithHeap(properties) {
        track('template_selector_click', properties);
      },
      onClearFilterClick() {
        this.onTrack({ component: 'Filter clear' });
        this.clearAllFilters();
      },
      onSearchRemove() {
        this.onTrack({
          component: 'Search',
          setting: 'remove',
          options: this.activeSearch,
        });
        this.resetSearch();
      },
    },
  };
</script>

<style lang="sass">
  @import '@/sass/variables/_colors.sass'
  .brand-template-chooser-v2
    padding-top: 0
    padding-bottom: 0
    .template-chooser-header
      display: flex
      justify-content: space-between
    .search-bar
      &-shadow
        box-shadow: 0 4px 16px 0 rgba(0,0,0,0.1)
      &-heading
        position: sticky
        top: -1px
        background: white
        z-index: 19
        margin: 0 -3.125rem
        padding: 1.25rem 2.5rem
      &-button
        padding: .375rem !important
      &-input
        &-wrapper
          max-width: 25rem
        .input-suffix-slot
          padding: 0 !important
    .template-chooser-search-term
      margin-bottom: 2.5rem
    .om-overlay-center
      overflow: hidden !important
    .layout-side-by-side
      margin-top: 1.25rem
      .layout-right-column
        display: flex
        justify-content: center
        flex-grow: 1
    .template-filter
      padding: 0
      padding-bottom: 6rem
      height: calc(100vh - 88px)
      .brand-slide-down-title
        color: $om-gray-800
    .filters
      position: sticky
      &.filters-fixed
        .template-filter
          overflow-y: auto

    .template-chooser
      &-page-content
        max-width: 93.75rem
        width: 100%

      &-filter
        &-tags
          margin-left: -6px

        &-clear
          color: $om-orange-500
</style>
