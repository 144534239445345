import moment from 'moment';
import planDetails from '@om/payment/src/helpers/planDetails';

const _clone = (v) => JSON.parse(JSON.stringify(v));

class GroupedPlanListService {
  constructor(plans) {
    this.plans = plans;

    this.currentPlan = null;
    this.recommendedPlan = null;
    this.outOfPvPlans = [];

    this.groupList = null;
  }

  setCurrentPlan(currentPlan) {
    this.currentPlan = currentPlan;
  }

  setRecommenderData({ recommendedPlan, outOfPvPlans }) {
    this.recommendedPlan = recommendedPlan;
    this.outOfPvPlans = outOfPvPlans;
  }

  hasRecommendation() {
    if (planDetails.isDowngrade(this.currentPlan, this.recommendedPlan)) return false;
    return !!this.recommendedPlan;
  }

  getGroupList() {
    if (!this.groupList) this.loadGroupList();
    return this.groupList;
  }

  changeVariant({ variant }) {
    const groups = this.groupList || this.loadGroupList();
    const _plan = this._publicPlans().find((plan) => plan.name === variant);
    const plan = this._trimPlan(_plan);
    this._overwriteGroupDataWithVariant(groups, plan);
    this._setRecommendationData(groups);
  }

  loadGroupList() {
    const groups = {};
    for (const _plan of this._publicPlans()) {
      const plan = this._trimPlan(_plan);

      // Set group data with first variant
      if (!groups[plan.group]) {
        this._setGroupDataWithFirstVariant(groups, plan);
      }

      groups[plan.group].variantOptions.push({
        key: plan.name,
        value: `${plan.details.pageViews}`,
      });

      // Overwrite group data with current plan variant
      if (plan.name === this.currentPlan) {
        this._overwriteGroupDataWithVariant(groups, plan);
      }

      // Overwrite group data with recommended plan variant except if it is in the same group with the current
      if (
        plan.name === this.recommendedPlan &&
        this._getGroup(this.currentPlan) !== this._getGroup(this.recommendedPlan)
      ) {
        this._overwriteGroupDataWithVariant(groups, plan);
      }
    }

    this._setRecommendationData(groups);

    this.groupList = groups;
  }

  _publicPlans() {
    return this.plans.filter((plan) => plan.public !== false);
  }

  _getGroup(planName) {
    return this.plans.find((plan) => plan.name === planName)?.group ?? null;
  }

  _setGroupDataWithFirstVariant(groups, plan) {
    groups[plan.group] = {
      ...plan,
      displayName: this._formatDisplayName(plan.group),
      variantOptions: [],
      recommendation: {},
      selectedVariant: plan.name,
    };
  }

  _overwriteGroupDataWithVariant(groups, plan) {
    groups[plan.group] = {
      ...plan,
      displayName: groups[plan.group].displayName,
      variantOptions: groups[plan.group].variantOptions,
      recommendation: groups[plan.group].recommendation,
      selectedVariant: plan.name,
      ...(plan.name === this.currentPlan ? { currentVariant: true } : {}),
    };
  }

  _setRecommendationData(groups) {
    if (!this.hasRecommendation()) return;

    Object.keys(groups).forEach((group) => {
      groups[group]?.variantOptions.forEach(({ key: variantName }) => {
        if (variantName === this.recommendedPlan) {
          groups[group].recommendation.recommendedVariant = variantName;
          groups[group].recommendation.isRecommendedGroup = true;
        }
      });

      const selectedVariant = groups[group].selectedVariant;

      if (this.outOfPvPlans.find(([name]) => name === selectedVariant)) {
        const remainingDays = this._getRemainingDaysToRunOut(selectedVariant);
        groups[group].recommendation.remainingDaysToRunOut = remainingDays;
        groups[group].recommendation.notSufficient = true;
      } else {
        delete groups[group].recommendation.remainingDaysToRunOut;
        delete groups[group].recommendation.notSufficient;
      }
    });
  }

  _getRemainingDaysToRunOut(planName) {
    const date = this.outOfPvPlans.find(([name]) => name === planName)?.[1];
    if (!date) return 0;
    return moment.utc(date).diff(moment.utc().startOf('date'), 'days');
  }

  _formatDisplayName(planName) {
    return planName.charAt(0).toUpperCase() + planName.toLowerCase().slice(1);
  }

  _trimPlan(_plan) {
    const plan = _clone(_plan);
    delete plan.variant;
    delete plan.public;
    return plan;
  }
}

export { GroupedPlanListService };
