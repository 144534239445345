<template lang="pug">
.om-wizard-pick-theme
  .text-center
    wizard-title.mb-4 {{ $t('onboarding.customTheme.pickThemeV2.title') }}
    om-body-text(bt400md) {{ $t('onboarding.customTheme.pickThemeV2.description') }}
    .grid-container
      .grid-item(v-for="theme in showableMulticolorThemes")
        ThemeCard(
          :theme="theme"
          :key="theme.name"
          :name="theme.name"
          :to="theme.name"
          :needToTrack="false"
          @navigateWithFilter="nextPage($event)"
        )
</template>
<script>
  import ThemeCard from '@/components/TemplateChooser/components/cards/ThemeCard.vue';
  import GET_TEMPLATE_THEMES from '@/graphql/GetTemplateThemes.gql';
  import navigationMixin from '../navigation';
  import sharedMixin from '../shared';

  const THEME_NAMES_ORDERED = [
    'Essential',
    'Black',
    'Pure',
    'Modern',
    'Inverse',
    'Midnight',
    'Groundy',
    'Edge',
    'Monochrome',
    'Botanic',
    'Spark',
    'Photographic',
  ];

  export default {
    components: {
      ThemeCard,
    },
    mixins: [navigationMixin, sharedMixin],
    data() {
      return {
        themes: [],
        theme: null,
        customThemes: null,
      };
    },
    computed: {
      showableMulticolorThemes() {
        return this.themes
          .filter(({ multicolor, thumbnail }) => multicolor && thumbnail)
          .sort((a, b) => {
            const indexA = THEME_NAMES_ORDERED.indexOf(a.name);
            const indexB = THEME_NAMES_ORDERED.indexOf(b.name);
            return indexA - indexB;
          });
      },
    },
    apollo: {
      themes: {
        query: GET_TEMPLATE_THEMES,
      },
    },
    created() {
      const searchParams = new URLSearchParams(window.location.search);
      ['color', 'baseThemeId', 'customTheme', 'theme'].forEach((key) => {
        searchParams.delete(key);
      });
      window.history.replaceState({}, '', `${window.location.pathname}?${searchParams}`);
    },
    methods: {
      async nextPage(clickedTheme) {
        const { _id, name } = this.themes.find((theme) => theme.name === clickedTheme);

        await this.updateWizardPreferences({
          customThemeId: _id,
          style: name,
        });
        await this.next(null, { baseThemeId: _id, theme: name });
      },
    },
  };
</script>
<style lang="sass" scoped>
  .grid-container
    display: grid
    grid-template-columns: auto auto
    gap: 2rem
    padding-block: 3.25rem
    .grid-item
      overflow: hidden
      border-radius: 1rem
      border: 1px solid #E4E3E5
      .brand-themes-card
        display: block
        transition: all 0.25s
        border: unset
        &:hover
          transform: scale(1.06)
</style>
