<template lang="pug">
.editor-right-column
  popper(
    v-if="popoverVisible"
    trigger="click"
    :force-show="popoverVisible"
    :options="{ placement: 'left' }"
  )
    .popper.brand-tooltip.brand-tooltip-light.brand-tooltip-left.ml-n1(
      v-bind:style="{ marginTop: `${top}px` }"
    )
      component(:is="popoverComponentName" @close="closePopover")
    div(slot="reference")
  Sidebar.editor(:beta="true" :saveType="saveType" :key="sidebarKey")
</template>

<script>
  import popover from '@/editor/mixins/popover';

  export default {
    name: 'EditorComponentSettings',
    mixins: [popover],

    props: {
      defaultPane: { type: String, default: 'PagePane' },
      saveType: { type: String, default: null },
      sidebarKey: { type: String, default: null },
    },
    mounted() {
      this.$bus.$on('showSidebarPopover', this.showPopover);
      this.$bus.$on('closeSidebarPopover', this.closePopover);
    },
  };
</script>

<style lang="sass" scoped>
  .popper ::v-deep .popper__arrow
    top: 6px !important
  .editor-right-column
    overflow: visible !important
</style>
