<template lang="pug">
.editor-accordion
  Heading.editor-accordion-heading.my-3(
    v-if="$slots.title || $slots.toggle || $slots.device"
    :class="headingClasses"
    :icon="headingIcon"
    :data-pane-name="paneName"
    :data-block-name="blockName"
    @click="toggle"
  )
    slot(name="title")
    template(#titleTrailing)
      slot(name="device")
    template(#prependEnding)
      slot(name="toggle")
  .editor-accordion-content(ref="expandable" :class="contentClasses" :style="{ gap }")
    slot
    MoreOrLess(v-if="$slots.moreOrLess" :fix="fixOptions" :open="open")
      slot(name="moreOrLess")
</template>

<script>
  import Heading from '@/components/Editor/Heading.vue';
  import MoreOrLess from '@/components/Editor/MoreOrLess.vue';
  import expandMixin from '@/components/Editor/mixins/expand';

  export default {
    name: 'EditorAccordion',
    components: { Heading, MoreOrLess },
    mixins: [expandMixin],
    props: {
      fix: {
        type: Boolean,
        default: false,
      },
      fixOptions: {
        type: Boolean,
        default: false,
      },
      gap: {
        type: String,
        default: '12px',
      },
    },
    data: () => ({
      expanded: false,
      paneName: null,
      blockName: null,
    }),
    computed: {
      headingClasses() {
        if (this.fix) return;
        return 'cursor-pointer';
      },
      headingIcon() {
        if (this.fix) return;
        return this.expanded ? 'angle-up' : 'angle-down';
      },
      contentClasses() {
        if (this.fix) return 'h-100';
        return '';
      },
    },
    mounted() {
      this.$nextTick(() => {
        this.paneName =
          this.$el?.closest?.('.ds-pane')?.querySelector?.('.ds-pane-title')?.textContent || null;
        this.blockName =
          this.$el?.querySelector?.('.editor-accordion-heading')?.textContent || null;
      });
    },
    methods: {
      toggle() {
        if (this.fix) return;
        this.expanded = !this.expanded;
        this.$emit('toggle', this);
      },
    },
  };
</script>

<style lang="sass">
  .editor-accordion
    &-content
      font-size: .75rem
      transition: opacity 150ms ease-out
      display: grid
      &.h-100
        opacity: 1
</style>
