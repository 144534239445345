<template lang="pug">
.plan-details
  .row
    .col
      .name {{ getFormattedPackageName }}
  .row(:class="{ 'no-flexipay': !hasFlexiPay }")
    .col-auto
      .detail {{ isPageViewBasedPackage ? $t('payment.basicPv') : $t('payment.uniqueVisitor')}} {{ getFormattedData() }}
    .col
      .price {{ getPlanPrice }}
        template(v-if="hasValidCouponDiscountForCurrentPlan")
          om-tooltip.info-tooltip.ml-1(theme="dark" placement="top" color="#505763")
            div {{ couponDetails.couponDescription }}
            div(v-if="couponDetails.couponValidityPeriod") {{ $t('couponDetails.validityPeriod') }}:<br>{{ couponDetails.couponValidityPeriod.start }} - {{ couponDetails.couponValidityPeriod.end }}
  .row.mb-6(v-if='hasFlexiPay')
    .col-auto
      .detail FlexiPay ({{ buildFlexiPayHeader() }})
    .col
      .price
        UilInfoCircle.mx-2(v-tooltip="{content: $t('payment.amountMayChange')}")
        .raw {{ getFlexiPayTotalValue }}
  .row
    .col-auto
      .detail {{isPageViewBasedPackage ? $t('planDetailTitles.totalPageViews') : $t('planDetailTitles.totalUniqueVisitor') }} {{ getLimitResetInfoSection}}
    .col
      .total {{ getVisitorHeader }}
  .row.mb-6
    .col
      progress-bar(
        height="8px"
        :width="visitorProgressWidth"
      )
  .row
    .col
      .detail {{ isPageViewBasedPackage ? $t('activeSites') : $t('activeDomainsCount') }}
    .col
      .total {{ getDomainHeader }}
  .row.mb-6
    .col
      .progress
        .progress-bar(
          role="progressbar"
          :style="{ width: account.type === 'sub' ? 0 : (activeDomainsCount / account.limits.domains) * 100 + '%', 'background-color': '#6D9AE7' }"
          :aria-valuenow="activeDomainsCount"
          aria-valuemin="0"
          :aria-valuemax="account.limits.domains"
        )
</template>
<script>
  import { UilInfoCircle } from '@iconscout/vue-unicons';
  import ProgressBar from '@/components/Subscription/ProgressBar.vue';
  import flexiPay from '@/mixins/planSettings';
  import { mapGetters, mapState, createNamespacedHelpers } from 'vuex';
  import { REGIONS_TYPE } from '@/config/payment';
  import { formatCurrencyByRegion, separatePrice } from '@/util';

  const { mapGetters: paymentGetters, mapState: paymentState } = createNamespacedHelpers('payment');

  export default {
    components: { UilInfoCircle, ProgressBar },
    mixins: [flexiPay],
    props: {
      couponDetails: { type: Object, default: null },
    },
    computed: {
      ...mapGetters([
        'activeDomainsCount',
        'isPaying',
        'getRegion',
        'currentPlan',
        'currentPeriod',
        'isPayingBy',
      ]),
      ...mapState(['account', 'partnerTypeService']),
      ...paymentState(['flexiPayDetails', 'plans']),
      ...paymentGetters([
        'isPageViewBasedPackage',
        'isQuarterlyPeriod',
        'isOverrunRunning',
        'isOverrun',
        'getOverrunLimit',
        'getHufUsdRate',
        'maxLimit',
        'calculateCurrentOverRun',
        'getPlainPlanName',
        'isMonthlyPeriod',
        'hasFuturePackage',
        'getCurrentPeriodLanguageKey',
      ]),
      hasFlexiPay() {
        return this.isOverrun && this.getFlexiPayPrice;
      },
      getLimitResetInfoSection() {
        const { nextLimitResetDate } = this.flexiPayDetails;
        if (!nextLimitResetDate) return '';

        return `(${this.$t('limitResetInfo')} ${nextLimitResetDate})`;
      },
      getPlanPrice() {
        if (this.hasFuturePackage || !this.isPaying) return '';
        const price = this.getCurrentPackagePrice ?? null;
        if (!price || price === '0') return '';
        const { prefix, priceNumber, suffix } = separatePrice(price);
        return !priceNumber ? '' : this.formatPrice({ prefix, priceNumber, suffix });
      },
      getCurrentPackagePrice() {
        const packageName = this.currentPlan;

        const [current] = this.plans.filter(({ name }) => name === packageName);

        if (!current?.currentPrice) {
          return null;
        }

        return current.currentPrice;
      },
      getFormattedPackageName() {
        const plan = this.currentPlan;
        const planName = this.getPlainPlanName(plan);
        const formattedPlanName =
          planName.charAt(0).toUpperCase() + planName.toLowerCase().slice(1);

        if (this.isPayingBy(plan) === false) {
          return `${formattedPlanName} ${this.$t('plan.plan2')}`;
        }

        const periodKey = this.getCurrentPeriodLanguageKey;
        if (!periodKey) {
          return `${formattedPlanName} ${this.$t('plan.plan2')}`;
        }

        const periodLanguageKey = `billingCycle.${periodKey}`;
        return `${formattedPlanName} ${this.$t('plan.plan2')} (${this.$t(periodLanguageKey)})`;
      },
      getFlexiPayPrice() {
        return this.account.billing.overrunPrice ?? 0;
      },
      getFlexiPayTotalValue() {
        const {
          flexiPayTotal: { total },
        } = this.flexiPayDetails;

        if (!total || total === '0') return 0;
        const { prefix, suffix } = separatePrice(this.getCurrentPackagePrice);
        return `+ ${this.formatPrice({ prefix, priceNumber: Number(total), suffix })}`;
      },
      hasValidCouponDiscountForCurrentPlan() {
        if (!this.couponDetails?.hasValidCoupon) return false;
        const planDetails = this.getCurrentPlanDetails;
        if (!planDetails) return false;
        const periodKey = this.getCurrentPeriodLanguageKey;
        return planDetails.hasDiscount && planDetails.hasDiscount[periodKey];
      },
      getCurrentPlanDetails() {
        const current = this.plans.find(({ name }) => name === this.currentPlan);
        return current;
      },
    },
    methods: {
      addThousandSepByRegion(value) {
        return this.$options.filters.thousandSep(value, this.getRegion === 'Hungary' ? ' ' : ',');
      },
      formatPrice({ prefix, priceNumber, suffix }) {
        if (this.getRegion === 'Hungary') {
          const roundedPrice = Math.round(priceNumber);
          return `${prefix}${this.addThousandSepByRegion(roundedPrice)} ${suffix}`;
        }
        return `${prefix}${(priceNumber || 0).toLocaleString('en-US', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })} ${suffix}`;
      },
      buildFlexiPayHeader() {
        const overrunPrice = this.getFlexiPayPrice;
        const { region } = this.account.login;
        const text = this.isPageViewBasedPackage ? 'plan.extraPv' : 'plan.extraUniqueVisitor';

        return this.flexiPayHeader(overrunPrice, region, text);
      },
      flexiPayHeader(overrunPrice, region, text) {
        const price =
          region === REGIONS_TYPE.USA ? overrunPrice / this.getHufUsdRate : overrunPrice;

        return `${formatCurrencyByRegion(region, price)} / ${this.addThousandSepByRegion(
          this.getOverrunLimit,
        )} ${this.$t(text)}`;
      },
      getFormattedData() {
        return `(${this.addThousandSep(this.maxLimit)} / ${this.$t('plan.monthlyShort')})`;
      },
    },
  };
</script>
<style lang="sass" scoped>
  .plan-details
    border: 1px solid #E3E5E8
    border-radius: 8px
    padding: 24px
    overflow: visible
    .no-flexipay
      margin-bottom: 47px
    .name
      font-size: 18px
      font-weight: 700
      color: #23262A
      margin-bottom: 24px
    .detail
      font-size: 14px
      font-weight: 400
      color: #23262A
      margin-bottom: 4px
    .price
      display: flex
      justify-content: flex-end
      align-items: center
      font-weight: 700
      font-size: 14px
      svg
        align-self: center
        height: 1.3rem
        width: 1.3rem
        color: #8F97A4
    .total
      text-align: end
      font-size: 14px
    .progress
      height: 8px
</style>
