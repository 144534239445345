import { mapState, mapGetters } from 'vuex';
import pageValidationMixin from '@/editor/mixins/pageValidation';

export default {
  mixins: [pageValidationMixin],
  computed: {
    ...mapState(['hasRadioFeedback', 'campaign', 'hasCouponOnPage', 'selectedElement']),
    ...mapGetters(['isActiveShopifyDomain', 'isEmbedded']),
    actionTypes() {
      const types = [
        { key: 'jumpToPage', value: 'jumpToPage' },
        { key: 'redirect', value: 'redirect' },
        { key: 'dial', value: 'dial' },
      ];

      if (!this.isEmbedded) {
        types.push({ key: 'closePopup', value: 'closePopup' });
      }

      if (this.selectedElement.type === 'OmButton' && this.hasRadioFeedback) {
        types.push({ key: 'basedOnFeedback', value: 'basedOnFeedback' });
      }

      if (this.selectedElement.type === 'OmButton' && this.hasCouponOnPage) {
        types.push({ key: 'copyCoupon', value: 'copyCoupon' });
      }

      if (!this.isNextPageValid) {
        return types;
      }

      const nextPopupType = { value: 'nextPopup', key: 'nextPopup' };
      return [...types, nextPopupType];
    },
  },
};
