<template lang="pug">
.ds-input-common-fields
  slot(name="placeholder")
    PaneLayout(gap="4px")
      template(#left)
        .input-label {{ $t('placeholder') }}
      template(#right)
        OmInput#inputPlaceholder(small v-model="placeholder")
  .mt-3(v-if="$slots.afterPlaceholder")
    slot(name="afterPlaceholder")
  Heading.mt-3(label v-if="selectedElementType !== 'survey'") {{ $t('inputRequired') }}
    template(#prependEnding)
      OmSwitch#inputRequired(:value="required" @switch="required = $event")
  template(v-if="selectedElement.data.required")
    PaneLayout.mt-4(gap="4px")
      template(#left)
        .input-label {{ $t('errorMessage') }}
      template(#right)
        OmInput#inputErrorMessage(small v-model="errorMessage")
  template(v-if="selectedElementType === 'date'")
    Dropdown#dateFormat.mt-4(
      layout="row"
      :label="$t('dateFormat')"
      :items="dateFormatTypes"
      v-model="dateFormat"
      customClass="full-width"
    )
  template(v-if="selectedElementType === 'phoneNumber'")
    PaneLayout#country-block.mt-3(gap="4px")
      template(#left) {{ $t('defaultCountry') }}
      template(#right)
        Dropdown#country(
          :items="countryData"
          v-model="selectedCountry"
          searchable
          customClass="full-width"
        )
      template(#help v-if="selectedElement.data.phoneDefaultCountry === 'auto'")
        span {{ $t('phoneCountryAutoDetectTooltip') }}
</template>
<script>
  import { mapState } from 'vuex';
  import PaneLayout from '@/components/Editor/PaneLayout.vue';
  import Heading from '@/components/Editor/Heading.vue';
  import Dropdown from '@/components/Editor/Dropdown/Dropdown.vue';
  import itemMixin from '@/editor/mixins/item';

  export default {
    components: { PaneLayout, Heading, Dropdown },
    mixins: [itemMixin],
    data: () => ({
      hungarian: null,
      countryData: [],
    }),
    computed: {
      ...mapState(['selectedElement']),
      selectedElementType() {
        return this.selectedElement.data.form.customSettings.type;
      },
      dateFormatTypes() {
        const { flatpickr, hungarian } = this;
        if (!flatpickr) return;
        // needed so if the user changes global language, we can load back the format
        const formatByLocale = {
          hu: {
            dMY: 'Y. M. d.',
            dFY: 'Y. F d.',
            Ymd: 'Y-m-d',
            DJMY: 'Y. M. j. D.',
          },
          en: {
            dMY: 'd M Y',
            dFY: 'd F Y',
            Ymd: 'Y-m-d',
            JMY: 'J M Y',
            DJMY: 'D J M Y',
          },
        };

        const formatDate = (f) => {
          return this.$i18n.locale === 'hu'
            ? flatpickr.formatDate(new Date(), f, hungarian).toLowerCase()
            : flatpickr.formatDate(new Date(), f);
        };

        const result = [];

        Object.keys(formatByLocale.en).forEach((f) => {
          if (formatByLocale[this.$i18n.locale].hasOwnProperty(f)) {
            result.push({
              text: formatDate(formatByLocale[this.$i18n.locale][f]),
              value: f,
            });
          }
        });

        return result;
      },
      placeholder: {
        get() {
          return this.getValueOf('data.epicPlaceholder');
        },
        set(v) {
          this.setValueOf('data.epicPlaceholder', v);
        },
      },
      required: {
        get() {
          return this.getValueOf('data.required');
        },
        set(v) {
          this.setValueOf('data.required', v);
        },
      },

      errorMessage: {
        get() {
          return this.getValueOf('data.errorMessage');
        },
        set(v) {
          this.setValueOf('data.errorMessage', v);
        },
      },

      dateFormat: {
        get() {
          return this.getValueOf('data.format');
        },
        set(v) {
          this.setValueOf('data.format', v);
        },
      },

      selectedCountry: {
        get() {
          return this.getValueOf('data.phoneDefaultCountry');
        },
        set(v) {
          this.setValueOf('data.phoneDefaultCountry', v);
        },
      },
    },
    watch: {
      selectedElementType() {
        this.setCountryData();
        this.setEmailRequired();
      },
    },
    mounted() {
      this.loadFlatpickr();
      this.setCountryData();
    },
    methods: {
      async loadFlatpickr() {
        const [{ default: flatpickr }, { Hungarian }] = await Promise.all([
          import('flatpickr'),
          import('flatpickr/dist/l10n/hu'),
        ]);
        this.flatpickr = flatpickr;
        this.hungarian = Hungarian;
      },
      async setCountryData() {
        if (this.selectedElementType === 'phoneNumber' || this.selectedElementType === 'country') {
          const [{ default: intlHuCountries }] = await Promise.all([
            import('@om/editor-ssr-shared/src/config/intl_countries_hu.json'),
            import('intl-tel-input'),
          ]);
          this.countryData = [
            { text: 'Auto', value: 'auto' },
            ...window.intlTelInputGlobals.getCountryData().map((c) => ({
              text: this.$i18n.locale === 'hu' ? intlHuCountries[c.iso2] : c.name,
              value: c.iso2,
            })),
          ];
        }
      },
      setEmailRequired() {
        if (this.selectedElementType === 'email') {
          this.setValueOf('data.required', true);
        }
      },
    },
  };
</script>

<style scoped lang="sass" scoped>
  @import '@/sass/variables/_colors.sass'

  .input-label
    color: $om-dark-grey-3
    font-size: 0.75rem
</style>
