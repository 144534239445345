<template lang="pug">
.wrapper.brand-auth.brand-registration(
  v-if="!isWhitelabelDomain && isLoggedInChecked"
  :class="{ 'is-hu': isHu }"
)
  .row.d-flex.m-0.w-100
    .col-12.brand-wrapper-right.brand-registration-wrapper-right.flex-column.mt-6
      .mx-auto.text-center.mt-6
        .font-weight-bold.font-size-2.line-height-1--2.mt-6 {{ getTitle }}
        .text-center.px-4.mt-4.font-size-1--125.subtitle(
          v-if="$route.params.type !== 'email'"
          v-html="getSubtitle"
        )
      .w-24.mx-auto.text-center
        template(v-if="!$route.params.type")
          om-button#sign-up-google.mt-5(block secondary large @click="register('google')") {{ $t('registration.signUpWith', { provider: 'Google' }) }}
            template(#icon)
              google-svg.sign-up-icon.mr-2
          om-button#sign-up-shopify.mt-4(
            v-if="isEn"
            block
            secondary
            large
            @click="registerWithShopify"
          ) {{ $t('registration.signUpWith', { provider: 'Shopify' }) }}
            template(#icon)
              inline-svg.sign-up-icon.mr-2(
                :src="require('@/assets/admin/svg/platforms/shopify.svg')"
              )

          om-button#sign-up-email.mt-4(block primary large @click="registerWithEmail") {{ $t('registration.signUpWitEmail') }}
          .font-weight-500.font-size-0--875(:class="{ 'py-2': isHu, 'pt-6 pb-0': !isHu }")
            span.pr-1 {{ $t('registration.haveAccount') }}
            router-link.brand-link.font-weight-500(
              :to="{ name: 'login', params: { lang: $route.params.lang } }"
              tag="a"
            ) {{ $t('registration.login') }}
        template(v-if="$route.params.type === 'email'")
          form.mb-0.mt-4
            template(v-if="isEn")
              .row(style="height: 72px")
                .form-group.col-12.col-md-6.mb-4.pr-2(style="height: 56px")
                  input#firstName.form-control.form-control-lg.text-indent-sm(
                    v-model.trim="firstName"
                    :class="{ 'is-invalid': $v.firstName.$error }"
                    :placeholder="$t('firstName')"
                    autocomplete="given-name"
                    style="height: 56px"
                  )
                  .text-danger.text-left.font-size-0--8125(
                    v-if="$v.firstName.$error && !$v.firstName.required"
                  ) {{ $t('requiredField') }}
                  .text-danger.text-left.font-size-0--8125(
                    v-if="$v.firstName.$error && !$v.firstName.notUrl"
                  ) {{ $t('notValidFormat') }}
                  .text-danger.text-left.font-size-0--8125(
                    v-if="$v.firstName.$error && !$v.firstName.maxLength"
                  ) {{ $t('firstNameTooLong', { length: $v.firstName.$params.maxLength.max }) }}
                .form-group.col-12.col-md-6.pl-2
                  input#lastName.form-control.form-control-lg.text-indent-sm(
                    v-model.trim="lastName"
                    :class="{ 'is-invalid': $v.lastName.$error }"
                    :placeholder="$t('lastName')"
                    autocomplete="family-name"
                    style="height: 56px"
                  )
                  .text-danger.text-left.font-size-0--8125(
                    v-if="$v.lastName.$error && !$v.lastName.required"
                  ) {{ $t('requiredField') }}
                  .text-danger.text-left.font-size-0--8125(
                    v-if="$v.lastName.$error && !$v.lastName.notUrl"
                  ) {{ $t('notValidFormat') }}
                  .text-danger.text-left.font-size-0--8125(
                    v-if="$v.lastName.$error && !$v.lastName.maxLength"
                  ) {{ $t('lastNameTooLong', { length: $v.lastName.$params.maxLength.max }) }}
            template(v-else)
              .row
                .form-group.col-12.col-md-6
                  input#lastName.form-control.text-indent-sm(
                    v-model.trim="lastName"
                    :class="{ 'is-invalid': $v.lastName.$error }"
                    :placeholder="$t('lastName')"
                    autocomplete="family-name"
                    style="height: 56px"
                  )
                  .text-danger.text-left.font-size-0--8125(
                    v-if="$v.lastName.$error && !$v.lastName.required"
                  ) {{ $t('requiredField') }}
                  .text-danger.text-left.font-size-0--8125(
                    v-if="$v.lastName.$error && !$v.lastName.notUrl"
                  ) {{ $t('notValidFormat') }}
                  .text-danger.text-left.font-size-0--8125(
                    v-if="$v.lastName.$error && !$v.lastName.maxLength"
                  ) {{ $t('lastNameTooLong', { length: $v.lastName.$params.maxLength.max }) }}

                .form-group.col-12.col-md-6
                  input#firstName.form-control.text-indent-sm(
                    v-model.trim="firstName"
                    :class="{ 'is-invalid': $v.firstName.$error }"
                    :placeholder="$t('firstName')"
                    autocomplete="given-name"
                    style="height: 56px"
                  )
                  .text-danger.text-left.font-size-0--8125(
                    v-if="$v.firstName.$error && !$v.firstName.required"
                  ) {{ $t('requiredField') }}
                  .text-danger.text-left.font-size-0--8125(
                    v-if="$v.firstName.$error && !$v.firstName.notUrl"
                  ) {{ $t('notValidFormat') }}
                  .text-danger.text-left.font-size-0--8125(
                    v-if="$v.firstName.$error && !$v.firstName.maxLength"
                  ) {{ $t('firstNameTooLong', { length: $v.firstName.$params.maxLength.max }) }}
            .form-group.row.mb-3
              .col-12
                input#email.form-control.text-indent-sm.mb-1(
                  v-model.trim="email"
                  :class="{ 'is-invalid': $v.email.$error, 'form-control-lg': isEn }"
                  :placeholder="$t('emailAddress')"
                  autocomplete="email"
                  style="height: 56px"
                )
                .text-danger.text-left.font-size-0--8125(
                  v-if="$v.email.$error && !$v.email.required"
                ) {{ $t('requiredField') }}
                .text-danger.text-left.font-size-0--8125(
                  v-if="$v.email.$error && !$v.email.isCoolEmail"
                ) {{ $t('validEmail') }}
                .mb-3
                label.email-info.col-12.align-items-center.font-size-0--75.font-weight-400 {{ $t('registration.emailInfo') }}
              .col-12.font-size-0--8125.flex-grow.pr-2.mt-1.text-danger.text-left(
                v-if="emailAlreadyExists"
                v-html="$t('registration.emailAlreadyExists', { email: this.email })"
              )
              .col-12.font-size-0--8125.flex-grow.pr-2.mt-1.text-danger.text-left(
                v-if="emailInvalid"
                v-html="$t('registration.emailInvalid')"
              )
            .form-group.row.mb-6
              .col-12
                input#password.form-control.text-indent-sm.mb-1(
                  type="password"
                  v-model.trim="password"
                  :class="{ 'is-invalid': $v.password.$error, 'form-control-lg': isEn }"
                  :placeholder="`${$t('password')} ${$t('registration.passwordLength')}`"
                  autocomplete="new-password"
                )
                .text-danger.text-left.font-size-0--8125(
                  v-if="$v.password.$error && !$v.password.required"
                ) {{ $t('requiredField') }}
                .text-danger.text-left.font-size-0--8125(
                  v-if="$v.password.$error && !$v.password.minLength"
                ) {{ $t('passwordLength', { length: $v.password.$params.minLength.min }) }}
                .text-danger.text-left.font-size-0--8125(
                  v-if="$v.password.$error && !$v.password.maxLength"
                ) {{ $t('passwordTooLong', { length: $v.password.$params.maxLength.max }) }}
                .d-flex.align-items-center.row.mt-6
                  .col-12.col-sm-4.password-strength-meter-title.d-flex.font-size-0--75.flex-grow.pr-2 {{ $t('passwordStrength') }}
                  .col-12.col-sm-8.password-strength-meter
                    PasswordMeter(v-model="password" :strength-meter-only="true" :secureLength="7")
            .d-flex.justify-content-center.align-items-center(:class="isHu ? 'mt-3' : 'mt-4'")
              invisible-recaptcha#get-started(
                :class="getButtonClasses"
                :sitekey="siteKey"
                :callback="register"
                :validate="validate"
                v-if="siteKey"
              ) {{ $t('registration.getStarted') }}
              om-button(primary :large="isEn" block v-else @click.prevent="register()") {{ $t('registration.getStarted') }}
            .font-weight-500.font-size-0--875(:class="{ 'py-2': isHu, 'pt-6 pb-0': !isHu }")
              span.pr-1 {{ $t('registration.haveAccount') }}
              router-link.brand-link.font-weight-500(
                :to="{ name: 'login', params: { lang: $route.params.lang } }"
                tag="a"
              ) {{ $t('registration.login') }}
        .brand-registration-privacy-policy.font-size-0--875(
          v-html="$t('registration.termsAndConditionsAndPrivacyLinkV2', { link: $t('termsAndConditionsLink') })"
          :class="isHu ? 'mt-3' : 'mt-5'"
        )
        .d-sm-none.brand-auth-link-bottom.col-12.text-center.align-self-start.py-3
          .font-weight-500.py-4
            span.pr-1 {{ $t('registration.haveAccount') }}
            router-link.brand-link.font-weight-500(
              :to="{ name: 'login', params: { lang: $route.params.lang } }"
              tag="a"
            ) {{ $t('registration.login') }}
</template>

<script>
  import * as Cookie from 'js-cookie';
  import { required, email, minLength, maxLength } from 'vuelidate/lib/validators';
  import { validateDomain, setAccountIdInCookie, pushDataLayer } from '@/util';
  import REGISTER from '@/graphql/Register.gql';
  import GET_ABANDONED_REG from '@/graphql/GetAbandonedReg.gql';
  import InvisibleRecaptcha from 'vue-invisible-recaptcha';
  import queryParamToCookie from '@/mixins/queryParamToCookie';
  import cookieReferrer from '@/mixins/cookieReferrer';
  import guessLang from '@/mixins/guessLang';
  import whiteLabelling from '@/mixins/whiteLabelling';
  import { mapMutations, mapGetters } from 'vuex';
  import { apiBaseUrl, isWhitelabelDomain } from '@/config/url';
  import PasswordMeter from 'vue-password-strength-meter';
  import iosViewportFix from '@/mixins/iosViewportFix';
  import runtimeConfig from '@/config/runtime';
  import IS_AGENCY_AFFILIATE from '@/graphql/IsAgencyAffiliate.gql';
  import templateCreate from '@/mixins/templateCreate';
  import { getBrandedClassString } from '@/components/Elements/Button';
  import authCheckMixin from '@/mixins/auth-check';
  import { accountSetupRouteNames } from '@/router/routes/accountSetupRoutes';
  import Background from '@/editor/components/sidebar/components/Background.vue';

  const backendUrl = apiBaseUrl;
  const siteKey = runtimeConfig.VUE_APP_RECAPTCHA_SITE_KEY;

  export default {
    name: 'RegistrationV2',
    components: {
      Background,
      'invisible-recaptcha': InvisibleRecaptcha,
      PasswordMeter,
    },
    mixins: [
      queryParamToCookie,
      guessLang,
      cookieReferrer,
      whiteLabelling,
      iosViewportFix,
      templateCreate,
      authCheckMixin,
    ],

    data() {
      return {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        language: 'en',
        partner: null,
        siteKey,
        emailAlreadyExists: false,
        emailInvalid: false,
        privacyPolicyBox: true,
        isAgencyAffiliate: false,
        isLoggedInChecked: false,
      };
    },
    computed: {
      ...mapGetters(['isMobile']),
      getButtonClasses() {
        return getBrandedClassString({ primary: true, large: this.isEn }, [
          'w-100',
          'design-system',
        ]);
      },

      getTitle() {
        let key = 'registration.titleV2';

        if (this.partner === 'agency') {
          key = 'registration.titleAgency';
        } else if (this.partner === 'affiliate') {
          key = 'registration.titleAffiliate';
        } else if (this.isAgencyAffiliate) {
          return this.$t('registration.agencyAffiliateInvite', { name: this.agencyAffiliateName });
        }

        return this.$t(key);
      },
      getSubtitle() {
        return this.isAgencyAffiliate
          ? this.$t('registration.agencyAffiliateSubtitle', { name: this.agencyAffiliateName })
          : this.$t('registration.agencyAffiliateSubtitleV2');
      },
      isHu() {
        return this.$i18n.locale === 'hu';
      },
      isEn() {
        return this.$i18n.locale === 'en';
      },
    },

    watch: {
      email(email) {
        if (email) {
          this.emailAlreadyExists = false;
          this.emailInvalid = false;
        }
      },
    },

    validations: {
      firstName: {
        required,
        notUrl(v) {
          return !validateDomain(v);
        },
        maxLength: maxLength(30),
      },
      lastName: {
        required,
        notUrl(v) {
          return !validateDomain(v);
        },
        maxLength: maxLength(30),
      },
      email: {
        isCoolEmail(v) {
          return email(v.toLowerCase());
        },
        required,
      },
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(32),
      },
      privacyPolicyBox: {
        required,
        checked(val) {
          return val;
        },
      },
    },

    async created() {
      const isRedirected = await this.verifyUserAuthAndViewport();
      if (isRedirected) {
        return;
      }
      this.isLoggedInChecked = true;

      if (!isWhitelabelDomain()) {
        document.title = this.$t('titles.register');
      }

      this.language = this.guessLanguage();
      this.$i18n.locale = this.language;
      if (this.isWhitelabelDomain) {
        this.$router.replace({ name: 'login', params: { lang: this.language } });
        return;
      }

      if (!this.$route.params.lang) {
        this.$router.replace({ name: 'registration', params: { lang: this.language } });
        return;
      }

      const partnerId = this.$route.query.p_id || Cookie.get('customer_from_aa');

      if (partnerId) {
        const { data } = await this.$apollo.query({
          query: IS_AGENCY_AFFILIATE,
          variables: {
            partnerId,
          },
        });

        if (data.isAgencyAffiliate) {
          const { isAgencyAffiliate, name } = data.isAgencyAffiliate;

          this.isAgencyAffiliate = isAgencyAffiliate;
          this.agencyAffiliateName = name;
        }
      }
    },

    mounted() {
      const self = this;
      const {
        partner,
        continue: abandonedHash,
        email,
        business_email: businessEmail,
      } = this.$route.query;

      if (partner) {
        this.partner = partner.toLowerCase();
      }

      this.$gtm.trackEvent({
        event: 'Trial start',
        region: this.isHu ? 'Hungary' : 'USA',
        partner: this.partner,
      });

      if (abandonedHash) {
        this.$apollo
          .query({
            query: GET_ABANDONED_REG,
            variables: {
              hash: abandonedHash,
            },
          })
          .then(({ data: { abandoned } }) => {
            if (abandoned.email) {
              self.email = abandoned.email;
            }
          });
      }

      if (email) {
        try {
          this.email = atob(email);
        } catch (e) {
          this.email = '';
        }
      }

      if (businessEmail) {
        this.email = businessEmail;
      }
    },

    methods: {
      ...mapMutations(['showAdminLoader']),
      registerWithShopify() {
        window.location.href = 'https://apps.shopify.com/optimonk/?auth=1';
      },
      registerWithEmail() {
        const route = {
          query: this.$route.query,
          name: this.$route.name,
          params: {
            ...this.$route.params,
            type: 'email',
          },
        };
        this.$router.push(route);
      },
      register(type = 'normal') {
        const language = this.guessLanguage();

        if (type === 'facebook' || type === 'google') {
          window.location.href = `${backendUrl}/login/${type}?lang=${language}&register=true`;
        } else {
          this.$v.$touch();
          if (this.$v.$invalid) {
            this.showAdminLoader(false);
            this.$notify({
              type: 'error',
              text: this.$t('notifications.validationError'),
            });
          } else {
            if (!this.siteKey) {
              this.showAdminLoader(true);
            }

            this.$apollo
              .mutate({
                mutation: REGISTER,
                variables: {
                  input: {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    email: this.email.toLowerCase(),
                    password: this.password,
                    partner: this.partner,
                    region: language === 'hu' ? 'Hungary' : 'USA',
                    recaptcha: type,
                  },
                },
              })
              .then(({ data: { register } }) => {
                this.showAdminLoader(false);
                if (register.success) {
                  const userId = register.dataAccountId;
                  setAccountIdInCookie(userId);
                  this.$store.dispatch('login', {
                    expiration: register.expiration,
                    accountType: register.accountType,
                    locale: register.locale,
                  });
                  this.$gtm.trackEvent({
                    event: 'Trial end',
                    region: this.isHu ? 'Hungary' : 'USA',
                    partner: this.partner,
                  });
                  pushDataLayer({ event: 'sign_up' });

                  this.$router.replace({
                    name:
                      this.isMobile && this.partner !== 'affiliate'
                        ? 'mobile_redirect'
                        : accountSetupRouteNames.WELCOME,
                    params: { userId },
                  });
                } else if (register.message === 'emailAlreadyExists') {
                  this.emailAlreadyExists = true;
                } else if (register.message === 'emailValidationFailed') {
                  this.emailInvalid = true;
                } else {
                  const transKey = `backendMessages.${register.message}`;
                  this.$notify({
                    type: 'error',
                    text:
                      register.message && this.$t(transKey)
                        ? this.$t(transKey)
                        : this.$t('notifications.registerError'),
                  });
                }
              });
          }
        }
      },
      validate() {
        this.showAdminLoader(true);
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import '../sass/variables/_colors.sass'

  .brand-registration
    height: 100vh
    background-size: cover !important
    background: url('../assets/admin/img/registration-bg-v2.png') no-repeat left
    background-position-y: top

    .form-control
      border-radius: 8px
    .subtitle
      color: #5C5266
      width: 80%
      margin: auto

    @media (max-width: 1920px) and (min-width: 1541px)
      background-size: cover !important
      background: url('../assets/admin/img/registration-bg-v2.png') no-repeat left
      background-position-y: top

    @media (max-width: 1540px) and (min-width: 901px)
      background-size: cover !important
      background: url('../assets/admin/img/registration-bg-v2-1440.png') no-repeat left
      background-position-y: top

    @media (max-width: 900px)
      background-size: cover !important
      background: url('../assets/admin/img/registration-bg-v2-800.png') no-repeat left
      background-position-y: top

    .password-strength-meter::v-deep
      .Password__strength-meter
        height: 4px

    .bottom-bg
      pointer-events: none
      z-index: -1
      display: none
      position: fixed
      bottom: 0
      width: 100%
      height: auto

    .sign-up-icon
      width: 20px
      height: auto

    .brand-registration-privacy-policy
      width: 75%
      margin: auto
      color: #A19DA6 !important

      a,
      a:active,
      a:visited,
      a:hover
        color: #A19DA6 !important
        text-decoration: underline !important



    #sign-up-google
      border-radius: 8px
      color: #2673E4
      border-color: #2673E4

      &:hover
        background: #DEE4ED

      &:active
        background: #C3CEDF
    #sign-up-email,#get-started
      border-radius: 8px
      background: linear-gradient(to bottom, #ED5A29, #ED825F)

    #sign-up-shopify
      border-radius: 8px
      color: #5E8E3E
      border-color: #5E8E3E

      &:hover
        background: #E4EDDE

      &:active
        background: #CEDFC3

    .email-info
      color: $om-gray-600
      margin-bottom: 0
      width: 90%

    @media (min-width: 767px)
      .bottom-bg
        display: block
</style>
