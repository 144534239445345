<template lang="pug">
BasePane.class-for-product-tour(:showAdvanced="isNewTeaser")
  template(#title)
    sidebar-title {{ $t('teaserPane') }}
  om-info-box#teaser-info-box.mt-5(
    v-if="showTeaserInfoBox"
    :title="$t('infoBox.teaserTitle')"
    :text="$t('infoBox.teaserText')"
    :learnMoreUrl="$t('infoBox.teaserLearnMoreUrl')"
  )
  Accordion(fix)
    template(#title) {{ $t('showTeaser') }}
    .old-teaser-disabled-box(v-if="!isNewTeaser && !isTeaserEnabled")
      .icon-warning
        UilExclamationTriangle
      .old-teaser-disabled-box-label {{ $t('teaserDisabledWarning') }}
    .d-flex.justify-space-between
      .sidebar-label {{ $t('before') }}
      OmSwitch#teaser-show-before.ml-auto(:value="showBefore" @switch="showBefore = $event")
    .old-teaser-help(v-if="!isNewTeaser") {{ $t('beforeTooltip') }}
    transition(name="fade")
      RangeInput#teaser-show-before-sec(
        v-if="showBefore && isNewTeaser"
        :label="$t('teaserTriggering.appearAfterPageLoad')"
        :max="60"
        :step="1"
        :unit="$t('teaserSec')"
        v-model="showBeforeSec"
      )
    .d-flex.justify-space-between
      .sidebar-label {{ $t('after') }}
      OmSwitch#teaser-show-after.ml-auto(:value="showAfter" @switch="showAfter = $event")
    transition(name="fade")
      RangeInput#teaser-show-before-sec(
        v-if="showAfter && isNewTeaser"
        :label="$t('teaserTriggering.appearAfter')"
        :max="60"
        :step="1"
        :unit="$t('teaserSec')"
        v-model="showAfterSec"
      )
    template(v-if="!isNewTeaser")
      .sidebar-label {{ $t('display') }}
      .flex-row-baseline.main-graphic-select
        .flex-column-3
          .graphic-item(:class="{ 'graphic-selected-item': oldTeaserType === 'corner' }")
            .graphic-select.graphic-select-tab.graphic-select-tab-corner(
              @click="oldTeaserType = 'corner'"
            )
          span.graphic-label {{ $t('corner') }}
        .flex-column-3
          .graphic-item(:class="{ 'graphic-selected-item': oldTeaserType === 'basic' }")
            .graphic-select.graphic-select-tab.graphic-select-tab-rectangle(
              @click="oldTeaserType = 'basic'"
            )
          span.graphic-label {{ $t('rectangle') }}
        .flex-column-3
          .graphic-item(:class="{ 'graphic-selected-item': oldTeaserType === 'floating' }")
            .graphic-select.graphic-select-tab.graphic-select-tab-circle(
              @click="oldTeaserType = 'floating'"
            )
          span.graphic-label {{ $t('circle') }}
    template(v-else)
      transition(name="fade")
        .ds-teaser-device-selector(v-if="showBefore || showAfter")
          .sidebar-label {{ $t('teaserDeviceChooser') }}
          .ds-teaser-device-selector.d-flex.flex-row.mt-2
            .ds-teaser-device-option.cursor-pointer(
              v-for="deviceOption in deviceOptions"
              :class="{ selected: device === deviceOption.key }"
              @click="device = deviceOption.key"
            )
              .d-flex.flex-row.justify-content-center.mt-2
                .ds-teaser-device-icon(v-for="icon in deviceOption.icon")
                  component(:is="icon" :size="deviceOption.size")
              .ds-teaser-device-text.text-center.mt-2 {{ $t(deviceOption.name) }}
  hr.mt-3.mb-4
  Accordion(fix)
    template(#title) {{ $t('teaserPosition') }}
    PaneLayout(layout="col" grow)
      template(#left)
        .d-flex.align-items-start
          .d-flex.align-items-center
            div {{ $t('position') }}
            DeviceSelector(:hasViewText="false")
      template(#right)
        .d-flex.justify-content-center
          template(v-if="isNewTeaser")
            PositionSvg(
              v-if="!mobilePreview"
              property="selectedPage.data.position"
              bgFill="#dedede"
            )
            PositionSvgMobile(v-else property="selectedPage.data.mobilePosition")
          PositionSvg(v-else property="globalStyle.tab.position" bgFill="#dedede")
  hr.mt-4.mb-4
  Accordion(fix)
    template(#title) {{ $t('sizeAndSpacing') }}
    Margin(
      v-if="isNewTeaser"
      :labelOverride="$t('distanceFromTheEdge')"
      :disabled="disabledMargins"
      deviceSelector
    )
    RangeInput#teaser-old-size(
      v-if="!isNewTeaser"
      :label="$t('size')"
      :min="60"
      :max="150"
      :step="1"
      v-model.number="oldTeaserSize"
    )
    RangeInput#teaser-width(
      v-if="isNewTeaser"
      :label="$t('width')"
      :min="15"
      :max="teaserWidthMax"
      :step="1"
      :unit="mobilePreview ? '%' : 'px'"
      v-model.number="teaserWidth"
    )
    RangeInput#teaser-height(
      v-if="isNewTeaser"
      :label="$t('minHeight')"
      :min="40"
      :max="250"
      :step="1"
      v-model.number="teaserMinHeight"
    )
    PaneLayout(layout="col" v-if="isNewTeaser")
      template(#left)
        .d-flex.align-items-center {{ $t('contentAlignment') }}
      template(#right)
        Dropdown#teaser-content-alig(v-model="alignment" :items="alignmentItems")
    Padding(v-if="isNewTeaser")
  hr.mt-3.mb-4
  BackgroundAndBorder(v-if="isNewTeaser" typeOfComponent="global" :deviceSelector="false")
  Accordion(v-else fix)
    template(#title) {{ $t('backgroundAndBorder') }}
    OmColorInput(
      :label="$t('background')"
      typeOfComponent="global"
      property="globalStyle.tab.background.color"
      layout="col"
      dsAllowed
    )
    Border(
      typeOfComponent="global"
      selectedProperty="globalStyle.tab.border.selectedBorder"
      typeProperty="globalStyle.tab.border.type"
      widthProperty="globalStyle.tab.border.width"
      coloringProperty="globalStyle.tab.border.color"
    )
    Shadow(
      typeOfComponent="global"
      v-model="shadowComputed"
      coloringProperty="globalStyle.tab.shadow.color"
    )
  hr.mt-1.mb-4
  Accordion(fix)
    template(#title) {{ $t('animation') }}
    transition(name="fade")
      PaneLayout(v-if="!(isNewTeaser && !teaserPreview)" layout="col")
        template(#left) {{ $t('switchFromCampaign') }}
        template(#right)
          Dropdown#teaser-switch-animation(
            :items="switchFromCampaignTypes"
            v-model="switchFromCampaign"
          )
    PaneLayout(layout="col")
      template(#left) {{ $t('attentionSeeker') }}
      template(#right)
        Dropdown#teaser-attention-seeker-type(
          :items="attentionSeekers"
          v-model="attentionSeekerType"
        )
    transition(name="fade")
      RangeInput(
        v-if="attentionSeekerType"
        :label="$t('attentionSeekerFreq')"
        :min="3"
        :max="10"
        :step="1"
        unit="sec"
        v-model.number="seekerFrequency"
      )
  hr.mt-3.mb-4(v-if="isNewTeaser")
  template(v-if="isSteveMode || isSuperAdmin" #afterAdvanced)
    hr.sidebar-spacing-y
    OmButton(v-if="!isNewTeaser" primary block @click="migrateTeaser") {{ $t('convertTeaser.oldToPage') }}
    OmButton(v-else primary block @click="revertTeaser") {{ $t('convertTeaser.pageToOld') }}
    OmButton(v-if="isNewTeaser && isSteveMode" secondary block @click="toggleTeaserVersion") {{ $t('convertTeaser.toggleVersion') }}
</template>

<script>
  import { mapGetters, mapMutations, mapState } from 'vuex';
  import { get } from 'lodash-es';
  import { TEMPLATE_FEATURES } from '@/editor/util';
  import { PERMANENT_TEASER } from '@/utils/features';
  import BasePane from '@/editor/components/sidebar/panes/level2/BasePane.vue';
  import Accordion from '@/components/Editor/Accordion/Accordion.vue';
  import RangeInput from '@/components/Editor/RangeInput/RangeInput.vue';
  import Margin from '@/components/Editor/Controls/InputGroup/Margin.vue';
  import itemMixin from '@/editor/mixins/item';
  import NewDesktopSvg from '@/editor/components/svg/NewDesktopSVG';
  import NewMobileSvg from '@/editor/components/svg/NewMobileSVG';
  import { UilExclamationTriangle } from '@iconscout/vue-unicons';
  import PaneLayout from '@/components/Editor/PaneLayout.vue';
  import PositionSvg from '@/editor/components/svg/PositionSvg.vue';
  import PositionSvgMobile from '@/editor/components/svg/PositionSvgMobile.vue';
  import Padding from '@/components/Editor/Controls/InputGroup/Padding.vue';
  import DeviceSelector from '@/editor/components/sidebar/components/DeviceSelector.vue';
  import BackgroundAndBorder from '@/components/Editor/Blocks/BackgroundAndBorder.vue';
  import Dropdown from '@/components/Editor/Dropdown/Dropdown.vue';
  import { attentionSeekers as attentionSeekerTypes } from '@/editor/config/animation';
  import Border from '@/components/Editor/Controls/Border.vue';
  import Shadow from '@/components/Editor/Controls/Shadow.vue';

  export default {
    components: {
      BasePane,
      Accordion,
      RangeInput,
      Margin,
      NewDesktopSvg,
      NewMobileSvg,
      UilExclamationTriangle,
      PaneLayout,
      PositionSvg,
      PositionSvgMobile,
      DeviceSelector,
      Padding,
      BackgroundAndBorder,
      Dropdown,
      Border,
      Shadow,
    },
    mixins: [itemMixin],
    data: () => ({
      TEMPLATE_FEATURES,
      deviceOptions: [
        {
          key: 'all',
          name: 'teaser.device.all',
          icon: [NewDesktopSvg, NewMobileSvg],
          size: 20,
        },
        {
          key: 'desktop',
          name: 'teaser.device.desktop',
          icon: [NewDesktopSvg],
          size: 20,
        },
        {
          key: 'mobile',
          name: 'teaser.device.mobile',
          icon: [NewMobileSvg],
          size: 20,
        },
      ],
    }),
    computed: {
      ...mapState(['account', 'data', 'teaserPreview', 'mobilePreview']),
      ...mapGetters([
        'teaserPage',
        'hasAccountFeature',
        'hasTemplateFeature',
        'isSteveMode',
        'isSuperAdmin',
      ]),
      isNewTeaser() {
        return this.hasTemplateFeature(TEMPLATE_FEATURES.NEW_TEASER) && !this.teaserPreview;
      },
      isTeaserEnabled() {
        const { before, after } = this.isNewTeaser
          ? this.teaserPage.data.display
          : this.getValueOf('globalStyle.tab.display');

        return before || after;
      },
      dataPropertyPrefix() {
        return this.isNewTeaser ? 'selectedPage.data' : 'globalStyle.tab';
      },
      showBefore: {
        get() {
          return this.getValueOf(`${this.dataPropertyPrefix}.display.before`);
        },
        set(value) {
          this.setValueOf(`${this.dataPropertyPrefix}.display.before`, value);
        },
      },
      showBeforeSec: {
        get() {
          return Number(this.getValueOf(`${this.dataPropertyPrefix}.display.afterPageLoadValue`));
        },
        set(value) {
          this.setValueOf(`${this.dataPropertyPrefix}.display.afterPageLoadValue`, Number(value));
        },
      },
      showAfter: {
        get() {
          return this.getValueOf(`${this.dataPropertyPrefix}.display.after`);
        },
        set(value) {
          this.setValueOf(`${this.dataPropertyPrefix}.display.after`, value);
        },
      },
      showAfterSec: {
        get() {
          return Number(this.getValueOf(`${this.dataPropertyPrefix}.display.afterValue`));
        },
        set(value) {
          this.setValueOf(`${this.dataPropertyPrefix}.display.afterValue`, Number(value));
        },
      },
      disabledMargins() {
        const INDEXES = {
          TOP: 0,
          BOTTOM: 2,
          LEFT: 3,
          RIGHT: 1,
        };
        const sides = [true, true, true, true];

        const position = this.mobilePreview
          ? this.teaserPage.data.mobilePosition
          : this.teaserPage.data.position;

        const isMobileTopOrBottom = this.mobilePreview && ['top', 'bottom'].includes(position);

        if (position.includes('top')) {
          sides[INDEXES.TOP] = false;
        }

        if (position.includes('bottom')) {
          sides[INDEXES.BOTTOM] = false;
        }

        if (position.includes('left') || isMobileTopOrBottom) {
          sides[INDEXES.LEFT] = false;
        }

        if (position.includes('right') || isMobileTopOrBottom) {
          sides[INDEXES.RIGHT] = false;
        }

        return sides;
      },
      teaserWidthProperty() {
        if (this.mobilePreview) {
          return !this.page.isTeaser
            ? 'selectedPage.mobile.width'
            : 'selectedPage.mobile.teaserWidth';
        }
        return 'selectedPage.desktop.width';
      },
      teaserWidthMax() {
        return this.mobilePreview ? 100 : 980;
      },
      teaserWidth: {
        get() {
          return Number(this.getValueOf(this.teaserWidthProperty));
        },
        set(value) {
          this.setValueOf(this.teaserWidthProperty, Number(value));
        },
      },
      teaserMinHeight: {
        get() {
          return Number(this.getValueOf('selectedPage.desktop.minHeight'));
        },
        set(value) {
          this.setValueOf('selectedPage.desktop.minHeight', Number(value));
        },
      },
      alignmentItems() {
        return [
          { text: this.$t('top'), value: 'om-flex-top' },
          { text: this.$t('center'), value: 'om-flex-center' },
          { text: this.$t('bottom'), value: 'om-flex-bottom' },
        ];
      },
      alignment: {
        get() {
          return this.getValueOf('selectedPage.data.vAlign');
        },
        set(value) {
          this.setValueOf('selectedPage.data.vAlign', value);
        },
      },
      oldTeaserType: {
        get() {
          return this.getValueOf('globalStyle.tab.type.style');
        },
        set(value) {
          this.setValueOf('globalStyle.tab.type.style', value);
        },
      },
      oldTeaserSize: {
        get() {
          return this.getValueOf('globalStyle.tab.type.size');
        },
        set(value) {
          this.setValueOf('globalStyle.tab.type.size', value);
        },
      },
      tabPosition() {
        return this.getValueOf('globalStyle.tab.position');
      },
      switchFromCampaignTypes() {
        const items = [{ text: this.$t('none'), value: null }];

        if (this.tabPosition && this.tabPosition.includes('top')) {
          items.push({ text: this.$t('animated'), value: 'fadeInDown' });
        } else {
          items.push({ text: this.$t('animated'), value: 'fadeInUp' });
        }

        return items;
      },
      switchFromCampaign: {
        get() {
          return this.getValueOf('globalStyle.tab.animation.switch.type');
        },
        set(value) {
          this.setValueOf('globalStyle.tab.animation.switch.type', value);
        },
      },
      attentionSeekers() {
        const items = [{ text: this.$t('none'), value: null }];

        attentionSeekerTypes.forEach((type) => {
          if (type === 'heartBeat' && this.oldTeaserType !== 'floating') {
            return;
          }

          items.push({ text: this.$t(type), value: type });
        });

        return items;
      },
      attentionSeekerType: {
        get() {
          const prop = this.isNewTeaser ? 'attentionSeeker.type' : 'animation.attentionSeeker.type';
          return this.getValueOf(`${this.dataPropertyPrefix}.${prop}`);
        },
        set(value) {
          const prop = this.isNewTeaser ? 'attentionSeeker.type' : 'animation.attentionSeeker.type';
          this.setValueOf(`${this.dataPropertyPrefix}.${prop}`, value);
        },
      },
      seekerFrequency: {
        get() {
          const prop = this.isNewTeaser ? 'attentionSeeker.freq' : 'animation.attentionSeeker.freq';
          return this.getValueOf(`${this.dataPropertyPrefix}.${prop}`);
        },
        set(value) {
          const prop = this.isNewTeaser ? 'attentionSeeker.freq' : 'animation.attentionSeeker.freq';
          this.setValueOf(`${this.dataPropertyPrefix}.${prop}`, value);
        },
      },
      device: {
        get() {
          return this.getValueOf('data.display.deviceDisplay');
        },
        set(value) {
          this.setValueOf('data.display.deviceDisplay', value);
        },
      },
      showTeaserInfoBox() {
        return (
          !get(this.account, 'profile.infoBox.hideTeaser') &&
          this.hasAccountFeature(PERMANENT_TEASER)
        );
      },
      shadowComputed: {
        get() {
          return this.getValueOf('globalStyle.tab.shadow.type');
        },
        set(value) {
          this.setValueOf('globalStyle.tab.shadow.type', value);
        },
      },
    },
    watch: {
      showBefore(show) {
        if (show && !this.showBeforeSec) this.showBeforeSec = 6;
      },
      showAfter(show) {
        if (show && !this.showAfterSec) this.showAfterSec = 2;
      },
      tabPosition() {
        const animationValues = this.switchFromCampaigns?.map((a) => a.value) ?? [];

        if (this.switchFromCampaign !== '' && !animationValues.includes(this.switchFromCampaign)) {
          this.switchFromCampaign = this.switchFromCampaignTypes[1];
        }
      },
      tabType() {
        const attentionSeekerTypes = this.attentionSeekerTypes.map((a) => a.value);

        if (!attentionSeekerTypes.includes(this.attentionSeekerType)) {
          this.attentionSeekerType = null;
        }
      },
    },
    methods: {
      ...mapMutations([
        'setStateAttr',
        'addTemplateFeature',
        'removeTemplateFeature',
        'selectPage',
      ]),
      migrateTeaser() {
        if (!this.hasTemplateFeature(TEMPLATE_FEATURES.NEW_TEASER)) {
          this.$bus.$emit('initV2Teaser');
          this.$bus.$on('teaserPageAdded', (uid) => {
            this.addTemplateFeature(TEMPLATE_FEATURES.NEW_TEASER);
            this.$bus.$emit('deselectAll');
            this.setStateAttr({ attr: 'paneLevel', value: 1 });
            setTimeout(() => {
              this.selectPage(uid);
              this.setStateAttr({ attr: 'teaserPreview', value: false });
              this.$bus.$emit('setTeaserPreview', false);
            }, 200);
          });
        } else {
          this.addTemplateFeature(TEMPLATE_FEATURES.NEW_TEASER);
          this.$bus.$emit('deselectAll');
          this.setStateAttr({ attr: 'paneLevel', value: 1 });
          setTimeout(() => {
            this.selectPage(this.teaserPage.uid);
            this.setStateAttr({ attr: 'teaserPreview', value: false });
            this.$bus.$emit('setTeaserPreview', false);
          }, 200);
        }
      },
      revertTeaser() {
        if (this.teaserPage) {
          this.$bus.$emit('deselectAll');
          this.setStateAttr({ attr: 'paneLevel', value: 1 });
          this.removeTemplateFeature(TEMPLATE_FEATURES.NEW_TEASER);
          this.setStateAttr({ attr: 'teaserPreview', value: true });
          this.$bus.$emit('setTeaserPreview', true);
        }
      },
      toggleTeaserVersion() {
        const newTeaser = this.hasTemplateFeature(TEMPLATE_FEATURES.NEW_TEASER);
        if (newTeaser) {
          this.$bus.$emit('toggleTeaser');
        } else {
          this.$bus.$emit('deselectAll');
          setTimeout(() => {
            this.selectPage(this.teaserPage.uid);
          }, 150);
        }
      },
    },
  };
</script>

<style lang="sass">
  @import "@/sass/variables/_colors.sass"
  .ds-pane
    .graphic-select-tab
      background-repeat: no-repeat
      background-position: center
      height: 3.125rem
  .ds-teaser-device
    &-option
      border: 1px solid #e3e5e8
      padding: .25rem
      width: 4.625rem
      border-radius: 4px
      transition: border-color .3s, background-color .3s
      &.selected
        border-color: $om-orange
        background-color: $om-gray-200
    &-text
      font-size: 0.625rem

  .old-teaser
    &-help
      color: #8F97A4
      font-size: 0.75rem
    &-disabled-box
      display: flex
      align-items: center
      padding: 14px
      border: 1px solid #D5D8DD
      border-radius: 4px
      .icon-warning
        align-self: center
        margin-right: 9px
        font-size: 1.5rem
        color: #F0C920
        height: 24px
        width: 24px
        margin-top: -10px
</style>
