export const TEMPLATE_AUTHOR = 'TEMPLATE_AUTHOR';
export const JF_EPV_SUPERADMIN = 'JF_EPV_SUPERADMIN';
export const JF_EPV = 'JF_EPV';
export const AVG_TIME_ON_PAGE_TRIGGER = 'AVG_TIME_ON_PAGE_TRIGGER';
export const EDITOR_BETA = 'EDITOR_BETA';
export const SSR_FOR_PREVIEW = 'SSR_FOR_PREVIEW';
export const ACER_CCDB = 'ACER_CCDB';
export const ATTENTIVE_V2 = 'ATTENTIVE_V2';
export const NEW_INTEGRATION_FLOW = 'NEW_INTEGRATION_FLOW';
export const SSR_PREVIEWS_TEMPLATES = 'SSR_PREVIEWS_TEMPLATES';
export const SSR_PREVIEWS_CHOOSER = 'SSR_PREVIEWS_CHOOSER';
export const SSR_PREVIEWS_CAMPAIGNS = 'SSR_PREVIEWS_CAMPAIGNS';
export const SSR_PREVIEWS_MODALS = 'SSR_PREVIEWS_MODALS';
export const DEV_MODE_FOR_REVIEW = 'DEV_MODE_FOR_REVIEW';
export const DYNAMIC_CONTENT = 'DYNAMIC_CONTENT';
export const SMART_HEADLINE_GENERATOR = 'SMART_HEADLINE_GENERATOR';
export const SPAM_PROTECTION_MENU = 'SPAM_PROTECTION_MENU';
export const NEW_TEMPLATE_CHOOSER = 'NEW_TEMPLATE_CHOOSER';
export const PERMANENT_TEASER = 'PERMANENT_TEASER';
export const JETFABRIC_EMBEDDED_AND_DC = 'JETFABRIC_EMBEDDED_AND_DC';
export const CONTROL_VARIANT_UI = 'CONTROL_VARIANT_UI';
export const PRODUCT_PAGE_OPTIMIZER = 'PRODUCT_PAGE_OPTIMIZER';
export const ONBOARDING_AI = 'ONBOARDING_AI';
export const CAMPAIGN_SHARE = 'CAMPAIGN_SHARE';
export const NEW_SITE_DETECTION = 'NEW_SITE_DETECTION';
export const CONTENT_BY_AI = 'CONTENT_BY_AI';
export const OPTIMONK_RECOMMENDER = 'OPTIMONK_RECOMMENDER';
export const SMART_NEW_CAMPAIGN = 'SMART_NEW_CAMPAIGN';
export const SMART_AB_TEST = 'SMART_AB_TEST';
export const SAB_NEW_CHIP = 'SAB_NEW_CHIP';
export const SMART_PRODUCT_RECOMMENDATION = 'SMART_PRODUCT_RECOMMENDATION';
export const EDITOR_BATCH_IMAGE_CHANGE = 'EDITOR_BATCH_IMAGE_CHANGE';
export const MULTI_LIST_CAMPAIGN = 'MULTI_LIST_CAMPAIGN';
export const CLEAN_URL_IN_RULES = 'CLEAN_URL_IN_RULES';
export const WIZARD_AB_TEST = 'WIZARD_AB_TEST';
export const FREQUENCY_RULE_V2 = 'FREQUENCY_RULE_V2';
export const VISUALIZED_MARGINS = 'VISUALIZED_MARGINS';

export const FEATURE_CONSTANTS = {
  TEMPLATE_AUTHOR,
  JF_EPV_SUPERADMIN,
  JF_EPV,
  EDITOR_BETA,
  AVG_TIME_ON_PAGE_TRIGGER,
  SSR_FOR_PREVIEW,
  ACER_CCDB,
  ATTENTIVE_V2,
  SSR_PREVIEWS_TEMPLATES,
  SSR_PREVIEWS_CAMPAIGNS,
  SSR_PREVIEWS_MODALS,
  NEW_INTEGRATION_FLOW,
  SSR_PREVIEWS_CHOOSER,
  DYNAMIC_CONTENT,
  SMART_HEADLINE_GENERATOR,
  NEW_TEMPLATE_CHOOSER,
  PERMANENT_TEASER,
  JETFABRIC_EMBEDDED_AND_DC,
  CONTROL_VARIANT_UI,
  ONBOARDING_AI,
  NEW_SITE_DETECTION,
  SMART_NEW_CAMPAIGN,
  SMART_AB_TEST,
  SMART_PRODUCT_RECOMMENDATION,
  EDITOR_BATCH_IMAGE_CHANGE,
  MULTI_LIST_CAMPAIGN,
  CLEAN_URL_IN_RULES,
  WIZARD_AB_TEST,
  FREQUENCY_RULE_V2,
  VISUALIZED_MARGINS,
};

export const isFeatureEnabled = (features, flag) => {
  return Array.isArray(features) ? features.includes(flag) : false;
};

export const isTemplatesWithSsr = (features) => {
  return isFeatureEnabled(features, SSR_PREVIEWS_TEMPLATES);
};

export const isCampaignsWithSsrEnabled = (features) => {
  return isFeatureEnabled(features, SSR_PREVIEWS_CAMPAIGNS);
};

export const isModalsWithSsrEnabled = (features) => {
  return isFeatureEnabled(features, SSR_PREVIEWS_MODALS);
};

export const isChooserWithSsr = (features) => {
  return isFeatureEnabled(features, SSR_PREVIEWS_CHOOSER);
};

export const isCustomCssJsEditorDisabled = (features) => {
  return isFeatureEnabled(features, DEV_MODE_FOR_REVIEW);
};

export const isDynamicContentEnabled = (features) => {
  return isFeatureEnabled(features, DYNAMIC_CONTENT);
};

export const isSpamProtectionMenuEnabled = (features) => {
  return isFeatureEnabled(features, SPAM_PROTECTION_MENU);
};

export const isPPOEnabled = (features) => {
  return isFeatureEnabled(features, PRODUCT_PAGE_OPTIMIZER);
};

export const isOnboardingAiEnabled = (features) => {
  return isFeatureEnabled(features, ONBOARDING_AI);
};

export const isNewSiteDetectionEnabled = (features) => {
  return isFeatureEnabled(features, NEW_SITE_DETECTION);
};

export const hasCampaignShareFeature = (features) => {
  return isFeatureEnabled(features, CAMPAIGN_SHARE);
};

export const isGenerateWithAIEnabled = (features) => {
  return isFeatureEnabled(features, CONTENT_BY_AI);
};

export const isOptimonkRecommenderEnabled = (features) => {
  return isFeatureEnabled(features, OPTIMONK_RECOMMENDER);
};

export const hasSmartNewCampaignFeature = (features) => {
  return isFeatureEnabled(features, SMART_NEW_CAMPAIGN);
};

export const hasSmartAbTestFeature = (features) => {
  return isFeatureEnabled(features, SMART_AB_TEST);
};

export const isSPREnabled = (features) => {
  return isFeatureEnabled(features, SMART_PRODUCT_RECOMMENDATION);
};

export const hasEditorBatchImageChangeFeature = (features) => {
  return isFeatureEnabled(features, EDITOR_BATCH_IMAGE_CHANGE);
};

export const isCleanUrlInRulesEnabled = (features) => {
  return isFeatureEnabled(features, CLEAN_URL_IN_RULES);
};

export const isNewFrequencyRuleEnabled = (features) => {
  return isFeatureEnabled(features, FREQUENCY_RULE_V2);
};
