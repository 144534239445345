import CHANGE_PACKAGE from '@/graphql/ChangePackage.gql';
import CANCEL_SUBSCRIPTION from '@/graphql/CancelSubscription.gql';
import KEEP_SUBSCRIPTION from '@/graphql/KeepSubscription.gql';
import GET_SHOPIFY_CONFIRM_URL from '@/graphql/GetShopifyConfirmUrl.gql';
import GET_SHOPIFY_CUSTOM_CONFIRM_URL from '@/graphql/GetShopifyCustomConfirmUrl.gql';
import CHECK_SHOPIFY_CHARGE_STATUS from '@/graphql/CheckShopifyChargeStatus.gql';
import GET_AFFILIATE_DATA from '@/graphql/GetAffiliateData.gql';
import GET_AFFILIATE_PAYOUT_ITEMS from '@/graphql/GetAffiliatePayoutItems.gql';
import AFFILIATE_PAYOUT from '@/graphql/AffiliatePayoutRequest.gql';

class PaymentAdapter {
  constructor(authData, accountFeatures, apollo) {
    this.authData = authData;
    this.accountFeatures = accountFeatures;
    this.apollo = apollo;
  }

  createAddress(data) {
    return {
      firstName: data.firstName,
      lastName: data.lastName,
      countryId: data.country,
      company: data.billingName,
      city: data.city,
      address: data.address,
      postalCode: data.postalCode,
      euVatNumber: data.euVatNumber,
    };
  }

  async sendCreateOrder(data) {
    const response = await this.apollo.mutate({
      mutation: CHANGE_PACKAGE,
      variables: {
        packageName: data.plan,
        period: data.period,
        address: this.createAddress(data),
        paymentNonce: data.paymentNonce,
        method: data.paymentMethod,
        couponCode: data.couponCode,
        flexiPay: data.flexiPay ?? 0,
      },
    });

    return { data: response.data.changePackage };
  }

  cancelSubscription(reason) {
    return this.apollo.mutate({
      mutation: CANCEL_SUBSCRIPTION,
      variables: {
        reason,
      },
    });
  }

  keepSubscription(reason, solution) {
    return this.apollo.mutate({
      mutation: KEEP_SUBSCRIPTION,
      variables: {
        reason,
        solution,
      },
    });
  }

  // First req during charge creation / updating process
  async sendShopifyPay(
    plan,
    period,
    couponCode = null,
    type = 'recurring',
    replacementBehavior = 'default',
  ) {
    const response = await this.apollo.query({
      query: GET_SHOPIFY_CONFIRM_URL,
      variables: {
        packageName: plan,
        period,
        type,
        replacementBehavior,
        couponCode,
      },
    });

    return { data: { ...response.data.getShopifyConfirmUrl } };
  }

  // Second req during charge creation / updating process (after SY plan approving)
  async sendShopifyReturn(chargeId, type = 'recurring') {
    const response = await this.apollo.query({
      query: CHECK_SHOPIFY_CHARGE_STATUS,
      variables: {
        chargeId,
        type,
      },
    });

    return { data: { ...response.data.checkShopifyChargeStatus } };
  }

  async sendCustomShopifyConfirm(type) {
    const response = await this.apollo.query({
      query: GET_SHOPIFY_CUSTOM_CONFIRM_URL,
      variables: {
        type,
      },
    });

    return { data: response.data.getShopifyCustomConfirmUrl };
  }

  async sendCustomShopifyReturn(type, chargeId) {
    const response = await this.apollo.query({
      query: CHECK_SHOPIFY_CHARGE_STATUS,
      variables: {
        custom: 1,
        chargeId,
        type,
      },
    });

    return { data: response.data.checkShopifyChargeStatus };
  }

  async getAffiliateData() {
    const rawResponse = await this.apollo.query({
      query: GET_AFFILIATE_DATA,
    });

    const response = rawResponse?.data?.data;

    if (!response) {
      return { data: {} };
    }

    const main = {
      minimum_payout: response.minimumPayout,

      referred_subscribers: response.subscribers.total,
      referred_customers: response.customers.total,
      referred_visitors: response.visitors.total,
      month_referred_subscribers: response.subscribers.monthly,
      month_referred_customers: response.customers.monthly,
      month_referred_visitors: response.visitors.monthly,
      month_can_payout: response.payout.actual.canPayoutInPeriods.monthly,
      week_referred_subscribers: response.subscribers.weekly,
      week_referred_customers: response.customers.weekly,
      week_referred_visitors: response.visitors.weekly,
      week_can_payout: response.payout.actual.canPayoutInPeriods.weekly,
      year_referred_subscribers: response.subscribers.yearly,
      year_referred_customers: response.customers.yearly,
      year_referred_visitors: response.visitors.yearly,
      year_can_payout: response.payout.actual.canPayoutInPeriods.yearly,
      lastmonth_referred_subscribers: response.subscribers.lastMonth,
      lastmonth_referred_customers: response.customers.lastMonth,
      lastmonth_referred_visitors: response.visitors.lastMonth,
      lastmonth_can_payout: response.payout.actual.canPayoutInPeriods.lastMonth,
      lastyear_referred_subscribers: response.subscribers.lastYear,
      lastyear_referred_customers: response.customers.lastYear,
      lastyear_referred_visitors: response.visitors.lastYear,
      lastyear_can_payout: response.payout.actual.canPayoutInPeriods.lastYear,
      total_all_payout: response.payout.total,
      total_actual_depend: response.payout.actual.depend,
      total_actual_can_payout: response.payout.actual.canPayout,
      total_actual_do_payout: response.payout.actual.doPayout,
      total_can_payout: response.payout.actual.canPayoutInPeriods.total,
      uniqueCustomers: response.customers.uniqueCustomers,

      enable_new_payout: response.payout.enableNewPayoutForPartner,
    };
    response.main = main;

    response.partner_data = response.partnerData;
    response.commissions = [
      { ...response.commissionData, total: `${response.commissionData.total}%` },
    ];

    return { data: response };
  }

  async getAffiliatePayoutItems() {
    const rawResponse = await this.apollo.query({
      query: GET_AFFILIATE_PAYOUT_ITEMS,
    });

    const response = rawResponse?.data?.data;

    if (!response) {
      return { data: {} };
    }

    return { data: response };
  }

  async sendPayout() {
    const response = await this.apollo.mutate({
      mutation: AFFILIATE_PAYOUT,
      variables: {},
    });

    return response?.data?.affiliatePayoutRequest?.success || false;
  }
}

export { PaymentAdapter };
