<template lang="pug">
BasePane
  Accordion(fix)
    Dropdown#number-of-products.mt-3(
      :items="nrOfProductOptions"
      :label="$t('nrOfProducts')"
      v-model="nrOfProducts"
      layout="col"
    )
      template(#help)
        span {{ $t('productPaneTooltips.notDisplayedIfNoEnoughProducts') }}
    template(v-if="isModeSelectAvailable")
      Dropdown#modes(:items="modes" :label="$t('mode')" v-model="mode" layout="col")
      template(v-if="productFilterTypes[selectedMode]")
        Dropdown#product-filters(
          :items="productFilterTypes[selectedMode]"
          :label="$t(`productFilterLabels.${selectedMode}`)"
          v-model="productFilter"
          layout="col"
        )
        template(v-if="selectedProductFilterType === 'category'")
          multiselect.wide-select-100(
            v-model="categoryId"
            :options="categories"
            label="key"
            track-by="value"
            :closeOnSelect="true"
            :searchable="true"
            :allow-empty="false"
            :placeholder="$t('select')"
            selectLabel=""
            deselectLabel=""
            selectedLabel=""
            open-direction="bottom"
          )
    Heading(label v-if="selectedElement.data.clickAction !== 'add-to-cart'")
      template(#titleTrailing)
        .label {{ $t('productTexts.title.openInNewTab') }}
      template(#prependEnding)
        OmSwitch#open-in-new-tab(v-model="openInNewTab")
    Heading(label v-if="isActiveShopify && isManual" :open="showOutOfStock")
      template(#titleTrailing)
        .label {{ $t('productTexts.title.showOutOfStock') }}
      template(#prependEnding)
        OmSwitch#show-out-of-stock(v-model="showOutOfStock")
      template(#expandableContent)
        OmInput#out-of-stock-text(v-model="outOfStockText" small)
  hr.mt-3
  template(v-for="({ name, component }, index) in types")
    Accordion.mt-3(:key="index" ref="subElementAccordions")
      template(#title)
        span {{ $t(`productTexts.title.${name}`) }}
      template(#toggle)
        OmSwitch#title-toggle(
          :value="selectedElement.data[name].active"
          @click.native.stop
          @switch="updateActive(name, $event, index)"
        )
      template
        component.mb-4(
          :is="component"
          :subItem="name"
          :clickActions="clickActions"
          :selectedMode="selectedMode"
          :isStatic="isStatic"
        )
    hr.m-0
  PaneLayout.my-3(layout="col")
    template(#left)
      .editor-heading-title {{ $t('display') }}
    template(#right)
      .product-layout-wrapper
        .product-layout-item(@click="changeLayout('horizontal')")
          LayoutHorizontal(width="100%" height="100%" :class="{ selected: isHorizontal }")
        .product-layout-item(@click="changeLayout('vertical')")
          LayoutVertical(width="100%" height="100%" :class="{ selected: isVertical }")
  Accordion.mb-3(fix)
    Alignment(
      v-if="isVertical && !mobilePreview"
      :label="$t('align')"
      :options="alignOptions"
      v-model="alignment"
    )
    template(v-if="!mobilePreview")
      Dropdown#width(layout="col" :label="$t('width')" :items="fitOptions" v-model="fitValue")
      RangeInput(
        v-if="fitValue === 'manual'"
        :min="30"
        :max="600"
        :step="1"
        :label="$t('width')"
        v-model="width"
      )
  hr.mb-4
  Accordion(fix)
    template(#title)
      span {{ $t('spacing') }}
    Margin
  hr.mt-3
  BackgroundAndBorder(shadow typeOfComponent="product")
    template(#color)
      OmColorInput(
        :label="$t('background')"
        property="desktop.background.color"
        typeOfComponent="product"
        layout="col"
        dsAllowed
      )
  hr.mt-1
  template(#advancedContent)
    OmButton.mt-5.w-100(
      v-if="canMoneyFormatSettingBeAdjusted"
      primary
      @click="$modal.show('money-format-modal')"
    ) Modify money format
    Padding
    Hide(:hideOnDesktop.sync="hideElementOnDesktop" :hideOnMobile.sync="hideElementOnMobile")
  portal(to="root")
    ProductCatalog
    ProductDetails
</template>

<script>
  import { mapGetters, mapMutations, mapState } from 'vuex';
  import GET_SHOP_INFO from '@/graphql/GetEcommerceShopInfo.gql';
  import Accordion from '@/components/Editor/Accordion/Accordion.vue';
  import Dropdown from '@/components/Editor/Dropdown/Dropdown.vue';
  import Heading from '@/components/Editor/Heading.vue';
  import PaneLayout from '@/components/Editor/PaneLayout.vue';
  import RangeInput from '@/components/Editor/RangeInput/RangeInput.vue';
  import Alignment from '@/components/Editor/Alignment/Alignment.vue';
  import Padding from '@/components/Editor/Controls/InputGroup/Padding.vue';
  import Margin from '@/components/Editor/Controls/InputGroup/Margin.vue';
  import Hide from '@/components/Editor/Controls/Hide.vue';
  import itemMixin from '@/editor/mixins/item';
  import BackgroundAndBorder from '@/components/Editor/Blocks/BackgroundAndBorder.vue';
  import ProductImage from '@/components/Editor/Blocks/Product/Image.vue';
  import ProductShared from '@/components/Editor/Blocks/Product/Shared.vue';
  import ProductCta from '@/components/Editor/Blocks/Product/Cta.vue';
  import LayoutHorizontal from '@/editor/components/svg/product/LayoutHorizontal.vue';
  import LayoutVertical from '@/editor/components/svg/product/LayoutVertical.vue';
  import { getCollections } from '@/services/jfAdapter';
  import { isOptimonkRecommenderEnabled } from '@/utils/features';
  import { ELEMENTS } from '@om/template-properties/src/propertyHelper';
  import BasePane from './BasePane.vue';

  export default {
    components: {
      BasePane,
      Accordion,
      Dropdown,
      Heading,
      PaneLayout,
      RangeInput,
      Alignment,
      Padding,
      Margin,
      Hide,
      BackgroundAndBorder,
      ProductImage,
      ProductShared,
      ProductCta,
      LayoutHorizontal,
      LayoutVertical,
      ProductCatalog: () => import('@/editor/components/modals/ProductCatalog.vue'),
      ProductDetails: () => import('@/editor/components/modals/ProductDetails.vue'),
    },
    mixins: [itemMixin],
    data: () => ({
      canEditMobile: true,
      types: [
        { name: 'image', component: 'ProductImage' },
        { name: 'name', component: 'ProductShared' },
        { name: 'sku', component: 'ProductShared' },
        { name: 'price', component: 'ProductShared' },
        { name: 'oldPrice', component: 'ProductShared' },
        { name: 'cta', component: 'ProductCta' },
      ],
      display: true,
      isActiveShopify: false,
      isActiveShoprenter: false,
      categories: [],
      nrOfProductOptions: [
        { text: '1', value: 1 },
        { text: '2', value: 2 },
        { text: '3', value: 3 },
        { text: '4', value: 4 },
        { text: '5', value: 5 },
      ],
      alignOptions: [
        { text: 'left', value: 'flex-start' },
        { text: 'center', value: 'center' },
      ],
    }),
    computed: {
      ...mapState([
        'account',
        'selectedElement',
        'validationError',
        'userInfoMessage',
        'campaign',
        'mobilePreview',
      ]),
      ...mapGetters([
        'shopSettingsByDomain',
        'databaseId',
        'isActiveShopifyDomain',
        'isActiveShoprenterDomain',
        'isTemplateEditorMode',
        'isSuperAdmin',
        'domains',
      ]),
      nrOfProducts: {
        get() {
          return this.getValueOf('data.nrOfProducts');
        },
        set(v) {
          this.setValueOf('selectedElement.data.nrOfProducts', v);
        },
      },
      mode: {
        get() {
          return this.getValueOf('data.mode');
        },
        set(v) {
          this.setValueOf('selectedElement.data.mode', v);
        },
      },
      productFilter: {
        get() {
          return this.getValueOf('data.productFilter.type');
        },
        set(v) {
          this.setValueOf('data.productFilter.type', v);
        },
      },
      openInNewTab: {
        get() {
          return this.getValueOf('data.cta.openInNewTab');
        },
        set(v) {
          this.setValueOf('data.cta.openInNewTab', v);
        },
      },
      showOutOfStock: {
        get() {
          return this.getValueOf('data.showOutOfStock');
        },
        set(v) {
          this.setValueOf('data.showOutOfStock', v);
        },
      },
      outOfStockText: {
        get() {
          return this.getValueOf('data.ctaOutOfStockText');
        },
        set(v) {
          this.setValueOf('data.ctaOutOfStockText', v);
        },
      },
      hideElementOnDesktop: {
        get() {
          return this.getValueOf('desktop.hidden');
        },
        set(v) {
          this.setValueOf('desktop.hidden', v);
        },
      },
      hideElementOnMobile: {
        get() {
          return this.smartGetValueOf('mobile.hidden');
        },
        set(v) {
          this.smartSetValueOf('mobile.hidden', v);
        },
      },
      alignment: {
        get() {
          return this.getValueOf('desktop.alignItems');
        },
        set(v) {
          this.setValueOf('desktop.alignItems', v);
        },
      },
      fitValue: {
        get() {
          return this.getValueOf('desktop.smartSize.type');
        },
        set(v) {
          this.setValueOf('desktop.smartSize.type', v);
        },
      },
      width: {
        get() {
          return Number(this.getValueOf('desktop.smartSize.width'));
        },
        set(v) {
          this.setValueOf('desktop.smartSize.width', v);
        },
      },
      categoryId: {
        get() {
          return this.categories.find(
            ({ value }) => `${value}` === this.selectedElement.data.productFilter.categoryId,
          );
        },
        set({ value }) {
          this.setValueOf('data.productFilter.categoryId', value);
        },
      },
      isModeSelectAvailable() {
        return (
          this.isActiveShopify ||
          this.isActiveShoprenter ||
          this.isTemplateEditorMode ||
          this.featureOptimonkRecommender
        );
      },
      isEmbeddedV3() {
        return this.campaign.version === 3;
      },
      features() {
        return this.account && this.account.features ? this.account.features : [];
      },
      featureOptimonkRecommender() {
        return isOptimonkRecommenderEnabled(this.features);
      },
      fitOptions() {
        const opts = [
          { value: 'fluid', text: this.$t('fluid') },
          { value: 'manual', text: this.$t('manual') },
        ];

        if (!this.isNano) {
          opts.push({ value: '100w', text: this.$t('simple100') });
        }

        return opts;
      },
      modes() {
        const modes = [{ text: this.$t('productModes.manual'), value: 'manual' }];
        if (this.isEmbeddedV3) return modes;

        if (this.isActiveShopify || this.isTemplateEditorMode) {
          modes.push(
            { text: this.$t('productModes.last-visited'), value: 'last-visited' },
            { text: this.$t('productModes.most-viewed'), value: 'most-viewed' },
            { text: this.$t('productModes.smart-recommendation'), value: 'smart-recommendation' },
            { text: this.$t('productModes.products-in-the-cart'), value: 'products-in-the-cart' },
          );
        }

        if (this.isActiveShoprenter) {
          modes.push(
            { text: this.$t('productModes.last-visited'), value: 'last-visited' },
            { text: this.$t('productModes.most-viewed'), value: 'most-viewed' },
            {
              text: this.$t('productModes.smart-recommendation-related'),
              value: 'smart-recommendation-related',
            },
            {
              text: this.$t('productModes.smart-recommendation-similar'),
              value: 'smart-recommendation-similar',
            },
            {
              text: this.$t('productModes.products-in-the-cart'),
              value: 'products-in-the-cart',
            },
          );
        }

        if (this.featureOptimonkRecommender) {
          modes.push({
            text: this.$t('productModes.optimonk-recommender'),
            value: 'optimonk-recommender',
          });
        }

        return modes;
      },
      productFilterTypes() {
        const types = {
          'most-viewed': [
            { text: this.$t('productFilters.most-viewed.none'), value: 'none' },
            { text: this.$t('productFilters.most-viewed.category'), value: 'category' },
            {
              text: this.$t('productFilters.most-viewed.viewed-category'),
              value: 'viewed-category',
            },
          ],
        };

        return types;
      },
      isStatic() {
        return this.selectedMode === 'manual' && !this.isActiveShopify && !this.isActiveShoprenter;
      },
      selectedMode() {
        return this.getValueOf('selectedElement.data.mode');
      },
      selectedProductFilterType() {
        return this.getValueOf('selectedElement.data.productFilter.type');
      },
      defaultCtaTexts() {
        return [
          this.$t('productTexts.example.cta.addToCart'),
          this.$t('productTexts.example.cta.redirect'),
          this.$t('productTexts.example.cta.redirectToCart'),
        ];
      },
      deviceLayout() {
        return this.mobilePreview ? 'mobileLayout' : 'layout';
      },
      isVertical() {
        return this.selectedElement.data[this.deviceLayout] === 'vertical';
      },
      isHorizontal() {
        return this.selectedElement.data[this.deviceLayout] === 'horizontal';
      },
      validationFailedIndex() {
        if (this.validationError) {
          for (const i in this.selectedElement.data.products) {
            if (this.validationError.property === `selectedElement.data.products[${i}].url`)
              return i;
          }
        }
        return false;
      },
      isManual() {
        return this.selectedMode === 'manual';
      },
      clickActions() {
        if (this.selectedMode === 'products-in-the-cart') {
          return [
            {
              text: this.$t('productTexts.clickActions.redirectToCart'),
              value: 'redirect-to-cart',
            },
          ];
        }
        return [
          { text: this.$t('productTexts.clickActions.addToCart'), value: 'add-to-cart' },
          { text: this.$t('productTexts.clickActions.redirectToProduct'), value: 'redirect' },
        ];
      },
      isOMRecommender() {
        return (
          this.selectedElement.type === ELEMENTS.OmProduct &&
          this.selectedElement.data.mode === 'optimonk-recommender'
        );
      },
      canMoneyFormatSettingBeAdjusted() {
        if (!this.isSuperAdmin || !this.isOMRecommender) return false;

        const { platform = '' } =
          this.domains?.find?.(({ domain }) => domain === this.campaign.domain) ?? {};

        return platform !== 'shopify' && platform !== 'shoprenter';
      },
    },
    watch: {
      selectedMode(newMode) {
        if (this.isTemplateEditorMode) {
          if (newMode === 'manual') {
            this.selectedElement.data.clickAction = 'redirect';
            return;
          }
        }
        if (newMode === 'manual') {
          this.selectedElement.data.clickAction = this.clickActions[0].value;
          if (this.isStatic) {
            this.selectedElement.data.cta.active = true;
          }
        } else if (newMode === 'products-in-the-cart') {
          this.selectedElement.data.clickAction = 'redirect-to-cart';
          this.selectedElement.data.cta.active = false;
        } else {
          this.selectedElement.data.clickAction = this.clickActions[0].value;
          const storeType = this.selectedElement.data.storeType;
          if (storeType === 'shopify' || storeType === 'shoprenter') {
            this.fetchShopInfo(storeType);
          }

          if (newMode !== 'most-viewed') {
            this.selectedElement.data.categoryId = null;
          }
        }
        this.setExampleProducts();
      },
      selectedProductFilterType(type) {
        if (type === 'none') {
          this.selectedElement.data.productFilter.categoryId = null;
        }
      },
      'selectedElement.data.clickAction': function (action) {
        const isDefaultText = this.defaultCtaTexts.includes(this.selectedElement.data.ctaText);
        if (isDefaultText) {
          if (action === 'add-to-cart') {
            this.selectedElement.data.ctaText = this.$t('productTexts.example.cta.addToCart');
          } else if (action === 'redirect') {
            this.selectedElement.data.ctaText = this.$t('productTexts.example.cta.redirect');
          } else if (action === 'redirect-to-cart') {
            this.selectedElement.data.ctaText = this.$t('productTexts.example.cta.redirectToCart');
          }
        }
      },
      'selectedElement.data.nrOfProducts': function (n, o) {
        if (this.selectedElement.data.mode !== 'manual') {
          this.setExampleProducts();
        }
        if (n < o) {
          const prods = this.getValueOf('selectedElement.data.products');
          this.setValueOf('selectedElement.data.products', prods.slice(0, n));
        }
      },
      validationError() {
        if (this.validationFailedIndex !== false) {
          this.openProductDetailsOnError(this.validationFailedIndex);
        }
      },
    },
    created() {
      this.$bus.$on('handleProductClick', this.handleProductClick);
    },
    beforeDestroy() {
      this.$bus.$off('handleProductClick', this.handleProductClick);
    },
    mounted() {
      if (this.campaign) {
        const { domain } = this.campaign;
        if (domain) {
          this.isActiveShopify = this.isActiveShopifyDomain(domain);
          this.isActiveShoprenter = this.isActiveShoprenterDomain(domain);
          const storeType = this.selectedElement.data.storeType;
          if (storeType) {
            this.fetchShopInfo(storeType);
            this.fetchCategories();
          }
        }
      }
      this.$bus.$emit('checkShopConnection');
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.validationFailedIndex !== false) {
            this.openProductDetailsOnError(this.validationFailedIndex);
          }
        }, 150);
      });
    },
    methods: {
      ...mapMutations(['updateStyle', 'setSelectedProductIndex']),
      updateActive(type, value, index) {
        this.setValueOf(`data.${type}.active`, value);
        this.toggleAccordion(index, value);
      },
      toggleAccordion(index, active) {
        const accordion = this.$refs.subElementAccordions?.[index];

        if (!accordion) return;

        const isActivatedAndClosed = active && !accordion.expanded;
        const isInactiveAndOpen = !active && accordion.expanded;
        if (isActivatedAndClosed || isInactiveAndOpen) {
          accordion.toggle();
        }
      },
      changeLayout(direction) {
        this.setValueOf(`selectedElement.data.${this.deviceLayout}`, direction);
        const align = this.isVertical ? 'center' : 'left';
        this.setValueOf('selectedElement.data.align', align);
        this.$bus.$emit('historySave');
      },
      toggle(item) {
        if (this[item].active) this[`${item}Show`] = !this[`${item}Show`];
      },
      async fetchShopInfo(type) {
        const { data } = await this.$apollo.query({
          query: GET_SHOP_INFO,
          variables: { type, domain: this.campaign.domain },
        });

        if (data && data.getShopInfo) {
          const { moneyFormat } = data.getShopInfo;

          if (moneyFormat) {
            this.selectedElement.data.moneyFormat = moneyFormat;
          }
        }
      },
      openProductDetailsOnError(index) {
        this.$nextTick(() => {
          this.openProductDetails(index);
        });
      },
      async fetchCategories() {
        const shop = this.shopSettingsByDomain(this.campaign.domain);

        if (shop) {
          try {
            const shopId = shop.type === 'shoprenter' ? shop.shopname : shop.myshopify_domain;
            const collections = await getCollections(this.databaseId, shop.type, shopId);

            if (collections) {
              const collectionIdMap = new Map(
                collections.map((collection) => [collection.collectionId, collection]),
              );

              const normalizeName = (collection) => {
                const parentId = collection.parent && collection.parent.collectionId;
                if (parentId && parentId !== collection.collectionId) {
                  const parent = collectionIdMap.get(parentId);

                  if (parent) {
                    return `${normalizeName(parent)} > ${collection.title}`;
                  }
                }

                return collection.title;
              };

              this.categories = collections.map((collection) => ({
                key: normalizeName(collection),
                value: `${collection.collectionId}`,
              }));
              return;
            }
          } catch (err) {
            console.error(err);
          }
        }
      },
      handleProductClick(index) {
        if (this.isManual) {
          this.setSelectedProductIndex(index);
          const selectedProducts = this.getValueOf('selectedElement.data.products');
          if (this.isActiveShopify) {
            this.$modal.show('product-catalog', {
              type: 'shopify',
              setProducts: selectedProducts || [],
            });
          } else if (this.isActiveShoprenter) {
            this.$modal.show('product-catalog', {
              type: 'shoprenter',
              setProducts: selectedProducts || [],
            });
          } else {
            this.openProductDetails(index);
          }
        }
      },
      openProductDetails(index) {
        this.setSelectedProductIndex(index);
        this.$modal.show('product-details');
      },
      setExampleProducts() {
        const nrOfProducts = this.selectedElement.data.nrOfProducts;
        const products = [...this.selectedElement.data.products];

        for (let i = 0; i < nrOfProducts; i++) {
          if (!products[i]) {
            products[i] = {
              image: {
                imageId: null,
                imageUrl: null,
              },
              name: this.$t('productTexts.example.name'),
              sku: this.$t('productTexts.example.sku'),
              price: this.$t('productTexts.example.price.new'),
              oldPrice: this.$t('productTexts.example.price.old'),
            };
          }
        }

        this.$set(this.selectedElement.data, 'products', products);
      },
    },
  };
</script>
<style lang="sass">
  .product-layout-wrapper
    display: flex
    flex-direction: row
    gap: 4px
    .product-layout-item
      max-width: 4rem
      padding: 1px
      height: 2.625rem
</style>
