import { separatePrice } from '@/util';
import GET_PLANS from '@/graphql/GetPlans.gql';
import { getPlanByName } from '@/config/plans';
import { isNonPayingPlan, isStandardPlan } from '@om/payment/src/helpers/planDetails';

const _clone = (v) => JSON.parse(JSON.stringify(v));

const PERIOD_KEYS = {
  MONTHLY: 'monthly',
  ANNUAL: 'annual',
};

class PlanListService {
  constructor(apollo) {
    this.apollo = apollo;
    this.couponService = null;
    this.defaultPlanList = [];
    this.fractionDigits = 0;
    this.currency = 'USD';
    this.currentPlanName = '';
    this.currentPlanPeriod = null;
    this.isInactive = false;
    this.isShopifyPayment = false;

    this.hasDiscountsInMonthlyPeriod = false;
    this.hasDiscountsInAnnualPeriod = false;
  }

  setCouponService(couponService) {
    this.couponService = couponService;
  }

  setRegion(region) {
    this.fractionDigits = region === 'Hungary' ? 0 : 2;
    this.currency = region === 'Hungary' ? 'HUF' : 'USD';
  }

  setCurrentPlanName(name) {
    this.currentPlanName = name.toUpperCase();
  }

  setCurrentPlanPeriod(period) {
    this.currentPlanPeriod = period;
  }

  setAsInactive() {
    this.isInactive = true;
  }

  setAsShopifyPay() {
    this.isShopifyPayment = true;
  }

  getCouponValidationNotificationType() {
    return this.couponService.getNotificationType();
  }

  getSuggestedPeriod() {
    if (isNonPayingPlan(this.currentPlanName) === false) return this.currentPlanPeriod;
    if (!this.hasDiscountsInMonthlyPeriod && this.hasDiscountsInAnnualPeriod) return 12;
    return 1;
  }

  hasDiscountInPeriod(period) {
    if (period === 1 && this.hasDiscountsInMonthlyPeriod) return true;
    if (period === 12 && this.hasDiscountsInAnnualPeriod) return true;
    return false;
  }

  async getPlanList(options) {
    options = options ?? {};
    options.forceReload = options?.forceReload ?? false;
    options.disableDiscount = options?.disableDiscount ?? false;

    this.hasDiscountsInMonthlyPeriod = false;
    this.hasDiscountsInAnnualPeriod = false;

    if (!this.defaultPlanList.length || options.forceReload) {
      await this.loadDefaultPlanList();
    }
    if (!options.disableDiscount && options.forceReload) {
      await this.couponService.reload();
    }

    const plans = _clone(this.defaultPlanList);
    if (!plans) {
      console.warn('The package list is unavailable due to a backoffice connection error.');
      return [];
    }

    const updatedPlans = [];

    for (let plan of plans) {
      plan.name = plan.name.toUpperCase();

      if (isStandardPlan(plan.name)) {
        const foundPlan = getPlanByName(plan.name);
        if (!foundPlan) continue;
        plan = { ...plan, ...foundPlan };
      }

      if (this.isFreePlan(plan)) {
        plan.price = { monthly: '$0', annual: '$0', quarterly: '$0' };
      }

      plan = this.updatePlanWithDefaultProperties(plan);

      if (!this.isUniqueOrNonPayingPlan(plan)) {
        plan = this.updatePlanWithCalculatedPrices(plan);
      }

      if (!options.disableDiscount) {
        plan = this.updatePlanWithMonthlyDiscount(plan);
        plan = this.updatePlanWithAnnualDiscount(plan);
      }

      if (this.currentPlanName === plan.name) {
        plan = this.updateCurrentPlanWithCurrentPrice(plan);
        plan = this.updateCurrentPlanWithNextBillingDiscountPrice(plan);
      }

      updatedPlans.push(plan);
    }

    return updatedPlans;
  }

  updatePlanWithDefaultProperties(plan) {
    plan.originalPrice = {
      monthly: plan.price.monthly,
      annual: plan.price.annual,
      quarterly: plan.price.quarterly,
    };
    plan.hasDiscount = {
      monthly: false,
      annual: false,
      quarterly: false,
    };
    return plan;
  }

  updatePlanWithCalculatedPrices(plan) {
    return this.calculateMonthlyPriceWithAnnual(plan);
  }

  updatePlanWithMonthlyDiscount(plan) {
    const period = 'monthly';
    const periodInMonth = 1;
    if (this.couponService.hasValidCouponForPlan(plan.name, periodInMonth) === false) {
      return plan;
    }
    plan = this.calculateDiscount(plan, period);
    plan = this.updatePlanWithCalculatedPrices(plan);
    this.hasDiscountsInMonthlyPeriod = true;
    return plan;
  }

  updatePlanWithAnnualDiscount(plan) {
    const period = 'annual';
    const periodInMonth = 12;
    if (this.couponService.hasValidCouponForPlan(plan.name, periodInMonth) === false) {
      return plan;
    }
    plan = this.calculateDiscount(plan, period);
    plan = this.updatePlanWithCalculatedPrices(plan);
    this.hasDiscountsInAnnualPeriod = true;
    return plan;
  }

  calculateDiscount(plan, period) {
    const { prefix, priceNumber, suffix } = this.parsePriceString(plan.price[period]);

    const discounted = this.couponService.getDiscountedPriceForPlan(
      plan.name,
      period,
      priceNumber,
      this.currency,
    );
    const dynamicDigits = Math.ceil(discounted) === discounted ? 0 : this.fractionDigits;

    plan.price[period] = `${prefix}${discounted.toFixed(dynamicDigits)}${suffix}`;
    plan.originalPrice[period] = `${prefix}${priceNumber}${suffix}`;
    plan.hasDiscount[period] = true;

    return plan;
  }

  calculateMonthlyPriceWithAnnual(plan) {
    const { prefix, priceNumber, suffix } = this.parsePriceString(plan.price[PERIOD_KEYS.ANNUAL]);
    const discounted = priceNumber / 12;
    const dynamicDigits = Math.ceil(discounted) === discounted ? 0 : this.fractionDigits;

    if (!plan.calculatedPrice) plan.calculatedPrice = {};
    plan.calculatedPrice.monthlyPriceWithAnnual = `${prefix}${discounted.toFixed(
      dynamicDigits,
    )}${suffix}`;

    return plan;
  }

  updateCurrentPlanWithCurrentPrice(plan) {
    const period = this.getPeriodKey(this.currentPlanPeriod);
    plan.currentPrice = plan.originalPrice[period];
    if (this.couponService.hasValidActivatedCoupon()) {
      plan.currentPrice = plan.price[period];
    }
    return plan;
  }

  updateCurrentPlanWithNextBillingDiscountPrice(plan) {
    if (
      !this.couponService.hasValidNextBillingCoupon() &&
      !this.couponService.hasValidPendingCoupon()
    ) {
      return plan;
    }
    const period = this.getPeriodKey(this.currentPlanPeriod);
    const { prefix, priceNumber, suffix } = this.parsePriceString(plan.price[period]);
    const discounted = this.couponService.getNextBillingDiscountedPriceForPlan(
      plan.name,
      period,
      priceNumber,
      this.currency,
    );
    const dynamicDigits = Math.ceil(discounted) === discounted ? 0 : this.fractionDigits;
    plan.nextBillingDiscountPrice = `${prefix}${discounted.toFixed(dynamicDigits)}${suffix}`;
    return plan;
  }

  parsePriceString(priceString) {
    let { prefix, priceNumber, suffix } = separatePrice(priceString);
    if (prefix) prefix = `${prefix}`;
    if (suffix) suffix = ` ${suffix}`;
    return { prefix, priceNumber, suffix };
  }

  isFreePlan(plan) {
    return plan.name === 'FREE';
  }

  isUniqueOrNonPayingPlan(plan) {
    return (
      plan.name.indexOf('MASTER') === 0 ||
      plan.name.indexOf('ENTERPRISE') === 0 ||
      isNonPayingPlan(plan.name)
    );
  }

  getPeriodKey(periodInMonth) {
    let period = 'monthly';
    if (periodInMonth === 12) {
      period = 'annual';
    } else if (periodInMonth === 3) {
      period = 'quarterly';
    }
    return period;
  }

  async loadDefaultPlanList() {
    const {
      data: { plans },
    } = await this.apollo.query({
      query: GET_PLANS,
    });

    this.defaultPlanList = _clone(plans);
  }
}

export { PlanListService };
