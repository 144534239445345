import { mapGetters, mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['pages']),
    ...mapGetters(['selectedPageIndex']),
    isNextPageValid() {
      const nextPage = this.pages[this.selectedPageIndex + 1];
      return nextPage && !nextPage.isTeaser;
    },
  },
};
