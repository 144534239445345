<template lang="pug">
.register-wrapper
  component(:is="currentComponent")
</template>

<script>
  import Register from '@/views/Register.vue';
  import RegisterV2 from '@/views/RegisterV2.vue';
  import cookie from '@/mixins/cookie';

  export default {
    name: 'RegistrationSwitcher',
    mixins: [cookie],
    data() {
      return {
        isMobile: false,
      };
    },
    computed: {
      currentComponent() {
        const cname = 'registration-page-version';
        const { query, params } = this.$route;
        const { lang } = params;
        let version = this.getCookie(cname);

        if (this.isMobile) {
          this.setCookie(cname, version ?? 1, 365);
          return Register;
        }

        if (lang !== 'en') {
          return Register;
        }

        version = this.getCookie(cname);

        if (!version) {
          version = Math.random() > 0.5 ? '2' : '1';
          this.setCookie(cname, version, 365);
          this.$router.replace({ query: { ...query, version } });
        } else {
          this.$router.replace({ query: { ...query, version } });
        }

        return version === '2' ? RegisterV2 : Register;
      },
    },
    mounted() {
      this.checkViewport();
      window.addEventListener('resize', this.checkViewport);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.checkViewport);
    },
    methods: {
      checkViewport() {
        this.isMobile = window.innerWidth <= 768;
      },
    },
  };
</script>
