<template lang="pug">
.om-progressive-image(:style="backgroundImageUrl" ref="imageRef")
</template>
<script>
  import lazyImage from '@/mixins/lazyImage';

  export default {
    mixins: [lazyImage],
  };
</script>

<style lang="sass">
  .om-progressive-image
    transition: background 300ms ease-out
    width: 100%
</style>
