import Vue from 'vue';
import Router from 'vue-router';
import queryString from 'query-string';
import store from '@/store';
import { applyDesignSystemOutline } from '@/utils/designSystemHighlight';
import { clickEventTracker } from '@/services/userInteractionTracker/tracker';
import { track } from '@/services/xray';
import { omRoutes } from './routes';
import { addRouteClassesAndStyles, removeRouteClassesAndStyles } from './routeClassesAndStyles';
import {
  trackPageView,
  showEmailConfirmNotification,
  showShopifyInstallNotified,
  persistUTMParams,
  hasPermission,
  initializeEditor,
  getLang,
} from './utils';
import {
  redirectToHomeScreen,
  redirectToEditorParams,
  redirectByName,
  redirectByPath,
  noLoginNeeded,
  getParamsWithUserId,
  duringGracePeriod,
} from './guards';

clickEventTracker.init(document);

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(Router);

const router = new Router({
  routes: omRoutes,
  mode: 'history',
  parseQuery: queryString.parse,
  stringifyQuery: (v) => (Object.keys(v).length !== 0 ? `?${queryString.stringify(v)}` : ''),
});

router.beforeEach(async (to, from, next) => {
  const utmRedirectPath = persistUTMParams(to, from);
  if (utmRedirectPath !== null) {
    next(utmRedirectPath);
  }

  if (store.getters.isLoggedIn() && !store.state.account.settings.general) {
    await store.dispatch('fetchAccount');
  }
  store.commit('hideAllModals');
  window.om.bus.$emit('hideProductTour');

  initializeEditor(from, to);

  addRouteClassesAndStyles(to);

  const params = getParamsWithUserId(to);

  // pages need no permission (login, register, 404...)
  if (noLoginNeeded(to)) {
    next();
    return;
  }

  if (
    to.name !== 'better-email-check' &&
    !store.getters.userProfileBetterEmail &&
    store.getters.isOwner &&
    !store.getters.isSubUser &&
    !store.getters.isImpersonate &&
    !store.getters.isSuperAdmin
  ) {
    next({ name: 'better-email-check', params, query: from.query });
    return;
  }

  const lang = getLang();

  if (to.name !== 'mobile_redirect' && store.getters.isMobile && store.getters.isLoggedIn()) {
    next({ name: 'mobile_redirect', params });
    return;
  }

  if (to.path.includes('edit_beta')) {
    const redirectToEditor = redirectToEditorParams({ to });
    next(redirectToEditor);
    return;
  }

  if (!store.getters.isLoggedIn()) {
    next({ name: 'login', params: { lang } }); // accounttype undefined
    return;
  }

  const redirectToName = redirectByName({ from, params });
  if (redirectToName) {
    next(redirectToName);
    return;
  }

  const redirectToPath = redirectByPath({ from, router, params });
  if (redirectToPath) {
    next(redirectToPath);
    return;
  }

  const redirectToHome = redirectToHomeScreen({ to, store, params });
  if (redirectToHome) {
    next(redirectToHome);
    return;
  }

  const redirectToPlanSettings = duringGracePeriod({ to, store, params });
  if (redirectToPlanSettings) {
    next(redirectToPlanSettings);
    return;
  }

  // When the route is not included in the router file but the user is authenticated
  if (!to.matched.length) {
    const redirectToUserHome = redirectToHomeScreen({ to: { fullPath: '/' }, store, params });
    if (redirectToUserHome) {
      const { fullPath } = to.fullPath;
      track('invalidRoute', { fullPath });
      next(redirectToUserHome);
      return;
    }
  }
  if (!hasPermission(to)) {
    next({ name: 'login', params: { lang } });
    return;
  }

  next({ params });
});

router.afterEach((to, from) => {
  if (!to.meta.disableScrollTop) {
    document.body.scrollTop = 0;
  }

  showEmailConfirmNotification(to);
  showShopifyInstallNotified(to);

  removeRouteClassesAndStyles(to);

  trackPageView(router.app, { from, to });

  setTimeout(() => {
    applyDesignSystemOutline();
  }, 500);
});

export default router;
