<template lang="pug">
div
  .mb-4
    .row.align-items-center.mb-2
      .col-sm-4.col-form-label
        label {{ $t('integrations.mailerLite.fields.groupId') }}
        om-tooltip.pl-1
          span {{ $t('integrations.mailerLite.tooltip.groupId') }}
      .col-sm-8
        select.form-control(
          :class="{ 'is-invalid': $v.settings.groupId.$error }"
          v-model="settings.groupId"
        )
          option(:value="null") {{ $t('select') }}
          template(v-for="list in lists")
            option(:value="list.id") {{ list.name }}
  integration-binding(:type="globalIntegration.type" :bindings.sync="bindings" :fields="fields")
</template>

<script>
  import GET_INTEGRATION_DATA from '@/graphql/GetIntegrationData.gql';
  import IntegrationBinding from '@/components/IntegrationBinding.vue';
  import integrationSetting from '@/mixins/integrationSetting';
  import { required } from 'vuelidate/lib/validators';

  export default {
    components: {
      IntegrationBinding,
    },
    mixins: [integrationSetting],

    data() {
      return {
        lists: [],
        fields: [],
        settings: {
          groupId: null,
          customFieldName: null,
          customFieldText: '',
        },
      };
    },

    validations: {
      settings: {
        groupId: {
          required,
        },
      },
    },

    apollo: {
      integrationData: {
        query: GET_INTEGRATION_DATA,
        variables() {
          return {
            integrationType: this.globalIntegration.type,
            integrationId: this.globalIntegration._id,
          };
        },
        result({ data }) {
          if (!data?.integrationData) {
            this.$emit('loaded');
            this.$emit('error');
            return;
          }
          const {
            integrationData: { lists, fields },
          } = data;
          this.lists = lists;
          this.fields = fields;
          this.$emit('loaded');
        },
      },
    },
  };
</script>
