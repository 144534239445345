<template lang="pug">
mixin pageViewExpression
  span(
    v-if="!expression.pageType || expression.pageType === 'page'"
    v-html="$t('frontendRuleLead.viewedPage', { operator: $t(expression.operator).toLowerCase(), value: needStartingSlash(expression.operator) ? domain + expression.value : expression.value })"
  )
  span(
    v-else-if="!expression.operand && expression.pageType === 'product'"
    v-html="$t('frontendRuleLead.onAllProductPages')"
  )
  span(
    v-else-if="!expression.operand && expression.pageType === 'category'"
    v-html="$t('frontendRuleLead.onAllCategoryPages', { category: isActiveShopifyDomain(domain) ? 'collection' : 'category' })"
  )
  span(
    v-else-if="expression.operand && expression.pageType === 'product' && expression.operand !== 'productAvailability' && expression.operand !== 'productCategory'"
    v-html="$t('frontendRuleLead.viewedProductPage', { column: ':', operand: $t(`frontendRules.viewedPage.operands.${expression.operand}`, { category: isActiveShopifyDomain(domain) ? 'collection' : 'category' }).toLowerCase(), operator: $t(expression.operator).toLowerCase(), value: expression.value })"
  )
  span(
    v-else-if="expression.operand && expression.pageType === 'product' && expression.operand === 'productAvailability'"
    v-html="$t('frontendRuleLead.viewedProductPage', { the: $t('frontendRuleLead.the'), column: '', operand: $t('product', { category: isActiveShopifyDomain(domain) ? 'collection' : 'category' }).toLowerCase(), operator: $t(expression.operator).toLowerCase(), value: $t('available') })"
  )
  span(
    v-else-if="expression.operand && expression.pageType === 'product' && expression.operand === 'productCategory'"
    v-html="$t('frontendRuleLead.viewedProductPageWithCategory', { value: !expression.value ? '' : expression.value.map((category) => category.key).join(', '), category: isActiveShopifyDomain(domain) ? 'collection' : 'category' })"
  )
  span(
    v-else-if="expression.operand && expression.pageType === 'category' && expression.operand !== 'specificCategory'"
    v-html="$t('frontendRuleLead.viewedCategoryPage', { operand: $t(`frontendRules.viewedPage.operands.${expression.operand}`, { category: isActiveShopifyDomain(domain) ? 'collection' : 'category' }).toLowerCase(), operator: $t(expression.operator).toLowerCase(), value: expression.value, category: isActiveShopifyDomain(domain) ? 'collection' : 'category' })"
  )
  span(
    v-else-if="expression.operand && expression.pageType === 'category' && expression.operand === 'specificCategory'"
    v-html="$t('frontendRuleLead.viewedSpecificCategoryPage', { operand: $t(`frontendRules.viewedPage.operands.${expression.operand}`, { category: isActiveShopifyDomain(domain) ? 'collection' : 'category' }).toLowerCase(), value: !expression.value ? '' : expression.value.map((category) => category.key).join(', '), category: isActiveShopifyDomain(domain) ? 'collection' : 'category' })"
  )
  span(v-else-if="expression.pageType === homepageType" v-html="$t('frontendRuleLead.onHomepage')")

.text-color-mild-gray
  template(v-if="rule.type === 'pageViewerType'")
    span(
      v-html="$t('frontendRuleLead.pageViewerType', { p: prefix, type: $t(`pageViewerType.${rule.options.value}`).toLowerCase() })"
    )
  template(v-if="rule.type === 'source'")
    span(v-html="$t('frontendRuleLead.source', { p: prefix })")
    .mt-2
      template(v-for="type in notCustomSources(rule.options.expression)")
        .text-primary.font-weight-bold {{ getSourceByType(type) }}
    div
      template(v-for="expression in rule.options.expression")
        template(v-if="expression.source === 'custom'")
          div(
            v-html="$t('frontendRuleLead.sourceCustom', { operator: $t(expression.operator).toLowerCase(), value: expression.value })"
          )
  template(v-if="rule.type === 'country'")
    span {{ $t('frontendRuleLead.countryPrefix') }}
    span.text-primary.font-weight-bold {{ $t(`frontendRuleLead.${shouldIncludeCountry}`) }}
    span {{ $t('frontendRuleLead.country') }}
    template(v-if="rule.options.countries.length > 2")
      span.text-primary.font-weight-bold {{ `${rule.options.countries[0].label}, ` }}
      span.text-primary.font-weight-bold {{ `${rule.options.countries[1].label} ` }}
      span.font-weight-bold(:title="rule.options.countries.map((c) => c.label).join(', ')") +{{ rule.options.countries.length - 2 }} {{ $t('frontendRuleLead.more') }}
    template(v-else)
      span.text-primary.font-weight-bold {{ rule.options.countries[0].label }}
      template(v-if="rule.options.countries.length > 1")
        span {{ ` ${$t('and')} ` }}
        span.text-primary.font-weight-bold {{ rule.options.countries[1].label }}

  template(v-if="rule.type === 'minimumPageVisit'")
    span(
      v-html="$t('frontendRuleLead.minimumPageVisit', { p: prefix, operator: $t(`frontendRules.minimumPageVisit.operators.${rule.options.operator || 'atLeast'}`), value: rule.options.value })"
    )
  .mt-1(v-if="rule.type === 'viewedPageV2'")
    div(v-if="rule.options.includeExpressions && rule.options.includeExpressions.length")
      template(v-for="(expression, index) in rule.options.includeExpressions")
        .d-flex
          span.font-weight-bold.mr-1(v-if="index === 0") {{ $t('frontendRules.viewedPageV2.introInclude') }}
          +pageViewExpression
        div
          span.font-weight-bold(v-if="index !== rule.options.includeExpressions.length - 1") {{ $t('or') }}

    div(
      v-if="rule.options.excludeExpressions && rule.options.excludeExpressions.length"
      :class="rule.options.includeExpressions.length > 1 ? 'mt-2' : ''"
    )
      template(v-for="(expression, index) in rule.options.excludeExpressions")
        .d-flex
          span.font-weight-bold.mr-1(v-if="index === 0") {{ $t('frontendRules.viewedPageV2.introExclude') }}
          +pageViewExpression
        div
          span.font-weight-bold(v-if="index !== rule.options.excludeExpressions.length - 1") {{ $t('or') }}

  template(v-if="rule.type === 'previouslyViewedPage'")
    template(v-for="(expressionGroup, groupIndex) in rule.options.expressions")
      template(v-for="expression in expressionGroup")
        div(
          v-html="$t('frontendRuleLead.previouslyViewedPage', { operator: $t(expression.operator).toLowerCase(), value: needStartingSlash(expression.operator) ? domain + expression.value : expression.value })"
        )
      div(v-if="groupIndex !== rule.options.expressions.length - 1") {{ $t('orOperation') }}
  template(v-if="rule.type === 'notViewedPageRecent'")
    template(v-for="expression in rule.options.expression")
      div(
        v-if="ruleset === 'default'"
        v-html="`${$t('frontendRuleLead.notViewedPageRecentDefaultRuleset.text', { operator: $t(expression.operator).toLowerCase(), value: needStartingSlash(expression.operator) ? domain + expression.value : expression.value })} ${$t(`${expression.secondsToBlock > 0 ? 'frontendRuleLead.notViewedPageRecentDefaultRuleset.withBlockingTime' : 'frontendRuleLead.notViewedPageRecentDefaultRuleset.noBlockingTime'}`, { secondsToBlock: expression.secondsToBlock })}`"
      )
      div(
        v-else
        v-html="$t('frontendRuleLead.notViewedPageRecent', { operator: $t(expression.operator).toLowerCase(), value: needStartingSlash(expression.operator) ? domain + expression.value : expression.value, secondsToBlock: expression.secondsToBlock })"
      )

  template(v-if="rule.type === 'timeBasedActualPage'")
    span(
      v-html="$t('frontendRuleLead.timeBasedActualPage', { p: prefix, value: rule.options.value })"
    )
  template(v-if="rule.type === 'timeBasedSession'")
    span(
      v-html="$t('frontendRuleLead.timeBasedSession', { p: prefix, value: rule.options.value })"
    )
  template(v-if="rule.type === 'maximumPopupDisplay'")
    span(
      v-html="$t('frontendRuleLead.maximumPopupDisplay', { p: prefix, value: rule.options.value })"
    )
    template(v-if="rule.options.value > 1")
      span(
        v-html="$t('frontendRuleLead.maximumPopupDisplay1', { time: rule.options.time, unit: $t('fr' + rule.options.unit) })"
      )
    template(v-else)
      span .
  template(v-if="rule.type === 'visitorAttribute'")
    template(v-for="(expressionGroup, groupIndex) in rule.options.expressions")
      template(v-for="(expression, targetIndex) in expressionGroup")
        div(
          v-if="isCustomAttribute(groupIndex, targetIndex)"
          v-html="$t('frontendRuleLead.visitorAttributeCustomAttribute', { name: expression.attributeName, operator: $t(expression.operator).toLowerCase(), value: expression.value })"
        )
        div(
          v-else
          v-html="$t('frontendRuleLead.visitorAttribute', getRuleSummary(groupIndex, targetIndex))"
        )
      div(v-if="groupIndex !== rule.options.expressions.length - 1") {{ $t('orOperation') }}
  template(v-if="rule.type === 'visitorCartV3'")
    template(v-for="(expression, index) in rule.options.expressions")
      .row.mx-0
        span.mr-1 {{ index === 0 ? $t('visitorCartV3.appearIf') : $t('and') }}
        template(v-if="Array.isArray(expression.value)")
          .col-auto.px-0.text-primary.font-weight-bold
            span.text-primary.font-weight-bold.mr-1 {{ $t(`visitorCartV3.${expression.for}`).toLowerCase() }}
            span.mr-1 {{ $t(`visitorCartV3.operators.${expression.operator}`) }}
            template(v-if="expression.attributeName === 'name'")
              span.mr-1 {{ $t(`visitorCartV3.productOperators.${expression.attributeName}`) }}
              div
                span.mr-1 {{ $t('visitorCartV3.whereV2', { operator: $t(`visitorCartV3.attributeOperators.${expression.attributeOperator}`) }) }}
                template(v-for="(productName, productIndex) in expression.value")
                  span.mr-1 {{ productName }}
                  template(v-if="productIndex < expression.value.length - 1")
                    span.mr-1 {{ $t('or') }}
            template(v-else-if="expression.attributeName === 'id'")
              span.mr-1 {{ $t(`visitorCartV3.productOperators.${expression.attributeName}`) }}
              div
                template(v-for="(productId, productIndex) in expression.value")
                  span.mr-1 {{ getProductTitleById(productId, expression) }}
                  template(v-if="productIndex < expression.value.length - 1")
                    span.mr-1 {{ $t('or') }}
            template(v-else)
              span.mr-1.text-primary.font-weight-bold {{ $t('visitorCartV3.any') }}
              div
                span.mr-1.font-weight-bold {{ $t('visitorCartV3.where') }}
                span.mr-1 {{ expression.attributeName }}
                span.mr-1 {{ $t('visitorCartV3.which') }}
                span.mr-1 {{ $t(`visitorCartV3.type.${expression.type}`) }}
                span.mr-1 {{ $t(`visitorCartV3.attributeOperators.${expression.attributeOperator}`) }}
                template(v-for="(value, index) in expression.value")
                  span.mr-1 {{ value }}
                  template(v-if="index < expression.value.length - 1")
                    span.mr-1 {{ $t('or') }}
          .col-5.px-0
        template(v-else)
          .col-auto.px-0.text-primary.font-weight-bold
            span.text-primary.font-weight-bold.mr-1 {{ $t(`visitorCartV3.${expression.for}`).toLowerCase() }}
            template(v-if="expression.operator")
              span.mr-1 {{ $t(`visitorCartV3.operators.${expression.operator}`) }}
              span.mr-1.text-primary.font-weight-bold {{ $t('visitorCartV3.any') }}
              br
              span.mr-1.text-primary.font-weight-bold {{ $t('visitorCartV3.where') }}
              span.mr-1 {{ expression.attributeName }}
              span.mr-1 {{ $t('visitorCartV3.which') }}
              span.mr-1 {{ $t(`visitorCartV3.type.${expression.type}`) }}
              span.mr-1 {{ $t(`visitorCartV3.attributeOperators.${expression.attributeOperator}`) }}
            template(v-else)
              span.text-primary.font-weight-bold.mr-1 {{ $t(`visitorCartV3.attributeOperators.${expression.attributeOperator}`) }}
            template(v-if="expression.attributeOperator === 'interval'")
              span.text-primary.font-weight-bold.mr-1 {{ expression.value.split('-')[0] }}
              span.text-primary.font-weight-bold.mr-1 {{ $t('and') }}
              span.text-primary.font-weight-bold.mr-1 {{ expression.value.split('-')[1] }}
            template(v-else)
              span.text-primary.font-weight-bold.mr-1 {{ expression.value }}
  template(v-if="rule.type === 'campaignProgressState'")
    template(v-for="(expressionGroup, groupIndex) in rule.options.expressions")
      template(v-for="(expression, expressionIndex) in expressionGroup")
        div(v-html="campaignProgressStateTranslation(expression)")
        //- span(v-if="expressionIndex !== expressionGroup.length -1") {{$t('and')}}
      div(v-if="groupIndex !== rule.options.expressions.length - 1") {{ $t('orOperation') }}
  template(v-if="rule.type === 'ipBlock'")
    template(v-for="expression in rule.options.expression")
      div
        span(
          v-if="withPrefix"
          v-html="(expression.type === 'range' ? $t('ip.inRange') : $t('ip.specific')) + ': '"
        )
        span(
          v-else
          v-html="(expression.type === 'range' ? $t('ip.notInRange') : $t('ip.notSpecific')) + ': '"
        )
        span(
          v-html="$t('frontendRuleLead.ipBlock', { ips: expression.type === 'range' ? expression.value.replace('|', ' - ') : expression.value })"
        )
  template(v-if="rule.type === 'cookie'")
    template(v-for="(expressionGroup, groupIndex) in rule.options.expressions")
      template(v-for="expression in expressionGroup")
        div(
          v-html="$t('frontendRuleLead.cookie', { name: expression.cookieName, operator: $t(expression.operator).toLowerCase(), value: expression.value })"
        )
      div(v-if="groupIndex !== rule.options.expressions.length - 1") {{ $t('orOperation') }}
  template(v-if="rule.type === 'aBlock'")
    span(
      v-html="$t('frontendRuleLead.aBlock', { p: prefix, value: $t(`aBlock.${rule.options.value}`).toLowerCase() })"
    )
  template(v-if="rule.type === 'loggedIn'")
    span(
      v-html="$t('frontendRuleLead.loggedIn', { p: prefix, value: $t(`loggedIn.${rule.options.value}`).toLowerCase() })"
    )
  template(v-if="rule.type === 'subscribers'")
    span(v-html="subscribersRuleLead")
  template(v-if="rule.type === 'klaviyoSegment'")
    template(v-if="rule.options.condition === 'existingProfile'")
      span.d-block(v-html="$t('frontendRuleLead.klaviyoSegmentExistingProfiles')")
    template(v-if="rule.options.condition === 'groupMember'")
      span.d-block(
        v-if="displaySegments.length"
        v-html="$t('frontendRuleLead.klaviyoSegmentDisplay', { items: displaySegments })"
      )
      span.d-block(
        v-if="dontDisplaySegments.length"
        v-html="$t('frontendRuleLead.klaviyoSegmentDontDisplay', { items: dontDisplaySegments })"
      )
</template>

<script>
  import CAMPAIGN_LIST from '@/graphql/CampaignList.gql';
  import getSourceByType from '@/mixins/getSourceByType';
  import { needStartingSlash } from '@/util';
  import { mapGetters } from 'vuex';
  import ALL_USER_FIELDS from '@/graphql/AllUserFields.gql';
  import visitorAttribute from '@/mixins/visitorAttribute';

  const HOMEPAGE_TYPE = 'homepage';

  export default {
    mixins: [visitorAttribute, getSourceByType],
    props: {
      rule: {
        type: Object,
      },
      withPrefix: {
        type: Boolean,
        default: true,
      },
      domain: {
        type: String,
      },
      ruleset: { type: String, default: 'default' },
    },
    data() {
      return {
        homepageType: HOMEPAGE_TYPE,
        campaignList: [],
        userFields: [],
      };
    },

    computed: {
      ...mapGetters(['isActiveShopifyDomain', 'hasAccountFeature', 'getLocale']),
      shouldIncludeCountry() {
        return this.rule.options.operator === 'notAppear' ? 'negation' : 'willAppear';
      },
      prefix() {
        return this.withPrefix ? this.$t('frontendRuleLead.prefix') : '';
      },
      isCampaignProgressStateTurbo() {
        return this.rule.type === 'campaignProgressState';
      },
      displaySegments() {
        return this.rule.options.expression.in.map((item) => item.name).join(', ');
      },
      dontDisplaySegments() {
        return this.rule.options.expression.notIn.map((item) => item.name).join(', ');
      },
      subscribersRuleLead() {
        const anyList = `<span class="text-primary font-weight-bold">${this.$t(
          'frontendRules.subscribers.anyList',
        )}</span>`;
        const type = this.$t(`frontendRules.subscribers.types.${this.rule.options.type}`);
        const joinedLists = this.rule.options.lists
          .map(
            (v) =>
              `<span class="text-primary font-weight-bold">${
                v.id.indexOf('default') !== 0
                  ? v.name
                  : `${v.name} (${v.type.charAt(0).toUpperCase() + v.type.slice(1)}${
                      v.listName ? `, ${v.listName}` : ''
                    })`
              }</span>`,
          )
          .join(this.$t('frontendRuleLead.subscribers.orTo'));
        const lists =
          this.rule.options.lists.length === 1 && this.rule.options.lists[0].id === 'all'
            ? anyList
            : joinedLists;

        return `${this.$t('frontendRules.subscribers.yourCampaignWill')} ${this.$t(
          'frontendRuleLead.subscribers.text',
          { type, lists },
        )}`;
      },
      userFieldIds() {
        if (!this.rule?.options?.expressions) {
          return [];
        }
        return this.rule.options.expressions.reduce((userFieldIds, expressionGroup) => {
          expressionGroup?.forEach?.((expression) => {
            if (expression.state === 'filledForm') {
              userFieldIds.push(expression.field.customId);
            }
          });

          return userFieldIds;
        }, []);
      },
    },

    watch: {
      async userFieldIds() {
        if (this.userFieldIds.length) {
          this.userFields = await this.getUserFields(this.userFieldIds);
        }
      },
    },
    async created() {
      if (this.rule.type === 'campaignProgressState') {
        this.$apollo
          .query({
            query: CAMPAIGN_LIST,
          })
          .then(({ data: { campaignList } }) => {
            this.campaignList = campaignList;
          });
      }
    },
    async mounted() {
      if (this.userFieldIds.length) {
        this.userFields = await this.getUserFields(this.userFieldIds);
      }
    },

    methods: {
      getMoreItemsTitle(expressionValue) {
        return expressionValue.map(({ title }) => title).join(', ');
      },
      getFirstThreeProduct(expressionValue) {
        return expressionValue.slice(0, 3);
      },
      async getUserFields(fieldIds) {
        try {
          const {
            data: { allFields },
          } = await this.$apollo.query({
            query: ALL_USER_FIELDS,
            variables: {
              fieldIds,
              excludeBuiltIn: true,
            },
          });

          return allFields;
        } catch (e) {
          console.error('Error during fetch fields', e.message);
          return [];
        }
      },
      campaignProgressStateTranslation(expression) {
        if ((expression.messageType || expression.operator) && this.isCampaignProgressStateTurbo) {
          let campaignName;
          if (expression.campaignId === 'CURRENT_CAMPAIGN') {
            campaignName = this.$t('frontendRules.campaignProgressState.turbo.currentCampaign');
          } else {
            campaignName =
              this.getCampaignNameById(expression.campaignId) ||
              this.$t('frontendRules.campaignProgressState.turbo.deletedCampaign', {
                campaign: expression.campaignName,
              });
          }

          const stateTranslation = this.$t(
            `frontendRules.campaignProgressState.turbo.states.${expression.state}`,
          );

          if (expression.state === 'filledForm') {
            const operatorTranslation = this.$t(
              `frontendRules.campaignProgressState.turbo.operators.${expression.operator}`,
            );
            const field = this.userFields.find(
              (field) => field.customId === expression.field.customId,
            );
            const deletedString = field?.name
              ? ''
              : ` ${this.$t('frontendRules.campaignProgressState.turbo.deleted')}`;

            return this.$t('frontendRuleLead.campaignProgressState.filledForm', {
              field: `${field?.name || expression.field?.name}${deletedString}`,
              state: stateTranslation,
              operator: operatorTranslation,
              attributeValue: expression.attributeValue,
            });
          }

          const messageType = expression.messageType || 'campaign';
          const messageTypeTranslation = this.$t(
            `frontendRules.campaignProgressState.turbo.messageType.${messageType}`,
          );
          const visitType = expression.visitType || 'ever';
          const visitTypeTrans = this.$t(
            `frontendRules.campaignProgressState.turbo.visitType.${visitType}`,
          );

          return this.$t('frontendRuleLead.campaignProgressState.turbo', {
            campaign: campaignName,
            state: stateTranslation,
            messageType: messageTypeTranslation,
            visitType: visitTypeTrans,
          });
        }

        // for backwards compatibility for CampaignProgressState
        const state = this.isCampaignProgressStateTurbo
          ? this.$t(`frontendRules.campaignProgressState.turbo.states.${expression.state}`)
          : this.$t(`frontendRules.campaignProgressState.states.${expression.state}`);

        return this.$t('frontendRuleLead.campaignProgressState.text', {
          campaign: this.getCampaignNameById(expression.campaignId),
          state,
        });
      },
      getCampaignNameById(id) {
        if (!id) {
          return this.isCampaignProgressStateTurbo ? this.$t('any').toLowerCase() : this.$t('all');
        }

        for (const campaign of this.campaignList) {
          if (campaign.id === id) {
            return campaign.name;
          }
        }
      },
      getProductTitleById(productId, expression) {
        return (
          expression?.meta?.products?.find((product) => product.id === productId)?.title ??
          productId
        );
      },
      notCustomSources(expression) {
        const ret = {};

        for (const e of expression) {
          if (e.source !== 'custom') {
            ret[e.source] = e.source;
          }
        }

        return ret;
      },

      needStartingSlash,
      groupValuesByProp(expressionGroup, prop) {
        return (
          expressionGroup?.reduce((acc, expression) => {
            const existingExpressionIndex = acc.findIndex(
              (item) => item && item[prop] === expression[prop],
            );
            if (existingExpressionIndex > -1) {
              acc[existingExpressionIndex].values.push(expression.value);
            } else {
              acc.push({ ...expression, values: [expression.value] });
            }
            return acc;
          }, []) ?? []
        );
      },
      isCartPositionBefore(forExpression) {
        return (
          this.getLocale === 'en' || (forExpression === 'allProducts' && this.getLocale !== 'hu')
        );
      },
    },
  };
</script>
