const { UNLIMITED_VALUE, TRIAL_PACKAGE_SKU, BELSO_PACKAGE_SKU } = require('./constants');

const trialPlan = {
  sku: {
    monthly: TRIAL_PACKAGE_SKU,
  },
  name: 'TRIAL',
  displayName: 'Trial',
  color: '#7954ea',
  details: {
    visitors: 40000,
    domains: 2,
    campaigns: UNLIMITED_VALUE,
    unbranded: false,
  },
  features: {},
  public: false,
};

const standardPlans = [
  trialPlan,
  {
    sku: {
      monthly: 'Package-HU-BRONZE-1',
      annual: 'Package-HU-BRONZE-12',
    },
    name: 'BRONZE',
    displayName: 'Bronze',
    color: '#7954ea',
    details: {
      visitors: 5000,
      domains: 1,
      campaigns: UNLIMITED_VALUE,
      unbranded: false,
    },
    features: {},
    public: true,
  },
  {
    sku: {
      monthly: 'Package-HU-SILVER-1',
      annual: 'Package-HU-SILVER-12',
    },
    name: 'SILVER',
    displayName: 'Silver',
    color: '#7954ea',
    details: {
      visitors: 15000,
      domains: 2,
      campaigns: UNLIMITED_VALUE,
      unbranded: false,
    },
    features: {},
    public: true,
  },
  {
    sku: {
      monthly: 'Package-HU-GOLD-1',
      annual: 'Package-HU-GOLD-12',
    },
    name: 'GOLD',
    displayName: 'Gold',
    color: '#7954ea',
    details: {
      visitors: 50000,
      domains: 4,
      campaigns: UNLIMITED_VALUE,
      unbranded: false,
    },
    features: {},
    public: true,
  },
  {
    sku: {
      monthly: 'Package-HU-PLATINUM-1',
      annual: 'Package-HU-PLATINUM-12',
    },
    name: 'PLATINUM',
    displayName: 'Platinum',
    color: '#7954ea',
    details: {
      visitors: 250000,
      domains: 10,
      campaigns: UNLIMITED_VALUE,
      unbranded: false,
    },
    features: {},
    public: true,
  },
  {
    sku: {
      monthly: 'Package-HU-DIAMOND-1',
      annual: 'Package-HU-DIAMOND-12',
    },
    name: 'DIAMOND',
    displayName: 'Diamond',
    color: '#7954ea',
    details: {
      visitors: 1000000,
      domains: 999,
      campaigns: UNLIMITED_VALUE,
      unbranded: true,
    },
    features: {},
    public: true,
  },
];

const customPlan = {
  name: 'ENTERPRISE',
  displayName: 'Enterprise',
  color: '#7954ea',
  details: {
    domains: UNLIMITED_VALUE,
    campaigns: UNLIMITED_VALUE,
    unbranded: true,
  },
  features: {},
  public: true,
};

const demoPlan = {
  sku: {
    monthly: BELSO_PACKAGE_SKU,
  },
  name: 'BELSO',
  displayName: 'Belso',
  color: '#2cc898',
  details: {
    visitors: 500000,
    domains: 10,
    campaigns: UNLIMITED_VALUE,
    prioritySupport: false,
    unbranded: false,
  },
  features: {},
  public: false,
};

const getSkues = () => {
  return [
    ...standardPlans.map((plan) => plan.sku?.monthly).filter((sku) => !!sku),
    ...standardPlans.map((plan) => plan.sku?.annual).filter((sku) => !!sku),
  ].filter((sku) => !!sku);
};

module.exports = {
  NON_FREEMIUM_PLAN_NAMES: standardPlans.map((plan) => plan.name),
  NON_FREEMIUM_PLAN_SKUES: getSkues(),
  NON_FREEMIUM_PLANS: standardPlans,
  TRIAL_PLAN: trialPlan,
  ENTERPRISE_PLAN: customPlan,
  BELSO_PLAN: demoPlan,
  BELSO_PLAN_NAME: demoPlan.name,
};
