<template lang="pug">
.ds-input-element-dropdown
  PaneLayout(layout="row" gap="4px")
    template(#left)
      .ds-input-element-dropdown-label {{ $t('type') }}
    template(#right)
      .d-flex.w-100.align-items-center
        OmSelect#inputElementDropdown.w-100.full-width(
          :value="value"
          :options="items"
          :placeholder="placeholder"
          @input="$emit('input', $event)"
          useCustomOption
          size="small"
          optionText="name"
          optionKey="customId"
          optionValue="customId"
        )
          template(#last-option)
            OmButton.brand-btn-primary-outline(block link small @click="$emit('create')") {{ $t('createNew') }}
        OmButton.ml-2(iconOnly icon="cog" ghost small @click="$emit('manage')")
</template>
<script>
  import PaneLayout from '@/components/Editor/PaneLayout.vue';

  export default {
    components: { PaneLayout },
    props: {
      label: { type: String, required: false },
      items: { type: Array, default: () => [], required: true },
      placeholder: { type: String, required: false },
      value: { type: Object, required: true }, // structure => { name, customId }
    },
  };
</script>

<style scoped lang="sass">
  @import "@/sass/variables/_colors.sass"

  .ds-input-element-dropdown
    &-create
      border-top: 1px solid gray
    &-label
      font-size: .75rem
      color: $om-dark-grey-3
</style>
<style lang="sass">
  #inputElementDropdown
    &.select-input
      max-width: 9.5rem
      ~ .select-selection
        max-width: 9.5rem
</style>
