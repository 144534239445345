import { mapState, mapMutations, mapGetters } from 'vuex';
import { get as _get, debounce, setWith } from 'lodash-es';
import { EXCLUDED_COMPONENTS } from '@/config/constants';

const $undefined = Symbol('$undefined');

export default {
  props: {
    editMobile: { type: Boolean, default: false },
    useNested: { type: Boolean, default: false },
  },
  data: () => ({
    canEditMobile: false,
    canUseNested: false,
  }),
  computed: {
    ...mapGetters(['nestedAccess']),
    ...mapState({
      editedDeviceType(state) {
        return (this.canEditMobile || this.editMobile) && state.mobilePreview
          ? 'mobile'
          : 'desktop';
      },
      isEditor(state) {
        return state.isEditor;
      },
    }),
    element: {
      get() {
        return this.$store.state.selectedElement;
      },
      set(value) {
        this.$store.state.selectedElement = value;
      },
    },
    block: {
      get() {
        return this.$store.state.selectedRow;
      },
      set(value) {
        this.$store.state.selectedRow = value;
      },
    },
    column: {
      get() {
        return this.$store.state.selectedColumn;
      },
      set(value) {
        this.$store.state.selectedColumn = value;
      },
    },
    page: {
      get() {
        return this.$store.state.selectedPage;
      },
      set(value) {
        this.$store.state.selectedPage = value;
      },
    },
    global: {
      get() {
        return this.$store.state.globalStyle;
      },
      set(value) {
        this.$store.state.globalStyle = value;
      },
    },
    isExcluded() {
      return !EXCLUDED_COMPONENTS.includes(this.selectedElement.type);
    },
  },
  methods: {
    ...mapMutations(['updateData', 'updateStyle']),
    isGlobalStyle(path) {
      return /^globalStyle/.test(path);
    },
    isPageStyle(path) {
      return /^selectedPage/.test(path);
    },
    isSubElement(path) {
      return /^subElements/.test(path);
    },
    isDataPath(path) {
      return /^data/.test(path);
    },
    isFixedDevice(path) {
      return /^(mobile|desktop)/.test(path);
    },
    isStatePath(path) {
      return /^state/.test(path);
    },
    getPath(path) {
      let result = `${this.editedDeviceType}.${path}`;

      if (this.subPath) {
        result = `${this.subPath}.${path}`.replace('$device', this.editedDeviceType);
      }

      return result;
    },
    replacePathPrefixes(path) {
      return path.replace(/^(selectedElement\.|selectedRow\.|selectedColumn\.)/, '');
    },
    getRealPath(path, removeChanger = true, device = null) {
      path = this.replacePathPrefixes(path);
      if (device) {
        path = path.replace('mobile', device);
      } else {
        path = path.replace('$device', this.editedDeviceType);
      }

      if (this.isGlobalStyle(path)) {
        return removeChanger ? path.replace('globalStyle.', '') : path;
      }

      if (this.isPageStyle(path)) {
        path = removeChanger ? path.replace('selectedPage.', '') : path;

        if (!removeChanger) {
          return path;
        }
      }

      if (this.isStatePath(path)) {
        return removeChanger ? path.replace('state.', '') : path;
      }

      if (this.isSubElement(path)) {
        if (device && path.includes('$device')) {
          return path.replace('$device', device);
        }
        return path;
      }

      if (!this.isDataPath(path) && !this.isFixedDevice(path)) {
        if (device) return `${device}.${path}`;

        return `${this.editedDeviceType}.${path}`;
      }

      return path;
    },
    getValueOf(path, _def = null, removeChanger = true, type = null) {
      if (this.useNested || this.canUseNested) {
        return this.nestedAccess(path);
      }

      const realPath = this.getRealPath(path, removeChanger);
      const isGlobalStyle = this.isGlobalStyle(path);
      const isPageStyle = this.isPageStyle(path);
      const isStatePath = this.isStatePath(path);

      let context;
      if (isGlobalStyle) {
        context = this.global;
      } else if (isPageStyle) {
        context = this.page;
      } else if (isStatePath) {
        context = this.$store.state;
      } else {
        context = this.element;
      }

      const value = _get(context, realPath, $undefined);

      if (!value && type === 'OmInputGroup') {
        const oppositePath = this.getRealPath(path, removeChanger, 'desktop');
        const value = _get(context, oppositePath, $undefined);
        return { forced: true, desktopValue: value };
      }

      if (context && value === $undefined) {
        if (process.env.NODE_ENV !== 'production') {
          console.warn(
            `You are trying to get the value of an undefined property at '${path}'. This may result in reactivity issues.`,
          );
        }

        return _def;
      }

      return value;
    },
    smartGetValueOf(path, _def = null) {
      const ctx = this.element || this.column || this.block || this.page;

      if (!ctx) {
        if (this.isEditor) throw new Error('No context');
        return;
      }
      const replacedPath = path.replace('$device', this.editedDeviceType);
      const value = _get(ctx, replacedPath, $undefined);

      if (this.editedDeviceType === 'mobile' && value === null) {
        const desktopPath = path.replace('$device', 'desktop');
        const desktopDefaultValue = _get(ctx, desktopPath, $undefined);
        return { defaultToDesktop: true, desktopValue: desktopDefaultValue };
      }

      if (ctx && value === $undefined) {
        if (process.env.NODE_ENV !== 'production') {
          console.warn(
            `You are trying to get the value of an undefined property at '${path}'. This may result in reactivity issues.`,
          );
        }

        return _def;
      }

      return value;
    },
    _emitUserInputChange: debounce(function (path, value, { emitChange = true } = {}) {
      if (emitChange) {
        window.om.bus.$emit('userInputChange', {
          property: path.replace('$device', this.editedDeviceType),
          value,
        });
      }
    }, 500),
    setValueOf(path, value, forceCss = false, options = {}) {
      this._emitUserInputChange(path, value, options);

      if (this.useNested || this.canUseNested) {
        if (path.includes('data')) {
          this.updateData({ property: path, value });
        } else {
          this.updateStyle({ property: path, value });
        }

        if (forceCss && this.element) {
          this.updateElementStyle(this.element.uid);
        }

        return;
      }
      const realPath = this.getRealPath(path);
      const isGlobalStyle = this.isGlobalStyle(path);
      const isPageStyle = this.isPageStyle(path);
      const isStatePath = this.isStatePath(path);

      if (isGlobalStyle) {
        setWith(this.global, realPath, value, (ns, key, target) => {
          this.$set(target, key, ns);
        });
        return;
      }
      if (isStatePath) {
        setWith(this.$store.state, realPath, value, (ns, key, target) => {
          this.$set(target, key, ns);
        });
        return;
      }
      if (isPageStyle) {
        setWith(this.page, realPath, value, (ns, key, target) => {
          this.$set(target, key, ns);
        });
        this.updateElementStyle(this.page.uid);

        return;
      }

      setWith(this.element, realPath, value, (ns, key, target) => {
        this.$set(target, key, ns);
      });

      if (!isGlobalStyle && (!this.isDataPath(path) || forceCss)) {
        this.updateElementStyle(this.element.uid);
      }
    },
    smartSetValueOf(path, value) {
      const ctx = this.element || this.column || this.block || this.page;

      if (!ctx) {
        if (this.isEditor) throw new Error('No context');
        return;
      }
      const replacedPath = path.replace('$device', this.editedDeviceType);

      setWith(ctx, replacedPath, value, (ns, key, target) => {
        this.$set(target, key, ns);
      });

      this._emitUserInputChange(path, value);
      this.updateElementStyle(ctx.uid);
    },
    updateElementStyle(uid) {
      this.$bus.$emit('updateElementStyle', uid);
    },
  },
};
