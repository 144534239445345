<template lang="pug">
.step-field-mapping
  loading-logo(v-if="loading")
  template(v-else)
    .title {{ `${$t('integrationFlow.integrationModal.general.fieldMappingAltTitle', { type: 'Listamester' })}` }}
    .d-flex.fields-wrapper.w-100
      om-toast.mb-6(v-if="!validByAlert.fields" color="danger" :closable="false" type="side") {{ $t('integrationFlow.integrationModal.general.checkFields', { integration: 'Listamester' }) }}
      om-toast.mb-6(
        v-if="reqFields.length"
        :color="missingRequiredFields.length ? 'danger' : 'warning'"
        :closable="false"
        type="side"
      )
        div(v-html="requiredFieldsMessage")
      .d-flex.fields-title
        .om-fields-header {{ $t('integrationFlow.integrationModal.general.omInputFields') }}
        .arrow-block
        .integration-fields-header {{ $t('integrationFlow.integrationModal.general.integrationFields', { type: 'Listamester' }) }}
      .d-flex.fields(v-for="(binding, index) in formBindings")
        binding-row.w-100.justify-content-between(
          :isNewFlow="true"
          :binding="binding"
          :index="index"
          :fields="fields"
          :type="settings.type"
        )
    .d-flex.additional-data
      .d-flex.additional-data-row
        label.d-flex(for="additionalDataCheckbox")
          input#additionalDataCheckbox(type="checkbox" v-model="checkboxValue")
          .additional-data-text {{ $t('integrationFlow.integrationModal.general.additionalData') }}
      .d-flex.additional-data-row
        .additional-data-helper {{ $t('integrationFlow.integrationModal.general.additionalDataHelper') }}
    transition(name="fade")
      .d-flex.other-fields(v-if="checkboxValue")
        .d-flex.fields-wrapper.w-100
          .d-flex.fields-title
            .om-fields-header {{ $t('integrationFlow.integrationModal.general.customValue') }}
            .arrow-block
            .integration-fields-header {{ $t('integrationFlow.integrationModal.general.integrationFields', { type: 'Listamester' }) }}
          .d-flex.fields(v-for="(binding, index) in campaignDataBindings")
            .om-campaign-additional-fields.w-100
              binding-row.w-100.justify-content-between(
                :isNewFlow="true"
                :binding="binding"
                :index="index"
                :fields="fields"
                :type="settings.type"
              )
          .d-flex.fields(
            v-if="staticBindings.length > 0"
            v-for="(binding, index) in staticBindings"
          )
            .om-campaign-static-fields.w-100
              binding-row.w-100.justify-content-between(
                :isNewFlow="true"
                :binding="binding"
                :index="index"
                :fields="fields"
                :type="settings.type"
                @removeStaticRow="deleteStaticField(index)"
              )
          .add-fields
            .d-flex.cursor-pointer(@click="addStaticField")
              om-button(icon="plusCircle" iconSize="1.715em" iconOnly style="color: #ed5a29")
              .font-size-0--875.font-weight-semibold.line-height-1.align-self-center {{ $t('integrationFlow.integrationModal.general.integrationFields', { type: 'Listamester' }) }}
</template>
<script>
  import BindingRow from '@/components/IntegrationBindings/Row.vue';
  import { UilArrowRight } from '@iconscout/vue-unicons';
  import bindingMixin from '@/mixins/integration/binding';
  import { IntegrationService } from '@/services/integrations/integrationService';

  export default {
    components: {
      UilArrowRight,
      BindingRow,
    },

    mixins: [bindingMixin],

    props: {
      validByAlert: {
        type: Object,
        required: true,
      },
    },

    data: () => ({
      loading: false,
      fields: [],
      reqFields: [],
      missingRequiredFields: [],
    }),

    computed: {
      requiredFieldsMessage() {
        return `${this.$t('integrationBindingErrorMessages.requiredFieldsInList', {
          integration: 'Listamester',
          fieldList: this.requiredFieldNames.map((field) => this.$t(field)).join(', '),
        })}`;
      },
      requiredFieldNames() {
        return this.reqFields.map((reqField) => {
          return this.fields.find((field) => field.id === reqField).name;
        });
      },
    },

    async mounted() {
      this.$on('isBindingModified', (value) => this.$emit('isModified', value));

      this.loading = true;

      const integrationService = new IntegrationService(this.$apollo);

      const fields = await integrationService.fetchIntegrationFields(
        this.settings.type,
        this.settings.id,
        this.settings.convertedSettings.listId,
      );

      this.fields = fields.map((field) => ({ id: field.id, name: field.name }));
      this.reqFields = fields.filter(({ required }) => required === true).map(({ id }) => id);

      this.loading = false;
    },

    methods: {
      validateBindings() {
        const assignedFields = [];
        this.settings.bindings.forEach((binding) => {
          if (binding.externalId) assignedFields.push(binding.externalId);
        });

        const isValid = this.reqFields.every((reqField) => assignedFields.includes(reqField));

        if (!isValid) {
          this.missingRequiredFields = [...this.requiredFieldNames];
        }

        return isValid;
      },
    },
  };
</script>
