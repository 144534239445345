import { get } from 'lodash-es';
import { mapGetters } from 'vuex';
import { track } from '@/services/xray';
import { lsStore } from '@/helpers/localStorage';
import {
  RECOMMENDATION,
  BRAND_KIT,
  SIMILAR_TEMPLATES,
  getSmartWizardFlow,
  getSmartWizardStepsOrder,
} from './flow';
import sharedMixin from './shared';
import abTestMixin from './abTest';
import { LS_WIZARD_PATH } from './consts';

export default {
  mixins: [sharedMixin, abTestMixin],
  data() {
    return {
      order: null,
      step: '',
      flow: null,
    };
  },

  computed: {
    ...mapGetters(['databaseId', 'wizardPreferences']),
    stepIndex() {
      if (this.step === SIMILAR_TEMPLATES) {
        return this.order.findIndex((o) => o === RECOMMENDATION);
      }

      return this.order.findIndex((o) => o === this.step);
    },
    reachedUpgradeFlowEnd() {
      return this.step === BRAND_KIT;
    },

    isOnboarding() {
      return this.$route.fullPath.includes('/onboarding');
    },
  },

  created() {
    this.validateNavigation();
  },

  methods: {
    async next(answer = null, queryMods = {}) {
      const nextStep = answer ? this.flow[this.step][answer] : this.flow[this.step];
      if (answer) {
        track('wizard-option-next', { step: this.step, answer });
      }
      if (nextStep) {
        const query = this.addToQueryParams(answer);
        await this.continueFlow({ nextStep, query, queryMods });
      } else {
        console.error(`No next step`);
      }
    },

    getLatestMainColor() {
      const params = new URLSearchParams(window.location.search);
      return get(this.wizardPreferences, 'color', decodeURIComponent(params.get('color')));
    },

    async continueFlow(params) {
      if (this.reachedUpgradeFlowEnd) {
        const { query } = params;
        query.color = this.getLatestMainColor();
        const inputs = this.getWizardPreferences(query);
        await this.updateWizardPreferences(inputs);
        params.nextStep = RECOMMENDATION;
      }
      this.showNextStep(params);
    },

    showNextStep({ nextStep, query, queryMods }) {
      this.$router.push({
        name: this.$route.name,
        params: { ...this.$route.params, step: nextStep },
        query: {
          ...query,
          ...queryMods,
        },
      });
    },

    saveOnboardingStage(fullPath) {
      lsStore(LS_WIZARD_PATH(this.databaseId), fullPath);
    },

    validateNavigation() {
      const {
        fullPath,
        params: { step },
      } = this.$route;
      this.order = getSmartWizardStepsOrder(this.isOriginalFlow);
      const isValidStep = this.order.includes(step);
      this.step = isValidStep ? step : 'colorBrandkit';
      this.flow = getSmartWizardFlow(this.isOriginalFlow);
      this.order = getSmartWizardStepsOrder(this.isOriginalFlow);
      this.saveOnboardingStage(fullPath);
    },

    addToQueryParams(answer) {
      const params = new URLSearchParams(window.location.search);
      const query = { ...this.$route.query };
      params.forEach((v, k) => {
        if (!query[k]) {
          query[k] = encodeURIComponent(v);
        }
      });

      if (answer) {
        query[this.step] = answer;
      }

      if (this.step.includes('color')) {
        query.color = this.color;
      }

      return query;
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
