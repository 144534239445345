<template lang="pug">
.alert.alert-danger.text-center.mb-0.p-0(role="alert")
  .box-center.d-flex.justify-content-center
    .icon
      UilExclamationTriangle
    .text
      template(v-if="priceChange2024")
        span {{ $t('priceChangeAlert.shopify2024.line1') }}
      template(v-else)
        span {{ $t('priceChangeAlert.shopify.line1') }}
    .button
      om-button(primary :loading="isActionInProgress" @click="redirectToPlanSettings") {{ $t('priceChangeAlert.shopify.redirect') }}
</template>

<script>
  import { createNamespacedHelpers } from 'vuex';
  import { UilExclamationTriangle } from '@iconscout/vue-unicons';
  import { isInPriceChange2024 } from '@/config/plans';

  const { mapGetters: paymentGetters } = createNamespacedHelpers('payment');

  export default {
    components: {
      UilExclamationTriangle,
    },
    computed: {
      ...paymentGetters(['getCurrentSku']),
      isActionInProgress() {
        if (this.$route.query.updateRecurringPlan) return true;
        return false;
      },
      priceChange2024() {
        return isInPriceChange2024(this.getCurrentSku);
      },
    },
    methods: {
      redirectToPlanSettings() {
        this.$router.replace({
          name: 'plan_settings',
          query: { updateRecurringPlan: true },
        });
      },
    },
  };
</script>
